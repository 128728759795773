import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Invoice from '../../shared/components/orders/Invoice';
// import OrderCmp from '../../shared/components/orders/order-cmp';
import { connect } from 'react-redux';
import {
  getOneOrder
} from "../../redux/actions/ordersActions";

class ViewOrderPage extends PureComponent {

  constructor() {
    super();
    this.state = {
      order_id: ''
    };
  }

  componentDidMount() {
    const { match, getOneOrder } = this.props

    this.setState({ order_id: (match && match.params) ? match.params.orderId : '' })
    if (match && match.params) getOneOrder({ order_id: match.params.orderId })
  }


  render() {
    return (<Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Commande</h3>
        </Col>
        <Col md={12}>
          {this.props.selected_order ? <Invoice 
            match={this.props.match} 
            order={this.props.selected_order} 
            shipping_real_price={this.props.selected_order ? this.props.selected_order.shipping_real_price : 0} 
            history={this.props.history} 
          /> : "chargement.."}
          {/* <NavLink to={"/app/orders/edit/" + this.state.order_id}>
            Modifier
          </NavLink> */}
          {/* <OrderCmp editing={true} match={this.props.match} order_id={this.state.order_id} history={this.props.history}/> */}
        </Col>
      </Row>
    </Container>)
  }
}


const mapStateToProps = ({ order }) => {
  const {
    selected_order, loading_one_order, error_one_order,
  } = order
  return {
    selected_order, loading_one_order, error_one_order
  }
}
export default connect(mapStateToProps, {
  getOneOrder
})(ViewOrderPage);