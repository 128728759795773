import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ListFollowsPage from './list-follows-page';
// import List from './list';

const TrainingsRoutingPages = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListFollowsPage {...props} />}
      />

      {/* <Route path={`${match.url}/listing`}
        render={props => <List {...props} />}
      /> */}



      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
      <Redirect to="/error" />
    </Switch>

  </Suspense>
);
export default TrainingsRoutingPages;
