import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ListOrdersPage from "./list-orders-page";
import ListCanceledOrdersPage from "./list-orders-canceled-page";
import AddOrderPage from "./add-order-page";
import EditOrderPage from "./edit-order-page";
import ViewOrderPage from "./view-order-page";

const OrdersRoutingPages = ({ match }) => (
	<Suspense fallback={<div className="loading" />}>
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

			<Route path={`${match.url}/list`} render={(props) => <ListOrdersPage {...props} />} />

			<Route path={`${match.url}/add-new`} render={(props) => <AddOrderPage {...props} />} />

			<Route path={`${match.url}/edit/:orderId`} render={(props) => <EditOrderPage {...props} />} />

			<Route path={`${match.url}/view/:orderId`} render={(props) => <ViewOrderPage {...props} />} />

			<Route path={`${match.url}/canceled`} render={(props) => <ListCanceledOrdersPage {...props} />} />

			{/* <Route path={`${match.url}/training`} exact
        render={props => <ListOrdersPage {...props} order_trainings={true} />}
      /> */}

			{/* <Route path={`${match.url}/training/add-new`} exact
        render={props => <AddOrderPage {...props} order_trainings={true} />}
      /> */}
			{/* /:isTrainingOrder */}
			{/* <Route path={`${match.url}/training/add-new`} exact
        render={props => <AddOrderPage {...props} order_trainings={true} />}
        // render={props => <p  {...props} order_trainings={true}>
        //   ==> {props.order_trainings}
        // </p>}
      /> */}

			{/* <Route path={`${match.url}/training/edit/:orderId`} exact
        render={props => <EditOrderPage {...props} order_trainings={true} />}
      />
      <Route path={`${match.url}/training/view/:orderId`} exact
        render={props => <ViewOrderPage {...props} order_trainings={true} />}
      /> */}

			<Redirect from={`${match.url}/`} to={`${match.url}/list`} />
			<Redirect to="/error" />
		</Switch>
	</Suspense>
);
export default OrdersRoutingPages;
