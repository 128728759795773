
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_CHILDS, getChildsSuccess, getChildsError, 
  ADD_CHILD, addChildError, addChildSuccess, 
  GET_ONE_CHILD, getOneChildSuccess, getOneChildError, 
  EDIT_CHILD, editChildError, editChildSuccess,

  DELETE_CHILD, deleteChildError, deleteChildSuccess,
} from '../actions/childsActions';
import { api } from '../../const';



// *** GET CHILDS
export function* watchGetChilds() { yield takeEvery(GET_CHILDS, getChilds) }
function* getChilds({ payload }) {
  try {
    const ret = yield call(getChildsRequest, payload);

    if (ret && ret.status == 200) yield put(getChildsSuccess({ childs: ret.data?.childs || [], count: ret.data?.count || 0}));
    else yield put(getChildsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getChildsError({ error, message: (error) ? error.message : '' }));
  }
}
const getChildsRequest = async (payload) => {  
  return await axios.post(api.childs+'/filter/'+payload.offset+'/'+payload.limit, payload.filter || {})
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// // *** GET ONE CHILD
export function* watchGetOneChild() { yield takeEvery(GET_ONE_CHILD, getOneChild) }
function* getOneChild({ payload }) {
  try {
    const ret = yield call(getOneChildRequest, payload);

    if (ret && ret.status == 200) yield put(getOneChildSuccess({ child: ret.data?.child || null }));
    else yield put(getOneChildError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneChildError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneChildRequest = async (payload) => {  
  return await axios.get(api.childs+"/get-one/"+payload.child_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD CHILDS
export function* watchAddChilds() { yield takeEvery(ADD_CHILD, addChild) }
function* addChild({ payload }) {
  try {
    const ret = yield call(addChildsRequest, payload);

    if (ret && ret.status == 201) yield put(addChildSuccess({ child: ret.data.child }));
    else yield put(addChildError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addChildError({ error, message: (error) ? error.message : '' }));
  }
}
const addChildsRequest = async (payload) => {  
  return await axios.post(api.childs+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT CHILDS
export function* watchEditChild() { yield takeEvery(EDIT_CHILD, editChild) }
function* editChild({ payload }) {
  try {
    const ret = yield call(editChildsRequest, payload);

    if (ret && ret.status == 200) yield put(editChildSuccess({ child: ret.data.child }));
    else yield put(editChildError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editChildError({ error, message: (error) ? error.message : '' }));
  }
}
const editChildsRequest = async (payload) => {  
  return await axios.patch(api.childs+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE CHILDS
export function* watchDeleteChild() { yield takeEvery(DELETE_CHILD, deleteChild) }
function* deleteChild({ payload }) {
  try {
    const ret = yield call(deleteChildsRequest, payload);

    if (ret && ret.status == 200) yield put(deleteChildSuccess({ }));
    else yield put(deleteChildError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteChildError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteChildsRequest = async (payload) => {  
  return await axios.delete(api.childs+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetChilds),
    fork(watchGetOneChild),
    fork(watchAddChilds),
    fork(watchEditChild),
    fork(watchDeleteChild),
  ]);
}