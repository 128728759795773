// ***** ADD ORDER
export const ADD_ORDER            = 'ADD_ORDER';
export const ADD_ORDER_SUCCESS    = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_ERROR      = 'ADD_ORDER_ERROR';

export function addOrder(payload) {
  return {type: ADD_ORDER, payload};
}
export function addOrderSuccess(payload) {
  return { type: ADD_ORDER_SUCCESS, payload };
}
export function addOrderError(error) {
  return { type: ADD_ORDER_ERROR, error };
}




// ***** GET ORDERS
export const GET_ORDERS            = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS    = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR      = 'GET_ORDERS_ERROR';

export function getOrders(payload) {
  return {type: GET_ORDERS, payload};
}
export function getOrdersSuccess(payload) {
  return { type: GET_ORDERS_SUCCESS, payload };
}
export function getOrdersError(error) {
  return { type: GET_ORDERS_ERROR, error };
}





// ***** GET ONE ORDER
export const GET_ONE_ORDER            = 'GET_ONE_ORDER';
export const GET_ONE_ORDER_SUCCESS    = 'GET_ONE_ORDER_SUCCESS';
export const GET_ONE_ORDER_ERROR      = 'GET_ONE_ORDER_ERROR';

export function getOneOrder(payload) {
  return {type: GET_ONE_ORDER, payload};
}
export function getOneOrderSuccess(payload) {
  return { type: GET_ONE_ORDER_SUCCESS, payload };
}
export function getOneOrderError(error) {
  return { type: GET_ONE_ORDER_ERROR, error };
}



// ***** EDIT ORDER
export const EDIT_ORDER            = 'EDIT_ORDER';
export const EDIT_ORDER_SUCCESS    = 'EDIT_ORDER_SUCCESS';
export const EDIT_ORDER_ERROR      = 'EDIT_ORDER_ERROR';

export function editOrder(payload) {
  return {type: EDIT_ORDER, payload};
}
export function editOrderSuccess(payload) {
  return { type: EDIT_ORDER_SUCCESS, payload };
}
export function editOrderError(error) {
  return { type: EDIT_ORDER_ERROR, error };
}


// ***** DELETE ORDER
export const DELETE_ORDER            = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS    = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_ERROR      = 'DELETE_ORDER_ERROR';

export function deleteOrder(payload) {
  return {type: DELETE_ORDER, payload};
}
export function deleteOrderSuccess(payload) {
  return { type: DELETE_ORDER_SUCCESS, payload };
}
export function deleteOrderError(error) {
  return { type: DELETE_ORDER_ERROR, error };
}