// ***** ADD TRAINING
export const ADD_TRAINING            = 'ADD_TRAINING';
export const ADD_TRAINING_SUCCESS    = 'ADD_TRAINING_SUCCESS';
export const ADD_TRAINING_ERROR      = 'ADD_TRAINING_ERROR';

export function addTraining(payload) {
  return {type: ADD_TRAINING, payload};
}
export function addTrainingSuccess(payload) {
  return { type: ADD_TRAINING_SUCCESS, payload };
}
export function addTrainingError(error) {
  return { type: ADD_TRAINING_ERROR, error };
}




// ***** GET TRAININGS
export const GET_TRAININGS            = 'GET_TRAININGS';
export const GET_TRAININGS_SUCCESS    = 'GET_TRAININGS_SUCCESS';
export const GET_TRAININGS_ERROR      = 'GET_TRAININGS_ERROR';

export function getTrainings(payload) {
  return {type: GET_TRAININGS, payload};
}
export function getTrainingsSuccess(payload) {
  return { type: GET_TRAININGS_SUCCESS, payload };
}
export function getTrainingsError(error) {
  return { type: GET_TRAININGS_ERROR, error };
}





// ***** GET ONE TRAINING
export const GET_ONE_TRAINING            = 'GET_ONE_TRAINING';
export const GET_ONE_TRAINING_SUCCESS    = 'GET_ONE_TRAINING_SUCCESS';
export const GET_ONE_TRAINING_ERROR      = 'GET_ONE_TRAINING_ERROR';

export function getOneTraining(payload) {
  return {type: GET_ONE_TRAINING, payload};
}
export function getOneTrainingSuccess(payload) {
  return { type: GET_ONE_TRAINING_SUCCESS, payload };
}
export function getOneTrainingError(error) {
  return { type: GET_ONE_TRAINING_ERROR, error };
}



// ***** EDIT TRAINING
export const EDIT_TRAINING            = 'EDIT_TRAINING';
export const EDIT_TRAINING_SUCCESS    = 'EDIT_TRAINING_SUCCESS';
export const EDIT_TRAINING_ERROR      = 'EDIT_TRAINING_ERROR';

export function editTraining(payload) {
  return {type: EDIT_TRAINING, payload};
}
export function editTrainingSuccess(payload) {
  return { type: EDIT_TRAINING_SUCCESS, payload };
}
export function editTrainingError(error) {
  return { type: EDIT_TRAINING_ERROR, error };
}


// ***** DELETE TRAINING
export const DELETE_TRAINING            = 'DELETE_TRAINING';
export const DELETE_TRAINING_SUCCESS    = 'DELETE_TRAINING_SUCCESS';
export const DELETE_TRAINING_ERROR      = 'DELETE_TRAINING_ERROR';

export function deleteTraining(payload) {
  return {type: DELETE_TRAINING, payload};
}
export function deleteTrainingSuccess(payload) {
  return { type: DELETE_TRAINING_SUCCESS, payload };
}
export function deleteTrainingError(error) {
  return { type: DELETE_TRAINING_ERROR, error };
}