import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Table, Input, Label } from "reactstrap";

import "./edit-level-table.scss";

const EditLevelTable = (props) => {
	const { level, onChange, read_only } = props;

	const [index, setIndex] = useState(1);
	const [sub_index, setSubIndex] = useState(1);

	useEffect(() => {
		if (level) {
			setIndex(level.index || 1);
			setSubIndex(level.sub_index || 1);
		}
	}, [level]);

	return (
		<React.Fragment>
			<Table responsive striped className="tbl-edit-level">
				<thead>
					<tr>
						<th colSpan={4}>Niveau 1</th>
						<th colSpan={2}>Niveau 2</th>
						<th>Niveau 3</th>
						<th>Niveau 4</th>
						<th>Niveau 5</th>
						<th>Niveau 6</th>
					</tr>
				</thead>
				<tbody className={read_only ? "read_only" : ""}>
					<tr>
						<td className={index === 1 && sub_index === 1 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 1 && sub_index === 1}
									type="radio"
									name="level_sub_index"
									id="sub_level_1"
									value={1}
									onChange={(e) => onChange({ index: 1, sub_index: 1 })}
								/>
							)}
							<Label htmlFor="sub_level_1">M1</Label>
						</td>
						<td className={index === 1 && sub_index === 2 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 1 && sub_index === 2}
									type="radio"
									name="level_sub_index"
									id="sub_level_2"
									value={2}
									onChange={(e) => onChange({ index: 1, sub_index: 2 })}
								/>
							)}
							<Label htmlFor="sub_level_2">M2</Label>
						</td>
						<td className={index === 1 && sub_index === 3 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 1 && sub_index === 3}
									type="radio"
									name="level_sub_index"
									id="sub_level_3"
									value={3}
									onChange={(e) => onChange({ index: 1, sub_index: 3 })}
								/>
							)}
							<Label htmlFor="sub_level_3">C1</Label>
						</td>
						<td className={index === 1 && sub_index === 4 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 1 && sub_index === 4}
									type="radio"
									name="level_sub_index"
									id="sub_level_4"
									value={4}
									onChange={(e) => onChange({ index: 1, sub_index: 4 })}
								/>
							)}
							<Label htmlFor="sub_level_4">S1</Label>
						</td>

						{/* LEVEL2 */}
						<td className={index === 2 && sub_index === 1 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 2 && sub_index === 1}
									type="radio"
									name="level_sub_index"
									id="sub_level_5"
									value={5}
									onChange={(e) => onChange({ index: 2, sub_index: 1 })}
								/>
							)}
							<Label htmlFor="sub_level_5">C2</Label>
						</td>
						<td className={index === 2 && sub_index === 2 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 2 && sub_index === 2}
									type="radio"
									name="level_sub_index"
									id="sub_level_6"
									value={6}
									onChange={(e) => onChange({ index: 2, sub_index: 2 })}
								/>
							)}
							<Label htmlFor="sub_level_6">S2</Label>
						</td>

						{/* SINGLE LEVELS */}
						<td className={index === 3 && sub_index === 1 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 3 && sub_index === 1}
									type="radio"
									name="level_sub_index"
									id="sub_level_7"
									value={7}
									onChange={(e) => onChange({ index: 3, sub_index: 1 })}
								/>
							)}
							<Label htmlFor="sub_level_7">S3</Label>
						</td>
						<td className={index === 4 && sub_index === 1 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 4 && sub_index === 1}
									type="radio"
									name="level_sub_index"
									id="sub_level_8"
									value={8}
									onChange={(e) => onChange({ index: 4, sub_index: 1 })}
								/>
							)}
							<Label htmlFor="sub_level_8">S4</Label>
						</td>
						<td className={index === 5 && sub_index === 1 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 5 && sub_index === 1}
									type="radio"
									name="level_sub_index"
									id="sub_level_9"
									value={9}
									onChange={(e) => onChange({ index: 5, sub_index: 1 })}
								/>
							)}
							<Label htmlFor="sub_level_9">S5</Label>
						</td>
						<td className={index === 6 && sub_index === 1 ? "active" : ""}>
							{!read_only && (
								<Input
									checked={index === 6 && sub_index === 1}
									type="radio"
									name="level_sub_index"
									id="sub_level_10"
									value={10}
									onChange={(e) => onChange({ index: 6, sub_index: 1 })}
								/>
							)}
							<Label htmlFor="sub_level_10">S6</Label>
						</td>
					</tr>
				</tbody>
			</Table>
		</React.Fragment>
	);
};

EditLevelTable.prototype = {
	onChange: PropTypes.func.isRequired,

	read_only: PropTypes.bool,

	level: PropTypes.objectOf({
		index: PropTypes.number,
		sub_index: PropTypes.number,
	}),
};
export default EditLevelTable;
