import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListTrainingsCmp from '../../shared/components/trainings/list-trainings-cmp';

const ListTrainingsPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Formations</h3>
      </Col>

      <Col md={12}>
        <ListTrainingsCmp />
      </Col>
    </Row>
  </Container>
);

export default ListTrainingsPage;