import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { NavLink as NavLinkRouter } from "react-router-dom";

import {
	Button,
	ButtonToolbar,
	Card,
	CardBody,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Table,
	Input,
} from "reactstrap";
// import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";

import { addArticle, editArticle, getOneArticle } from "../../../redux/actions/articlesActions";

import Axios from "axios";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import classnames from "classnames";
import SelectCtgMdl from "../ctgs/select-ctg-mdl";
import VariantsCmp from "./Variants-cmp";
import { api } from "../../../const";
import SelectArticle from "./SelectArticle";

import { quillFormats, quillModules } from "../../helper/utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import EditLevelTable from "./edit-level-table/EditLevelTable";

const pub_url = process.env.PUBLIC_URL;

class ArticleCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			name: "",
			ref: "",
			price: 0,
			price_purchase: 0,
			is_educational_support: false,
			is_first_educ_support: false,
			is_bag_cm: false,
			short_desc: "",
			qty: 0,

			is_pack: false,
			is_internal: false,
			is_supply: false,

			category: null,

			img: null,
			img_url: "",

			show_select_ctg_mdl: false,

			active_tab: "desc",

			variants: [],

			gallery_1: null,
			gallery_1_url: "",

			gallery_2: null,
			gallery_2_url: "",

			gallery_3: null,
			gallery_3_url: "",

			gallery_4: null,
			gallery_4_url: "",

			articles: [],
			show_select_prd: false,
			products: [],

			error_frm: "",
			privilege: 0,

			level_index: 1,
			level_sub_index: 1,
		};
	}

	toggleSelectCtgMdl = () =>
		this.setState((prev) => {
			return { show_select_ctg_mdl: !prev.show_select_ctg_mdl };
		});

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	changeImg = (e, name = "img") => {
		try {
			const img = e.target.files[0];
			const obj = {};
			obj[name] = img;
			obj[name + "_url"] = URL.createObjectURL(img);

			this.setState(obj);
		} catch (error) {
			// this.setState({ img: null, img_url: '' })
		}
	};

	toggle = (active_tab) => this.setState({ active_tab });

	submit = () => {
		const error_frm = this.checkFrm(this.state);
		this.setState({ error_frm });

		const { addArticle, editArticle, editing } = this.props;
		const { articles } = this.state;

		if (error_frm == "") {
			const frm = new FormData();
			frm.append("name", this.state.name);
			frm.append("ref", this.state.ref || "");
			frm.append("price", this.state.price);
			frm.append("price_purchase", this.state.price_purchase || 0);
			frm.append("desc", this.state.desc || "");
			frm.append("qty", this.state.qty);
			frm.append("privilege", this.state.privilege || 0);

			if (this.state.category) frm.append("category", this.state.category._id);

			frm.append("short_desc", this.state.short_desc || "");

			if (this.state.img && this.state.img_url != "") frm.append("img", this.state.img);

			frm.append("variants", JSON.stringify(this.state.variants));

			if (this.state.is_pack) {
				frm.append("is_pack", true);
			} else frm.append("is_pack", false);

			if (articles.length > 0) {
				const articles_qty = [];
				for (let i = 0; i < articles.length; i++) {
					articles_qty.push({
						qty: articles[i].qty,
						article: articles[i].article._id,
					});
				}

				frm.append("articles", JSON.stringify(articles_qty));
			}

			frm.append("level_index", this.state.level_index || 1);
			frm.append("level_sub_index", this.state.level_sub_index || 1);

			frm.append("is_internal", this.state.is_internal);
			frm.append("is_supply", this.state.is_supply);
			frm.append("is_educational_support", this.state.is_educational_support);
			frm.append("is_first_educ_support", this.state.is_first_educ_support);
			frm.append("is_bag_cm", this.state.is_bag_cm);

			if (this.state.gallery_1 && this.state.gallery_1_url != "") frm.append("gallery_1", this.state.gallery_1);

			if (this.state.gallery_2 && this.state.gallery_2_url != "") frm.append("gallery_2", this.state.gallery_2);

			if (this.state.gallery_3 && this.state.gallery_3_url != "") frm.append("gallery_3", this.state.gallery_3);

			if (this.state.gallery_4 && this.state.gallery_4_url != "") frm.append("gallery_4", this.state.gallery_4);

			if (editing) editArticle({ _id: this.props.article_id, data: frm });
			else addArticle(frm);
		} else window.scrollTo(0, 0);
	};
	checkFrm = (data) => {
		const { name, category, short_desc, desc, price } = data;
		if (name == "") return "Le nom est requis.";
		if (!category) return "Le catégorie est requis.";
		if (short_desc == "") return "Déscription courte est requise.";
		if (desc == "") return "Déscription est requise.";
		if (price == 0 && !this.state.is_supply) return "Prix est requis.";
		return "";
	};

	componentDidUpdate(prev) {
		const {
			loading_add_article,
			error_add_article,
			new_article,
			selected_article,
			loading_one_article,
			error_one_article,
			loading_edit_article,
			error_edit_article,
		} = this.props;

		if (!loading_add_article && prev.loading_add_article) {
			// console.log("error_add_article", error_add_article, new_article);
			if (error_add_article) {
				if (error_add_article.message && error_add_article.message != "")
					this.setState({ error_frm: error_add_article.message });
			} else this.props.history.push("/app/articles/edit/" + new_article._id);
		}

		// EDITING
		if (!loading_edit_article && prev.loading_edit_article) {
			// console.log("error_add_article", error_add_article, new_article);
			if (error_edit_article) {
				if (error_edit_article.message && error_edit_article.message != "")
					this.setState({ error_frm: error_edit_article.message });
			} else this.props.history.push("/app/articles/articles");
		}

		//GET ONE
		if (prev.loading_one_article && !loading_one_article && !error_one_article) {
			if (selected_article) {
				// console.log("selected_article", selected_article);
				// this.setState({...selected_article})
				//category: null,

				const {
					price,
					price_purchase,
					name,
					ref,
					short_desc,
					desc,
					is_pack,
					is_internal,
					is_supply,
					qty,
					variants,
					category,
					img,
					gallery_1,
					gallery_2,
					gallery_3,
					gallery_4,
					articles,
					privilege,
					level,
					is_educational_support,
					is_first_educ_support,
					is_bag_cm,
				} = selected_article;

				this.setState({
					price,
					price_purchase,
					name,
					ref,
					short_desc,
					desc,
					is_pack,
					is_educational_support,
					is_first_educ_support,
					is_bag_cm,
					is_internal,
					is_supply,
					qty,
					variants,
					category,
					img,
					img_url: "",

					gallery_1,
					gallery_1_url: "",
					gallery_2,
					gallery_2_url: "",
					gallery_3,
					gallery_3_url: "",
					gallery_4,
					gallery_4_url: "",

					articles,
					privilege,
					products: [],

					level_index: level?.index || 1,
					level_sub_index: level?.sub_index || 1,
				});
			}
		}
	}

	componentDidMount() {
		if (this.props.editing) {
			const { match, getOneArticle } = this.props;
			if (match && match.params) getOneArticle({ article_id: match.params.articleId });
		} else
			Axios.get(`${api.articles}/next-ref`).then((resp) => {
				if (resp && resp.data) this.setState({ ref: resp.data.ref || "" });
			});
	}

	addVariant = () => {
		this.setState((prev) => ({
			variants: [...prev.variants, { name: "Variant " + (prev.variants.length + 1), options: [""] }],
		}));
	};
	deleteVariant = (i = 0) => {
		this.setState((prev) => {
			const variants = prev.variants;
			if (variants[i]) variants.splice(i, 1);
			return {
				variants: [...variants],
			};
		});
	};
	changeVariantName = (name, i = 0) => {
		this.setState((prev) => {
			const variants = prev.variants;
			if (variants[i]) variants[i]["name"] = name;
			return {
				variants: [...variants],
			};
		});
	};
	addVariantOption = (i) => {
		this.setState((prev) => {
			const variants = prev.variants;
			if (variants[i]) variants[i]["options"].push("");
			return {
				variants: [...variants],
			};
		});
	};
	deleteVariantOption = (i, j) => {
		try {
			this.setState((prev) => {
				const variants = prev.variants;
				variants[i].options.splice(j, 1);
				return { variants: [...variants] };
			});
		} catch (error) {}
	};

	changeVariantOptionName = (val, i = 0, j = 0) => {
		try {
			this.setState((prev) => {
				const variants = prev.variants;
				variants[i].options[j] = val;
				return { variants: [...variants] };
			});
		} catch (error) {}
	};

	toggleAddProduct = () =>
		this.setState((prevState) => ({
			show_select_prd: !prevState.show_select_prd,
		}));
	handleRmProduct = (_id) => {
		this.setState((prev) => {
			const { products, articles } = prev;
			for (let i = 0; i < products.length; i++) {
				if (products[i]?._id == _id) {
					products.splice(i, 1);
					break;
				}
			}
			for (let i = 0; i < articles.length; i++) {
				if (articles[i].article?._id == _id) {
					articles.splice(i, 1);
					break;
				}
			}
			return { articles: [...articles], products: [...products] };
		});
	};
	changeArticleQty = (i, val) =>
		this.setState((prev) => {
			const articles = prev.articles;
			articles[i].qty = val;
			return { articles: [...articles] };
		});
	addProducts = (data) => {
		if (data && Array.isArray(data)) {
			this.setState((prev) => {
				const articles = prev.articles;
				for (let i = 0; i < data.length; i++) {
					let exist = false;
					for (let j = 0; j < articles.length; j++) {
						if (articles[j].article._id == data[i]._id) {
							exist = true;
							break;
						}
					}
					if (!exist) articles.push({ qty: 1, article: data[i] });
				}
				return { show_select_prd: false, products: data, articles };
			});
		} else this.setState({ show_select_prd: false });
	};

	renderProductImage = (img, img_url, editing) => {
		if (editing) {
			if (img && img_url == "") return <img src={api.root + "/" + img.path} />;
			else if (img && img_url != "") return <img src={img_url} />;

			return <img src={`${pub_url}/img/no_product.png`} />;
		}

		return <img src={!img || img_url == "" ? ` ${pub_url}/img/no_product.png` : img_url} />;
	};

	render() {
		const { editing, user } = this.props;
		const {
			name,
			ref,
			short_desc,
			price,
			price_purchase,
			qty,
			desc,
			img_url,
			img,
			gallery_1,
			gallery_1_url,
			gallery_2,
			gallery_2_url,
			gallery_3,
			gallery_3_url,
			gallery_4,
			gallery_4_url,
			category,
			active_tab,
			show_select_ctg_mdl,
			variants,
			is_pack,
			show_select_prd,
			articles,
			products,
			is_internal,
			is_supply,
			privilege,

			level_index,
			level_sub_index,

			is_educational_support,
			is_first_educ_support,
			is_bag_cm,
		} = this.state;

		// console.log("ARTICLES", articles);

		return (
			<div>
				<Card>
					<CardBody>
						{/* {adding && <div className="card__title">
            <h5 className="bold-text">Ajouter un</h5>
            <h5 className="subhead">Use default button with necessary values of properties
            <span className="red-text"> color</span>,
            <span className="red-text"> outline</span> and
            <span className="red-text"> disabled</span>
            </h5>
          </div>} */}

						<Row className="form">
							<Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
								<Alert
									is_visible={this.state.error_frm != ""}
									color="danger"
									className="alert--colored"
									icon
								>
									<p>
										<span className="bold-text">Attention!</span> {this.state.error_frm}
									</p>
								</Alert>
							</Col>

							<Col md="4">
								<div className="add_img_wrap">
									{this.renderProductImage(img, img_url, editing)}

									<div className="caption">
										<label htmlFor="add_avatar">
											<span className="lnr lnr-picture"></span>
										</label>
										<input
											onChange={(e) => this.changeImg(e)}
											id="add_avatar"
											type="file"
											accept="image/*"
											hidden
										/>
									</div>
								</div>
							</Col>
							<Col md="8">
								<div className="form__form-group">
									<span className="form__form-group-label">Nom</span>
									<div className="form__form-group-field">
										<input
											style={{
												width: "100%",
												border: "none",
												borderBottom: "1px solid #ddd",
											}}
											name="name"
											defaultValue={name}
											component="input"
											placeholder="Nom"
											onChange={(e) => this.changeVal("name", e.target.value)}
										/>
									</div>
								</div>

								<Row>
									<Col md="6">
										<div className="form__form-group">
											<span className="form__form-group-label">Référence</span>
											<div className="form__form-group-field">
												<input
													readOnly={!editing}
													style={{
														width: "100%",
														border: "none",
														borderBottom: "1px solid #ddd",
													}}
													name="ref"
													defaultValue={ref}
													component="input"
													placeholder="#Référence"
													onChange={(e) => this.changeVal("ref", e.target.value)}
												/>
											</div>
										</div>
									</Col>
									<Col md="6">
										<div className="form__form-group">
											<span className="form__form-group-label">Categorie</span>
											<div
												onClick={() => this.setState({ show_select_ctg_mdl: true })}
												className="form__form-group-field"
											>
												<input
													readOnly
													style={{
														border: "none",
														borderBottom: "1px solid #ddd",
													}}
													component="input"
													placeholder="Catégorie"
													value={category ? category.name : ""}
												/>
											</div>
										</div>
									</Col>

									<Col md="12">
										{/* SHORT DESC */}
										<div className="form__form-group">
											<span className="form__form-group-label">Courte desc</span>
											<div className="form__form-group-field">
												<input
													style={{
														width: "100%",
														border: "none",
														borderBottom: "1px solid #ddd",
													}}
													name="short_desc"
													defaultValue={short_desc}
													component="input"
													placeholder="Courte desc"
													onChange={(e) => this.changeVal("short_desc", e.target.value)}
												/>
											</div>
										</div>
									</Col>

									<Col md="6">
										<div className="form__form-group">
											<span className="form__form-group-label">Prix </span>
											<div className="form__form-group-field">
												<input
													style={{
														width: "100%",
														border: "none",
														borderBottom: "1px solid #ddd",
													}}
													name="price"
													value={price}
													type="number"
													component="input"
													placeholder="0"
													onChange={(e) => this.changeVal("price", e.target.value)}
												/>
											</div>
										</div>
									</Col>

									<Col md="6">
										<div className="form__form-group">
											<span className="form__form-group-label">Quantité en stock</span>
											<div className="form__form-group-field">
												<input
													readOnly={is_pack}
													type="number"
													min="0"
													style={{
														border: "none",
														borderBottom: "1px solid #ddd",
													}}
													component="input"
													placeholder="0"
													value={qty}
													onChange={(e) => this.changeVal("qty", e.target.value)}
												/>
											</div>
										</div>
									</Col>
								</Row>
							</Col>
							{!is_pack && user?.roles?.includes("ADMIN") && (
								<Col md="4">
									<div className="form__form-group">
										<span className="form__form-group-label">
											Prix d'achat{" "}
											<span style={{ color: "#bbb" }}>(Facultatif et Temporaire)</span>{" "}
										</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												name="price_purchase"
												value={price_purchase}
												type="number"
												component="input"
												placeholder="0"
												onChange={(e) => this.changeVal("price_purchase", e.target.value)}
											/>
										</div>
									</div>
								</Col>
							)}

							<Col md="4">
								<div className="mt-3">
									<span style={{ marginRight: 8 }}>Fourniture</span>
									<Switch
										className="custom-switch custom-switch-primary"
										checked={is_supply}
										onChange={(supply) => {
											if (supply) {
												this.setState({
													is_pack: false,
													is_bag_cm: false,
													is_educational_support: false,
													is_first_educ_support: false,
													is_internal: false,
													is_supply: true,
												});
											} else this.changeVal("is_supply", false);
										}}
									/>
								</div>
							</Col>
							{is_supply ? null : (
								<>
									<Col md="4">
										<div className="mt-3">
											<span style={{ marginRight: 8 }}>C'est un article composé</span>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={is_pack}
												onChange={(pack) => this.changeVal("is_pack", pack)}
											/>
										</div>
									</Col>
									<Col md="4">
										<div className="mt-3">
											<span style={{ marginRight: 8 }}>C'est un article interne</span>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={is_internal}
												onChange={(internal) => this.changeVal("is_internal", internal)}
											/>
										</div>
									</Col>

									{is_internal ? (
										<Col md="4">
											<div className="form__form-group">
												<span className="form__form-group-label">Privilège (DT)</span>
												<div className="form__form-group-field">
													<input
														style={{
															width: "100%",
															border: "none",
															borderBottom: "1px solid #ddd",
														}}
														name="privilege"
														value={privilege}
														type="number"
														component="input"
														placeholder="0"
														onChange={(e) => this.changeVal("privilege", e.target.value)}
													/>
												</div>
											</div>
										</Col>
									) : null}

									<Col md="4">
										<span style={{ marginRight: 8, marginLeft: 8 }}>Valise CM</span>
										<Switch
											className="custom-switch custom-switch-primary"
											checked={is_bag_cm}
											onChange={(bag_cm) => this.changeVal("is_bag_cm", bag_cm)}
										/>
									</Col>
									<Col md="4">
										<span style={{ marginRight: 8, marginLeft: 8 }}>Support pédagogique</span>
										<Switch
											className="custom-switch custom-switch-primary"
											checked={is_educational_support}
											onChange={(educational_support) =>
												this.changeVal("is_educational_support", educational_support)
											}
										/>
									</Col>
									<Col md="4">
										<span style={{ marginRight: 8, marginLeft: 8 }}>
											Premier support pédagogique
										</span>
										<Switch
											className="custom-switch custom-switch-primary"
											checked={is_first_educ_support}
											onChange={(first_educ_support) =>
												this.changeVal("is_first_educ_support", first_educ_support)
											}
										/>
									</Col>
								</>
							)}
						</Row>

						<div className="tabs mt-5">
							<div className="tabs__wrap">
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({ active: active_tab === "desc" })}
											onClick={() => {
												this.toggle("desc");
											}}
										>
											Description
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active: active_tab === "variants",
											})}
											onClick={() => {
												this.toggle("variants");
											}}
										>
											Variants
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
												active: active_tab === "gallery",
											})}
											onClick={() => {
												this.toggle("gallery");
											}}
										>
											Galerie
										</NavLink>
									</NavItem>

									{is_supply ? null : (
										<NavItem>
											<NavLink
												className={classnames({
													active: active_tab === "level",
												})}
												onClick={() => {
													this.toggle("level");
												}}
											>
												Niveau
											</NavLink>
										</NavItem>
									)}

									{is_pack && (
										<NavItem>
											<NavLink
												className={classnames({
													active: active_tab === "pack",
												})}
												onClick={() => {
													this.toggle("pack");
												}}
											>
												Articles
											</NavLink>
										</NavItem>
									)}

									{editing && (
										<NavItem>
											<NavLink
												className={classnames({
													active: active_tab === "stock",
												})}
												onClick={() => {
													this.toggle("stock");
												}}
											>
												Stock
											</NavLink>
										</NavItem>
									)}
								</Nav>

								<TabContent activeTab={active_tab} style={{ width: "100%", minHeight: 200 }}>
									{/* DESCRIPTION */}
									<TabPane tabId="desc">
										<ReactQuill
											style={{ minHeight: 200 }}
											value={desc || ""}
											onChange={(val) => this.changeVal("desc", val)}
											modules={quillModules}
											formats={quillFormats}
										/>
									</TabPane>

									{/* VARIANTS */}
									<TabPane tabId="variants">
										<VariantsCmp
											data={variants}
											addVariant={this.addVariant}
											deleteVariant={this.deleteVariant}
											changeVariantName={this.changeVariantName}
											addVariantOption={this.addVariantOption}
											deleteVariantOption={this.deleteVariantOption}
											changeVariantOptionName={this.changeVariantOptionName}
										/>
									</TabPane>

									{/* GALLERY TAB */}
									<TabPane tabId="gallery">
										<Row style={{ width: "100%", overflow: "hidden" }}>
											<Col md="4">
												<div className="add_img_wrap mb-2">
													{this.renderProductImage(gallery_1, gallery_1_url, editing)}

													<div className="caption">
														<label htmlFor="add_gallery_1">
															<span className="lnr lnr-picture"></span>
														</label>
														<input
															onChange={(e) => this.changeImg(e, "gallery_1")}
															id="add_gallery_1"
															type="file"
															accept="image/*"
															hidden
														/>
													</div>
												</div>
											</Col>

											<Col md="4">
												<div className="add_img_wrap mb-2">
													{this.renderProductImage(gallery_2, gallery_2_url, editing)}

													<div className="caption">
														<label htmlFor="add_gallery_2">
															<span className="lnr lnr-picture"></span>
														</label>
														<input
															onChange={(e) => this.changeImg(e, "gallery_2")}
															id="add_gallery_2"
															type="file"
															accept="image/*"
															hidden
														/>
													</div>
												</div>
											</Col>

											<Col md="4">
												<div className="add_img_wrap mb-2">
													{this.renderProductImage(gallery_3, gallery_3_url, editing)}

													<div className="caption">
														<label htmlFor="add_gallery_3">
															<span className="lnr lnr-picture"></span>
														</label>
														<input
															onChange={(e) => this.changeImg(e, "gallery_3")}
															id="add_gallery_3"
															type="file"
															accept="image/*"
															hidden
														/>
													</div>
												</div>
											</Col>

											<Col md="4">
												<div className="add_img_wrap mb-2">
													{this.renderProductImage(gallery_4, gallery_4_url, editing)}

													<div className="caption">
														<label htmlFor="add_gallery_4">
															<span className="lnr lnr-picture"></span>
														</label>
														<input
															onChange={(e) => this.changeImg(e, "gallery_4")}
															id="add_gallery_4"
															type="file"
															accept="image/*"
															hidden
														/>
													</div>
												</div>
											</Col>
										</Row>
									</TabPane>

									{/* {is_pack && ( */}
									<TabPane tabId="level">
										<EditLevelTable
											level={{
												index: level_index || 1,
												sub_index: level_sub_index || 1,
											}}
											onChange={(new_level) =>
												this.setState({
													level_index: new_level?.index || 1,
													level_sub_index: new_level?.sub_index || 1,
												})
											}
										/>
									</TabPane>
									{/* )} */}

									{is_pack && (
										<TabPane tabId="pack">
											{articles.length > 0 && (
												<div style={{ padding: 10 }}>
													<Table responsive striped>
														<thead>
															<tr>
																<th style={{ width: 120 }}></th>

																<th>Nom</th>
																<th>Quantité</th>
																<th>Prix Vente</th>
																{user?.roles?.includes("ADMIN") && <th>Prix Achat</th>}
																<th>Stock</th>
																<th></th>
															</tr>
														</thead>
														<tbody>
															{articles.map((item, i) => (
																<tr key={"item" + i}>
																	<th scope="row" style={{ width: 120 }}>
																		<img
																			src={
																				item.article && item.article.img
																					? api.root +
																					  "/" +
																					  item.article.img.path
																					: `${pub_url}/img/no_product.png`
																			}
																			style={{ width: 40 }}
																		/>
																	</th>
																	<td style={{ verticalAlign: "middle" }}>
																		<a
																			href={
																				"/app/articles/edit/" + item.article._id
																			}
																			target="_blank"
																		>
																			{item.article?.name}
																		</a>
																	</td>

																	<td style={{ verticalAlign: "middle" }}>
																		<Input
																			type="number"
																			min={1}
																			onChange={(e) =>
																				this.changeArticleQty(i, e.target.value)
																			}
																			value={item.qty}
																			placeholder="Quantité"
																			style={{ width: 80 }}
																		/>
																	</td>

																	<td style={{ verticalAlign: "middle" }}>
																		{item.article?.price}
																	</td>
																	{user?.roles?.includes("ADMIN") && (
																		<td style={{ verticalAlign: "middle" }}>
																			{item.article?.price_purchase}
																		</td>
																	)}
																	<td>{item.article?.qty}</td>

																	<td
																		style={{
																			width: 60,
																			verticalAlign: "middle",
																		}}
																	>
																		<span
																			className="lnr lnr-trash"
																			onClick={() =>
																				this.handleRmProduct(item.article?._id)
																			}
																			style={{
																				cursor: "pointer",
																				color: "#F44336",
																				fontSize: 18,
																			}}
																		></span>
																	</td>
																</tr>
															))}
														</tbody>
													</Table>
												</div>
											)}

											<Button onClick={this.toggleAddProduct} outline color="secondary">
												{" "}
												Ajouter{" "}
											</Button>
										</TabPane>
									)}
								</TabContent>
							</div>
						</div>

						<div className="mt-5" style={{ textAlign: "right" }}>
							<ButtonToolbar
								className="modal__footer form__button-toolbar"
								style={{ textAlign: "right" }}
							>
								{user?.roles?.includes("ADMIN") && (
									<Button color="primary" onClick={this.submit}>
										Soumettre
									</Button>
								)}

								<NavLinkRouter to="/app/articles">
									<Button type="button" onClick={this.props.toggle}>
										Annuler
									</Button>
								</NavLinkRouter>
							</ButtonToolbar>
						</div>
					</CardBody>
				</Card>

				<SelectCtgMdl
					size="sm"
					handleSuccess={(ctg) => {
						this.setState({ show_select_ctg_mdl: false, category: ctg });
					}}
					toggle={this.toggleSelectCtgMdl}
					is_open={show_select_ctg_mdl}
				/>

				{/* MODALS */}
				<SelectArticle
					size="lg"
					multi={true}
					isOpen={show_select_prd}
					toggleOpen={this.toggleAddProduct}
					accept={this.addProducts}
					selectedItems={products}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ article, auth }) => {
	const {
		loading_add_article,
		error_add_article,
		new_article,
		selected_article,
		loading_one_article,
		error_one_article,
		loading_edit_article,
		error_edit_article,
	} = article;
	return {
		new_article,
		selected_article,
		loading_add_article,
		error_add_article,
		loading_one_article,
		error_one_article,
		loading_edit_article,
		error_edit_article,

		user: auth.user,
	};
};
export default connect(mapStateToProps, {
	addArticle,
	editArticle,
	getOneArticle,
})(ArticleCmp);
