import React, { Component } from 'react';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane, Table, CustomInput
} from 'reactstrap';
import classnames from 'classnames';
// import ProfileTimeLine from './ProfileTimeLine';
// import ProfileActivities from './ProfileActivities';
// import showResults from './Show';
// import ProfileSettings from './ProfileSettings';

export default class ProfileTabs extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount(){
  }

  render() {
    const initialValues = {
      username: 'Larry Boom',
      email: 'boom@mail.com',
    };

    const { activeTab } = this.state;
    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Achats
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => {
                      this.toggle('4');
                    }}
                  >
                    Cards
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>


                <TabPane tabId="2">
                  <h3></h3>
                </TabPane>










                <TabPane tabId="3">
                  {/* <h3>Fav Places</h3> */}
                  {/* <Table responsive hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Latitude</th>
                        <th>Longitude</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.fav_places && this.props.fav_places.map((favp, i) => (<tr key={'favp_' + i}>
                        <td style={{ verticalAlign: 'middle' }}>{favp.name}</td>
                        <td style={{ verticalAlign: 'middle' }}>{favp.address}</td>
                        <td style={{ verticalAlign: 'middle' }}>{favp.lat}</td>
                        <td style={{ verticalAlign: 'middle' }}>{favp.lng}</td>
                      </tr>))}
                    </tbody>
                  </Table> */}
                </TabPane>















                <TabPane tabId="4">
                  <h3>Registred Cards</h3>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}
