import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap';
import TrainingCmp from '../../shared/components/trainings/training-cmp';

export default class AddTrainingPage extends PureComponent {

  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return(<Container>
      <h3 className="page-title mb-3">Ajouter une formation</h3>
      <TrainingCmp history={this.props.history} adding={true} />
    </Container>)
  }
}
