import {
  GET_PROVIDERS, GET_PROVIDERS_SUCCESS, GET_PROVIDERS_ERROR,
  ADD_PROVIDER, ADD_PROVIDER_SUCCESS, ADD_PROVIDER_ERROR,
  GET_ONE_PROVIDER, GET_ONE_PROVIDER_SUCCESS, GET_ONE_PROVIDER_ERROR,
  EDIT_PROVIDER, EDIT_PROVIDER_SUCCESS, EDIT_PROVIDER_ERROR,
  DELETE_PROVIDER, DELETE_PROVIDER_SUCCESS, DELETE_PROVIDER_ERROR
} from '../actions/providersActions';

const initialState = {
  loading_get_providers: false,
  error_get_providers: null,
  providers: [],
  count_all_providers: 0,

  new_provider: null, loading_add_provider: false, error_add_provider: null,

  selected_provider: null, 
  loading_one_provider: false, error_one_provider: null,
  loading_edit_provider: false, error_edit_provider: null,

  loading_delete_provider: false, error_delete_provider: null
};

const providersReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_PROVIDERS: return {...state, loading_get_providers: true, error_get_providers: null };
    case GET_PROVIDERS_SUCCESS:
      return {...state, providers: action.payload.providers, loading_get_providers: false, error_get_providers: null, count_all_providers: action.payload.count };
    case GET_PROVIDERS_ERROR:
      // console.log("ACTION", {error_get_providers: action.error, loading_get_providers: false, providers: []});
      return {...state, error_get_providers: action.error, loading_get_providers: false, providers: [] };



    case ADD_PROVIDER: return {...state, loading_add_provider: true, error_add_provider: null };
    case ADD_PROVIDER_SUCCESS:
      return {...state, new_provider: action.payload.provider, loading_add_provider: false, error_add_provider: null };
    case ADD_PROVIDER_ERROR:
      return {...state, error_add_provider: action.error, loading_add_provider: false, new_provider: null };


    case GET_ONE_PROVIDER: return {...state, loading_one_provider: true, error_one_provider: null };
    case GET_ONE_PROVIDER_SUCCESS:
      return {...state, selected_provider: action.payload.provider, loading_one_provider: false, error_one_provider: null };
    case GET_ONE_PROVIDER_ERROR:
      return {...state, error_one_provider: action.error, loading_one_provider: false, selected_provider: null };



    case EDIT_PROVIDER: return {...state, loading_edit_provider: true, error_edit_provider: null };
    case EDIT_PROVIDER_SUCCESS:
      return {...state, selected_provider: action.payload.provider, loading_edit_provider: false, error_edit_provider: null };
    case EDIT_PROVIDER_ERROR:
      return {...state, error_edit_provider: action.error, loading_edit_provider: false, selected_provider: null };




    case DELETE_PROVIDER: return {...state, loading_delete_provider: true, error_one_provider: null };
    case DELETE_PROVIDER_SUCCESS:
      return {...state, loading_delete_provider: false, error_delete_provider: null };
    case DELETE_PROVIDER_ERROR:
      return {...state, error_delete_provider: action.error, loading_delete_provider: false };


    default: return state;
  }
};

export default providersReducer;
