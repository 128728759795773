import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

class SidebarContent extends Component {
	static propTypes = {
		changeToDark: PropTypes.func.isRequired,
		changeToLight: PropTypes.func.isRequired,
		onClick: PropTypes.func.isRequired,
	};

	hideSidebar = () => {
		const { onClick } = this.props;
		onClick();
	};

	render() {
		const {
			//changeToDark, changeToLight, is_admin,
			user,
		} = this.props;
		// console.log("=> IS ADMIN", user.is_admin);
		return (
			<div className="sidebar__content">
				{user && (user.roles.includes("ADMIN") || user.roles.includes("TECH")) && (
					<ul className="sidebar__block">
						<SidebarCategory title="Dashboard" icon="apartment">
							<SidebarLink title="Management" route="/app/dashboard/store" onClick={this.hideSidebar} />
							<SidebarLink title="CM" route="/app/dashboard/cm" onClick={this.hideSidebar} />
						</SidebarCategory>
					</ul>
				)}

				{/* <SidebarCategory title="Layout" icon="layers">
            <button type="button" className="sidebar__link" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button type="button" className="sidebar__link" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory> */}

				{user && (user.roles.includes("ADMIN") || user.roles.includes("TECH")) && (
					<ul className="sidebar__block">
						<SidebarCategory title="Edison Managements" icon="tag">
							<SidebarLink title="Commandes" route="/app/orders" onClick={this.hideSidebar} />

							{/* icon="users" */}
							<SidebarLink title="Clients" route="/app/clients" onClick={this.hideSidebar} />
							{/* icon="rocket" */}
							<SidebarLink title="Articles" route="/app/articles" onClick={this.hideSidebar} />

							{/* icon="layers" */}
							<SidebarLink title="Catégories" route="/app/categories" onClick={this.hideSidebar} />
						</SidebarCategory>
					</ul>
				)}

				{user && (user.roles.includes("ADMIN") || user.roles.includes("TECH")) && (
					<ul className="sidebar__block">
						<SidebarCategory title="Edison Calcul Mental" icon="lnr lnr-dice">
							<SidebarLink title="Commandes CM" route="/app/orders-trains" onClick={this.hideSidebar} />
							{/* <SidebarLink title="Club CM" route="/app/clients/internal" onClick={this.hideSidebar} /> */}
							<SidebarLink title="Club CM" route="/app/clients/trainers" onClick={this.hideSidebar} />
							<SidebarLink
								title="Articles CM"
								route="/app/articles/internal"
								onClick={this.hideSidebar}
							/>
							<SidebarLink title="Enfants" route="/app/childs" onClick={this.hideSidebar} />
							{user.roles.includes("TECH_TRAINS") ||
							user.roles.includes("TECH") ||
							user.roles.includes("ADMIN") ? (
								<SidebarLink title="Suivie" route="/app/follows" onClick={this.hideSidebar} />
							) : null}
						</SidebarCategory>
					</ul>
				)}

				{user && (user.roles.includes("ADMIN") || user.roles.includes("TECH_TRAINS")) && (
					<ul className="sidebar__block">
						<SidebarCategory title="Edison Formation" icon="graduation-hat">
							<SidebarLink title="Formations" route="/app/trainings" onClick={this.hideSidebar} />
							<SidebarLink title="Clients" route="/app/clients/internal" onClick={this.hideSidebar} />
							<SidebarLink title="Suivie" route="/app/follows" onClick={this.hideSidebar} />
						</SidebarCategory>
					</ul>
				)}

				{user && user.roles.includes("ADMIN") && (
					<ul className="sidebar__block">
						<SidebarCategory title="Administration" icon="diamond">
							<SidebarLink title="Paramètres" route="/app/settings" onClick={this.hideSidebar} />
							<SidebarLink title="Utilisateurs" route="/app/users" onClick={this.hideSidebar} />

							<SidebarLink title="Achat" route="/app/purchases" onClick={this.hideSidebar} />
							<SidebarLink title="Fournisseurs" route="/app/providers" onClick={this.hideSidebar} />

							<SidebarCategory title="Magasin">
								<SidebarLink title="Articles" route="/app/articles/supply" onClick={this.hideSidebar} />
								<SidebarLink
									title="Commandes Fournitures"
									route="/app/orders-supply"
									onClick={this.hideSidebar}
								/>
							</SidebarCategory>

							<SidebarLink
								title="Commandes annulées"
								route="/app/orders/canceled"
								onClick={this.hideSidebar}
							/>
							<SidebarLink title="Log" route="/app/log" onClick={this.hideSidebar} />
						</SidebarCategory>
					</ul>
				)}
			</div>
		);
	}
}

// export default SidebarContent;

const mapStateToProps = ({ auth }) => {
	const { user, is_admin } = auth;
	return {
		user,
		is_admin,
	};
};
export default connect(mapStateToProps, {})(SidebarContent);
