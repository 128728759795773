import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { Table, Space, Tag, Tooltip, Select } from "antd";

import { EyeOutlined } from "@ant-design/icons";
import Axios from "axios";
import { api, article_levels, levels } from "../../../../const";
import "./trainer.scss";
import moment from "moment";

const { Option } = Select;

const status_style = {
	textAlign: "center",
	width: "40px",
	height: "40px",
	padding: "4px",
	fontSize: "22px",
	background: "#fafafa",
	border: "1px solid #d9d9d9",
};

const LevelChilds = (props) => {
	const { client_id, index_level, index_sub_level } = props;

	const columns = [
		{
			title: "Nom Prénom",
			dataIndex: "name",
			key: "name",
			fixed: "left",
			width: 130,

			render: (text, record) => {
				return (
					<Fragment>
						<p style={{ margin: 0 }}>{record.name || ""}</p>
						<p style={{ margin: 0 }}>{record.name_ar || ""}</p>
					</Fragment>
				);
			},
		},
		{
			title: "Date Inscrit",
			dataIndex: "registration_date",
			key: "registration_date",
			// fixed: "left",
			width: 75,

			render: (text, record) => {
				return record?.registration_date ? (
					<span>{moment(record?.registration_date).format("DD-MM-YYYY")}</span>
				) : null;
			},
		},

		{
			title: "Age",
			dataIndex: "age",
			key: "age",
			width: 60,
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 115,
			render: (text, record) => {
				let select_color = "#777";
				switch (text) {
					case "valid":
						select_color = "#52c41a";
						break;
					case "in_progress":
						select_color = "#faad14";
						break;
					default:
						break;
				}

				return (
					<Fragment>
						<Select
							value={text}
							onChange={(new_status) => {
								updateChildStatus(record, new_status);
							}}
							style={{ padding: "0 8px", color: select_color }}
						>
							<Option value="in_progress">En cours</Option>
							<Option value="valid">Validé</Option>
							<Option value="breakup">Rupture</Option>
						</Select>
						{text === "valid" && record?.validation_date ? (
							<Tag color="success"> {moment(record.validation_date).format("DD-MM-YYYY")} </Tag>
						) : null}

						{text === "in_progress" && record?.Rupture ? (
							<Tag color="default"> {moment(record.Rupture).format("DD-MM-YYYY")} </Tag>
						) : null}
					</Fragment>
				);
			},
			_render: (text, record) => {
				switch (text) {
					case "in_progress":
						return "En cours";
					case "valid":
						return (
							<Fragment>
								<Tag color="success">Validé </Tag>
								{record?.validation_date ? (
									<Tag color="success"> {moment(record.validation_date).format("DD-MM-YYYY")} </Tag>
								) : null}
							</Fragment>
						);

					case "breakup":
						return (
							<Fragment>
								<Tag color="default">Rupture </Tag>
								{record?.Rupture ? (
									<Tag color="default"> {moment(record.Rupture).format("DD-MM-YYYY")} </Tag>
								) : null}
							</Fragment>
						);

					default:
						return "";
				}
			},
		},
		{
			title: "Niveaul CM",
			dataIndex: "level",
			key: "level",

			render: (text, record) => {
				return renderLevels(record?.levels);
			},
		},

		{
			title: "",
			key: "action",
			align: "center",
			width: 50,

			render: (text, record) => (
				<Space size="middle">
					<NavLink to={`/app/childs/preview/${record.key}`}>
						<EyeOutlined />
					</NavLink>
				</Space>
			),

			fixed: "right",
		},
	];

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	const getPage = (page = 1, index_level = 1, index_sub_level = 1) => {
		const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);

		const filter = {
			former: client_id,
			index_level,
			index_sub_level,
			no_draft: true,
		};

		setLoading(true);

		Axios.post(`${api.childs}/filter/${offset}/${limit}`, filter || {})
			.then((resp) => {
				setLoading(false);
				setCount(resp?.data?.count || 0);

				const new_data = resp?.data?.childs?.map((item) => {
					return {
						...item,
						name: `${item.first_name} ${item.last_name}`,
						name_ar: `${item.first_name_ar} ${item.last_name_ar}`,
						key: item?._id,
					};
				});

				setData(new_data || []);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (client_id && index_level && index_sub_level) getPage(1, index_level, index_sub_level);
	}, [client_id, index_level, index_sub_level, limit]);

	const sortLevels = (levels = []) => {
		return levels.sort((a, b) =>
			a.index_level > b.index_level || (a.index_level === b.index_level && a.index_sub_level > b.index_sub_level)
				? 1
				: -1
		);
	};

	const updateChildStatus = (child, new_status) => {
		const data = {
			status: new_status,
		};

		if (new_status === "valid") data["validation_date"] = moment().format("YYYY-MM-DD");
		else if (new_status === "breakup") data["breakup_date"] = moment().format("YYYY-MM-DD");

		const levels = sortLevels(
			child?.levels?.map((item) => {
				if (item._id) delete item._id;
				return {
					...item,
				};
			}) || []
		);

		const last_level = levels[levels?.length - 1];
		if (last_level) {
			last_level.status = new_status;
			if (new_status === "valid") last_level.validation_date = data.validation_date;
			else if (new_status === "breakup") last_level.validation_date = data.breakup_date;

			data["levels"] = levels;
		} else data["levels"] = [data];

		setLoading(true);

		Axios.patch(`${api.childs}/edit-one/${child?._id}`, data)
			.then((resp) => {
				if (client_id && index_level && index_sub_level) getPage(1, index_level, index_sub_level);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const renderStatus = (text, record) => {
		switch (text) {
			case "in_progress":
				return (
					<div
						style={{
							...status_style,
							color: "#faad14",
							background: "#fffbe6",
							border: "1px solid #ffe58f",
						}}
					>
						C
					</div>
				);
			case "valid":
				return (
					<div
						style={{
							...status_style,
							color: "#52c41a",
							background: "#f6ffed",
							border: "1px solid #b7eb8f",
						}}
					>
						V
					</div>

					// <Tag color="success" style={{ margin: "auto" }}>
					// 	Validé {record?.validation_date ? moment(record.validation_date).format("DD-MM-YYYY") : ""}
					// </Tag>
				);

			case "breakup":
				return (
					<div style={status_style}>R</div>
					// <Tag color="default">
					// 	Rupture {record?.breakup_date ? moment(record.breakup_date).format("DD-MM-YYYY") : ""}
					// </Tag>
				);

			default:
				return "";
		}
	};

	const renderLevels = (levels = []) => {
		return (
			<Space size={5}>
				{article_levels.map((lv, indx) => {
					return (
						<div style={{ textAlign: "center" }}>
							{/* <div
								style={{
									color: "#646777",
									textAlign: "center",
								}}
							>
								Niveau {indx + 1}
							</div> */}
							<Space size={5}>
								{lv.sub_indexes.map((sub, j) => {
									return (
										<div key={`sub-lv-${indx}-${j}`}>
											<div
												style={{
													// minWidth: 80,
													// minHeight: 60,
													// backgroundColor: "#dee2e6",
													// padding: "3px",
													textAlign: "center",
												}}
											>
												<span style={{ fontWeight: "bold" }}>{sub.name}</span>
												<div>{renderSublevel(levels, indx + 1, j + 1)}</div>
											</div>
										</div>
									);
								})}
							</Space>
						</div>
					);
				})}
			</Space>
		);
	};

	const renderSublevel = (levels = [], index_level = 1, index_sub_level = 1) => {
		try {
			const lv = levels.filter(
				(item) => item.index_level === index_level && item.index_sub_level === index_sub_level
			)[0];
			if (!lv) return <div style={status_style}></div>;

			return (
				<Tooltip
					title={
						<div>
							<p>
								{lv.status === "breakup"
									? `Rupture 
						${lv?.breakup_date ? moment(lv.breakup_date).format("DD-MM-YYYY") : ""}`
									: ""}
							</p>
							<p>
								{lv.status === "valid"
									? `Validé 
						${lv?.validation_date ? moment(lv.validation_date).format("DD-MM-YYYY") : ""}`
									: ""}
							</p>
							{lv.registration_date ? (
								<p className="m-0">Inscrit {moment(lv.registration_date).format("DD-MM-YYYY")}</p>
							) : null}
						</div>
					}
					placement="bottom"
				>
					{renderStatus(lv.status, lv)}
				</Tooltip>
			);
		} catch (error) {
			return <div style={status_style}></div>;
		}
	};

	return (
		<React.Fragment>
			<Table
				columns={columns}
				dataSource={data}
				scroll={{ x: 900 }}
				loading={loading}
				pagination={{
					total: count,
					pageSize: limit,
					pageSizeOptions: [10, 20, 30],
				}}
				onChange={(pagination, filters, sorter, extra) => {
					// const sort_fields = [];
					// if (sorter?.column)
					// 	sort_fields.push({
					// 		field: sorter?.field,
					// 		order: sorter?.order === "ascend" ? "asc" : "desc",
					// 	});
					// setLimit(pagination?.pageSize || 10);
					// getPage(pagination?.current || 1);
				}}
			/>
		</React.Fragment>
	);
};

LevelChilds.prototype = {
	index_level: PropTypes.number.isRequired,
	index_sub_level: PropTypes.number.isRequired,
	client_id: PropTypes.func.isRequired,
};
export default LevelChilds;
