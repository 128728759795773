import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Checkbox, DatePicker, Modal, Space, Select, Divider, Alert } from "antd";
import EditLevelTable from "../../articles/edit-level-table/EditLevelTable";
import moment from "moment";

const { Option } = Select;
const SelectLevelMdl = (props) => {
	const { visible, submit, close, checkLevelValidation = () => {}, action = "add", selected_level } = props;

	const [index_level, setIndex] = useState(1);
	const [index_sub_level, setSubIndex] = useState(1);
	const [status, setStatus] = useState("in_progress");
	const [validation_date, setValidationDate] = useState(moment());
	const [breakup_date, setBreackupDate] = useState(moment());
	const [registration_date, setRegistrationDate] = useState(null);
	
	const [error_msg, setErrorMsg] = useState("");

	useEffect(() => {
		if (action === "edit" && selected_level) {
			setIndex(selected_level.index_level);
			setSubIndex(selected_level.index_sub_level);

			setStatus(selected_level.status);
			setValidationDate(selected_level.validation_date ? moment(selected_level.validation_date) : null);
			setBreackupDate(selected_level.breakup_date ? moment(selected_level.breakup_date) : null);
			setRegistrationDate(selected_level.registration_date ? moment(selected_level.registration_date) : null);
		}
	}, [action, selected_level]);

	const renderDateByStatus = () => {
		switch (status) {
			case "valid":
				return (
					<div>
						<span className="mr-2">Date de validation</span>
						<DatePicker defaultValue={validation_date || moment()} onChange={(e) => setValidationDate(e)} />
					</div>
				);
			case "breakup":
				return (
					<div>
						<span className="mr-2">Date de rupture</span>
						<DatePicker defaultValue={breakup_date || moment()} onChange={(e) => setBreackupDate(e)} />
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<Modal
			width={"95%"}
			bodyStyle={{ padding: 5 }}
			centered
			title="Attribuer niveau"
			visible={visible}
			onOk={() => {
				if (
					action === "add" &&
					!checkLevelValidation({
						index_level,
						index_sub_level,
					})
				)
					setErrorMsg("Nivau déja attribué.");
				else
					submit({
						index_level,
						index_sub_level,
						status,
						validation_date,
						breakup_date,
						registration_date,
					});
			}}
			onCancel={close}
		>
			{error_msg !== "" ? <Alert message={error_msg} type="error" className="mb-1" /> : null}

			<EditLevelTable
				read_only={false}
				level={{ index: index_level, sub_index: index_sub_level }}
				onChange={(new_level) => {
					setIndex(new_level?.index || 1);
					setSubIndex(new_level?.sub_index || 1);

					if (
						action === "add" &&
						checkLevelValidation({
							index_level: new_level?.index || 1,
							index_sub_level: new_level?.sub_index || 1,
						})
					)
						setErrorMsg("");
				}}
			/>

			<Space className="mt-4">
				<span className="mr-2">Status</span>
				<Select value={status} style={{ width: 120 }} onChange={(new_status) => setStatus(new_status)}>
					<Option value="in_progress">En cours</Option>
					<Option value="valid">Validé</Option>
					<Option value="breakup">Rupture</Option>
				</Select>

				{renderDateByStatus()}

				<span className="mr-2">Date Inscrit</span>
				<DatePicker value={registration_date} onChange={(e) => setRegistrationDate(e)} />
			</Space>

			{/* <div>
				<Divider />
				<Space className="mt-2">
					<Checkbox checked={is_graduated} onChange={(e) => setIsGraduated(e?.target?.checked)}>
						Diplômé
					</Checkbox>
					{is_graduated && (
						<div>
							<span className="mr-2">Date d'obtention du diplôme</span>
							<DatePicker
								defaultValue={graduation_date || moment()}
								onChange={(e) => setGraduationDate(e)}
							/>
						</div>
					)}
				</Space>
			</div> */}
		</Modal>
	);
};
SelectLevelMdl.prototype = {
	close: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	checkLevelValidation: PropTypes.func.isRequired,
	action: PropTypes.oneOf(["add", "edit"]).isRequired,

	visible: PropTypes.bool,
	selected_level: PropTypes.object,
};
export default SelectLevelMdl;
