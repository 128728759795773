import React, { useState, PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { Field, reduxForm } from 'redux-form';

import Axios from "axios";

import {
	Button,
	ButtonToolbar,
	Card,
	CardBody,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Table,
} from "reactstrap";
// import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";

import { NavLink as LinkNav } from "react-router-dom";

// import moment from "moment"
// moment.locale('fr')
import { addTraining, editTraining, getOneTraining } from "../../../redux/actions/trainingsActions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
// import Switch from "rc-switch";
// import "rc-switch/assets/index.css";

import classnames from "classnames";
import { api, cities, formers, training_types } from "../../../const";
import SelectClientMemberMdl from "../clients/SelectClient";
import moment from "moment";
import { getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";
import ConfirmMdl from "../confirm-mdl";

import AccountSearchIcon from "mdi-react/AccountSearchIcon";
import SelectUsersMdl from "../users/select-users-mdl";
import AddClientMdl from "../clients/AddClientMdl";
import FollowMdl from "./FollowMdl";

class TrainingCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			start_date: new Date(),
			duration: 0,

			address: "",
			city: "",
			region: "",
			ref: "",

			type: "",
			error_frm: "",

			active_tab: "follow",
			name: "",
			category: "",

			// category: { type: Schema.Types.ObjectId, ref: 'TrainingCategory', default: '5fc764674875e60e6cb3675c' },
			// theme: { type: Schema.Types.ObjectId, ref: 'TrainingTheme', default: '5fc764674875e60e6cb3675c' },

			former: null,
			commercial: null,
			organizer: null,
			select_user: false,

			coordinator: null,
			select_coordinator: null,

			type: "internal",

			participants: [],
			count_all_participants: 0,
			show_delete_msg: false,
			req_delete_id: null,

			show_select_client: false,
			show_add_client: false,

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			training_categories: [],
			selected_ctg_names: [],

			selected_activity: null,
			open_follow_mdl: false,

			show_participants: false,
		};
	}

	getTrainCategories = () => {
		Axios.post(api.trainings + "/categories/filter/0/200").then((resp) => {
			const ctgs = resp?.data?.training_categories || [];
			let selected_ctg_names = [];
			for (const ctg of ctgs) {
				if (ctg.name === this.state.category) selected_ctg_names = ctg.training_names;
			}
			this.setState({ training_categories: ctgs, selected_ctg_names });
		});
	};

	toggle = (active_tab) => this.setState({ active_tab });
	toggleSelectClientMdl = () =>
		this.setState((prev) => {
			return { show_select_client: !prev.show_select_client };
		});

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	submit = () => {
		const error_frm = this.checkFrm(this.state);
		this.setState({ error_frm });

		const { addTraining, editTraining, editing } = this.props;
		const {
			start_date,
			duration,
			address,
			city,
			region,
			ref,
			former,
			coordinator,
			commercial,
			organizer,
			type,
			name,
			category,
		} = this.state;

		if (error_frm == "") {
			const data = {
				name,
				category,
				start_date,
				duration,
				address,
				city,
				region,
				ref,
				type,
				former: former?._id,
				commercial: commercial?._id,
				organizer: organizer?._id,
				coordinator: coordinator?._id,
			};

			if (editing) editTraining({ _id: this.props.training_id, data });
			else addTraining(data);
		} else window.scrollTo(0, 0);
	};
	checkFrm = (data) => {
		const { start_date, organizer, commercial, former, city, address, duration } = data;
		if (!start_date) return "Date de début est requis.";
		if (!duration) return "Duré est requis.";
		// if (!organizer) return "Organisateur est requis.";
		// if (!commercial) return "Commercial est requis.";
		if (!former) return "Formateur est requis.";
		if (city === "") return "Gouvernorat est requis.";
		if (address === "") return "Adresse est requis.";
		return "";
	};

	componentDidUpdate(prev) {
		const {
			loading_add_training,
			error_add_training,
			new_training,
			selected_training,
			loading_one_training,
			error_one_training,
			loading_edit_training,
			error_edit_training,
		} = this.props;

		if (!loading_add_training && prev.loading_add_training) {
			// console.log("error_add_training", error_add_training, new_training);
			if (error_add_training) {
				if (error_add_training.message && error_add_training.message != "")
					this.setState({ error_frm: error_add_training.message });
			} else this.props.history.push("/app/trainings/edit/" + new_training._id);
		}

		// EDITING
		if (!loading_edit_training && prev.loading_edit_training) {
			// console.log("error_add_training", error_add_training, new_training);
			if (error_edit_training) {
				if (error_edit_training.message && error_edit_training.message != "")
					this.setState({ error_frm: error_edit_training.message });
			} else this.props.history.push("/app/trainings/trainings");
		}

		//GET ONE
		if (prev.loading_one_training && !loading_one_training && !error_one_training) {
			if (selected_training) {
				const {
					name,
					category,
					start_date,
					duration,
					address,
					city,
					region,
					ref,
					former,
					coordinator,
					organizer,
					commercial,
					type,
				} = selected_training;

				this.setState(
					{
						name,
						category,
						start_date,
						duration,
						address,
						city,
						region,
						ref,
						former,
						coordinator,
						organizer,
						commercial,
						type,
					},
					() => {
						//after end init
						this.getTrainCategories();
					}
				);
			}
		}
	}

	componentDidMount() {
		if (this.props.editing) {
			const { match, getOneTraining } = this.props;
			if (match && match.params) {
				getOneTraining({ training_id: match.params.trainingId });
				// this.getParticipants(1)
			}
		}

		this.getTrainCategories();
	}

	addParticipant = (created_client) => {
		if (created_client)
			Axios.post(api.participants + "/create", {
				participant: created_client?._id,
				training: this.props.training_id,
				adding_date: new Date(),
			})
				.then((resp) => {
					// console.log(resp);
					this.getParticipants(1);
					this.setState({ show_add_client: false });
				})
				.catch((e) => {
					console.log("error add participant", e);
					alert("Erreur, Veuillez vérifier vos données et réessayer");
				});
	};

	getParticipants = (page = 1) => {
		const { selected_page_size } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		Axios.post(`${api.participants}/filter/${offset}/${selected_page_size}`, {
			training_id: this.props?.match?.params?.trainingId,
		}).then((resp) => {
			this.setState({
				participants: resp?.data?.participants || [],
				count_all_participants: resp?.data?.count || 0,
			});
		});
	};

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getParticipants(page));
	};

	deleteReq = () => {
		Axios.delete(api.participants + "/delete-one/" + this.state.req_delete_id).then((resp) => {
			this.getParticipants();
		});

		this.setState({ show_delete_msg: false });
	};

	render() {
		const { editing } = this.props;
		const {
			name,
			category,
			training_categories,
			selected_ctg_names,
			start_date,
			duration,
			address,
			city,
			region,
			ref,
			former,
			organizer,
			commercial,
			type,
			error_frm,
			active_tab,
			show_select_client,
			participants,
			current_page,
			count_all_participants,
			selected_page_size,
			show_delete_msg,
			select_commercial,
			select_organizer,
			select_former,
			select_user,

			show_add_client,
			open_follow_mdl,
			selected_activity,
			coordinator,
			select_coordinator,
			show_participants,
		} = this.state;

		return (
			<div>
				<Card>
					<CardBody>
						{!show_participants ? (
							<Row className="form">
								<Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
									<Alert is_visible={error_frm != ""} color="danger" className="alert--colored" icon>
										<p>
											<span className="bold-text">Attention!</span> {error_frm}
										</p>
									</Alert>
								</Col>

								<Col md="4" className="mt-2 mb-5">
									<span className="form__form-group-label">Categorie</span>
									<select
										className="form-control"
										value={category}
										onChange={(e) => {
											this.setState({
												category: e.target.value,
												selected_ctg_names:
													training_categories[e.target.selectedIndex - 1]?.training_names ||
													[],
											});
										}}
									>
										<option value=""></option>
										{training_categories.map((ctg, i) => (
											<option key={ctg._id} value={ctg.name}>
												{ctg.name}
											</option>
										))}
									</select>
								</Col>
								<Col md="4" className="mt-2 mb-5">
									<span className="form__form-group-label">Nom</span>
									<select
										className="form-control"
										value={name}
										onChange={(e) => {
											this.setState({ name: e.target.value });
										}}
									>
										<option value=""></option>
										{selected_ctg_names?.map((nm, i) => (
											<option key={"nm" + i} value={nm}>
												{nm}
											</option>
										))}
									</select>
								</Col>
								<Col md="4"></Col>
								{/* START DATE */}

								<Col md="3" className="mt-2">
									<span className="form__form-group-label">Date de début</span>
									<br />
									<DatePicker
										selected={new Date(start_date)}
										onChange={(e) => this.changeVal("start_date", e)}
										placeholderText="Date de début"
									/>
								</Col>

								{/* DURATION */}
								<Col md="3">
									<div className="form">
										<div
											onClick={() => {}}
											className="form__form-group"
											style={{ cursor: "pointer" }}
										>
											<span className="form__form-group-label">Duré</span>

											<div className="form__form-group-field">
												<input
													type="number"
													min="0"
													value={duration}
													placeholder="Jours"
													onChange={(e) => this.changeVal("duration", e.target.value)}
												/>

												<div className="form__form-group-icon">Jours</div>
											</div>
										</div>
									</div>
								</Col>

								{/* REFERENCE */}
								<Col md="3">
									<div className="form__form-group">
										<span className="form__form-group-label">Référence</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												name="name"
												value={ref}
												readOnly
												disabled
												component="input"
												// onChange={(e) => this.changeVal('ref', e.target.value)}
											/>
										</div>
									</div>
								</Col>

								{/* TYPE */}
								<Col md="3">
									<div className="form__form-group">
										<span className="form__form-group-label">Type</span>
										<div className="form__form-group-field">
											<Select
												name="type"
												defaultValue={type}
												value={training_types.filter((option) => option.value === type)}
												onChange={(e) => this.changeVal("type", e.value)}
												options={training_types}
												clearable={false}
												className="react-select"
												placeholder="Type"
												classNamePrefix="react-select"
											/>
										</div>
									</div>
								</Col>

								{/* ORGANIZER */}
								<Col md="3">
									<div className="form__form-group">
										<span className="form__form-group-label">Organisateur</span>
										<div className="form__form-group-field">
											<input
												style={
													{
														// width: '100%', border: 'none', borderBottom: '1px solid #ddd'
													}
												}
												readOnly
												value={
													organizer ? organizer?.first_name + " " + organizer?.last_name : ""
												}
												component="input"
											/>
											<div
												onClick={() => {
													this.setState({
														select_user: true,
														select_organizer: true,
														select_former: false,
														select_commercial: false,
														select_coordinator: false,
													});
												}}
												className="form__form-group-icon"
												style={{ cursor: "pointer" }}
											>
												<AccountSearchIcon />
											</div>
										</div>
									</div>
								</Col>
								{/* Commercial */}
								<Col md="3">
									<div className="form__form-group">
										<span className="form__form-group-label">Commercial</span>
										<div className="form__form-group-field">
											<input
												style={
													{
														// width: '100%', border: 'none', borderBottom: '1px solid #ddd'
													}
												}
												readOnly
												value={
													commercial
														? commercial?.first_name + " " + commercial?.last_name
														: ""
												}
												component="input"
											/>
											<div
												onClick={() => {
													this.setState({
														select_user: true,
														select_organizer: false,
														select_former: false,
														select_commercial: true,
														select_coordinator: false,
													});
												}}
												className="form__form-group-icon"
												style={{ cursor: "pointer" }}
											>
												<AccountSearchIcon />
											</div>
										</div>
									</div>
								</Col>

								{/* Former */}
								<Col md="3">
									<div className="form__form-group">
										<span className="form__form-group-label">Formateur</span>
										<div className="form__form-group-field">
											<input
												style={
													{
														// width: '100%', border: 'none', borderBottom: '1px solid #ddd'
													}
												}
												readOnly
												value={former ? former?.first_name + " " + former?.last_name : ""}
												component="input"
											/>
											<div
												onClick={() => {
													this.setState({
														select_user: true,
														select_organizer: false,
														select_former: true,
														select_commercial: false,
														select_coordinator: false,
													});
												}}
												className="form__form-group-icon"
												style={{ cursor: "pointer" }}
											>
												<AccountSearchIcon />
											</div>
										</div>
									</div>
								</Col>

								{/* FORMER */}
								{/* <Col md="3">
              <div className="form__form-group">
                <span className="form__form-group-label">Formateur</span>
                <div className="form__form-group-field">
                  <Select name="type" defaultValue={former}
                    value={formers.filter(option => option.value === former)}
                    onChange={(e) => this.changeVal('former', e.value)}
                    options={formers} clearable={false}
                    className="react-select"
                    placeholder="Formateur" classNamePrefix="react-select"
                  />
                </div>
              </div>
            </Col> */}

								<Col md="4">
									<div className="form__form-group">
										<span className="form__form-group-label">Gouvernorat</span>
										<div className="form__form-group-field">
											<Select
												name="city"
												defaultValue={city}
												value={cities.filter((option) => option.value === city)}
												onChange={(e) => this.changeVal("city", e.value)}
												options={cities}
												clearable={false}
												className="react-select"
												placeholder="Gouvernorat"
												classNamePrefix="react-select"
											/>
										</div>
									</div>
								</Col>
								<Col md="4">
									<div className="form__form-group">
										<span className="form__form-group-label">Région</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												name="region"
												defaultValue={region}
												component="input"
												placeholder="Région"
												onChange={(e) => this.changeVal("region", e.target.value)}
											/>
										</div>
									</div>
								</Col>
								<Col md="12">
									<div className="form__form-group">
										<span className="form__form-group-label">Adresse</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												name="address"
												defaultValue={address}
												component="input"
												placeholder="Adresse"
												onChange={(e) => this.changeVal("address", e.target.value)}
											/>
										</div>
									</div>
								</Col>

								<Col md="6">
									<div className="form__form-group">
										<span className="form__form-group-label">Coordinateur</span>
										<div className="form__form-group-field">
											<input
												style={
													{
														// width: '100%', border: 'none', borderBottom: '1px solid #ddd'
													}
												}
												readOnly
												value={
													coordinator
														? coordinator?.first_name + " " + coordinator?.last_name
														: ""
												}
												component="input"
											/>
											<div
												onClick={() => {
													this.setState({
														select_user: true,
														select_organizer: false,
														select_former: false,
														select_commercial: false,
														select_coordinator: true,
													});
												}}
												className="form__form-group-icon"
												style={{ cursor: "pointer" }}
											>
												<AccountSearchIcon />
											</div>
										</div>
									</div>
								</Col>

								{/* {select_coordinator && <SelectClientMemberMdl size="lg" multi={false}
              changeVal={() => { }}

              isOpen={select_coordinator} toggleOpen={() => this.setState({ select_coordinator: !select_coordinator })}
              accept={(selected_clients) => {
                if (selected_clients[0]) this.setState({ coordinator: selected_clients[0] })

                this.setState({ select_coordinator: false })
              }} selectedItems={[]}
            />} */}
							</Row>
						) : null}

						{editing && show_participants && (
							<div className="tabs mt-5">
								<div className="tabs__wrap">
									<Nav tabs>
										<NavItem>
											<NavLink
												className={classnames({ active: active_tab === "follow" })}
												onClick={() => {
													this.toggle("follow");
												}}
											>
												Participants <b>({count_all_participants || 0})</b>{" "}
											</NavLink>
										</NavItem>
									</Nav>

									<TabContent activeTab={active_tab} style={{ width: "100%", minHeight: 200 }}>
										<TabPane tabId="follow">
											<div style={{ textAlign: "right" }}>
												{/* <Button color="success" className="rounded">Ajouter</Button> */}

												{/* <AddMemberMdl only_clients={(only_clients)} handleSuccess={(new_user) => {
                      this.getPage(); this.setState({ show_select_client: false })
                    }} toggle={this.toggleAddUserMdl} is_open={show_select_client} /> */}
											</div>

											<Table responsive hover striped>
												<thead>
													<tr>
														<th>Nom</th>
														<th>Date d'ajout</th>

														<th></th>
														<th></th>
														<th></th>
													</tr>

													{participants &&
														participants.map((item, i) => (
															<tr key={"participant_" + i}>
																{item.participant ? (
																	<td>
																		<span style={{ margin: 0, color: "#777" }}>
																			{item.participant.first_name +
																				" " +
																				item.participant?.last_name}
																		</span>
																		<span style={{ margin: 0, color: "#777" }}>
																			{item.participant.first_name_ar +
																				" " +
																				item.participant?.last_name_ar}
																		</span>
																	</td>
																) : (
																	<td></td>
																)}

																<td>
																	{moment(item.adding_date).format("ddd DD/MM/YYYY")}
																</td>

																<td>
																	<span
																		onClick={() => {
																			this.setState({
																				selected_activity: item,
																				open_follow_mdl: true,
																			});
																		}}
																		className="lnr lnr-rocket"
																		style={{
																			color: "#4CAF50",
																			display: "block",
																			marginTop: 12,
																			cursor: "pointer",
																			fontSize: 18,
																		}}
																	></span>
																</td>

																<td style={{ width: 60, verticalAlign: "middle" }}>
																	<a
																		href={
																			"/app/clients/edit/" +
																			item?.participant?._id
																		}
																		target="blank"
																	>
																		<span
																			className="lnr lnr-eye mr-2"
																			style={{
																				cursor: "pointer",
																				color: "#4CAF50",
																				fontSize: 18,
																			}}
																		></span>
																	</a>
																	{/* <NavLink target="blank" to={"/app/clients/edit/" + item?.participant?._id}>
                            <span className="lnr lnr-eye mr-2" style={{ cursor: 'pointer', color: "#4CAF50", fontSize: 18 }}></span>
                          </NavLink> */}
																</td>
																<td>
																	<span
																		onClick={() => {
																			this.setState({
																				req_delete_id: item._id,
																				show_delete_msg: true,
																			});
																		}}
																		className="lnr lnr-trash"
																		style={{
																			color: "#F44336",
																			display: "block",
																			marginTop: 12,
																			cursor: "pointer",
																		}}
																	></span>
																</td>
															</tr>
														))}

													<tr>
														<td colSpan="5">
															{/* <Button color="info" onClick={() => this.setState({ show_select_client: true })}>Sélectionner</Button> */}

															<Button
																onClick={() => {
																	this.setState({
																		show_add_client: true,
																	});
																}}
																color="success"
															>
																Ajouter
															</Button>
														</td>
													</tr>
												</thead>
												<tbody></tbody>
											</Table>

											{show_add_client && (
												<AddClientMdl
													isOpen={show_add_client}
													add_as_participant={true}
													toggleOpen={() =>
														this.setState({ show_add_client: !show_add_client })
													}
													addParticipant={(created_client) =>
														this.addParticipant(created_client)
													}
												/>
											)}

											<SelectClientMemberMdl
												size="lg"
												multi={false}
												// first_name={first_name} first_name_ar={first_name_ar} city={city} region={region}
												// phone={phone} phone2={phone2} address={address} type={type}

												changeVal={() => {}}
												isOpen={show_select_client}
												toggleOpen={this.toggleSelectClientMdl}
												accept={(selected_clients) => {
													if (selected_clients[0]) this.addParticipant(selected_clients[0]);

													this.setState({ show_select_client: false });
												}}
												selectedItems={[]}
											/>

											<Pagination
												currentPage={current_page}
												totalPage={getCountPages(count_all_participants, selected_page_size)}
												onChangePage={(i) => this.onChangePage(i)}
											/>
										</TabPane>
									</TabContent>
								</div>
							</div>
						)}

						<div className="mt-5" style={{ textAlign: "left" }}>
							<ButtonToolbar className="" style={{ textAlign: "left" }}>
								{!show_participants && (
									<React.Fragment>
										<Button
											disabled={!name || name === "" || !category || category === ""}
											color="primary"
											onClick={this.submit}
											style={{ height: 40 }}
										>
											Soumettre
										</Button>

										<LinkNav to="/app/trainings" style={{ padding: 0 }}>
											<Button type="button">Annuler</Button>
										</LinkNav>
									</React.Fragment>
								)}

								{/* {editing ? <div style={{ textAlign: "right", width: "100%", marginTop: -60 }}>
                <Button color="info" onClick={() => this.setState({ show_participants: !show_participants })}
                  style={{ height: 40 }}
                >{show_participants ? "Modifier formation" : "Voir participants"} </Button>
              </div> : null} */}
								{editing ? (
									<div style={{ textAlign: "right", width: "100%", marginTop: -60 }}>
										<LinkNav to={"/app/trainings/preview/" + this.props?.match?.params?.trainingId}>
											{/* <Button color="info" type="button"></Button> */}
											Voir participants
										</LinkNav>
									</div>
								) : null}
							</ButtonToolbar>
						</div>

						<ConfirmMdl
							toggle={() =>
								this.setState((prev) => {
									return { show_delete_msg: !prev.show_delete_msg };
								})
							}
							is_open={show_delete_msg}
							accept={() => {
								this.deleteReq();
							}}
						/>

						<SelectUsersMdl
							is_open={select_user}
							toggle={() => this.setState({ select_user: !select_user })}
							multi={false}
							size="lg"
							onSuccess={(selected_user) => {
								if (select_organizer) this.setState({ organizer: selected_user });
								if (select_former) this.setState({ former: selected_user });
								if (select_commercial) this.setState({ commercial: selected_user });
								if (select_coordinator) this.setState({ coordinator: selected_user });
							}}
						/>
					</CardBody>
				</Card>

				<FollowMdl
					isOpen={open_follow_mdl}
					toggleOpen={() => this.setState({ open_follow_mdl: !open_follow_mdl })}
					activity={selected_activity}
					onSuccess={() => {
						this.getParticipants(current_page);
						this.setState({ open_follow_mdl: false });
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ training }) => {
	const {
		loading_add_training,
		error_add_training,
		new_training,
		selected_training,
		loading_one_training,
		error_one_training,
		loading_edit_training,
		error_edit_training,
	} = training;
	return {
		new_training,
		selected_training,
		loading_add_training,
		error_add_training,
		loading_one_training,
		error_one_training,
		loading_edit_training,
		error_edit_training,
	};
};
export default connect(mapStateToProps, {
	addTraining,
	editTraining,
	getOneTraining,
})(TrainingCmp);
