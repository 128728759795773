import Axios from "axios";
import { api } from "../../const";
import {
	AUTHENTICATE,
	AUTHENTICATE_SUCCESS,
	AUTHENTICATE_ERROR,
	LOGOUT,
	GET_USERS,
	GET_USERS_SUCCESS,
	GET_USERS_ERROR,
	ADD_USER,
	ADD_USER_SUCCESS,
	ADD_USER_ERROR,
	EDIT_USER,
	EDIT_USER_SUCCESS,
	EDIT_USER_ERROR,
	GET_PROFILE,
	GET_PROFILE_SUCCESS,
	GET_PROFILE_ERROR,
} from "../actions/authActions";

let user = null;
let is_admin = false;
let is_tech = false;
let is_tech_trains = false;

const user_prefix = "edison_user";

try {
	user = JSON.parse(localStorage.getItem(user_prefix));
} catch (error) {}

Axios.post(api.users + "/check-session", {}, { withCredentials: true })
	.then((resp) => {
		// console.log("==========+++++++++++++> check-session", resp);

		if (resp.status == 200) {
			user = resp.data;

			is_admin = user.roles.includes("ADMIN");
			is_tech = user.roles.includes("TECH");
			is_tech_trains = user.roles.includes("TECH_TRAINS");

			user["is_admin"] = is_admin;
			user["is_tech"] = is_tech;
			user["is_tech_trains"] = is_tech_trains;

			if (!is_admin && !is_tech && !is_tech_trains) {
				Axios.post(api.users + "/logout", {}, { withCredentials: true }).then();
				localStorage.setItem(user_prefix, null);
				user = null;

				// if(window.location.pathname!='/') window.location.href = "/";
			} else localStorage.setItem(user_prefix, JSON.stringify(user));
		} else {
			localStorage.setItem(user_prefix, null);
			user = null;
			if (window.location.pathname != "/" && window.location.pathname != "/login")
				window.location.href = "/login";
		}
	})
	.catch((err) => {
		localStorage.setItem(user_prefix, null);
		user = null;
		if (window.location.pathname != "/" && window.location.pathname != "/login") window.location.href = "/login";
	});

const initialState = {
	loading_login: false,
	user,
	is_admin,
	is_tech,
	is_tech_trains,
	error: null,

	loading_get_users: false,
	error_get_users: null,
	users: [],
	count_all_users: 0,

	new_user: null,
	loading_add_user: false,
	error_add_user: null,

	loading_edit_user: false,
	error_edit_user: null,

	profile: null,
	loading_profile: false,
	error_profile: null,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATE:
			return { ...state, loading_login: true, error: null };
		case AUTHENTICATE_SUCCESS:
			const connected_user = action.payload.user;
			is_admin = connected_user.roles.includes("ADMIN");
			is_tech = connected_user.roles.includes("TECH");
			is_tech_trains = connected_user.roles.includes("TECH_TRAINS");

			connected_user["is_admin"] = is_admin;
			connected_user["is_tech"] = is_tech;
			connected_user["is_tech_trains"] = is_tech_trains;

			localStorage.setItem(user_prefix, JSON.stringify(connected_user));
			return {
				...state,
				user: connected_user,
				is_admin,
				is_tech,
				is_tech_trains,
				loading_login: false,
				error: null,
			};
		case AUTHENTICATE_ERROR:
			localStorage.setItem(user_prefix, null);
			return { ...state, error: action.error, loading_login: false };
		case LOGOUT:
			localStorage.setItem(user_prefix, null);
			Axios.post(api.users + "/logout", {}, { withCredentials: true }).then();
			return { ...state, user: null };

		case GET_USERS:
			return { ...state, loading_get_users: true, error_get_users: null };
		case GET_USERS_SUCCESS:
			return {
				...state,
				users: action.payload.users,
				loading_get_users: false,
				error_get_users: null,
				count_all_users: action.payload.count,
			};
		case GET_USERS_ERROR:
			// console.log("ACTION", {error_get_users: action.error, loading_get_users: false, users: []});
			return { ...state, error_get_users: action.error, loading_get_users: false, users: [] };

		case ADD_USER:
			return { ...state, loading_add_user: true, error_add_user: null };
		case ADD_USER_SUCCESS:
			return { ...state, new_user: action.user, loading_add_user: false, error_add_user: null };
		case ADD_USER_ERROR:
			return { ...state, error_add_user: action.error, loading_add_user: false, new_user: null };

		case EDIT_USER:
			return { ...state, loading_edit_user: true, error_edit_user: null };
		case EDIT_USER_SUCCESS:
			return { ...state, new_user: action.user, loading_edit_user: false, error_edit_user: null };
		case EDIT_USER_ERROR:
			return { ...state, error_edit_user: action.error, loading_edit_user: false, new_user: null };

		case GET_PROFILE:
			return { ...state, profile: null, loading_profile: true, error_profile: null };
		case GET_PROFILE_SUCCESS:
			return { ...state, profile: action.payload.user, loading_profile: false, error_profile: null };
		case GET_PROFILE_ERROR:
			return { ...state, error_profile: action.error, loading_profile: false, profile: null };

		default:
			return state;
	}
};

export default authReducer;
