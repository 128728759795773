import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListArticlesCmp from '../../shared/components/articles/list-articles-cmp';

const ListArticlesInternalPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Articles <span style={{fontSize: 12}}>( internes )</span> </h3>
      </Col>

      <Col md={12}>
        <ListArticlesCmp only_internal={true} />
      </Col>
    </Row>
  </Container>
);

export default ListArticlesInternalPage;