import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ListTrainingsPage from './list';
import EditTrainingPage from './edit';
import PreviewTrainingPage from './preview';
import AddTrainingPage from './add-training-page';

const TrainingsRoutingPages = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <ListTrainingsPage {...props} />}
      />


      <Route path={`${match.url}/add-new`}
        render={props => <AddTrainingPage {...props} />}
      />

      <Route path={`${match.url}/edit/:trainingId`}
        render={props => <EditTrainingPage {...props} />}
      />

      <Route path={`${match.url}/preview/:trainingId`}
        render={props => <PreviewTrainingPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
      <Redirect to="/error" />
    </Switch>

  </Suspense>
);
export default TrainingsRoutingPages;
