import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { statName } from '../../const';
// import classNames from 'classnames';
// const statusColor = (status) =>{
//   switch (status) {
//     case "pending":     return "#e9d184";
//     case "confirmed":   return "#777";
//     case "in_shipping": return "#007bff";
//     case "payed":       return "#3cf096";
//     case "delivered":   return "#000"; //#583a7b
//     case "canceled":    return "#ec4656";

//     default:            return "#999"
//   }
// }

const StatusBade = ({ status }) => {
  const status_name = statName(status)
  switch (status) {
    case "pending":
      return (<div className="status_badge" style={{ backgroundColor: "#ec4656" }}>{status_name}</div>)

    case "preparing":
      return (<div className="status_badge" style={{ backgroundColor: "#FF9800" }}>{status_name}</div>)

    case "prepared":
      return (<div className="status_badge" style={{ backgroundColor: "yellow" }}>{status_name}</div>)

    case "shipping":
      return (<div className="status_badge" style={{ backgroundColor: "#007bffd6" }}>{status_name}</div>)

    case "shipped":
      return (<div className="status_badge" style={{ backgroundColor: "#0052ab" }}>{status_name}</div>)

    case "payed":
      return (<div className="status_badge" style={{ backgroundColor: "#28a745" }}>{status_name}</div>)

    case "return":
      return (<div className="status_badge" style={{ backgroundColor: "gray" }}>{status_name}</div>)

    case "lost":
      return (<div className="status_badge" style={{ backgroundColor: "#a3498b" }}>{status_name}</div>)

    default: return null
  }
};

StatusBade.propTypes = {
  status: PropTypes.string.isRequired
};

StatusBade.defaultProps = {
  status: 'pending'
};

export default StatusBade;
