import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
	Card,
	CardBody,
	Badge,
	Table,
	Button,
	Row,
	Col,
	// ButtonGroup, ButtonToolbar,
} from "reactstrap";

// import Select from 'react-select';

import { getClients, editClient, deleteClient } from "../../../redux/actions/clientsActions";
import { getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";

import { CheckBoxField } from "../form/CheckBox";

import { NavLink } from "react-router-dom";
import ConfirmMdl from "../confirm-mdl";
// import { api } from '../../../const';

// const req_statuses = [{label: 'Request', value: 'request'}, {label: 'Accepted', value: 'accepted'}, {label: 'Refused', value: 'refused'}]

class ListClientsCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			clients: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			show_delete_msg: false,
			req_delete_id: null,

			search_query: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	componentWillMount() {
		this.getPage();
	}

	componentDidUpdate(prev) {
		const { loading_one_client, error_one_client, loading_delete_client, error_delete_client } = this.props;

		if (!loading_one_client && prev.loading_one_client && !error_one_client) {
			this.getPage(this.state.current_page);
		}

		if (prev.loading_delete_client && !loading_delete_client && !error_delete_client) {
			this.getPage(this.state.current_page);
		}
	}
	getPage(page = 1) {
		const { selected_page_size, filter_all, filter_status, search_query } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {};
		if (search_query && search_query !== "") filter["name"] = search_query;

		if (!filter_all) filter["statuses"] = filter_status;

		const { only_external, only_internal } = this.props;
		if (only_external == true) filter["type"] = "external";
		if (only_internal == true) filter["type"] = "internal";

		// if (filter_by_query != '') filter['name'] = filter_by_query

		this.props.getClients({ filter, offset, limit: selected_page_size });
	}

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	deleteReq = () => {
		this.props.deleteClient({ _id: this.state.req_delete_id });

		this.setState({ show_delete_msg: false });
	};

	resetFilter = (filter, checked, old_filter_status) => {
		if (checked != undefined) {
			const filter_status = JSON.parse(JSON.stringify(old_filter_status));

			if (filter == "all" && checked)
				this.setState({ filter_all: true, filter_status: [] }, () => this.getPage());
			else {
				const indx = filter_status.indexOf(filter);

				if (indx > -1) filter_status.splice(indx, 1);
				else filter_status.push(filter);

				this.setState({ filter_status, filter_all: filter_status.lengh == 0 }, () => this.getPage());
			}
		}
	};

	renderStatusBadge = (status, style = { fontSize: 13, marginRight: 5 }) => {
		switch (status) {
			case "request":
				return <Badge color="success">Request</Badge>;
			case "accepted":
				return (
					<Badge color="primary" style={style}>
						Accepted
					</Badge>
				);
			case "refused":
				return <Badge style={{ ...style, backgroundColor: "#ff4861" }}>Refused</Badge>;

			default:
				return null;
		}
	};
	render() {
		const { current_page, selected_page_size, filter_all, filter_status, show_delete_msg, search_query } =
			this.state;
		const { clients, count_all_clients } = this.props;

		return (
			<Card>
				<CardBody>
					<div className="card__title">
						{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
						<Row>
							<Col md="10" style={{ marginBottom: 10 }}>
								<CheckBoxField
									CheckBoxField="all"
									value={filter_all}
									label="Tous"
									name="all"
									onChange={(e) => this.resetFilter("all", e.target?.checked, filter_status)}
									color="primary"
									style={{ display: "inline-block", marginRight: 8 }}
								/>

								{/* <CheckBoxField CheckBoxField="request" value={(filter_status.includes("request"))} label="Request" name="request" onChange={(e) => this.resetFilter("request", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							</Col>
							<Col md="2" style={{ marginBottom: 10 }}>
								<NavLink to={"/app/clients/add-new"}>
									<Button color="success" className="rounded">
										Ajouter
									</Button>
								</NavLink>
							</Col>
							<Col md="4" style={{ marginBottom: 0 }}>
								<input
									className="form-control"
									value={search_query}
									onChange={(e) =>
										this.setState(
											{
												search_query: e.target.value,
											},
											() => this.getPage()
										)
									}
									placeholder="Recherche"
								/>
							</Col>
						</Row>
					</div>
					<Table responsive hover striped>
						<thead>
							<tr>
								<th>#</th>
								<th>Nom</th>
								<th>Téléphone</th>
								<th>Type</th>
								<th>Adresse</th>
								<th>Gouvernorat</th>
								<th>Région</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{clients &&
								clients.map((client, i) => (
									<tr key={"client_" + i}>
										<td>{client.type === "internal" ? client.ref_participant : client.ref}</td>
										<td style={{ verticalAlign: "middle" }}>
											<p style={{ margin: 0, color: "#777" }}>
												{client.first_name + " " + client.last_name}
											</p>
											<p style={{ margin: 0, color: "#777" }}>
												{client.first_name_ar + " " + client.last_name_ar}
											</p>
										</td>

										<td style={{ verticalAlign: "middle" }}>
											{client.phone + " / " + client.phone2}
										</td>

										<td style={{ verticalAlign: "middle" }}>{client.type}</td>

										<td style={{ verticalAlign: "middle" }}>{client.address}</td>

										<td style={{ verticalAlign: "middle" }}>{client.city}</td>

										<td style={{ verticalAlign: "middle" }}>{client.region}</td>

										<td style={{ width: 60, verticalAlign: "middle" }}>
											<a target="_blank" href={"/app/clients/edit/" + client._id}>
												<span
													className="lnr lnr-eye mr-2"
													style={{ cursor: "pointer", color: "#4CAF50", fontSize: 18 }}
												></span>
											</a>
										</td>
										<td>
											<span
												onClick={() => {
													this.setState({ req_delete_id: client._id, show_delete_msg: true });
												}}
												className="lnr lnr-trash"
												style={{ color: "#F44336", display: "block", marginTop: 12 }}
											></span>
										</td>
									</tr>
								))}
						</tbody>
					</Table>

					<Pagination
						currentPage={current_page}
						totalPage={getCountPages(count_all_clients, selected_page_size)}
						onChangePage={(i) => this.onChangePage(i)}
					/>

					<ConfirmMdl
						toggle={() =>
							this.setState((prev) => {
								return { show_delete_msg: !prev.show_delete_msg };
							})
						}
						is_open={show_delete_msg}
						accept={() => {
							this.deleteReq();
						}}
					/>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = ({ client }) => {
	const {
		loading_get_clients,
		clients,
		count_all_clients,
		loading_one_client,
		error_one_client,
		loading_delete_client,
		error_delete_client,
	} = client;
	return {
		loading_get_clients,
		clients,
		count_all_clients,
		loading_one_client,
		error_one_client,

		loading_delete_client,
		error_delete_client,
	};
};
export default connect(mapStateToProps, {
	getClients,
	editClient,
	deleteClient,
})(ListClientsCmp);
