import React, { Fragment } from "react";
// import PropTypes from "prop-types";

import { Breadcrumb, Card } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import { Container } from "reactstrap";
import "antd/dist/antd.css";
import ListFollowsCmp from "../../shared/components/follows/ListFollowsCmp";

const ListFollowsPage = (props) => {
	return (
		<Container>
			<Breadcrumb>
				<Breadcrumb.Item href="/">
					<HomeOutlined />
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					{/* <UserSwitchOutlined /> */}
					<span>Suivie</span>
				</Breadcrumb.Item>
			</Breadcrumb>

			<Card className="mt-4" bodyStyle={{ padding: "3px" }}>
				<ListFollowsCmp user={props?.user} />
			</Card>
		</Container>
	);
};
// export default ListFollowsPage;
const mapStateToProps = ({ auth }) => {
	return {
		user: auth.user,
	};
};
export default connect(mapStateToProps, {})(ListFollowsPage);
