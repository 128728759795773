import React, { PureComponent } from 'react';
import { Col, Row, Button } from 'reactstrap';

import { connect } from 'react-redux';

import {
  editShipMode
} from "../../../redux/actions/shipModesActions";

class EditShipModeCmp extends PureComponent {
  constructor() {
    super();
    this.state = {
      name: "",
      price: 0,
      extra_price: 0,
      _id: "",
    };
  }

  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  editShipMode = () => {
    const { name, price, extra_price, _id } = this.state

    if (name != "" && price >= 0) this.props.editShipMode({ data: { name, price, extra_price }, _id })
  }

  componentDidMount() {
    if (this.props.ship_mode) this.setState({
      name: this.props.ship_mode.name,
      price: this.props.ship_mode.price,
      extra_price: this.props.ship_mode.extra_price || 0,
      _id: this.props.ship_mode._id,
    })
  }

  componentDidUpdate(prev) {
    if (prev.loading_edit_ship_mode && !this.props.loading_edit_ship_mode && !this.props.error_edit_ship_mode) {
      this.props.success()
    }
  }


  render() {
    const { name, price, extra_price } = this.state

    return (<Row>
      <Col md={4}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">Nom</span>

            <div className="form__form-group-field">
              <input value={name} placeholder="Nom" onChange={(e) => this.changeVal('name', e.target.value)} />

              {/* <div className="form__form-group-icon">
                meter
              </div> */}
            </div>
          </div>
        </div>
      </Col>

      <Col md={2}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">Price</span>

            <div className="form__form-group-field">
              <input type="number" min="0" value={price} placeholder="Prix" onChange={(e) => this.changeVal('price', e.target.value)} />

              <div className="form__form-group-icon">
                TND
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col md={2}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">Extra</span>

            <div className="form__form-group-field">
              <input type="number" min="0" value={extra_price} placeholder="00.00" onChange={(e) => this.changeVal('extra_price', e.target.value)} />

              <div className="form__form-group-icon">
                TND
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col md={4} style={{ textAlign: 'center', paddingTop: 16 }}>
        <Button color="success" disabled={name == "" || price < 0} onClick={this.editShipMode}>Sauvegardez</Button>
        <Button color="danger" onClick={(this.props.cancelEditing) ? this.props.cancelEditing : () => { }}>Cancel</Button>
      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ shipMode }) => {
  const { loading_edit_ship_mode, error_edit_ship_mode } = shipMode
  return {
    loading_edit_ship_mode, error_edit_ship_mode
  }
}
export default connect(mapStateToProps, {
  editShipMode
})(EditShipModeCmp);