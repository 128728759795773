// ***** ADD PROVIDER
export const ADD_PROVIDER            = 'ADD_PROVIDER';
export const ADD_PROVIDER_SUCCESS    = 'ADD_PROVIDER_SUCCESS';
export const ADD_PROVIDER_ERROR      = 'ADD_PROVIDER_ERROR';

export function addProvider(payload) {
  return {type: ADD_PROVIDER, payload};
}
export function addProviderSuccess(payload) {
  return { type: ADD_PROVIDER_SUCCESS, payload };
}
export function addProviderError(error) {
  return { type: ADD_PROVIDER_ERROR, error };
}




// ***** GET PROVIDERS
export const GET_PROVIDERS            = 'GET_PROVIDERS';
export const GET_PROVIDERS_SUCCESS    = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_ERROR      = 'GET_PROVIDERS_ERROR';

export function getProviders(payload) {
  return {type: GET_PROVIDERS, payload};
}
export function getProvidersSuccess(payload) {
  return { type: GET_PROVIDERS_SUCCESS, payload };
}
export function getProvidersError(error) {
  return { type: GET_PROVIDERS_ERROR, error };
}





// ***** GET ONE PROVIDER
export const GET_ONE_PROVIDER            = 'GET_ONE_PROVIDER';
export const GET_ONE_PROVIDER_SUCCESS    = 'GET_ONE_PROVIDER_SUCCESS';
export const GET_ONE_PROVIDER_ERROR      = 'GET_ONE_PROVIDER_ERROR';

export function getOneProvider(payload) {
  return {type: GET_ONE_PROVIDER, payload};
}
export function getOneProviderSuccess(payload) {
  return { type: GET_ONE_PROVIDER_SUCCESS, payload };
}
export function getOneProviderError(error) {
  return { type: GET_ONE_PROVIDER_ERROR, error };
}



// ***** EDIT PROVIDER
export const EDIT_PROVIDER            = 'EDIT_PROVIDER';
export const EDIT_PROVIDER_SUCCESS    = 'EDIT_PROVIDER_SUCCESS';
export const EDIT_PROVIDER_ERROR      = 'EDIT_PROVIDER_ERROR';

export function editProvider(payload) {
  return {type: EDIT_PROVIDER, payload};
}
export function editProviderSuccess(payload) {
  return { type: EDIT_PROVIDER_SUCCESS, payload };
}
export function editProviderError(error) {
  return { type: EDIT_PROVIDER_ERROR, error };
}


// ***** DELETE PROVIDER
export const DELETE_PROVIDER            = 'DELETE_PROVIDER';
export const DELETE_PROVIDER_SUCCESS    = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_ERROR      = 'DELETE_PROVIDER_ERROR';

export function deleteProvider(payload) {
  return {type: DELETE_PROVIDER, payload};
}
export function deleteProviderSuccess(payload) {
  return { type: DELETE_PROVIDER_SUCCESS, payload };
}
export function deleteProviderError(error) {
  return { type: DELETE_PROVIDER_ERROR, error };
}