
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_PROVIDERS, getProvidersSuccess, getProvidersError, 
  ADD_PROVIDER, addProviderError, addProviderSuccess, 
  GET_ONE_PROVIDER, getOneProviderSuccess, getOneProviderError, 
  EDIT_PROVIDER, editProviderError, editProviderSuccess,

  DELETE_PROVIDER, deleteProviderError, deleteProviderSuccess,
} from '../actions/providersActions';
import { api } from '../../const';



// *** GET PROVIDERS
export function* watchGetProviders() { yield takeEvery(GET_PROVIDERS, getProviders) }
function* getProviders({ payload }) {
  try {
    const ret = yield call(getProvidersRequest, payload);

    if (ret && ret.status == 200) yield put(getProvidersSuccess({ providers: ret.data?.providers || [], count: ret.data?.count || 0}));
    else yield put(getProvidersError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getProvidersError({ error, message: (error) ? error.message : '' }));
  }
}
const getProvidersRequest = async (payload) => {  
  return await axios.post(api.providers+'/filter/'+payload.offset+'/'+payload.limit, payload.filter || {})
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// // *** GET ONE PROVIDER
export function* watchGetOneProvider() { yield takeEvery(GET_ONE_PROVIDER, getOneProvider) }
function* getOneProvider({ payload }) {
  try {
    const ret = yield call(getOneProviderRequest, payload);

    if (ret && ret.status == 200) yield put(getOneProviderSuccess({ provider: ret.data?.provider || null }));
    else yield put(getOneProviderError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneProviderError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneProviderRequest = async (payload) => {  
  return await axios.get(api.providers+"/get-one/"+payload.provider_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD PROVIDERS
export function* watchAddProviders() { yield takeEvery(ADD_PROVIDER, addProvider) }
function* addProvider({ payload }) {
  try {
    const ret = yield call(addProvidersRequest, payload);

    if (ret && ret.status == 201) yield put(addProviderSuccess({ provider: ret.data.provider }));
    else yield put(addProviderError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addProviderError({ error, message: (error) ? error.message : '' }));
  }
}
const addProvidersRequest = async (payload) => {  
  return await axios.post(api.providers+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT PROVIDERS
export function* watchEditProvider() { yield takeEvery(EDIT_PROVIDER, editProvider) }
function* editProvider({ payload }) {
  try {
    const ret = yield call(editProvidersRequest, payload);

    if (ret && ret.status == 200) yield put(editProviderSuccess({ provider: ret.data.provider }));
    else yield put(editProviderError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editProviderError({ error, message: (error) ? error.message : '' }));
  }
}
const editProvidersRequest = async (payload) => {  
  return await axios.patch(api.providers+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE PROVIDERS
export function* watchDeleteProvider() { yield takeEvery(DELETE_PROVIDER, deleteProvider) }
function* deleteProvider({ payload }) {
  try {
    const ret = yield call(deleteProvidersRequest, payload);

    if (ret && ret.status == 200) yield put(deleteProviderSuccess({ }));
    else yield put(deleteProviderError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteProviderError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteProvidersRequest = async (payload) => {  
  return await axios.delete(api.providers+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetProviders),
    fork(watchGetOneProvider),
    fork(watchAddProviders),
    fork(watchEditProvider),
    fork(watchDeleteProvider),
  ]);
}