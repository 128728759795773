// ***** GET CATEGORYS
export const GET_CATEGORYS            = 'GET_CATEGORYS';
export const GET_CATEGORYS_SUCCESS    = 'GET_CATEGORYS_SUCCESS';
export const GET_CATEGORYS_ERROR      = 'GET_CATEGORYS_ERROR';

export function getCtgs(payload) {
  return {type: GET_CATEGORYS, payload};
}
export function getCtgsSuccess(payload) {
  return { type: GET_CATEGORYS_SUCCESS, payload };
}
export function getCtgsError(error) {
  return { type: GET_CATEGORYS_ERROR, error };
}






// ***** ADD CATEGORY
export const ADD_CATEGORY            = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS    = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR      = 'ADD_CATEGORY_ERROR';

export function addCtg(payload) {
  return {type: ADD_CATEGORY, payload};
}
export function addCtgSuccess(payload) {
  return { type: ADD_CATEGORY_SUCCESS, payload };
}
export function addCtgError(error) {
  return { type: ADD_CATEGORY_ERROR, error };
}


// ***** EDIT CATEGORY
export const EDIT_CATEGORY            = 'EDIT_CATEGORY';
export const EDIT_CATEGORY_SUCCESS    = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_ERROR      = 'EDIT_CATEGORY_ERROR';

export function editCtg(payload) {
  return {type: EDIT_CATEGORY, payload};
}
export function editCtgSuccess(payload) {
  return { type: EDIT_CATEGORY_SUCCESS, payload };
}
export function editCtgError(error) {
  return { type: EDIT_CATEGORY_ERROR, error };
}













// ***** DELETE CATEGORY
export const DELETE_CATEGORY            = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS    = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR      = 'DELETE_CATEGORY_ERROR';

export function deleteCtg(payload) {
  return {type: DELETE_CATEGORY, payload};
}
export function deleteCtgSuccess(payload) {
  return { type: DELETE_CATEGORY_SUCCESS, payload };
}
export function deleteCtgError(error) {
  return { type: DELETE_CATEGORY_ERROR, error };
}

