import React, { PureComponent } from "react";
// import { NavLink } from 'react-router-dom';
import { Container } from "reactstrap";
import ArticleCmp from "../../shared/components/articles/article-cmp";

// const AddArticlePage = () => (
//   <Container>
//     <h3 className="page-title mb-3">Ajouter un article</h3>
//     <ArticleCmp history={props} adding={true} />
//   </Container>
// );
// export default AddArticlePage;

export default class AddArticlePage extends PureComponent {
	constructor() {
		super();
		this.state = {};
	}

	render() {
		return (
			<Container>
				<h3 className="page-title mb-3">Ajouter un article</h3>
				<ArticleCmp history={this.props.history} adding={true} />
			</Container>
		);
	}
}
