import React, { PureComponent } from 'react';
// import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  Card, CardBody, Badge, Table, ButtonGroup, ButtonToolbar, Button, Row, Col, CardFooter
} from 'reactstrap';
import { api, no_product_img, pub_url } from '../../../const';

import {
  getCtgs
} from "../../../redux/actions/categoryActions";
import {
  getArticles
} from "../../../redux/actions/articlesActions";

import AddCtgMdl from './add-ctg-mdl';
import CtgsTree from './ctgs-tree';

import classnames from 'classnames';
import Steps from '../default-steps';

class ListCtgsCmp extends PureComponent {

  constructor() {
    super();
    this.state = {
      show_add_ctg_mdl: false,

      mode: "map",


      selected_ctg: null,
      // selected_ctgs 	: []

      ctgs_path: [{ _id: 'main', value: 'main' }],
      active_ctg: { _id: 'main' },
      filtred_ctgs: []
    };
  }


  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  componentWillMount() {
    this.props.getCtgs()
    this.getCtgArticles()
  }

  componentDidUpdate(prev) {
    // console.log("PREV", );
  }

  changeRadio = (e) => {
    this.setState({ selected_ctg: e.target.value })
  }

  changeCtgPath = (ctg, editing = false, index = 0) => {
    this.setState((prev) => {
      let ctgs_path = prev.ctgs_path
      if (!editing)
        ctgs_path.push({
          ...ctg, value: ctg._id, label: ctg.name
        })

      else {
        const new_ctgs = []
        for (let i = 0; i <= index; i++) {
          if (ctgs_path[i]) new_ctgs.push(ctgs_path[i])
        }

        ctgs_path = new_ctgs;
      }

      return { ctgs_path: [...ctgs_path], active_ctg: ctg, filtred_ctgs: ctg.children || [] }
    }, () => this.getCtgArticles(ctg?._id))
  }

  getCtgArticles(ctg_id) {
    this.props.getArticles({
      filter: {
        category: (!ctg_id || ctg_id == "main") ? "5fc764674875e60e6cb3675c" : ctg_id
      }, offset: 0, limit: 200
    })
  }



  toggleAddCtgMdl = () => this.setState((prev) => { return { show_add_ctg_mdl: !prev.show_add_ctg_mdl } })

  render() {
    const { show_add_ctg_mdl, selected_ctg, mode, active_ctg, ctgs_path, filtred_ctgs } = this.state;

    const { show_footer, onSuccess, onCancel, card_style, body_style, ctgs, articles } = this.props

    const map_ctgs = (active_ctg._id == "main") ? ctgs : active_ctg.children || []


    return (<Card style={card_style ? card_style : {}}>
      <CardBody style={body_style ? body_style : {}}>
        <div className="card__title" style={{ textAlign: 'right' }}>
          {/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
          <Button onClick={() => this.setState({ show_add_ctg_mdl: true })} color="success" className="rounded">Ajouter</Button>
        </div>


        <span onClick={() => this.setState({ mode: 'map' })} className={"lnr lnr-layers mode_btn " + (mode == 'map' ? "mode_btn_active" : "")}></span>
        <span onClick={() => this.setState({ mode: 'tree' })} className={"lnr lnr-list mode_btn " + classnames({ mode_btn_active: mode === 'tree' })}></span>




        <div style={{ marginTop: 15 }}></div>
        {mode == 'tree' && <CtgsTree data={ctgs} show_editing={true} show_deleting={true} checkCtg={(ctg_id) => this.setState({ selected_ctg: ctg_id })} />}
        {mode == 'map' && <Row>
          <Col md="12">
            <Steps active_step={active_ctg._id} steps={ctgs_path} changeStep={(e) => {
              this.changeCtgPath(e.step, true, e.index)
            }} />
          </Col>
          {map_ctgs.map((ctg, i) => <Col md="3" key={'map_ctg_' + i}>
            <div onClick={() => this.changeCtgPath(ctg)} className="add_img_wrap ctg_wrapper mb-2">
              <img src={ctg.img ? api.root + "/" + ctg.img.path : no_product_img} />

              <div className="caption">
                {ctg.is_internal && <span className="lnr lnr-enter-down" style={{ fontSize: 25, fontWeight: 500, color: "green" }}></span>}
                {ctg.is_educational_support && <span className="lnr lnr-flag" style={{ fontSize: 25, fontWeight: 500, color: "green" }}></span>}
                <label>{ctg.name}</label>
              </div>
            </div>
          </Col>)}
        </Row>}


        {(mode == "map" && articles && articles.length > 0) && <div>
          <hr style={{ marginTop: 30 }} />
          <h3 style={{ textAlign: 'center', marginTop: -35, marginBottom: 30 }} >
            <span style={{ padding: 10, backgroundColor: "#fff" }} >Produits</span>
          </h3>
          <Row>
            {articles.map((article, i) => <Col md="4" key={'article_' + i}>
              <div className="add_img_wrap ctg_wrapper mb-2">
                <img src={article.img ? api.root + "/" + article.img.path : no_product_img} />

                <div className="caption">
                  <label>{article.name}</label>
                </div>
              </div>
            </Col>)}
          </Row>
        </div>}

      </CardBody>

      {show_footer && <div style={{ padding: 5 }}>
        <ButtonToolbar className="modal__footer form__button-toolbar">
          <Button color="primary" onClick={() => onSuccess(selected_ctg)}>Select</Button>
          <Button type="button" onClick={() => onCancel()}>Cancel</Button>
        </ButtonToolbar>
      </div>}






      <AddCtgMdl handleSuccess={(new_ctg) => {
        this.props.getCtgs(); this.setState({ show_add_ctg_mdl: false })
      }} toggle={this.toggleAddCtgMdl} is_open={show_add_ctg_mdl} />
    </Card>);
  }
}


const mapStateToProps = ({ category, article }) => {
  const { loading_get_ctgs, ctgs } = category
  const { articles } = article

  return {
    loading_get_ctgs, ctgs, articles
  }
}
export default connect(mapStateToProps, {
  getCtgs, getArticles
})(ListCtgsCmp);