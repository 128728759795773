
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_SHIPMODES, getShipModesSuccess, getShipModesError, 
  ADD_SHIPMODE, addShipModeError, addShipModeSuccess, 
  EDIT_SHIPMODE, editShipModeError, editShipModeSuccess,
  DELETE_SHIPMODE, deleteShipModeError, deleteShipModeSuccess
} from '../actions/shipModesActions';
import { api } from '../../const';



// *** GET SHIPMODES
export function* watchGetShipModes() { yield takeEvery(GET_SHIPMODES, getShipModes) }
function* getShipModes({ payload }) {
  try {
    const ret = yield call(getShipModesRequest, payload);

    if (ret && ret.status == 200) yield put(getShipModesSuccess({ ship_modes: ret.data?.ship_modes || [], count: ret.data?.count || 0}));
    else yield put(getShipModesError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getShipModesError({ error, message: (error) ? error.message : '' }));
  }
}
const getShipModesRequest = async (payload) => {  
  return await axios.get(api.ship_modes+'/list')
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** GET ONE SHIPMODE
// export function* watchGetOneShipMode() { yield takeEvery(GET_ONE_SHIPMODE, getOneShipMode) }
// function* getOneShipMode({ payload }) {
//   try {
//     const ret = yield call(getOneShipModeRequest, payload);

//     if (ret && ret.status == 200) yield put(getOneShipModeSuccess({ ship_mode: ret.data?.ship_mode || null }));
//     else yield put(getOneShipModeError({ message: ret.data ? ret.data.msg : "Error" }))

//   } catch (error) {
//     yield put(getOneShipModeError({ error, message: (error) ? error.message : '' }));
//   }
// }
// const getOneShipModeRequest = async (payload) => {  
//   const endpoint = (payload.track) ? '/track-one/' : '/get-one/'
//   return await axios.get(api.ship_modes+endpoint+payload.ship_mode_id)
//   .then(resp => resp)
//   .catch(error => { return (error && error.response) ? error.response : error })
// };





// *** ADD SHIPMODES
export function* watchAddShipModes() { yield takeEvery(ADD_SHIPMODE, addShipMode) }
function* addShipMode({ payload }) {
  try {
    const ret = yield call(addShipModesRequest, payload);

    if (ret && ret.status == 201) yield put(addShipModeSuccess({ ship_mode: ret.data.ship_mode }));
    else yield put(addShipModeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addShipModeError({ error, message: (error) ? error.message : '' }));
  }
}
const addShipModesRequest = async (payload) => {  
  return await axios.post(api.ship_modes+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT SHIPMODES
export function* watchEditShipMode() { yield takeEvery(EDIT_SHIPMODE, editShipMode) }
function* editShipMode({ payload }) {
  try {
    const ret = yield call(editShipModesRequest, payload);

    if (ret && ret.status == 200) yield put(editShipModeSuccess({ ship_mode: ret.data.ship_mode }));
    else yield put(editShipModeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editShipModeError({ error, message: (error) ? error.message : '' }));
  }
}
const editShipModesRequest = async (payload) => {  
  return await axios.patch(api.ship_modes+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};















// *** DELETE SHIPMODE
export function* watchDeleteShipMode() { yield takeEvery(DELETE_SHIPMODE, deleteShipMode) }
function* deleteShipMode({ payload }) {
  try {
    const ret = yield call(deleteShipModeRequest, payload);

    if (ret && ret.status == 200) yield put(deleteShipModeSuccess({ ship_mode: ret.data.ship_mode }));
    else yield put(deleteShipModeError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteShipModeError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteShipModeRequest = async (payload) => {  
  return await axios.delete(api.ship_modes+"/delete-one/"+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetShipModes),
    fork(watchDeleteShipMode),
    fork(watchAddShipModes),
    fork(watchEditShipMode)
  ]);
}