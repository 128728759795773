import React, { PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { Field, reduxForm } from 'redux-form';

import { Button, ButtonToolbar, Card, CardBody, Row, Col, Table } from "reactstrap";
// import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";

import { NavLink } from "react-router-dom";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { addPurchase, editPurchase, getOnePurchase } from "../../../redux/actions/purchasesActions";

// import Switch from "rc-switch";
// import "rc-switch/assets/index.css";

// import classnames from 'classnames';
import { api, no_product_img, no_user_img, shipping_modes } from "../../../const";
import SelectArticle from "../articles/SelectArticle";
import SelectProvider from "../providers/SelectProvider";
import ConfirmMdl from "../confirm-mdl";
import { articleStockColor } from "../../helper/utils";
// import SelectPurchase from './SelectPurchase';

const statuses = [
	{ label: "En attente", value: "pending", key: 0 },
	{ label: "Payée", value: "payed", key: 1 },
];

class PurchaseCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			ref_provider: "",
			status: "payed",

			provider: null,

			articles: [],
			selected_articles: [],

			date: new Date(),

			shipping_price: 0,
			shipping_mode: "other",

			sub_total: 0,
			expenses: 0,
			discount: 0,
			total: 0,

			show_select_pro_mdl: false,
			show_select_prd: false,
			show_confirm: false,
			error_frm: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj, () => this.calcTotal());
	};

	toggleSelectProMdl = () =>
		this.setState((prev) => {
			return { show_select_pro_mdl: !prev.show_select_pro_mdl };
		});
	addPurchaseProvider = (data) => {
		if (data && Array.isArray(data) && data[0]) {
			this.setState({ provider: data[0], show_select_pro_mdl: false });
		} else this.setState({ show_select_pro_mdl: false });
	};

	toggleSelectArticleMdl = () =>
		this.setState((prev) => {
			return { show_select_prd: !prev.show_select_prd };
		});
	addPurchaseArticle = (data) => {
		if (data && Array.isArray(data)) {
			this.setState(
				(prev) => {
					const articles = prev.articles;
					const selected_articles = prev.selected_articles;

					for (let i = 0; i < data.length; i++) {
						let exist = false;
						for (let j = 0; j < articles.length; j++) {
							if (articles[j].article._id == data[i]._id) {
								exist = true;
								break;
							}
						}
						if (!exist) {
							articles.push({
								name: data[i].name,
								ref: data[i].ref,
								desc: data[i].desc,
								short_desc: data[i].short_desc,
								category_name: data[i].category ? data[i].category.name : "",

								qty: 1,
								unit_price: data[i].price || 0,
								// total_price: data[i].price || 0,

								article: data[i],
							});

							selected_articles.push(data[i]);
						}
					}
					return {
						show_select_prd: false,
						articles: [...articles],
						selected_articles: [...selected_articles],
					};
				},
				() => {
					this.calcTotal();
				}
			);
		} else
			this.setState({ show_select_prd: false }, () => {
				this.calcTotal();
			});
	};
	handleRmArticle = (_id) => {
		this.setState(
			(prev) => {
				const { selected_articles, articles } = prev;
				for (let i = 0; i < selected_articles.length; i++) {
					if (selected_articles[i]._id == _id) {
						selected_articles.splice(i, 1);
						break;
					}
				}
				for (let i = 0; i < articles.length; i++) {
					if (articles[i].article._id == _id) {
						articles.splice(i, 1);
						break;
					}
				}

				return { articles: [...articles], selected_articles: [...selected_articles] };
			},
			() => {
				this.calcTotal();
			}
		);
	};
	changeArticleValue = (i, key, val) =>
		this.setState(
			(prev) => {
				const articles = prev.articles;
				articles[i][key] = val;
				return { articles: [...articles] };
			},
			() => {
				this.calcTotal();
			}
		);

	submit = () => {
		const error_frm = this.checkFrm(this.state);
		this.setState({ error_frm });

		const { addPurchase, editPurchase, editing } = this.props;

		if (error_frm == "") {
			const {
				ref_provider,
				status,
				provider,
				articles,
				date,
				shipping_price,
				shipping_mode,
				sub_total,
				expenses,
				total,
				discount,
			} = this.state;

			const new_articles = [];
			for (let i = 0; i < articles.length; i++) {
				const item = articles[i];

				new_articles.push({
					...item,
					article: item.article._id,
				});
			}

			const data = {
				ref_provider,
				status,
				date,
				shipping_price,
				shipping_mode,
				sub_total,
				expenses,
				total,
				discount,
				provider: provider ? provider._id : null,
				articles: new_articles,
			};

			if (editing) editPurchase({ _id: this.props.purchase_id, data });
			else addPurchase(data);
		}
	};
	checkFrm = (data) => {
		const { provider, articles } = data;
		if (!provider) return "Le fournisseur est requis.";
		// if (ref_provider == "") return "Référence de fournisseur est requis.";
		if (!articles || articles.length == 0) return "Vous devez sélectionner au moins un article.";
		return "";
	};

	componentDidUpdate(prev) {
		const {
			loading_add_purchase,
			error_add_purchase,
			new_purchase,
			selected_purchase,
			loading_one_purchase,
			error_one_purchase,
			loading_edit_purchase,
			error_edit_purchase,
		} = this.props;

		if (!loading_add_purchase && prev.loading_add_purchase) {
			// console.log("error_add_purchase", error_add_purchase, new_purchase);
			if (error_add_purchase) {
				if (error_add_purchase.message && error_add_purchase.message != "")
					this.setState({ error_frm: error_add_purchase.message });
			} else this.props.history.push("/app/purchases/");
		}

		// EDITING
		if (!loading_edit_purchase && prev.loading_edit_purchase) {
			// console.log("error_add_purchase", error_add_purchase, new_purchase);
			if (error_edit_purchase) {
				if (error_edit_purchase.message && error_edit_purchase.message != "")
					this.setState({ error_frm: error_edit_purchase.message });
			} else this.props.history.push("/app/purchases/purchases");
		}

		//GET ONE
		if (prev.loading_one_purchase && !loading_one_purchase && !error_one_purchase) {
			if (selected_purchase) {
				console.log("selected_purchase", selected_purchase);
				const {
					ref_provider,
					status,
					provider,
					articles,
					date,
					shipping_price,
					shipping_mode,
					sub_total,
					expenses,
					total,
					discount,
				} = selected_purchase;

				this.setState({
					ref_provider,
					status,
					provider,
					articles,
					date,
					shipping_price,
					shipping_mode,
					sub_total,
					expenses,
					total,
					discount,
				});
			}
		}
	}

	componentDidMount() {
		if (this.props.editing) {
			const { match, getOnePurchase } = this.props;
			if (match && match.params) getOnePurchase({ purchase_id: match.params.purchaseId });
		}
	}

	calcTotal = () => {
		const { articles, expenses, shipping_price, discount } = this.state;
		const sub_total = articles.reduce(
			(sub, pur_article) => Number(pur_article.unit_price) * Number(pur_article.qty) + sub,
			0
		);
		this.setState({
			sub_total,
			total: Number(
				parseFloat(sub_total + Number(expenses) + Number(shipping_price) - Number(discount)).toFixed(2)
			),
		});
	};

	render() {
		const { editing } = this.props;
		const {
			ref_provider,
			status,
			provider,
			articles,
			date,
			shipping_price,
			shipping_mode,
			sub_total,
			expenses,
			total,
			error_frm,
			discount,
			show_select_pro_mdl,
			show_select_prd,
			selected_articles,
			show_confirm,
		} = this.state;

		return (
			<div>
				<Card>
					<CardBody>
						<Row className="form">
							<Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
								<Alert
									is_visible={this.state.error_frm != ""}
									color="danger"
									className="alert--colored"
									icon
								>
									<p>
										<span className="bold-text">Attention!</span> {this.state.error_frm}
									</p>
								</Alert>
							</Col>

							<Col md="2">
								<div className="add_img_wrap">
									<img
										onClick={(e) => this.setState({ show_select_pro_mdl: true })}
										src={
											provider && provider.img ? api.root + "/" + provider.img.path : no_user_img
										}
									/>

									<div className="caption">
										<label htmlFor="select_provider">
											<span className="lnr lnr-store"></span>
										</label>
										<input
											readOnly
											onClick={(e) => this.setState({ show_select_pro_mdl: true })}
											id="select_provider"
											hidden
										/>
									</div>
								</div>
							</Col>

							<Col md="6">
								<div className="form__form-group">
									<span className="form__form-group-label">Fournisseur</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											onClick={(e) => this.setState({ show_select_pro_mdl: true })}
											readOnly
											component="input"
											placeholder="Fournisseur"
											value={provider ? provider.name : ""}
										/>
									</div>
								</div>

								{provider && (
									<div style={{ color: "#777" }}>
										<p>
											<span className="lnr lnr-phone-handset"></span> {provider.phone}
										</p>
										<p>
											<span className="lnr lnr-map-marker"></span> {provider.address}
										</p>
									</div>
								)}
							</Col>

							<Col md="4">
								<div className="form__form-group">
									<span className="form__form-group-label">Statut</span>
									<div className="form__form-group-field">
										<Select
											name="status"
											defaultValue={status}
											value={statuses.filter((option) => option.value === status)}
											onChange={(e) => this.changeVal("status", e.value)}
											options={statuses}
											clearable={false}
											className="react-select"
											placeholder="Statut"
											classNamePrefix="react-select"
										/>
									</div>
								</div>

								<div className="form__form-group">
									<span className="form__form-group-label">Ref fournisseur</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="ref_provider"
											defaultValue={ref_provider}
											component="input"
											placeholder="Ref fournisseur"
											onChange={(e) => this.changeVal("ref_provider", e.target.value)}
										/>
									</div>
								</div>
							</Col>

							<Col md="4"></Col>
							<Col md="4">
								<span className="form__form-group-label">Date d'achat</span>{" "}
								<DatePicker
									selected={new Date(date)}
									onChange={(e) => this.changeVal("date", e)}
									placeholderText="Date"
								/>
							</Col>
							<Col md="4"></Col>

							<Col md="12" className="mt-5">
								<Table responsive hover>
									<thead>
										<tr>
											<th colSpan="2">Article</th>
											<th style={{ width: 120 }}>QTE</th>
											<th style={{ width: 120 }}>Prix unitaire</th>
											<th style={{ width: 120 }}>Total</th>
											<th style={{ width: 60 }}></th>
										</tr>
									</thead>
									<tbody>
										{articles &&
											articles.map((pur_article, i) => (
												<tr
													key={"article_" + i}
													style={
														!pur_article.article.is_pack
															? {
																	backgroundColor: articleStockColor(
																		pur_article.article.qty
																	),
															  }
															: {}
													}
												>
													<td style={{ width: 50 }}>
														{pur_article.article && pur_article.article.img ? (
															<img
																src={api.root + "/" + pur_article.article.img.path}
																style={{ width: 40 }}
															/>
														) : (
															<img src={no_product_img} style={{ width: 40 }} />
														)}
													</td>

													<td style={{ verticalAlign: "middle" }}>
														{pur_article.article.name}
														<p style={{ color: "#777" }}>{pur_article.article.price} dt</p>
													</td>

													<td style={{ verticalAlign: "middle" }}>
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															type="number"
															min="1"
															defaultValue={pur_article.qty}
															component="input"
															placeholder="QTE"
															onChange={(e) =>
																this.changeArticleValue(
																	i,
																	"qty",
																	Number(e.target.value)
																)
															}
														/>
													</td>
													<td style={{ verticalAlign: "middle" }}>
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															type="number"
															min="0"
															defaultValue={pur_article.unit_price}
															component="input"
															placeholder="Prix unitaire"
															onChange={(e) =>
																this.changeArticleValue(
																	i,
																	"unit_price",
																	Number(e.target.value)
																)
															}
														/>
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{pur_article.unit_price * pur_article.qty}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														<span
															onClick={() =>
																this.handleRmArticle(pur_article.article._id)
															}
															style={{ color: "red", cursor: "pointer" }}
															className="lnr lnr-trash"
														></span>
													</td>
												</tr>
											))}

										<tr>
											<td colSpan="6">
												<p
													onClick={() => this.setState({ show_select_prd: true })}
													style={{
														color: "#4CAF50",
														cursor: "pointer",
														width: 100,
													}}
												>
													<span className="lnr lnr-plus-circle"></span> Ajouter
												</p>
											</td>
										</tr>
									</tbody>
								</Table>
							</Col>

							{/* TOTALS */}
							<Col md="7"></Col>
							{articles.length == 0 ? (
								<Col md="5"></Col>
							) : (
								<Col md="5" style={{ textAlign: "right" }}>
									<Table>
										<tbody>
											<tr>
												<td>
													<strong>Sous-total</strong>
												</td>
												<td>{sub_total}</td>
											</tr>
											<tr>
												<td>
													<strong>Mode de livraison</strong>
												</td>
												<td>
													<Select
														name="shipping_modes"
														defaultValue={shipping_mode}
														value={shipping_modes.filter(
															(option) => option.value === shipping_mode
														)}
														onChange={(e) => this.changeVal("shipping_mode", e.value)}
														options={shipping_modes}
														clearable={false}
														className="react-select"
														placeholder="Mode de livraison"
														classNamePrefix="react-select"
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>Prix de livraison</strong>
												</td>
												<td>
													<div className="form__form-group-field">
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															name="shipping_price"
															type="number"
															min="0"
															defaultValue={shipping_price || 0}
															component="input"
															placeholder="Prix de livraison"
															onChange={(e) =>
																this.changeVal("shipping_price", Number(e.target.value))
															}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>Autres</strong>
												</td>
												<td>
													<div className="form__form-group-field">
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															name="expenses"
															type="number"
															min="0"
															defaultValue={expenses || 0}
															component="input"
															placeholder="Autres dépenses"
															onChange={(e) =>
																this.changeVal("expenses", Number(e.target.value))
															}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>Privilège</strong>
												</td>
												<td>
													<div className="form__form-group-field">
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															name="discount"
															type="number"
															min="0"
															defaultValue={discount || 0}
															component="input"
															placeholder="0"
															onChange={(e) =>
																this.changeVal("discount", Number(e.target.value))
															}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>Total</strong>
												</td>
												<td>
													<strong>{total}</strong>
												</td>
											</tr>
										</tbody>
									</Table>
								</Col>
							)}
						</Row>

						<div className="mt-5" style={{ textAlign: "right" }}>
							<ButtonToolbar
								className="modal__footer form__button-toolbar"
								style={{ textAlign: "right" }}
							>
								<Button color="primary" onClick={() => this.setState({ show_confirm: true })}>
									Soumettre
								</Button>
								<NavLink to="/app/purchases">
									<Button type="button">Annuler</Button>
								</NavLink>
							</ButtonToolbar>
						</div>
					</CardBody>
				</Card>

				{/* MODALS */}
				<SelectArticle
					size="lg"
					multi={true}
					isOpen={show_select_prd}
					toggleOpen={this.toggleSelectArticleMdl}
					accept={this.addPurchaseArticle}
					selectedItems={selected_articles}
				/>

				<SelectProvider
					size="lg"
					multi={false}
					isOpen={show_select_pro_mdl}
					toggleOpen={this.toggleSelectProMdl}
					accept={this.addPurchaseProvider}
					selectedItems={provider ? [provider] : []}
				/>

				<ConfirmMdl
					toggle={() =>
						this.setState((prev) => {
							return { show_confirm: !prev.show_confirm };
						})
					}
					message="Êtes-vous sûr de confirmer cette commande"
					message2={
						status == "payed"
							? "NB : Avec le statut payé, chaque article de cette commande augmentera la quantité en stock"
							: null
					}
					is_open={show_confirm}
					accept={() => {
						this.submit();
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ purchase }) => {
	const {
		loading_add_purchase,
		error_add_purchase,
		new_purchase,
		selected_purchase,
		loading_one_purchase,
		error_one_purchase,
		loading_edit_purchase,
		error_edit_purchase,
	} = purchase;
	return {
		new_purchase,
		selected_purchase,
		loading_add_purchase,
		error_add_purchase,
		loading_one_purchase,
		error_one_purchase,
		loading_edit_purchase,
		error_edit_purchase,
	};
};
export default connect(mapStateToProps, {
	addPurchase,
	editPurchase,
	getOnePurchase,
})(PurchaseCmp);
