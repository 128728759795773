import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { Card, Spin, Row, Col, Avatar, Badge, Typography, Tabs, Space } from "antd";

import { UserOutlined, HeatMapOutlined, FacebookOutlined } from "@ant-design/icons";
import Axios from "axios";
import { activityStatus, api } from "../../../../const";
import "./trainer.scss";
import TrainerCmdCM from "./TrainerCmdCM";
import ClientTrainings from "./ClientTrainings";
import ClubCm from "./ClubCm"

const { Title } = Typography;
const { TabPane } = Tabs;

const TrainerCmp = (props) => {
	const { trainer_id, changeName } = props;

	const [loading, setLoading] = useState(false);
	const [trainer, setTrainer] = useState(null);
	const [active_tab, setActiveTab] = useState("cmd_cm");
	const [count_cmd, setCountCmd] = useState(0);
	const [trainings_infos, setTrainingsInfos] = useState({
		count: 0,
		trainings: [],
		activity_status: {
			name: "",
			color: "",
		},
	});

	const getOne = () => {
		setLoading(true);

		Axios.get(`${api.clients}/get-one/${trainer_id}`)
			.then((resp) => {
				setLoading(false);
				const new_trainer = resp?.data?.client;
				setTrainer(new_trainer);
				changeName(
					`${new_trainer.first_name} ${new_trainer.last_name} ( ${new_trainer.first_name_ar} ${new_trainer.last_name_ar} )`
				);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const getClientTrainings = (trains_resp) => {
		// console.log(('----------------------->trains_resp', trains_resp));
		const trains = trains_resp?.participants || [];
		setTrainingsInfos({
			count: trains_resp?.count || 0,
			trainings: trains,
			activity_status: activityStatus(
				trains[0] && trains[0].activities && trains[0].activities[0] ? trains[0].activities[0].status : ""
			),
		});
	};

	const getLastTraining = (participant) => {
		Axios.post(`${api.participants}/filter/0/1`, { participant })
			.then((resp) => {
				getClientTrainings(resp.data);
			})
			.catch((err) => {});
	};

	useEffect(() => {
		getOne();
		getLastTraining(trainer_id);
	}, [trainer_id]);

	// useEffect(() => {
	// 	getShipModes();
	// }, []);

	if (loading)
		return (
			<div style={{ textAlign: "center", marginTop: 100 }}>
				<Spin size="large" />
			</div>
		);

	if (!trainer) return null;

	const renderActiveTab = () => {
		switch (active_tab) {
			case "cmd_cm":
				return (
					<TrainerCmdCM
						// ship_modes={ship_modes}
						trainer_id={trainer_id}
						changeCountAll={(count) => setCountCmd(count || 0)}
					/>
				);
			case "trains":
				return (
					<ClientTrainings
						getClientTrainings={(trains_resp) => getClientTrainings(trains_resp)}
						client_id={trainer_id}
						client={trainer}
					/>
				);
			case "club_cm":
				return <ClubCm client_id={trainer_id} client={trainer} />;
			default:
				return <p>{active_tab}</p>;
		}
	};

	// console.log(trainings_infos);

	return (
		<React.Fragment>
			<Card className="card-infos" bodyStyle={{ padding: "10px 10px 0 10px" }}>
				<Space size={13}>
					<Badge.Ribbon
						text={trainings_infos?.activity_status?.name}
						style={{ fontSize: 15 }}
						color={trainings_infos?.activity_status?.color}
					>
						<Avatar
							shape="square"
							size={150}
							icon={<UserOutlined />}
							// src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
							src={trainer?.image}
						/>
					</Badge.Ribbon>
					<Row>
						<Col md={24}>
							<Title
								level={3}
								style={{ color: "#333" }}
							>{`${trainer.first_name} ${trainer.last_name} ( ${trainer.first_name_ar} ${trainer.last_name_ar} )`}</Title>
							<Col md={24}>
								<HeatMapOutlined /> {trainer?.region}, {trainer?.city}
							</Col>
							<Col md={24}>
								<HeatMapOutlined /> {trainer?.address}
							</Col>
							<Col md={24}>
								<FacebookOutlined /> {trainer?.fb}
							</Col>
							<Col md={24}>Institution : {trainer?.institution}</Col>
							<Col md={24}>Profession : {trainer?.profession}</Col>
						</Col>
					</Row>

					<div style={{ width: 300, textAlign: "center" }}>Total CMD : {count_cmd}</div>
				</Space>

				<Tabs className="card-tabs" defaultActiveKey={active_tab} onChange={(key) => setActiveTab(key)}>
					<TabPane tab="Commandes CM" key="cmd_cm">
						{/* Commandes CM */}
					</TabPane>
					<TabPane tab="Formations" key="trains">
						{/* Formations */}
					</TabPane>
					<TabPane tab="Club CM" key="club_cm">
						{/* Club CM */}
					</TabPane>
					{/* <TabPane tab="Suivi" key="follow">
					</TabPane> */}
					<TabPane tab="Statistiques" key="stats">
						{/* Statistiques */}
					</TabPane>
				</Tabs>
			</Card>

			<Card className="mt-4" bodyStyle={{ padding: 5 }}>
				{renderActiveTab()}
			</Card>
		</React.Fragment>
	);
};

TrainerCmp.prototype = {
	changeName: PropTypes.func.isRequired,
	trainer_id: PropTypes.string,
};
export default TrainerCmp;
