import React, { PureComponent } from "react";
// import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { Card, CardBody, Badge, Table, ButtonGroup, ButtonToolbar, Button, Row, Col, CardFooter } from "reactstrap";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

// import { Redirect } from 'react-router-dom';

// import EyeIcon from 'mdi-react/EyeIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import { Button } from 'reactstrap';

// import renderCheckBoxField from '../../../shared/components/form/CheckBox';

import { getUsers, editUser } from "../../../redux/actions/authActions";
import { getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";
import AddUserMdl from "./add-user-mdl";
// import renderRadioButtonField from '../form/RadioButton';
// import { filter } from '../../../../../API/app/controllers/users-ctrl/actions/get';

import { NavLink } from "react-router-dom";
import { api } from "../../../const";

class ListUsersCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			filter_by_user: false,
			filter_by_tech_support: false,

			filter_roles: [],

			filter_all: true,
			users: [],
			filter_by_query: "",

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,
			page_sizes: [5, 10, 20, 30, 50],

			show_add_user_mdl: false,

			selected_user: null,
			selected_users: [],
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	componentWillMount() {
		this.getPage();
	}

	componentDidUpdate(prev) {
		const { loading_edit_user, error_edit_user } = this.props;

		if (prev.loading_edit_user != loading_edit_user && !error_edit_user) {
			this.getPage(this.state.current_page);
		}
	}

	getPage(page = 1) {
		const { selected_page_size, filter_all, filter_roles, filter_by_query } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {};
		if (this.props.only_clients) filter["roles"] = ["USER"];
		else if (!filter_all) filter["roles"] = filter_roles;

		if (filter_by_query != "") filter["name"] = filter_by_query;

		this.props.getUsers({ filter, offset, limit: selected_page_size });
	}

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	resetFilter = (filter, old_filter_roles) => {
		const filter_roles = JSON.parse(JSON.stringify(old_filter_roles));

		if (filter == "ALL") this.setState({ filter_roles: [], filter_all: true }, () => this.getPage());
		else {
			const indx = filter_roles.indexOf(filter);

			if (indx > -1) filter_roles.splice(indx, 1);
			else filter_roles.push(filter);

			this.setState({ filter_roles, filter_all: filter_roles.lengh == 0 }, () => this.getPage());
		}
	};

	toggleAddUserMdl = () =>
		this.setState((prev) => {
			return { show_add_user_mdl: !prev.show_add_user_mdl };
		});

	changeSelectedUser = (usr, checked = true) => {
		if (usr) {
			if (!this.props.multi) this.props.changeSelectedUser(usr);
			// console.log("changeSelectedUser", e.target.checked);
		}
	};

	editProfile = (_id, data) => {
		this.props.editUser({
			_id,
			data,
		});
	};

	render() {
		// const { handleSubmit } = this.props;
		const {
			current_page,
			selected_page_size,
			filter_all,
			filter_roles,
			show_add_user_mdl,
			//filter_by_query,
			selected_user,
			selected_users,
		} = this.state;

		const {
			user,
			// is_admin,
			users,
			count_all_users,
			multi,
			show_footer,
			onSuccess,
			onCancel,
			only_clients,
			card_style,
			body_style,
			show_selection,
		} = this.props;
		// console.log("=======+++++++++++++++++> CONNECTED USER", user);
		// console.log("is_admin", is_admin);

		return (
			<Card style={card_style ? card_style : {}}>
				<CardBody style={body_style ? body_style : {}}>
					<div className="card__title">
						{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
						<Row>
							<Col md="8">
								{!only_clients && (
									<ButtonToolbar>
										<ButtonGroup dir="ltr">
											<Button
												onClick={() => this.resetFilter("ALL", filter_roles)}
												outline
												style={filter_all ? { background: "#e7e2e2" } : {}}
											>
												All
											</Button>
											<Button
												onClick={() => this.resetFilter("USER", filter_roles)}
												style={filter_roles.includes("USER") ? { background: "#e7e2e2" } : {}}
												outline
											>
												Utilisateurs
											</Button>
											<Button
												onClick={() => this.resetFilter("TECH", filter_roles)}
												style={filter_roles.includes("TECH") ? { background: "#e7e2e2" } : {}}
												outline
											>
												Tech Support
											</Button>
											<Button
												onClick={() => this.resetFilter("TECH_TRAINS", filter_roles)}
												style={filter_roles.includes("TECH_TRAINS") ? { background: "#e7e2e2" } : {}}
												outline
											>
												Tech Formation
											</Button>
										</ButtonGroup>
									</ButtonToolbar>
								)}
							</Col>

							{user && user.roles.includes("ADMIN") && (
								<Col md="4" style={{ textAlign: "right" }}>
									<Button
										onClick={() => this.setState({ show_add_user_mdl: true })}
										color="success"
										className="rounded"
									>
										Ajouter
									</Button>
									<AddUserMdl
										only_clients={only_clients}
										handleSuccess={(new_user) => {
											this.getPage();
											this.setState({ show_add_user_mdl: false });
										}}
										toggle={this.toggleAddUserMdl}
										is_open={show_add_user_mdl}
									/>
								</Col>
							)}
						</Row>
					</div>

					<Table responsive hover>
						<thead>
							<tr>
								<th></th>
								<th></th>
								<th>Nom</th>
								<th>Email</th>
								<th>Role</th>
								<th>Mobile</th>

								<th>Cordinateur</th>
								<th>Formateur</th>
								<th>Organisateur</th>
								<th>Commercial</th>

								<th>Activé</th>
								<th>Connexion</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{users &&
								users.map((usr, i) => (
									<tr key={"user_" + i}>
										{show_selection && !multi && (
											<td style={{ verticalAlign: "middle" }}>
												<input
													name="selected_user"
													type="radio"
													onChange={() => this.setState({ selected_user: usr })}
													checked={selected_user && selected_user._id == usr._id}
												/>
											</td>
										)}
										{!show_selection && <td style={{ verticalAlign: "middle" }}></td>}
										<td>
											{usr.avatar ? (
												<img src={`${api.root}/${usr.avatar.path}`} style={{ width: 40 }} />
											) : (
												<img
													src={`${process.env.PUBLIC_URL}/img/no_user.png`}
													style={{ width: 40 }}
												/>
											)}
										</td>

										<td style={{ verticalAlign: "middle" }}>
											{usr.first_name + " " + usr.last_name}
										</td>

										<td style={{ verticalAlign: "middle" }}>{usr.email}</td>

										<td style={{ verticalAlign: "middle" }}>
											{usr.roles.includes("USER") && (
												<Badge color="success" style={{ fontSize: 13, margin: 3 }}>
													Client
												</Badge>
											)}
											{usr.roles.includes("TECH") && (
												<Badge color="warning" style={{ fontSize: 13, margin: 3 }}>
													Tech
												</Badge>
											)}
											{usr.roles.includes("TECH_TRAINS") && (
												<Badge color="info" style={{ fontSize: 13, margin: 3 }}>
													Tech Formation
												</Badge>
											)}
											{usr.roles.includes("TECH_CMD") && (
												<Badge color="info" style={{ fontSize: 13, margin: 3 }}>
													Tech CMD
												</Badge>
											)}
											{usr.roles.includes("TECH_CMD_CM") && (
												<Badge color="info" style={{ fontSize: 13, margin: 3 }}>
													Tech CMD CM
												</Badge>
											)}
											{usr.roles.includes("ADMIN") && (
												<Badge color="danger" style={{ fontSize: 13, margin: 3 }}>
													Admin
												</Badge>
											)}
										</td>

										<td style={{ verticalAlign: "middle" }}>{usr.mobile}</td>

										<td style={{ verticalAlign: "middle" }}>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={usr.is_coordinator}
												onChange={(cordinator) => {
													if (user?.roles?.includes("ADMIN"))
														this.editProfile(usr._id, { is_coordinator: cordinator });
												}}
											/>
										</td>
										<td style={{ verticalAlign: "middle" }}>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={usr.is_former}
												onChange={(former) => {
													if (user?.roles?.includes("ADMIN"))
														this.editProfile(usr._id, { is_former: former });
												}}
											/>
										</td>
										<td style={{ verticalAlign: "middle" }}>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={usr.is_organizer}
												onChange={(organizer) => {
													if (user?.roles?.includes("ADMIN"))
														this.editProfile(usr._id, { is_organizer: organizer });
												}}
											/>
										</td>
										<td style={{ verticalAlign: "middle" }}>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={usr.is_commercial}
												onChange={(commercial) => {
													if (user?.roles?.includes("ADMIN"))
														this.editProfile(usr._id, { is_commercial: commercial });
												}}
											/>
										</td>

										<td style={{ verticalAlign: "middle" }}>
											{usr.active && (
												<span
													className="lnr lnr-checkmark-circle"
													style={{ fontSize: 15, fontWeight: "bolder", color: "#28a745" }}
												></span>
											)}
											{!usr.active && (
												<span
													className="lnr lnr-cross-circle"
													style={{ fontSize: 15, fontWeight: "bolder", color: "#dc3545" }}
												></span>
											)}
										</td>

										<td style={{ verticalAlign: "middle" }}>
											<Switch
												className="custom-switch custom-switch-primary"
												checked={usr.can_connect}
												onChange={(can_conx) =>
													this.editProfile(usr._id, { can_connect: can_conx })
												}
											/>
										</td>

										<td style={{ verticalAlign: "middle" }}>
											{!only_clients && (
												<a target="_blank" href={"/app/users/edit/" + usr._id}>
													<span
														className="lnr lnr-eye"
														style={{ color: "#70bbfd", fontSize: 18 }}
													></span>
												</a>
											)}
											{only_clients && (
												<a target="_blank" href={"/app/clients/edit/" + usr._id}>
													<span
														className="lnr lnr-eye"
														style={{ color: "#70bbfd", fontSize: 18 }}
													></span>
												</a>
											)}
										</td>
									</tr>
								))}
						</tbody>
					</Table>

					<Pagination
						currentPage={current_page}
						totalPage={getCountPages(count_all_users, selected_page_size)}
						onChangePage={(i) => this.onChangePage(i)}
					/>
				</CardBody>

				{show_footer && (
					<div style={{ padding: 5 }}>
						<ButtonToolbar className="modal__footer form__button-toolbar">
							<Button color="primary" onClick={() => onSuccess(!multi ? selected_user : selected_users)}>
								Select
							</Button>
							<Button type="button" onClick={() => onCancel()}>
								Cancel
							</Button>
						</ButtonToolbar>
					</div>
				)}
			</Card>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	const { loading_get_users, user, users, count_all_users, is_admin, loading_edit_user, error_edit_user } = auth;
	return {
		loading_get_users,
		user,
		users,
		count_all_users,
		is_admin,
		loading_edit_user,
		error_edit_user,
	};
};
export default connect(mapStateToProps, {
	getUsers,
	editUser,
})(ListUsersCmp);
