import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import PreviewTrainingCmp from '../../shared/components/trainings/preview-cmp';

export default class PreviewTrainingPage extends PureComponent {

  constructor() {
    super();
    this.state = {
      training_id: ''
    };
  }

  componentDidMount(){
    const { match } = this.props
    this.setState({training_id: (match && match.params) ? match.params.trainingId : '' })
  }

  render(){
    return (<Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Formation</h3>
        </Col>
        <Col md={12}>
          <PreviewTrainingCmp editing={true} match={this.props.match} training_id={this.state.training_id} history={this.props.history}/>
        </Col>
      </Row>
    </Container>)
  }
}