import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ChildCmp from '../../shared/components/childs/child-cmp';

export default class AddChildPage extends PureComponent {

  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return(<Container>
      <h3 className="page-title mb-3">Ajouter un enfant</h3>
      <ChildCmp history={this.props.history} adding={true} />
    </Container>)
  }
}
