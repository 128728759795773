import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ChildCmp from '../../shared/components/childs/child-cmp';

export default class EditChildPage extends PureComponent {

  constructor() {
    super();
    this.state = {
      child_id: ''
    };
  }

  componentDidMount(){
    const { match } = this.props
    this.setState({child_id: (match && match.params) ? match.params.childId : '' })
  }

  render(){
    return (<Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Child</h3>
        </Col>
        <Col md={12}>
          <ChildCmp editing={true} match={this.props.match} child_id={this.state.child_id} history={this.props.history}/>
        </Col>
      </Row>
    </Container>)
  }
}