
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_CATEGORYS, getCtgsSuccess, getCtgsError, 

  ADD_CATEGORY, addCtgError, addCtgSuccess, 
  EDIT_CATEGORY, editCtgError, editCtgSuccess,
  DELETE_CATEGORY, deleteCtgError, deleteCtgSuccess
} from '../actions/categoryActions';
import { api } from '../../const';



// *** GET CATEGORYS
export function* watchGetCtgs() { yield takeEvery(GET_CATEGORYS, getCtgs) }
function* getCtgs({ payload }) {
  try {
    const ret = yield call(getCtgsRequest, payload);

    if (ret && ret.status == 200) yield put(getCtgsSuccess({ ctgs: ret.data?.ctgs || []}));
    else yield put(getCtgsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getCtgsError({ error, message: (error) ? error.message : '' }));
  }
}
const getCtgsRequest = async (payload) => {  
  return await axios.get(api.ctgs+'/list')
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};











// *** ADD CATEGORYS
export function* watchAddCtgs() { yield takeEvery(ADD_CATEGORY, addCtg) }
function* addCtg({ payload }) {
  try {
    const ret = yield call(addCtgsRequest, payload);

    if (ret && ret.status == 201) yield put(addCtgSuccess({ ctg: ret.data.ctg }));
    else yield put(addCtgError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addCtgError({ error, message: (error) ? error.message : '' }));
  }
}
const addCtgsRequest = async (payload) => {  
  return await axios.post(api.ctgs+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT CATEGORYS
export function* watchEditCtg() { yield takeEvery(EDIT_CATEGORY, editCtg) }
function* editCtg({ payload }) {
  try {
    const ret = yield call(editCtgsRequest, payload);

    if (ret && ret.status == 200) yield put(editCtgSuccess({ ctg: ret.data.ctg }));
    else yield put(editCtgError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editCtgError({ error, message: (error) ? error.message : '' }));
  }
}
const editCtgsRequest = async (payload) => {  
  return await axios.patch(api.ctgs+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};















// *** DELETE CATEGORY
export function* watchDeleteCtg() { yield takeEvery(DELETE_CATEGORY, deleteCtg) }
function* deleteCtg({ payload }) {
  try {
    const ret = yield call(deleteCtgRequest, payload);

    if (ret && ret.status == 200) yield put(deleteCtgSuccess({ ctg: ret.data.ctg }));
    else yield put(deleteCtgError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteCtgError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteCtgRequest = async (payload) => {  
  return await axios.delete(api.ctgs+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetCtgs),

    fork(watchDeleteCtg),
    fork(watchAddCtgs),
    fork(watchEditCtg)
  ]);
}