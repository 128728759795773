import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
// import {
//   Card, CardBody, Badge, Table, ButtonGroup, ButtonToolbar, Button, Row, Col, CardFooter
// } from 'reactstrap';
import { api } from '../../../const';
import EditCtgMdl from './edit-ctg-mdl';

import {
  getCtgs, deleteCtg
} from "../../../redux/actions/categoryActions";
import ConfirmMdl from '../confirm-mdl';

class CtgsTree extends PureComponent {

  constructor() {
    super();
    this.state = {
      selected_ctg: null,

      show_edit_ctg_mdl: false,

      ctg_editing: null,

      show_delete_msg: false,
      req_delete_id: null
    };
  }

  deleteReq = () => {
    this.props.deleteCtg({ _id: this.state.req_delete_id })

    this.setState({ show_delete_msg: false })
  }
  componentDidUpdate(prev) {
    const { loading_delete_ctg, error_delete_ctg, getCtgs } = this.props
    if (prev.loading_delete_ctg && !loading_delete_ctg && !error_delete_ctg)
      getCtgs()
  }

  toggleEditCtgMdl = () => this.setState((prev) => { return { show_edit_ctg_mdl: !prev.show_edit_ctg_mdl } })

  renderCtg = (item, i) => {
    const { select_one, checkCtg, show_editing, ctg, show_deleting } = this.props

    return (<div key={"ctg_" + i + item._id}>
      <div style={{
        backgroundColor: '#ddd',
        padding: 10,
        borderRadius: 5,
        marginBottom: 5
      }}>
        {select_one && <input type="radio" name="ctg" style={{ marginRight: 5, maxWidth: 20, height: 15 }}
          value={item._id} defaultChecked={!show_editing && ctg && item._id == ctg.parent}
          onChange={(e) => {
            if (!ctg || (ctg && ctg._id != item._id)) checkCtg(e.target.value, item)
          }}
          readOnly={ctg && ctg._id == item._id}
        />}


        <img style={{ display: 'inline-block', width: 50, marginRight: 5 }} src={item.img ? `${api.root}/${item.img.path}` : ` ${process.env.PUBLIC_URL}/img/no_product.png`} />
        <p style={{ display: "inline-block" }} >
          {item.is_internal && <span className="lnr lnr-enter-down" style={{ fontSize: 25, fontWeight: 500, color: "green" }}></span>}
          {item.is_educational_support && <span className="lnr lnr-flag" style={{ fontSize: 25, fontWeight: 500, color: "green" }}></span>}
          {item.name}
        </p>



        {/* DELETE */}
        {show_deleting && <p onClick={() => this.setState({ req_delete_id: item._id, show_delete_msg: true })} style={{
          float: "right", marginTop: 15,
          fontSize: 15, color: "#F44336", cursor: "pointer"
        }}><span className="lnr lnr-trash ml-3"></span></p>}



        {/* EDIT */}
        {show_editing && <p onClick={() => this.setState({ ctg_editing: item, show_edit_ctg_mdl: true })} style={{
          float: "right", marginTop: 15,
          fontSize: 15, color: "#4CAF50", cursor: "pointer"
        }}><span className="lnr lnr-pencil"></span></p>}


      </div>

      {item.children && <div style={{ marginLeft: 25 }}>
        {item.children.map((ctg, j) => this.renderCtg(ctg, j))}
      </div>}
    </div>)
  }

  render() {
    if (!this.props.data) return null

    const { show_edit_ctg_mdl, ctg_editing, show_delete_msg } = this.state
    const { data, show_editing, getCtgs } = this.props

    return (<div style={{ maxHeight: 550, overflowY: "scroll", padding: 5 }} >
      {this.props.select_one && <div style={{
        backgroundColor: '#ddd',
        padding: 10,
        borderRadius: 5,
        marginBottom: 5
      }}>
        <input type="radio" name="ctg" style={{ marginRight: 5, maxWidth: 20, height: 15 }} onChange={(e) => this.props.checkCtg(null)} />
        <p style={{ display: "inline-block", margin: 0 }} >Catégorie principale</p>
      </div>}


      {data.map((ctg, i) => this.renderCtg(ctg, i))}




      {show_editing && <EditCtgMdl handleSuccess={(new_ctg) => {
        getCtgs(); this.setState({ show_edit_ctg_mdl: false })
      }} toggle={this.toggleEditCtgMdl} is_open={show_edit_ctg_mdl} ctg={ctg_editing} />}






      <ConfirmMdl toggle={() => this.setState((prev) => { return { show_delete_msg: !prev.show_delete_msg } })}
        is_open={show_delete_msg} accept={() => {
          this.deleteReq()
        }}
      />
    </div>)
  }
}

const mapStateToProps = ({ category }) => {
  const { loading_delete_ctg, error_delete_ctg } = category
  return {
    loading_delete_ctg, error_delete_ctg
  }
}
export default connect(mapStateToProps, {
  getCtgs, deleteCtg
})(CtgsTree);