import React, { Component, Fragment } from "react";
import {
  Row, Table, Button, Col,
  Modal, ModalBody, ModalFooter, CustomInput
} from "reactstrap";
// import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Select from 'react-select';

import {
  getClients
} from "../../../redux/actions/clientsActions";
import { cities, client_types } from "../../../const"; //no_user_img
import { getCountPages } from "../../helper/utils";


import Pagination from "../pagination/Pagination";

class SelectClient extends Component {
  constructor(props) {
    super(props);

    // const { messages } = this.props.intl || {};

    this.state = {
      dropdownSplitOpen: false,
      modalOpen: false,
      selectedItems: [],
      lastChecked: null,

      current_page: 1,
      totalPage: 1,
      selected_page_size: 10,

      show_filters: true,
      // name: '',
      // phone: '',
      // address: "",
      // city: "",
      // region: "",
      // type: "",
    };
  }

  resetFilters = () => {
    this.props.changeVal('first_name', "")
    this.props.changeVal('first_name_ar', "")
    this.props.changeVal('phone', "")
    this.props.changeVal('phone2', "")
    this.props.changeVal('address', "")
    this.props.changeVal('city', "")
    this.props.changeVal('region', "")
    this.props.changeVal('type', "")
  }
  // this.setState({
  //   first_name: '',
  //   first_name_ar: '',
  //   phone: '',
  //   phone2: '',
  //   address: "",
  //   city: "",
  //   region: "",
  //   type: ""
  // })

  // changeVal = (key, value) => {
  //   const obj = {}; obj[key] = value;
  //   this.setState(obj)
  // }
  componentWillMount() {
    this.getPage()
  }

  componentDidUpdate(prev) {
    const { isOpen, selectedItems } = this.props
    if (!prev.isOpen && isOpen) {
      this.getPage()
      // this.setState({selectedItems})
      const items = [];
      for (let i = 0; i < selectedItems.length; i++) {
        items.push({ ...selectedItems[i] })
      }

      this.setState({ selectedItems: items });
    }
  }

  onChangePage = page => {
    this.setState({ current_page: page }, () => this.getPage(page));
  };

  changeSelected = (checked, item, i) => {
    // item['checked'] = (checked)
    if (checked) this.setState(prev => {
      const selectedItems = prev.selectedItems
      if (selectedItems.length > 0 && !this.props.multi) return { selectedItems: [item] }

      selectedItems.push(item)
      return { selectedItems }
    })
    else this.setState(prev => {
      const selectedItems = prev.selectedItems
      try {
        for (let indx = 0; indx < selectedItems.length; indx++) {
          if (selectedItems[indx]._id == item._id) {
            selectedItems.splice(indx, 1)
            break;
          }
        }
      } catch (error) { }
      return { selectedItems }
    })
  }

  isChecked = (_id) => {
    const { selectedItems } = this.state
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i]._id == _id) return true
    }
    return false
  }

  isInSelected(_id) {
    const { selectedItems } = this.props
    if (selectedItems)
      for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i]._id == _id) return true
      }
    return false
  }


  getPage(page = 1) {
    const { selected_page_size } = this.state;
    const { first_name_ar, first_name, phone, phone2, address, type, city, region, is_training_order } = this.props
    const offset = (page == 1 || page == 0) ? 0 : selected_page_size * (page - 1);

    const filter = {};
    if (first_name_ar && first_name_ar != "") filter['first_name_ar'] = first_name_ar
    if (first_name && first_name != "") filter['first_name'] = first_name

    if (phone && phone != "") filter['phone'] = phone
    // else if(search?.phone && search?.phone != "") filter['phone'] = search.phone

    if (phone2 && phone2 != "") filter['phone2'] = phone2

    if (address && address != "") filter['address'] = address
    // else if(search?.address && search?.address != "") filter['address'] = search.address

    if (type && type != "") filter['type'] = type
    // else if(search?.type && search?.type != "") filter['type'] = search.type

    if (is_training_order) filter['type'] = "internal"

    if (city && city != "") filter['city'] = city
    // else if(search?.city && search?.city != "") filter['city'] = search.city

    if (region && region != "") filter['region'] = region
    // else if(search?.region && search?.region != "") filter['region'] = search.region

    this.props.getClients({ filter, offset, limit: selected_page_size })
  }

  render() {
    const {
      current_page, selected_page_size, selectedItems,

      show_filters,
      // name, phone, address, type, city, region
    } = this.state;

    // const startIndex = (currentPage - 1) * selectedPageSize;
    // const endIndex = currentPage * selectedPageSize;

    const { isOpen, size, multi, toggleOpen, accept, count_all_clients, clients,
      changeVal, first_name, first_name_ar, phone, phone2, address, type, city, region,
      is_training_order
    } = this.props;

    // console.log("===> changeVal ", changeVal);


    return (<Modal isOpen={isOpen} size={size || "lg"} toggle={toggleOpen} className={"mdl_wraper"} >
      <ModalBody>
        <div style={{ maxHeight: 550, overflowY: 'auto' }}>
          <p onClick={() => this.setState((prev) => { return { show_filters: !prev.show_filters } })}
            style={{ cursor: "pointer", textAlign: "left" }}
          >
            <span className="lnr lnr-funnel"></span> Filter par
          </p>
          {show_filters && <div className="form" style={{
            padding: 5, borderBottom: "2px solid #4CAF50",
            // overflowX: "hidden" 
          }}>
            <Row>
              <Col md="3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      // defaultValue={this.props.search?.name} 
                      component="input" placeholder="الإسم"
                      value={first_name_ar}
                      onChange={(e) => changeVal('first_name_ar', e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              <Col md="3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      // defaultValue={this.props.search?.name} 
                      component="input" placeholder="Nom"
                      value={first_name}
                      onChange={(e) => changeVal('first_name', e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              {/* PHONE */}
              <Col md="3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      type="number" value={phone} component="input" placeholder="Téléphone"
                      onChange={(e) => changeVal('phone', e.target.value)}
                    />
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      type="number" value={phone2} component="input" placeholder="Téléphone 2"
                      onChange={(e) => changeVal('phone2', e.target.value)}
                    />
                  </div>
                </div>
              </Col>


              {/* CITY */}
              <Col md="2">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Select defaultValue={city}
                      value={cities.filter(option => (option.value === city || (city == "" && option.value == this.props.search?.city)))}
                      onChange={(e) => changeVal('city', e.value)}
                      options={cities} clearable={false}
                      className="react-select"
                      placeholder="Gouvernorat" classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      value={region} component="input" placeholder="Région"
                      onChange={(e) => changeVal('region', e.target.value)}
                    />
                  </div>
                </div>
              </Col>





              {/* ADDRESS */}
              <Col md="9">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                      value={address} component="input" placeholder="Adresse"
                      onChange={(e) => changeVal('address', e.target.value)}
                    />
                  </div>
                </div>
              </Col>

              {/* TYPE */}
              <Col md="2">
                {is_training_order ? <p style={{ margin: 5, color: "red" }}>Interne</p> : <div className="form__form-group" style={{ width: 84 }}>
                  <div className="form__form-group-field">
                    <Select defaultValue={type}
                      value={client_types.filter(option => option.value === type)}
                      onChange={(e) => changeVal('type', e.value)}
                      options={client_types} clearable={false}
                      className="react-select"
                      placeholder="Type" classNamePrefix="react-select"
                    />
                  </div>
                </div>}
              </Col>










              <Col md="10"></Col>
              <Col md="1" style={{ marginTop: -60 }}>
                <div onClick={this.resetFilters} className="search_client_btn" style={{ backgroundColor: "#ddd" }}>
                  <span className="lnr lnr-redo" ></span>
                </div>
              </Col>
              <Col md="1" style={{ marginTop: -60 }}>
                <div onClick={() => this.getPage()} className="search_client_btn">
                  <span className="lnr lnr-magnifier" ></span>
                </div>
              </Col>



            </Row>
          </div>}




          {/* LISTING */}
          {(clients && clients.length > 0) && <Table responsive striped>
            <thead>
              <tr>
                <th></th>
                <th>Nom</th>
                <th>Téléphone</th>

                <th>Gouvernorat</th>
                <th>Région</th>
                <th>Adresse</th>

                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((item, i) => <tr key={"item" + i} >
                <th scope="row" style={{ width: 60, verticalAlign: 'middle' }}>
                  {multi ? <span>
                    {this.isChecked(item._id) ? <CustomInput type="checkbox" id={item._id}
                      checked={true}
                      // disabled={disable_selected && this.isInSelected(item._id) }
                      onChange={(e) => this.changeSelected(e.target.checked, item, i)}
                      label="" /> :
                      <CustomInput type="checkbox" id={item._id}
                        checked={false}
                        // disabled={disable_selected && this.isInSelected(item._id) }
                        onChange={(e) => this.changeSelected(e.target.checked, item, i)}
                        label="" />}
                  </span> :

                    <CustomInput type="radio" id={item._id} name="school_level"
                      checked={this.isChecked(item._id)}
                      // disabled={disable_selected && this.isInSelected(item._id) }
                      onChange={(e) => this.changeSelected(e.target.checked, item, i)}
                      label="" />
                  }
                </th>




                <td style={{ verticalAlign: 'middle' }}>
                  <p style={{ margin: 0, color: "#777" }} >{item.first_name + " " + item.last_name}</p>
                  <p style={{ margin: 0, color: "#777" }} >{item.first_name_ar + " " + item.last_name_ar}</p>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {item.phone || ""}
                  {" / " + item.phone2 || ""}
                </td>

                <td style={{ verticalAlign: 'middle' }}>
                  {item.city}
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {item.region}
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {item.address}
                </td>

                <td style={{ verticalAlign: 'middle' }}>
                  {item.type}
                </td>
              </tr>)}
            </tbody>
          </Table>}
        </div>

        <Pagination currentPage={current_page}
          totalPage={getCountPages(count_all_clients, selected_page_size)}
          onChangePage={i => this.onChangePage(i)}
        />
      </ModalBody>

      <ModalFooter style={{ paddingBottom: 0 }}>
        <Button color="primary" onClick={() => accept(selectedItems)}>Ok</Button>{" "}
        <Button color="secondary" onClick={toggleOpen}>Annuler</Button>
      </ModalFooter>
    </Modal>
    );
  }
}

const mapStateToProps = ({ client }) => {
  const { loading_get_clients, clients, count_all_clients, } = client
  return {
    loading_get_clients, clients, count_all_clients
  };
};

export default connect(mapStateToProps, {
  getClients
})(SelectClient);