import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import ReactExport from "react-export-excel";

// import PropTypes from "prop-types";

import { Table, Space, Row, Col, Select, Typography, Input } from "antd";

import {
	RocketFilled,
	// FileExcelFilled
} from "@ant-design/icons";
import Axios from "axios";
import { api, cities } from "../../../const";
import moment from "moment";
import FollowMdl from "../trainings/FollowMdl";
import "./list-follows.scss";

const { Option } = Select;
const { Text } = Typography;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const renderStatus = (activities) => {
	const status = activities[0]?.status;
	switch (status) {
		case "not_active":
			return "Pas actif";
		case "active":
			return "Actif";
		case "in_progress":
			return "En cours";
		case "took_of":
			return "Retiré";
		case "out":
			return "Out";
		case "stopped":
			return "En Arrêt";
	}

	return "";
};

const renderFB = (activities) => {
	const fb_group = activities[0]?.fb_group;
	switch (fb_group) {
		case "subscribed":
			return "Abonné";
		case "subscription_sent":
			return "Demande envoyé";
		case "not_subscribed":
			return "Non abonné";
	}

	return "";
};

const renderJoin = (activities) => {
	const joinability = activities[0]?.joinability;
	switch (joinability) {
		case "reachable":
			return "Accessible";
		case "unavailable":
			return "Indisponible";
		case "unreachable":
			return "Inaccessible";
	}

	return "";
};

const ListFollowsCmp = (props) => {
	const { user } = props;

	const [limit, setLimit] = useState(20);
	const [count, setCount] = useState(0);
	// const [query, setQuery] = useState("");
	const [loading, setLoading] = useState(false);

	const [selected_activity, setSeledActivity] = useState(null);
	const [open_follow_mdl, setOpenFollowMdl] = useState(false);
	const [current_page, setCurrentPage] = useState(1);

	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [city, setCity] = useState("");

	const [status, setStatus] = useState("");
	const [joinability, setJoinability] = useState("");
	const [fb_group, setFBGroup] = useState("");

	const [data, setData] = useState([]);
	const [all_data, setAllData] = useState([]);

	// const [show_doownload_xl, setShowDownload] = useState(false);

	const getPage = (page = 1, sort_fields = []) => {
		const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);

		const filter = {
			// with_training: true,
			// city,
			// status,
			get_all: true,
		};
		// if (query && query !== "") filter["name"] = query;
		// if (sort_fields?.length) filter["sort"] = sort_fields[0];

		setLoading(true);
		setCurrentPage(page);

		// aggregate-activities
		Axios.post(`${api.participants}/filter/${offset}/${limit}`, filter || {})
			.then((resp) => {
				// console.log("------------------> resp", resp);
				setLoading(false);

				// setCount(resp?.data?.count || 0);

				// console.log("RESP  participants =>", resp?.data);

				const new_data = resp?.data?.participants?.map((doc) => {
					// console.log("DOC =>", doc);
					const participant = doc?.participant || {};
					return {
						name: `${participant.first_name} ${participant.last_name}`,
						name_ar: `${participant.first_name_ar} ${participant.last_name_ar}`,
						// activities: doc.activities || [],
						activities: [
							{
								// ...doc,
								status: doc.status,
								fb_group: doc.fb_group,
								joinability: doc.joinability,
							},
						],
						training: doc.training || {},
						participant,
						key: doc._id,
					};
				});

				// console.log("new_data", new_data);

				setData(new_data || []);
				setAllData(new_data || []);
				setCount(new_data?.length || 0);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getPage();
	}, []);

	useEffect(() => {
		let new_data = [];
		if (phone === "" && city === "" && status === "" && joinability === "" && fb_group === "" && name === "") {
			setData(all_data);
			setCount(all_data?.length);
		} else {
			setLoading(true);

			const query = name.toLowerCase();
			new_data = all_data.filter((item) => {
				if (city && city !== "") {
					const item_city = item.participant?.city;
					if (!item_city || item_city === "" || item_city.toLowerCase() !== city.toLowerCase()) return false;
					// if (item.participant?.city !== city) return false;
				}
				if (status && status !== "") {
					if (!item.activities[0] || item.activities[0]?.status !== status) return false;
				}

				if (joinability && joinability !== "") {
					if (!item.activities[0] || item.activities[0]?.joinability !== joinability) return false;
				}

				if (fb_group && fb_group !== "") {
					if (!item.activities[0] || item.activities[0]?.fb_group !== fb_group) return false;
				}

				if (query && query !== "") {
					if (!item.participant) return false;

					if (
						!item.participant.first_name?.toLowerCase().includes(query) &&
						!item.participant.first_name_ar?.toLowerCase().includes(query) &&
						!item.participant.last_name?.toLowerCase().includes(query) &&
						!item.participant.last_name_ar?.toLowerCase().includes(query)
					)
						return false;
				}

				if (phone && phone !== "") {
					if (!item.participant) return false;

					if (!item.participant.phone?.includes(phone) && !item.participant.phone2?.includes(phone))
						return false;
				}

				return true;
			});
			// console.log("new_data", new_data);
			setData(new_data);
			setCount(new_data?.length);
			setLoading(false);
		}
	}, [city, status, joinability, fb_group, name, phone]);

	const columns = [
		{
			title: "Nom Prénom",
			dataIndex: "name",
			key: "name",
			fixed: "left",

			render: (text, record) => {
				return (
					<Fragment>
						<NavLink target="_blank" to={"/app/clients/trainer-preview/" + record.participant?._id}>
							<p style={{ margin: 0, color: "#1890ff" }}>{record.name || ""}</p>
							<p style={{ margin: 0, color: "#1890ff" }}>
								{record.name_ar || ""}
								<span> , {record.participant?.city || ""}</span>
							</p>
						</NavLink>

						<p style={{ margin: 0 }}>
							<span>{record.participant?.phone}</span>
							<span>{`${record.participant?.phone2 ? "," : ""} ${
								record.participant?.phone2 || ""
							}`}</span>
						</p>
						{/* <p style={{ margin: 0 }}>{record.participant?.city || ""}</p> */}
					</Fragment>
				);
			},
		},
		// {
		// 	title: "Date ajout",
		// 	dataIndex: "adding_date",
		// 	key: "adding_date",
		// 	width: 110,

		// 	render: (text, record) => {
		// 		return (
		// 			<Fragment>
		// 				<p style={{ margin: 0 }}>{moment(record.adding_date).format("ddd DD/MM/YYYY")}</p>
		// 			</Fragment>
		// 		);
		// 	},
		// },
		{
			title: "Formation",
			dataIndex: "training",
			key: "training",
			// width: "30%",

			render: (text, record) => {
				return (
					<Fragment>
						<NavLink target="_blank" to={"/app/trainings/edit/" + record.training?._id}>
							#{record.training?.ref}
						</NavLink>
						<span style={{ marginLeft: 5, color: "#777" }}>({record.training?.type || ""})</span>
						<p style={{ margin: 0 }}>
							{record.training?.city}, {record.training?.region}
						</p>
						<p style={{ margin: 0 }}>
							{moment(record.training?.start_date).format("ddd DD/MM/YYYY")} ,{" "}
							{record.training?.duration || 0} Jours
						</p>
					</Fragment>
				);
			},
		},
		{
			title: "Status",
			dataIndex: "activities",
			key: "status",
			width: 110,

			render: (text, record) => {
				// console.log("----> record.activities", record.activities);
				return (
					<Fragment>
						<span>{renderStatus(record.activities)}</span>
					</Fragment>
				);
			},
		},
		{
			title: "Joignabilité",
			dataIndex: "activities",
			key: "Joignability",
			width: 110,

			render: (text, record) => {
				return (
					<Fragment>
						<span>{renderJoin(record.activities)}</span>
					</Fragment>
				);
			},
		},
		{
			title: "Facebook",
			dataIndex: "activities",
			key: "fb",
			width: 110,

			render: (text, record) => {
				return (
					<Fragment>
						<span>{renderFB(record.activities)}</span>
					</Fragment>
				);
			},
		},

		{
			title: "",
			key: "action",
			align: "center",
			width: 50,
			fixed: "right",

			render: (text, record) => (
				<Space size="middle">
					<RocketFilled
						style={{ color: "#1890ff" }}
						onClick={() => {
							setSeledActivity(record);
							setOpenFollowMdl(true);
						}}
					/>
				</Space>
			),
		},
	];

	// console.log(data);
	return (
		<React.Fragment>
			<Row className="mb-3">
				<Col md={7}>
					<Text type="secondary" style={{ display: "block" }}>
						Participant
					</Text>
					<Input
						value={name}
						onChange={(e) => setName(e?.target?.value)}
						placeholder="Chercher participant"
					/>
				</Col>
				<Col md={1}></Col>
				<Col md={7}>
					<Text type="secondary" style={{ display: "block" }}>
						Téléphone
					</Text>
					<Input value={phone} onChange={(e) => setPhone(e?.target?.value)} placeholder="Téléphone" />
				</Col>
			</Row>

			<Row className="mb-3">
				<Col md={3}>
					<Text type="secondary" style={{ display: "block" }}>
						Gouvernorat
					</Text>
					<Select
						showSearch
						defaultValue=""
						style={{ width: "100%" }}
						value={city}
						onChange={(new_city) => setCity(new_city)}
					>
						<Option value="">Tout</Option>
						{cities.map((city, indx) => (
							<Option value={city.value} key={`city-${indx}`}>
								{city.label}
							</Option>
						))}
					</Select>
				</Col>
				<Col md={1}></Col>
				<Col md={3}>
					<Text type="secondary" style={{ display: "block" }}>
						Status
					</Text>
					<Select
						showSearch
						defaultValue=""
						style={{ width: "100%" }}
						value={status}
						onChange={(new_status) => setStatus(new_status)}
					>
						<Option value="">Tout</Option>
						<Option value="not_active">Pas actif</Option>
						<Option value="active">Actif</Option>
						<Option value="in_progress">En cours</Option>
						<Option value="took_of">Retiré</Option>
						<Option value="out">Out</Option>
						<Option value="stopped">En Arrêt</Option>
					</Select>
				</Col>
				<Col md={1}></Col>
				<Col md={3}>
					<Text type="secondary" style={{ display: "block" }}>
						Joignabilité
					</Text>
					<Select
						showSearch
						defaultValue=""
						style={{ width: "100%" }}
						value={joinability}
						onChange={(new_joinability) => setJoinability(new_joinability)}
					>
						<Option value="">Tout</Option>
						<Option value="reachable">Accessible</Option>
						<Option value="unavailable">Indisponible</Option>
						<Option value="unreachable">Inaccessible</Option>
					</Select>
				</Col>
				<Col md={1}></Col>
				<Col md={3}>
					<Text type="secondary" style={{ display: "block" }}>
						Facebook
					</Text>
					<Select
						showSearch
						defaultValue=""
						style={{ width: "100%" }}
						value={fb_group}
						onChange={(new_fb_group) => setFBGroup(new_fb_group)}
					>
						<Option value="">Tout</Option>
						<Option value="subscribed">Abonné</Option>
						<Option value="subscription_sent">Demande envoyé</Option>
						<Option value="not_subscribed">Non abonné</Option>
					</Select>
				</Col>

				{/* <Col md={17} style={{ marginTop: 22, textAlign: "right" }}>
          <Button loading={loading} onClick={() => getPage()} type="primary"
            icon={<SearchOutlined />}>
            Chercher
          </Button>
        </Col> */}
			</Row>

			<Row className="mt-3 mb-3">
				<Col md={14}>
					<h4>Total : {count}</h4>
				</Col>
				<Col md={10} style={{ textAlign: "right" }}>
					{/* {data?.length && !show_doownload_xl && (
						<Button
							type="primary"
							icon={<FileExcelFilled />}
							// loading={loadings[2]}
							onClick={() => setShowDownload(true)}
						>
							Convertir en fichier excel
						</Button>
					)} */}

					{user?.roles.includes("ADMIN") && data?.length ? (
						<div
							className="ml-3"
							// onClick={() => setShowDownload(true)}
						>
							<ExcelFile>
								<ExcelSheet data={data} name="Suivie">
									{/* <ExcelColumn
										label="Date inscrit"
										value={(col) =>
											col.adding_date ? moment(col.adding_date).format("DD-MM-YYYY") : ""
										}
									/> */}
									<ExcelColumn label="Nom Prénom" value="name" />
									<ExcelColumn label="Name Ar" value="name_ar" />
									<ExcelColumn
										label="Téléphone"
										value={(col) =>
											col.participant
												? `${col.participant.phone} ${
														col.participant.phone2 ? "," + col.participant.phone2 : ""
												  }`
												: ""
										}
									/>
									<ExcelColumn
										label="Facebook"
										value={(col) =>
											col.activities && col.activities[0] ? renderFB(col.activities) : ""
										}
									/>
									<ExcelColumn
										label="Gouvernorat"
										value={(col) => (col.participant ? col.participant.region : "")}
									/>
									<ExcelColumn
										label="Ville"
										value={(col) => (col.participant ? col.participant.city : "")}
									/>
									<ExcelColumn
										label="Adresse"
										value={(col) => (col.participant ? col.participant.address : "")}
									/>
									<ExcelColumn
										label="Institution"
										value={(col) => (col.participant ? col.participant.institution : "")}
									/>
									<ExcelColumn
										label="Profession"
										value={(col) => (col.participant ? col.participant.profession : "")}
									/>

									<ExcelColumn
										label="Coordinateur Régional"
										value={(col) =>
											col.training?.coordinator
												? `${col.training.coordinator.first_name} ${col.training.coordinator.last_name} ${col.training.coordinator.first_name_ar} ${col.training.coordinator.last_name_ar}`
												: ""
										}
									/>

									<ExcelColumn
										label="Date Formation"
										value={(col) =>
											col.training ? moment(col.training.start_date).format("DD-MM-YYYY") : ""
										}
									/>

									<ExcelColumn
										label="Lieu"
										value={(col) =>
											col.training
												? `${col.training.address}, ${col.training.region}, ${col.training.city}`
												: ""
										}
									/>

									<ExcelColumn
										label="Formateur"
										value={(col) =>
											col.training?.former
												? `${col.training.former.first_name} ${col.training.former.last_name} ${col.training.former.first_name_ar} ${col.training.former.last_name_ar}`
												: ""
										}
									/>

									<ExcelColumn
										label="Type Formation"
										value={(col) =>
											col.training
												? `${col.training.type === "internal" ? "Interne" : "Externe"}`
												: ""
										}
									/>
									<ExcelColumn
										label="Actif"
										value={(col) =>
											col.activities && col.activities[0] ? renderStatus(col.activities) : ""
										}
									/>
								</ExcelSheet>
							</ExcelFile>
						</div>
					) : null}
				</Col>
			</Row>

			<Table
				className="follow_table"
				columns={columns}
				dataSource={data}
				scroll={{ x: 800 }}
				loading={loading}
				pagination={{
					total: count,
					pageSize: limit,
					pageSizeOptions: [10, 20, 30, 50],
				}}
				onChange={(pagination, filters, sorter, extra) => {
					setLimit(pagination?.pageSize || 10);
					// getPage(pagination?.current || 1);
				}}
			/>

			{open_follow_mdl && selected_activity && (
				<FollowMdl
					isOpen={open_follow_mdl}
					toggleOpen={() => setOpenFollowMdl(!open_follow_mdl)}
					activity={selected_activity}
					onSuccess={() => {
						getPage(current_page);
						setOpenFollowMdl(false);
					}}
				/>
			)}
		</React.Fragment>
	);
};

ListFollowsCmp.prototype = {};
export default ListFollowsCmp;
