import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

import {
  logout
} from "../../../redux/actions/authActions";
import { Fragment } from 'react';

const Ava = `${process.env.PUBLIC_URL}/img/no_user.png`;

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  fullscreen = () =>{
    try {
      if(document.fullscreenElement) document.exitFullscreen().then(resp=>{}).catch(err=>{})
      else document.body.requestFullscreen().then(resp=>{}).catch(err=>{})
    } catch (error) {}
  }

  render() {
    const { collapse } = this.state;

    const user = this.props.user
    if(!user) return null;

    return (<Fragment>
      <span onClick={this.fullscreen} className="lnr lnr-screen" style={{cursor: 'pointer', fontSize: 20, marginTop: 20}}></span>

      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">{user.first_name+" "+user.last_name}</p>
          <DownIcon className="topbar__icon" />
        </button>


        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}

        
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Profile" icon="list" path="/app/profile" />
            <TopbarMenuLink title="Dashboard" icon="inbox" path="/app/dashboard" />
            <div className="topbar__menu-divider" />

            <a className="topbar__link" onClick={()=>this.props.logout()}>
              <span className={`topbar__link-icon lnr lnr-exit`} />
              <p className="topbar__link-title">Log Out</p>
            </a>
          </div>
        </Collapse>
      </div>
    </Fragment>);
  }
}

const mapStateToProps = ({auth})=>{
  return {
    user: auth.user
  }
}
export default connect(mapStateToProps, {
  logout
})(TopbarProfile);
