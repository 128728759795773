import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Container, Row, Button, Badge, Table } from 'reactstrap';

import ConfirmMdl from "../../shared/components/confirm-mdl";

import AddShipModeCmp from "../../shared/components/settings/add-ship_mode";
import EditShipModeCmp from "../../shared/components/settings/edit-ship_mode";
import AddTrainCategoryMdl from "../../shared/components/trainings/AddTrainCategoryMdl";
import EditTrainCategoryMdl from "../../shared/components/trainings/EditTrainCategoryMdl";

import { connect } from 'react-redux';

import {
  getShipModes, deleteShipMode
} from "../../redux/actions/shipModesActions";
import Axios from 'axios';
import { api } from '../../const';

// import CollapseCmp from "../../shared/components/Collapse";
// import { api } from '../../const';

class SettingsPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      show_add_ship_mode: false,
      show_edit_ship_mode: false,
      ship_mode_delete: null,

      training_categories: [],
      show_add_train_ctg: false,
      show_edit_train_ctg: false,
      selected_train_ctg: null,
      train_ctg_delete: null,
    };
  }

  componentDidMount() {
    this.props.getShipModes()
    this.getTrainCategories()
  }

  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  deleteShipMode = () => {
    this.props.deleteShipMode({ _id: this.state.ship_mode_delete?._id })

    this.setState({ show_delete_msg: false })
  }



  componentDidUpdate(prev) {
    if (prev.loading_delete_ship_mode && !this.props.loading_delete_ship_mode && !this.props.error_delete_ship_mode) {
      this.props.getShipModes()
    }
  }


  getTrainCategories = () => {
    Axios.post(api.trainings + "/categories/filter/0/200").then(resp => {
      this.setState({ training_categories: resp?.data?.training_categories || [] })
    })
  }
  // Axios.post(api.trainings+"/categories/create")
  render() {
    const { show_delete_msg,
      show_add_ship_mode, show_edit_ship_mode, ship_mode,
      show_add_train_ctg, show_edit_train_ctg, train_ctg_delete, selected_train_ctg,
      training_categories
    } = this.state

    const { ship_modes } = this.props

    return (<Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Paramètres</h3>
        </Col>


        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Modes de livraison</h5>
                <h5 className="subhead">définir la liste des <span className="red-text">prix</span> des modes d'expédition</h5>
              </div>

              <Button color="success" style={{ float: 'right', marginTop: -75 }} onClick={() => this.setState({ show_add_ship_mode: true })}>Ajouter</Button>
              <Row>
                {/* ADD SHIP MODE */}
                {show_add_ship_mode && <Col md="12">
                  <AddShipModeCmp
                    cancelAdding={() => this.setState({ show_add_ship_mode: false })}
                    success={() => {
                      this.props.getShipModes()
                      this.setState({ show_add_ship_mode: false })
                    }}
                  />
                </Col>}

                {/* EDIT SHIP MODE */}
                {(show_edit_ship_mode && ship_mode) && <Col md="12">
                  <EditShipModeCmp ship_mode={ship_mode}
                    cancelEditing={() => this.setState({ show_edit_ship_mode: false })}
                    success={() => {
                      this.props.getShipModes()
                      this.setState({ show_edit_ship_mode: false, ship_mode: null })
                    }}
                  />
                </Col>}


                {/* LIST SHIPP MODE */}
                {!show_edit_ship_mode && <Table responsive hover>
                  <tbody>
                    {ship_modes && ship_modes.map((ship_mode, i) => <tr key={"ship_mode_" + i}>
                      <td>
                        <strong> Mode </strong>
                        <span> {ship_mode.name} </span>
                      </td>
                      <td>
                        <strong> Prix </strong>
                        <span> {ship_mode.price + " "} TND </span>
                      </td>
                      <td>
                        <strong> Extra </strong>
                        <span> {ship_mode.extra_price + " "} TND </span>
                      </td>
                      <td>
                        <span onClick={() => {
                          if (!show_add_ship_mode) this.setState({ show_edit_ship_mode: true, ship_mode })
                        }} className="lnr lnr-pencil" style={{ marginLeft: 10, cursor: "pointer", color: "#009688" }}></span>



                        <span onClick={() => {
                          this.setState({ show_delete_msg: true, ship_mode_delete: ship_mode })
                        }} className="lnr lnr-trash" style={{ marginLeft: 10, cursor: "pointer", color: "#F44336" }}></span>
                      </td>
                    </tr>)}
                  </tbody>
                </Table>}
              </Row>
            </CardBody>
          </Card>
        </Col>















        <Col md="12" style={{ marginBottom: 20 }}>
          <hr />
        </Col>








        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Catégories des formations</h5>
              </div>

              <Button color="success" style={{ float: 'right', marginTop: -75 }} onClick={() => this.setState({ show_add_train_ctg: true })}>Ajouter categorie</Button>
              <Row>
                {training_categories?.length ? training_categories.map(train_ctg => <React.Fragment key={train_ctg._id}>
                  <Col md="4">{train_ctg.name || ""}</Col>
                  <Col md="6">{train_ctg.training_names?.join(", ") || ""}</Col>
                  <Col md="1" style={{ textAlign: "center" }}>
                    <span onClick={() => this.setState({ selected_train_ctg: train_ctg, show_edit_train_ctg: true })}
                      className="lnr lnr-pencil" style={{ color: "#009688", display: 'block', marginTop: 12, cursor: "pointer" }} />
                  </Col>
                  <Col md="1" style={{ textAlign: "center" }}>
                    <span onClick={() => this.setState({ train_ctg_delete: train_ctg, show_delete_msg: true })}
                      className="lnr lnr-trash" style={{ color: "#F44336", display: 'block', marginTop: 12, cursor: "pointer" }} />
                  </Col>
                </React.Fragment>) : null}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>












      {/*  ADD TRAIN CTG */}
      {show_add_train_ctg && <AddTrainCategoryMdl isOpen={show_add_train_ctg} size="md"
        toggleOpen={() => this.setState({ show_add_train_ctg: !show_add_train_ctg })}
        accept={(created_client) => {
          this.getTrainCategories(); this.setState({ show_add_train_ctg: false })
        }}
      />}
      {(show_edit_train_ctg && selected_train_ctg) && <EditTrainCategoryMdl isOpen={show_edit_train_ctg} size="md"
        train_ctg={selected_train_ctg}
        toggleOpen={() => this.setState({ show_edit_train_ctg: !show_edit_train_ctg })}
        accept={(edited_client) => {
          this.getTrainCategories(); this.setState({ show_edit_train_ctg: false })
        }}
      />}


      {/* CONFIRM MDLs */}

      <ConfirmMdl toggle={() => this.setState((prev) => { return { show_delete_msg: !prev.show_delete_msg } })}
        is_open={show_delete_msg} accept={() => {
          if (train_ctg_delete) {
            Axios.delete(api.trainings + "/categories/delete-one/" + train_ctg_delete?._id).then(resp => {
              this.getTrainCategories()
              this.setState({ show_delete_msg: false })
            }).catch(err => {
              alert("please check your data and try again.")
              this.getTrainCategories()
              this.setState({ show_delete_msg: false })
            })
          } else this.deleteShipMode()
        }}
      />
    </Container>)
  }
}


const mapStateToProps = ({ shipMode }) => {
  const { ship_modes, loading_delete_ship_mode, error_delete_ship_mode } = shipMode

  return {
    ship_modes, loading_delete_ship_mode, error_delete_ship_mode
  }
}
export default connect(mapStateToProps, {
  getShipModes, deleteShipMode
})(SettingsPage);