import React, { Component } from "react";
import {
	// Row, Card, CardBody,
	Table,
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	CustomInput,
} from "reactstrap";
// import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import Select from "react-select";

import { getArticles } from "../../../redux/actions/articlesActions";
import { api } from "../../../const";
import { articleStockColor, getCountPages } from "../../helper/utils";
import { CheckBoxField } from "../form/CheckBox";

import Pagination from "../pagination/Pagination";
class SelectArticle extends Component {
	constructor(props) {
		super(props);

		// const { messages } = this.props.intl || {};

		this.state = {
			dropdownSplitOpen: false,
			modalOpen: false,
			selectedItems: [],
			lastChecked: null,

			current_page: 1,
			totalPage: 1,
			selected_page_size: 30,
			page_sizes: [
				{ label: "10", value: 10, key: 1 },
				{ label: "20", value: 20, key: 2 },
				{ label: "30", value: 30, key: 3 },
				{ label: "40", value: 40, key: 4 },
				{ label: "50", value: 50, key: 5 },
			],

			filter_all: true,
			is_pack: false,
			is_internal: false,
			is_prd: false,
			is_supply: false,

			query: "",
		};
	}
	componentWillMount() {
		this.getPage();
	}

	componentDidUpdate(prev) {
		const { isOpen, selectedItems } = this.props;
		if (!prev.isOpen && isOpen) {
			// this.setState({selectedItems})
			this.getPage();
			const items = [];
			for (let i = 0; i < selectedItems.length; i++) {
				items.push({ ...selectedItems[i] });
			}

			this.setState({ selectedItems: items });
		}
	}

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	changeSelected = (checked, item, i) => {
		// item['checked'] = (checked)
		if (checked)
			this.setState((prev) => {
				const selectedItems = prev.selectedItems;
				if (selectedItems.length > 0 && !this.props.multi) return { selectedItems: [item] };

				selectedItems.push(item);
				return { selectedItems };
			});
		else
			this.setState((prev) => {
				const selectedItems = prev.selectedItems;
				try {
					for (let indx = 0; indx < selectedItems.length; indx++) {
						if (selectedItems[indx]._id == item._id) {
							selectedItems.splice(indx, 1);
							break;
						}
					}
				} catch (error) {}
				return { selectedItems };
			});
	};

	isChecked = (_id) => {
		const { selectedItems } = this.state;
		for (let i = 0; i < selectedItems.length; i++) {
			if (selectedItems[i]._id == _id) return true;
		}
		return false;
	};

	isInSelected(_id) {
		const { selectedItems } = this.props;
		if (selectedItems)
			for (let i = 0; i < selectedItems.length; i++) {
				if (selectedItems[i]._id == _id) return true;
			}
		return false;
	}

	getPage(page = 1) {
		const { selected_page_size, filter_all, is_prd, is_pack, query } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {
			// is_internal: (this.props.order_trainings)
		};

		const { only_supply, only_external, only_internal } = this.props;
		// if (internal_filter) filter["is_internal"] = internal_filter == "internal";
		if (only_supply) {
			filter["is_supply"] = true;
			filter["is_internal"] = false;
		} else if (only_external) {
			filter["is_supply"] = false;
			filter["is_internal"] = false;
		} else if (only_internal) {
			filter["is_internal"] = true;
			filter["is_supply"] = false;
		}

		if (!filter_all) {
			if (is_pack) filter["is_pack"] = true;
			if (is_prd) filter["is_prd"] = true;
			// if (is_supply) filter["is_supply"] = true;
			// if (is_internal || is_external) {
			// 	if (is_internal && !is_external) filter["is_internal"] = true;
			// 	if (!is_internal && is_external) filter["is_internal"] = false;
			// }

			if (query && query != "") filter["query"] = query;
		}

		// if (filter_by_query != '') filter['name'] = filter_by_query

		this.props.getArticles({ filter, offset, limit: selected_page_size });
	}

	resetFilter = (filter, checked, _old_filter_status) => {
		if (checked != undefined) {
			if (filter == "all") {
				if (checked)
					this.setState(
						{
							filter_all: true, //filter_status: [],
							is_pack: false,
							// is_supply: false,
							is_prd: false,
							// is_internal: false,
							// is_external: false,
						},
						() => this.getPage()
					);
				else
					this.setState(
						{
							filter_all: false, //filter_status: [],
							// is_pack: false, is_prd: false,
						},
						() => this.getPage()
					);
			}
		}
	};

	render() {
		const { current_page, selected_page_size, selectedItems, is_pack, is_prd, filter_all, page_sizes, query } =
			this.state;

		// const startIndex = (currentPage - 1) * selectedPageSize;
		// const endIndex = currentPage * selectedPageSize;

		const { isOpen, size, multi, toggleOpen, accept, count_all_articles, articles } = this.props;

		return (
			<Modal
				isOpen={isOpen}
				size={size || "lg"}
				// style={{width: "90%"}}
				toggle={toggleOpen}
				className={"mdl_wraper"}
			>
				<ModalBody style={{ padding: 5 }}>
					<div style={{ maxHeight: 500, overflowY: "auto", textAlign: "left" }}>
						<div className="card__title" style={{ display: "inline-block" }}>
							<CheckBoxField
								CheckBoxField="all"
								value={filter_all}
								label="Tous"
								name="all"
								onChange={(e) => this.resetFilter("all", e.target?.checked)}
								color="primary"
								style={{ display: "inline-block", marginRight: 8 }}
							/>
							{/* <CheckBoxField
								CheckBoxField="is_supply"
								value={is_supply}
								label="Fourniture"
								name="is_supply"
								onChange={(e) =>
									this.setState(
										{
											is_supply: e.target?.checked,
                      is_pack: false,
											is_internal: false,
											is_prd: false,
											filter_all: false,
										},
										() => this.getPage()
									)
								}
								color="primary"
								style={{ display: "inline-block", marginRight: 8 }}
							/> */}

							<CheckBoxField
								CheckBoxField="is_pack"
								value={is_pack}
								label="Composé"
								name="is_pack"
								onChange={(e) =>
									this.setState(
										{
											is_pack: e.target?.checked,
											// is_internal: false,
											is_prd: false,
											filter_all: false,
											// is_supply: false,
										},
										() => this.getPage()
									)
								}
								color="primary"
								style={{ display: "inline-block", marginRight: 8 }}
							/>
							<CheckBoxField
								CheckBoxField="is_prd"
								value={is_prd ? true : false}
								label="Produit"
								name="is_prd"
								onChange={(e) =>
									this.setState(
										{
											// is_internal: false,
											is_prd: e.target?.checked,
											is_pack: false,
											// is_supply: false,
											filter_all: false,
										},
										() => this.getPage()
									)
								}
								color="primary"
								style={{ display: "inline-block", marginRight: 8 }}
							/>

							{/* {!internal_filter && (
								<CheckBoxField
									CheckBoxField="is_internal"
									value={is_internal}
									label="Interne"
									name="is_internal"
									onChange={(e) =>
										this.setState(
											{
												is_internal: e.target?.checked,
												is_pack: false,
												// is_supply: false,
												is_prd: false,
												filter_all: false,
											},
											() => this.getPage()
										)
									}
									color="primary"
									style={{ display: "inline-block", marginRight: 8 }}
								/>
							)}
							{!internal_filter && (
								<CheckBoxField
									CheckBoxField="is_external"
									value={is_external}
									label="Externe"
									name="is_external"
									onChange={(e) =>
										this.setState(
											{
												is_external: e.target?.checked,
												is_prd: false,
												is_pack: false,
												// is_supply: false,
												filter_all: false,
											},
											() => this.getPage()
										)
									}
									color="primary"
									style={{ display: "inline-block", marginRight: 8 }}
								/>
							)} */}
						</div>

						<div style={{ width: 60, float: "right" }}>
							<Select
								name="Page"
								// defaultValue={selected_page_size}
								value={page_sizes.filter((option) => option.value === selected_page_size)}
								onChange={(e) => this.setState({ selected_page_size: e.value }, () => this.getPage())}
								options={page_sizes}
								clearable={false}
								className="react-select"
								placeholder="Page"
								classNamePrefix="react-select"
							/>
						</div>

						<div className="form__form-group" style={{ width: 200 }}>
							<span className="form__form-group-label">Recherche</span>
							<div className="form__form-group-field">
								<input
									style={{
										width: "100%",
										border: "none",
										borderBottom: "1px solid #ddd",
										padding: 5,
									}}
									defaultValue={query}
									component="input"
									placeholder="Recherche"
									onChange={(e) =>
										this.setState({ query: e.target.value }, () => this.getPage(current_page))
									}
								/>
							</div>
						</div>

						{articles && articles.length > 0 && (
							<Table responsive striped>
								<thead>
									<tr>
										<th></th>
										<th></th>
										<th>Ref</th>
										<th>Nom</th>
										<th>Courte desc</th>
										<th>Catégorie</th>
										<th>Prix</th>
										<th>QTE</th>
										<th>Type</th>
									</tr>
								</thead>
								<tbody style={{ fontSize: 15, fontWeight: 500 }}>
									{articles.map((item, i) => (
										<tr
											key={"item" + i}
											style={
												!item.is_pack ? { backgroundColor: articleStockColor(item.qty) } : {}
											}
										>
											<th scope="row" style={{ width: 60, verticalAlign: "middle" }}>
												{multi ? (
													<span>
														{this.isChecked(item._id) ? (
															<CustomInput
																type="checkbox"
																id={item._id}
																checked={true}
																// disabled={disable_selected && this.isInSelected(item._id) }
																onChange={(e) =>
																	this.changeSelected(e.target.checked, item, i)
																}
																label=""
															/>
														) : (
															<CustomInput
																type="checkbox"
																id={item._id}
																checked={false}
																// disabled={disable_selected && this.isInSelected(item._id) }
																onChange={(e) =>
																	this.changeSelected(e.target.checked, item, i)
																}
																label=""
															/>
														)}
													</span>
												) : (
													<CustomInput
														type="radio"
														id={item._id}
														name="school_level"
														checked={this.isChecked(item._id)}
														// disabled={disable_selected && this.isInSelected(item._id) }
														onChange={(e) => this.changeSelected(e.target.checked, item, i)}
														label=""
													/>
												)}
											</th>

											<td style={{ padding: 2 }}>
												<img
													style={{ width: 100 }}
													src={
														item.img
															? api.root + "/" + item.img.path
															: `${process.env.PUBLIC_URL}/img/no_product.png`
													}
												/>
											</td>

											<td style={{ verticalAlign: "middle" }}>
												<span style={{ color: "#777" }}>#{item.ref}</span>
											</td>
											<td style={{ verticalAlign: "middle" }}>{item.name}</td>

											<td style={{ verticalAlign: "middle" }}>{item.short_desc}</td>

											<td style={{ verticalAlign: "middle" }}>{item.category?.name}</td>

											<td style={{ verticalAlign: "middle" }}>{item.price}</td>

											<td style={{ verticalAlign: "middle" }}>{!item.is_pack ? item.qty : ""}</td>

											{item.is_supply ? (
												<td style={{ verticalAlign: "middle" }}>Fourniture</td>
											) : (
												<td style={{ verticalAlign: "middle" }}>
													{item.is_internal ? "Interne" : "Externe"}
												</td>
											)}
										</tr>
									))}
								</tbody>
							</Table>
						)}
					</div>

					<Pagination
						currentPage={current_page}
						totalPage={getCountPages(count_all_articles, selected_page_size)}
						onChangePage={(i) => this.onChangePage(i)}
					/>
				</ModalBody>

				<ModalFooter style={{ paddingBottom: 0 }}>
					<Button color="primary" onClick={() => accept(selectedItems)}>
						Ok
					</Button>{" "}
					<Button color="secondary" onClick={toggleOpen}>
						Annuler
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

const mapStateToProps = ({ article }) => {
	const { loading_get_articles, articles, count_all_articles } = article;
	return {
		loading_get_articles,
		articles,
		count_all_articles,
	};
};

export default connect(mapStateToProps, {
	getArticles,
})(SelectArticle);
