import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ListOrdersCmp from '../../shared/components/orders/list-orders-cmp';
import OrderCmp from '../../shared/components/orders/order-cmp';
// import AddOrderPage from '../Orders/add-order-page';

// import ListOrdersPage from '../Orders/list-orders-page';
// import AddOrderPage from './add-order-page';
import EditOrderPage from '../Orders/edit-order-page';
import ViewOrderPage from '../Orders/view-order-page';

const OrdersTrainsRoutingPages = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route path={`${match.url}/list`}
        render={props => <Container>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Commandes CM</h3>
            </Col>
            <Col md={12}>
              <ListOrdersCmp {...props} is_training_order />
            </Col>
          </Row>
          <Row>

          </Row>
        </Container>}
      />

      <Route path={`${match.url}/add-new`}
        render={props => <Container>
          <OrderCmp {...props} adding={true} is_training_order />
        </Container>}
      />

      <Route path={`${match.url}/edit/:orderId`}
        render={props => <EditOrderPage {...props} is_training_order />}
      />

      <Route path={`${match.url}/view/:orderId`}
        render={props => <ViewOrderPage {...props} is_training_order />}
      />




      <Redirect to="/" />
    </Switch>

  </Suspense>
);
export default OrdersTrainsRoutingPages;
