
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_ORDERS, getOrdersSuccess, getOrdersError, 
  ADD_ORDER, addOrderError, addOrderSuccess, 
  GET_ONE_ORDER, getOneOrderSuccess, getOneOrderError, 
  EDIT_ORDER, editOrderError, editOrderSuccess,

  DELETE_ORDER, deleteOrderError, deleteOrderSuccess,
} from '../actions/ordersActions';
import { api } from '../../const';



// *** GET ORDERS
export function* watchGetOrders() { yield takeEvery(GET_ORDERS, getOrders) }
function* getOrders({ payload }) {
  try {
    const ret = yield call(getOrdersRequest, payload);

    if (ret && ret.status == 200) yield put(getOrdersSuccess({ orders: ret.data?.orders || [], count: ret.data?.count || 0}));
    else yield put(getOrdersError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOrdersError({ error, message: (error) ? error.message : '' }));
  }
}
const getOrdersRequest = async (payload) => {  
  return await axios.post(api.orders+'/filter/'+payload.offset+'/'+payload.limit, payload.filter || {})
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// // *** GET ONE ORDER
export function* watchGetOneOrder() { yield takeEvery(GET_ONE_ORDER, getOneOrder) }
function* getOneOrder({ payload }) {
  try {
    const ret = yield call(getOneOrderRequest, payload);

    if (ret && ret.status == 200) yield put(getOneOrderSuccess({ order: ret.data?.order || null }));
    else yield put(getOneOrderError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneOrderError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneOrderRequest = async (payload) => {  
  return await axios.get(api.orders+"/get-one/"+payload.order_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD ORDERS
export function* watchAddOrders() { yield takeEvery(ADD_ORDER, addOrder) }
function* addOrder({ payload }) {
  try {
    const ret = yield call(addOrdersRequest, payload);

    if (ret && ret.status == 201) yield put(addOrderSuccess({ order: ret.data.order }));
    else yield put(addOrderError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addOrderError({ error, message: (error) ? error.message : '' }));
  }
}
const addOrdersRequest = async (payload) => {  
  return await axios.post(api.orders+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT ORDERS
export function* watchEditOrder() { yield takeEvery(EDIT_ORDER, editOrder) }
function* editOrder({ payload }) {
  try {
    const ret = yield call(editOrdersRequest, payload);

    if (ret && ret.status == 200) yield put(editOrderSuccess({ order: ret.data.order }));
    else yield put(editOrderError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editOrderError({ error, message: (error) ? error.message : '' }));
  }
}
const editOrdersRequest = async (payload) => {  
  return await axios.patch(api.orders+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE ORDERS
export function* watchDeleteOrder() { yield takeEvery(DELETE_ORDER, deleteOrder) }
function* deleteOrder({ payload }) {
  try {
    const ret = yield call(deleteOrdersRequest, payload);

    if (ret && ret.status == 200) yield put(deleteOrderSuccess({ }));
    else yield put(deleteOrderError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteOrderError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteOrdersRequest = async (payload) => {  
  return await axios.delete(api.orders+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetOrders),
    fork(watchGetOneOrder),
    fork(watchAddOrders),
    fork(watchEditOrder),
    fork(watchDeleteOrder),
  ]);
}