import {
  GET_PURCHASES, GET_PURCHASES_SUCCESS, GET_PURCHASES_ERROR,
  ADD_PURCHASE, ADD_PURCHASE_SUCCESS, ADD_PURCHASE_ERROR,
  GET_ONE_PURCHASE, GET_ONE_PURCHASE_SUCCESS, GET_ONE_PURCHASE_ERROR,
  EDIT_PURCHASE, EDIT_PURCHASE_SUCCESS, EDIT_PURCHASE_ERROR,
  DELETE_PURCHASE, DELETE_PURCHASE_SUCCESS, DELETE_PURCHASE_ERROR
} from '../actions/purchasesActions';

const initialState = {
  loading_get_purchases: false,
  error_get_purchases: null,
  purchases: [],
  count_all_purchases: 0,

  new_purchase: null, loading_add_purchase: false, error_add_purchase: null,

  selected_purchase: null, 
  loading_one_purchase: false, error_one_purchase: null,
  loading_edit_purchase: false, error_edit_purchase: null,

  loading_delete_purchase: false, error_delete_purchase: null
};

const purchasesReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_PURCHASES: return {...state, loading_get_purchases: true, error_get_purchases: null };
    case GET_PURCHASES_SUCCESS:
      return {...state, purchases: action.payload.purchases, loading_get_purchases: false, error_get_purchases: null, count_all_purchases: action.payload.count };
    case GET_PURCHASES_ERROR:
      // console.log("ACTION", {error_get_purchases: action.error, loading_get_purchases: false, purchases: []});
      return {...state, error_get_purchases: action.error, loading_get_purchases: false, purchases: [] };



    case ADD_PURCHASE: return {...state, loading_add_purchase: true, error_add_purchase: null };
    case ADD_PURCHASE_SUCCESS:
      return {...state, new_purchase: action.payload.purchase, loading_add_purchase: false, error_add_purchase: null };
    case ADD_PURCHASE_ERROR:
      return {...state, error_add_purchase: action.error, loading_add_purchase: false, new_purchase: null };


    case GET_ONE_PURCHASE: return {...state, loading_one_purchase: true, error_one_purchase: null };
    case GET_ONE_PURCHASE_SUCCESS:
      return {...state, selected_purchase: action.payload.purchase, loading_one_purchase: false, error_one_purchase: null };
    case GET_ONE_PURCHASE_ERROR:
      return {...state, error_one_purchase: action.error, loading_one_purchase: false, selected_purchase: null };



    case EDIT_PURCHASE: return {...state, loading_edit_purchase: true, error_edit_purchase: null };
    case EDIT_PURCHASE_SUCCESS:
      return {...state, selected_purchase: action.payload.purchase, loading_edit_purchase: false, error_edit_purchase: null };
    case EDIT_PURCHASE_ERROR:
      return {...state, error_edit_purchase: action.error, loading_edit_purchase: false, selected_purchase: null };




    case DELETE_PURCHASE: return {...state, loading_delete_purchase: true, error_one_purchase: null };
    case DELETE_PURCHASE_SUCCESS:
      return {...state, loading_delete_purchase: false, error_delete_purchase: null };
    case DELETE_PURCHASE_ERROR:
      return {...state, error_delete_purchase: action.error, loading_delete_purchase: false };


    default: return state;
  }
};

export default purchasesReducer;
