import React from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ListPurchasesCmp from '../../shared/components/purchases/list-purchases-cmp';

const ListPurchasesPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Achats</h3>
      </Col>
      <Col md={12}>
        <ListPurchasesCmp />
      </Col>
    </Row>
    <Row>
      
    </Row>
  </Container>
);

export default ListPurchasesPage;