import React from "react";
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from "reactstrap";
import ListOrdersCmp from "../../shared/components/orders/list-orders-cmp";

const ListCanceledOrdersPage = () => (
	<Container>
		<Row>
			<Col md={12}>
				<h3 className="page-title">Commandes annulées</h3>
			</Col>
			<Col md={12}>
				<ListOrdersCmp only_canceled={true} />
			</Col>
		</Row>
		<Row></Row>
	</Container>
);

export default ListCanceledOrdersPage;
