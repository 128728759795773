import React, { Component, Fragment } from "react";
import {
  Row, Card, CardBody, Table, Button, Col,
  Modal, ModalBody, ModalFooter, CustomInput
} from "reactstrap";
// import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import {
  getProviders
} from "../../../redux/actions/providersActions";
import { api, no_user_img } from "../../../const";
import { getCountPages } from "../../helper/utils";


import Pagination from "../pagination/Pagination";

class SelectProvider extends Component {
  constructor(props) {
    super(props);

    // const { messages } = this.props.intl || {};

    this.state = {
      dropdownSplitOpen: false,
      modalOpen: false,
      selectedItems: [],
      lastChecked: null,

      current_page: 1,
      totalPage: 1,
      selected_page_size: 10,
    };
  }
  componentWillMount() {
    this.getPage()
  }

  componentDidUpdate(prev) {
    const { isOpen, selectedItems } = this.props
    if (!prev.isOpen && isOpen) {
      // this.setState({selectedItems})
      const items = [];
      for (let i = 0; i < selectedItems.length; i++) {
        items.push({ ...selectedItems[i] })
      }

      this.setState({ selectedItems: items });
    }
  }

  onChangePage = page => {
    this.setState({ current_page: page }, () => this.getPage(page));
  };

  changeSelected = (checked, item, i) => {
    // item['checked'] = (checked)
    if (checked) this.setState(prev => {
      const selectedItems = prev.selectedItems
      if (selectedItems.length > 0 && !this.props.multi) return { selectedItems: [item] }

      selectedItems.push(item)
      return { selectedItems }
    })
    else this.setState(prev => {
      const selectedItems = prev.selectedItems
      try {
        for (let indx = 0; indx < selectedItems.length; indx++) {
          if (selectedItems[indx]._id == item._id) {
            selectedItems.splice(indx, 1)
            break;
          }
        }
      } catch (error) { }
      return { selectedItems }
    })
  }

  isChecked = (_id) => {
    const { selectedItems } = this.state
    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i]._id == _id) return true
    }
    return false
  }

  isInSelected(_id) {
    const { selectedItems } = this.props
    if (selectedItems)
      for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i]._id == _id) return true
      }
    return false
  }


  getPage(page = 1) {
		const { selected_page_size, filter_all, filter_status, } = this.state;

		const offset = (page == 1 || page == 0) ? 0 : selected_page_size * (page - 1);

		const filter = {}
		if (!filter_all) filter['statuses'] = filter_status

		// if (filter_by_query != '') filter['name'] = filter_by_query

		this.props.getProviders({ filter, offset, limit: selected_page_size })
	}

  render() {
    const {
      current_page,
      selected_page_size,
      selectedItems,
    } = this.state;

    // const startIndex = (currentPage - 1) * selectedPageSize;
    // const endIndex = currentPage * selectedPageSize;

    const { isOpen, size, multi, toggleOpen, accept, count_all_providers, providers } = this.props;




    return (<Modal isOpen={isOpen} size={size || "lg"} toggle={toggleOpen} className={"mdl_wraper"} >
      <ModalBody>
        <div style={{ maxHeight: 400, overflowY: 'auto' }}>
          {(providers && providers.length > 0) && <Table responsive striped>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Référence</th>
                <th>Nom</th>
                <th>Téléphone</th>
                <th>Adresse</th>
              </tr>
            </thead>
            <tbody>
              {providers.map((item, i) => <tr key={"item" + i} >
                <th scope="row" style={{ width: 60, verticalAlign: 'middle' }}>
                  {multi ? <span>
                    {this.isChecked(item._id) ? <CustomInput type="checkbox" id={item._id}
                      checked={true}
                      // disabled={disable_selected && this.isInSelected(item._id) }
                      onChange={(e) => this.changeSelected(e.target.checked, item, i)}
                      label="" /> :
                      <CustomInput type="checkbox" id={item._id}
                        checked={false}
                        // disabled={disable_selected && this.isInSelected(item._id) }
                        onChange={(e) => this.changeSelected(e.target.checked, item, i)}
                        label="" />}
                  </span> :

                    <CustomInput type="radio" id={item._id} name="school_level"
                      checked={this.isChecked(item._id)}
                      // disabled={disable_selected && this.isInSelected(item._id) }
                      onChange={(e) => this.changeSelected(e.target.checked, item, i)}
                      label="" />
                  }
                </th>





                <td style={{ width: 50 }}>
                  <img src={item.img ? api.root + "/" + item.img.path : no_user_img} />
                </td>

                <td>#{item.ref}</td>

                <td style={{ verticalAlign: 'middle' }}>
                  <p style={{ margin: 0, color: "#777" }} >{item.name}</p>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {item.phone}
                </td>

                <td style={{ verticalAlign: 'middle' }}>
                  {item.address}
                </td>
              </tr>)}
            </tbody>
          </Table>}
        </div>

        <Pagination currentPage={current_page}
					totalPage={getCountPages(count_all_providers, selected_page_size)}
					onChangePage={i => this.onChangePage(i)}
				/>
      </ModalBody>

      <ModalFooter style={{ paddingBottom: 0 }}>
        <Button color="primary" onClick={() => accept(selectedItems)}>Ok</Button>{" "}
        <Button color="secondary" onClick={toggleOpen}>Annuler</Button>
      </ModalFooter>
    </Modal>
    );
  }
}

const mapStateToProps = ({ provider }) => {
  const { loading_get_providers, providers, count_all_providers, } = provider
  return {
    loading_get_providers, providers, count_all_providers
  };
};

export default connect(mapStateToProps, {
  getProviders
})(SelectProvider);