/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";

import { Card, Col, Button, ButtonToolbar, Table, CardBody, Spinner } from "reactstrap";
// import { statName } from '../../../const';

import { NavLink } from "react-router-dom";

import Select from "react-select";

import moment from "moment";
import StatusBade from "../../helper/StatusBadge";

import ConfirmMdl from "../confirm-mdl";
import { deleteOrder, editOrder } from "../../../redux/actions/ordersActions";

import axios from "axios";

import { api, statuses } from "../../../const";
import Row from "reactstrap/lib/Row";

moment.locale("fr");

class Invoice extends PureComponent {
	constructor() {
		super();
		this.state = {
			show_delete_msg: false,
			shipping_real_price: 0,

			show_edit_msg: false,
			req_edit_id: null,
			status_editing: "",
		};
	}

	componentDidMount() {
		// this.setState({shipping_real_price: this.props.shipping_real_price})
		this.setState({ shipping_real_price: this.props.order ? this.props.order.shipping_real_price : 0 });
	}

	componentDidUpdate(prev) {
		const {
			loading_delete_order,
			error_delete_order,
			history,
			loading_one_order,
			error_one_order,
			order,
			shipping_real_price,
		} = this.props;

		if (prev.loading_delete_order === true && loading_delete_order === false && !error_delete_order) {
			if (order?.is_training_order) {
				setTimeout(() => history?.push("/app/orders-trains/list"), 500);
			} else if (order?.is_supply_order) {
				setTimeout(() => history?.push("/app/orders-supply/list"), 500);
			} else setTimeout(() => history?.push("/app/orders/list"), 500);
		}

		// if(prev.order?.shipping_real_price != order?.shipping_real_price){
		//   this.setState({ shipping_real_price: order ? order.shipping_real_price : 0 })
		// }

		if (prev.loading_one_order && !loading_one_order && !error_one_order) {
			// console.log(prev.loading_one_order, loading_one_order);
			this.setState({ shipping_real_price: order ? order.shipping_real_price : 0 });
		}

		if (prev.shipping_real_price != shipping_real_price) {
			this.setState({ shipping_real_price: shipping_real_price });
		}
	}

	print = (order_id, print_type = "post") => {
		axios
			.post(api.orders + "/print", { print_type, order_id })
			.then((resp) => {
				// console.log("resp print", resp);
				if (resp.status == 200 && resp.data && !resp.data.err) {
					window.open(api.root + "/" + resp.data.pdf + "?" + new Date());
				} else alert("Veuillez vérifier vos données et réessayer");
			})
			.catch((error) => {
				alert("Veuillez vérifier vos données et réessayer");
			});
	};

	editStatus = (status, order_id) => {
		this.setState({ show_edit_msg: true, req_edit_id: order_id, status_editing: status });
	};

	editReq = () => {
		this.props.editOrder({ _id: this.state.req_edit_id, data: { status: this.state.status_editing } });

		this.setState({ show_edit_msg: false });
	};

	statusKey = (status) => {
		return statuses.filter((item) => item.value === status)[0]?.key || 0;
	};

	render() {
		const { order, user, deleteOrder, editOrder, loading_edit_order, loading_delete_order } = this.props;
		if (!order) return "chargement..";

		const { show_delete_msg, shipping_real_price, show_edit_msg } = this.state;

		const is_admin = user?.roles?.includes("ADMIN");
		const order_status_key = this.statusKey(order.status);

		return (
			<Card>
				<CardBody className="invoice p-2">
					<div className="invoice__head">
						<div className="invoice__head-left">
							{/* <h3>Client</h3> */}
							<h3>
								<a href={`/app/clients/edit/${order?.client?._id}`}>
									{order.client_data &&
									order.client_data.first_name_ar &&
									order.client_data.first_name_ar != ""
										? order.client_data.first_name_ar
										: order.client_data?.first_name}
								</a>
							</h3>

							<div style={{ float: "left" }}>
								<p>{order.client_data?.address}</p>
								<p>{order.client_data?.city}</p>
								<p>{order.client_data?.region},</p>
								<p>{order.client_data?.fb} </p>
								<p dir="ltr">
									(+216) {order.client_data?.phone} / {order.client_data?.phone2}
								</p>
							</div>

							{!order.same_shipping_infos ? (
								<div
									style={{
										float: "right",
										marginLeft: "10px",
										marginTop: "-35px",
										borderLeft: "1px solid #ddd",
										paddingLeft: "10px",
									}}
								>
									<p style={{ fontWeight: "bold" }}>Livraison</p>
									<p>{order.shipping_name}</p>
									<p>{order.shipping_address}</p>
									<p>{order.shipping_city}</p>
									<p>{order.shipping_region},</p>
									<p dir="ltr">(+216) {order.shipping_phone}</p>
								</div>
							) : null}
						</div>
						<div className="invoice__head-right">
							<h4>Facture n ° #{order.ref}</h4>
							<p className="invoice__date">{moment(order.date).format("llll")}</p>
							<StatusBade status={order.status} />

							{!order.is_trash &&
								(is_admin ||
									["pending", "preparing", "prepared", "shipping"].includes(order.status)) && (
									<div style={{ width: "100%", textAlign: "center", marginTop: 15 }}>
										<div style={{ width: 150, margin: "auto" }}>
											<Select
												name="status"
												defaultValue={order.status}
												value={statuses.filter((option) => option.value === order.status)}
												onChange={(e) => this.editStatus(e.value, order._id)}
												options={statuses}
												clearable={false}
												className="react-select"
												isOptionDisabled={(option) => {
													// return option.only_admin && !is_admin;
													return (
														option.key < order_status_key &&
														!(order_status_key === 2 && option.key === 1)
													);
												}}
												placeholder="Statut"
												classNamePrefix="react-select"
											/>
										</div>
									</div>
								)}
						</div>
					</div>

					{loading_delete_order ? (
						<div style={{ textAlign: "center", marginTop: "50px", marginBottom: "50px", color: "#f44336" }}>
							<Spinner />
							<p style={{ color: "#f44336", marginTop: "20px" }}>Suppression..</p>
						</div>
					) : (
						<Fragment>
							<Table responsive className="table--bordered">
								<thead>
									<tr>
										<th>#</th>
										<th>Article</th>
										<th>QTE</th>
										<th>Variants</th>
										{order?.is_training_order && <th>Enfant</th>}
										<th>Prix unitaire</th>
										{order?.is_training_order && <th>Privilège</th>}
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									{order.articles.map((article, index) => (
										<tr key={index}>
											<td>{index + 1}</td>
											{is_admin ? (
												<td>
													<a href={`/app/articles/edit/${article?.article?._id}`}>
														{article.name}
													</a>
												</td>
											) : (
												<td>{article.name}</td>
											)}
											<td>{article.qty}</td>
											<td>
												{article.variants &&
													article.variants.map((vr, j) => (
														<span key={"vr" + j}>{" " + vr.name + ": " + vr.value}</span>
													))}
											</td>
											{order.is_training_order && (
												<td>
													{/* {article.child?.first_name} */}
													{article.child_data &&
													article.child_data.first_name_ar &&
													article.child_data.first_name_ar != ""
														? article.child_data.first_name_ar
														: article.child_data?.first_name}

													{article.childs &&
														article.childs.map((child_data, i_child) => (
															<p key={"child_" + i_child + index}>
																{child_data &&
																child_data.first_name_ar &&
																child_data.first_name_ar != ""
																	? child_data.first_name_ar +
																	  " " +
																	  child_data.last_name_ar
																	: child_data?.first_name +
																	  " " +
																	  child_data?.last_name}
															</p>
														))}
												</td>
											)}
											<td>{article.unit_price} TND</td>
											{order.is_training_order && <td>{article.privilege}</td>}
											<td>{Number(article.unit_price * article.qty).toFixed(2)} TND</td>
										</tr>
									))}
								</tbody>
							</Table>

							<Row>
								<Col md="2">
									<div className="form__form-group" style={{ marginTop: 10 }}>
										<span className="form__form-group-label">Coût réel de livraison </span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
													padding: 5,
												}}
												type="number"
												min="0"
												value={shipping_real_price}
												component="input"
												placeholder="Coût réel de livraison"
												onChange={(e) => this.setState({ shipping_real_price: e.target.value })}
											/>
										</div>
									</div>

									{!order.is_trash ? (
										<Button
											onClick={() =>
												editOrder({ _id: order?._id, data: { shipping_real_price } })
											}
											className="square btn btn-outline-success"
										>
											{/* <span style={{display: 'inline-block'}} className="lnr lnr-car mr-5"></span>  */}
											{loading_edit_order ? (
												"chargement..."
											) : (
												<span style={{ display: "inline-block" }}>Changer</span>
											)}
										</Button>
									) : null}
								</Col>

								<Col md="10">
									<div className="invoice__total">
										<p>Sub - Total: {order.total + order.discount} TND</p>
										<p>
											{order.is_training_order ? "Commission" : "Remise"}: {order.discount} TND
										</p>
										<p className="invoice__grand-total">Somme finale: {order.total} TND</p>

										{/* ACTIONS */}
										{!order.is_trash ? (
											<ButtonToolbar className="invoice__toolbar">
												{(is_admin || order.status == "pending") && (
													<Button
														color="danger"
														onClick={() => this.setState({ show_delete_msg: true })}
													>
														<span className="lnr lnr-trash"></span> Annuler
													</Button>
												)}

												{(is_admin ||
													["pending", "preparing", "prepared"].includes(order.status)) && (
													<>
														{order.is_training_order && (
															<NavLink to={`/app/orders-trains/edit/${order._id}`}>
																<Button>
																	<span className="lnr lnr-pencil"></span> Modifier
																</Button>
															</NavLink>
														)}
														{order.is_supply_order && (
															<NavLink to={`/app/orders-supply/edit/${order._id}`}>
																<Button>
																	<span className="lnr lnr-pencil"></span> Modifier
																</Button>
															</NavLink>
														)}
														{!order.is_training_order && !order.is_supply_order && (
															<NavLink to={`/app/orders/edit/${order._id}`}>
																<Button>
																	<span className="lnr lnr-pencil"></span> Modifier
																</Button>
															</NavLink>
														)}
													</>
												)}

												<Button onClick={() => this.print(order._id)} color="primary">
													<span className="lnr lnr-envelope"></span> Bordereau
												</Button>
												<Button
													onClick={() => this.print(order._id, "address")}
													color="warning"
												>
													<span className="lnr lnr-map-marker"></span> Adresse
												</Button>

												<Button onClick={() => this.print(order._id, "quote")} color="info">
													<span className="lnr lnr-file-empty"></span> Bon de livraison
												</Button>
											</ButtonToolbar>
										) : null}
									</div>
								</Col>
							</Row>
						</Fragment>
					)}

					{show_delete_msg ? (
						<ConfirmMdl
							toggle={() =>
								this.setState((prev) => {
									return { show_delete_msg: !prev.show_delete_msg };
								})
							}
							is_open={show_delete_msg}
							accept={() => {
								this.setState({ show_delete_msg: false }, () => {
									deleteOrder({ _id: order._id });
								});
							}}
						/>
					) : null}

					<ConfirmMdl
						toggle={() =>
							this.setState((prev) => {
								return { show_edit_msg: !prev.show_edit_msg };
							})
						}
						message="Êtes-vous sûr de vouloir modifier le statut et la quantité en stock ?"
						is_open={show_edit_msg}
						accept={() => {
							this.editReq();
						}}
					/>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = ({ order, auth }) => {
	const { loading_delete_order, error_delete_order, loading_edit_order, loading_one_order } = order;

	return {
		loading_delete_order,
		error_delete_order,
		loading_edit_order,
		loading_one_order,
		user: auth?.user,
	};
};
export default connect(mapStateToProps, {
	deleteOrder,
	editOrder,
})(Invoice);
