import {
  GET_CATEGORYS, GET_CATEGORYS_SUCCESS, GET_CATEGORYS_ERROR,

  ADD_CATEGORY, ADD_CATEGORY_SUCCESS, ADD_CATEGORY_ERROR,
  EDIT_CATEGORY, EDIT_CATEGORY_SUCCESS, EDIT_CATEGORY_ERROR,
  DELETE_CATEGORY, DELETE_CATEGORY_SUCCESS, DELETE_CATEGORY_ERROR
} from '../actions/categoryActions';

import arrayToTree from "array-to-tree";

const initialState = {
  loading_get_ctgs: false,
  error_get_ctgs: null,
  ctgs: [],

  new_ctg: null, loading_add_ctg: false, error_add_ctg: null,
  loading_edit_ctg: false, error_edit_ctg: null,

  loading_delete_ctg: false, error_delete_ctg: null,
};

const ctgsReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_CATEGORYS: return {...state, loading_get_ctgs: true, error_get_ctgs: null };
    case GET_CATEGORYS_SUCCESS:
      return {...state, 
        ctgs: arrayToTree(action.payload.ctgs || [], {
          parentProperty: 'parent',
          customID: '_id'
        }), 
        loading_get_ctgs: false, error_get_ctgs: null 
      };
    case GET_CATEGORYS_ERROR:
      return {...state, error_get_ctgs: action.error, loading_get_ctgs: false, ctgs: [] };


    case ADD_CATEGORY: return {...state, loading_add_ctg: true, error_add_ctg: null };
    case ADD_CATEGORY_SUCCESS:
      return {...state, new_ctg: action.payload.ctg, loading_add_ctg: false, error_add_ctg: null };
    case ADD_CATEGORY_ERROR:
      return {...state, error_add_ctg: action.error, loading_add_ctg: false, new_ctg: null };



    case EDIT_CATEGORY: return {...state, loading_edit_ctg: true, error_edit_ctg: null };
    case EDIT_CATEGORY_SUCCESS:
      return {...state, loading_edit_ctg: false, error_edit_ctg: null };
    case EDIT_CATEGORY_ERROR:
      return {...state, error_edit_ctg: action.error, loading_edit_ctg: false};





    case DELETE_CATEGORY: return {...state, loading_delete_ctg: true, error_delete_ctg: null };
    case DELETE_CATEGORY_SUCCESS:
      return {...state, loading_delete_ctg: false, error_delete_ctg: null };
    case DELETE_CATEGORY_ERROR:
      return {...state, error_delete_ctg: action.error, loading_delete_ctg: false};


    default: return state;
  }
};

export default ctgsReducer;
