import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { Col, Container, Row } from "reactstrap";
import OrderCmp from "../../shared/components/orders/order-cmp";
// import ManageOrder from "../../shared/components/orders/ManageOrder";

// export default
class EditOrderPage extends PureComponent {
	constructor() {
		super();
		this.state = {
			order_id: "",
		};
	}

	componentDidMount() {
		const { match } = this.props;
		this.setState({ order_id: match && match.params ? match.params.orderId : "" });
	}

	render() {
		// const { match, user, is_training_order } = this.props;

		return (
			<Container>
				<Row>
					<Col md={12}>
						<h3 className="page-title">Commande</h3>
					</Col>
					<Col md={12}>
						{/* <ManageOrder
							is_training_order={is_training_order}
							order_id={match?.params?.orderId}
							action="edit"
							user={user}
						/> */}
						<OrderCmp editing={true} match={this.props.match} order_id={this.state.order_id} history={this.props.history}
            is_training_order={this.props.is_training_order}
          />
					</Col>
				</Row>
			</Container>
		);
	}
}
const mapStateToProps = ({ auth }) => {
	return {
		user: auth?.user,
	};
};
export default connect(mapStateToProps, {})(EditOrderPage);
