import React, { useState } from 'react';
import {
  Card, CardBody, Row, Col, Button, ButtonToolbar, ButtonGroup
} from 'reactstrap';
import { api } from '../../../../const';

// import Select from 'react-select';

// import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';

// const Ava = `${process.env.PUBLIC_URL}/img/no_user.png`;

// const renderEditAvatar = (img, img_url) => {
//   if (img && img_url != '') return (<img src={img_url} />)
//   if (!img && img_url != '') return (<img src={img_url} />)

//   return (<img src={`${process.env.PUBLIC_URL}/img/no_user.png`} />)
// }
// const req_statuses = [{ label: 'Request', value: 'request' }, { label: 'Accepted', value: 'accepted' }, { label: 'Refused', value: 'refused' }]

const ProfileMain = ({ profile, editProfile, changeAvatar, count_trips, count_finished, count_canceled }) => {
  const [editing, setEditing] = useState(false);

  const [first_name, setFirstName] = useState(profile?.first_name);
  const [last_name, setLastName] = useState(profile?.last_name);
  const [email, setEmail] = useState(profile?.email);
  const [mobile, setMobile] = useState(profile?.mobile);
  const [gender, setGender] = useState(profile?.gender);
  const [address, setAddress] = useState(profile?.address);
  const [zipcode, setZipcode] = useState(profile?.zipcode);
  const [admin_pass, setPass] = useState(profile?.admin_pass);
  const [roles, setRoles] = useState(profile?.roles || []);

  const changeRole = (key, checked) => {
    try {
      const new_roles = [...roles];
      if (checked) new_roles.push(key)
      else {
        new_roles.splice(new_roles.indexOf(key), 1)
      }
      setRoles(new_roles)
    } catch (error) { }
  }

  // console.log("ROLES", roles);
  return (
    <Col md={12} lg={12} xl={12}>
      {profile && <Card>
        <CardBody className="profile__card">
          <span onClick={() => setEditing(!editing)} className="lnr lnr-pencil" style={{
            color: "#50b179", cursor: "pointer",
            position: "absolute", right: 5, top: 5,
            fontSize: 16
          }}></span>

          <div className="profile__information">
            {!editing && <div className="profile__avatar profile_img_wrap">
              {
                (profile.avatar) ? <img src={`${api.root}/${profile.avatar.path}`} alt="#" />
                  : <img src={`${process.env.PUBLIC_URL}/img/no_user.png`} alt="#"/>
              }

              <div className="caption">
                <label htmlFor="add_avatar"><span className="lnr lnr-picture"></span></label>
                <input onChange={changeAvatar} id="add_avatar" type="file" accept="image/*" hidden />
              </div>
            </div>}

            {editing ? <Row>
              <Col md="6">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label">First Name</span>

                    <div className="form__form-group-field">
                      <input value={first_name} placeholder="First name" onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="6">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Last Name</span>

                    <div className="form__form-group-field">
                      <input value={last_name} placeholder="Last name" onChange={(e) => setLastName(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>

                    <div className="form__form-group-field">
                      <input value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Mobile</span>

                    <div className="form__form-group-field">
                      <input type="number" value={mobile} placeholder="Mobile" onChange={(e) => setMobile(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md="12">
                <ButtonToolbar>
                  <ButtonGroup dir="ltr" className="btn-group--icons mb-1">
                    <Button onClick={() => setGender("male")} outline style={gender === 'male' ? { background: '#e7e2e2' } : {}}>Male</Button>
                    <Button onClick={() => setGender("female")} outline style={gender !== 'male' ? { background: '#e7e2e2' } : {}}>Female</Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>

              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Address</span>

                    <div className="form__form-group-field">
                      <input value={address} placeholder="Address" onChange={(e) => setAddress(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col md="12">
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Zip Code</span>

                    <div className="form__form-group-field">
                      <input value={zipcode} placeholder="Zip Code" onChange={(e) => setZipcode(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>


              <Col md="12">
                <label>Roles</label>
                <div>
                  <input type="checkbox" checked={roles?.includes("ADMIN")} onChange={(e) => {
                    changeRole("ADMIN", e.target.checked)
                  }} /> Admin
                </div>
                <div>
                  <input type="checkbox" checked={roles?.includes("TECH")} onChange={(e) => {
                    changeRole("TECH", e.target.checked)
                  }} /> Tech
                </div>
                <div>
                  <input type="checkbox" checked={roles?.includes("TECH_TRAINS")} onChange={(e) => {
                    changeRole("TECH_TRAINS", e.target.checked)
                  }} /> Tech formations
                </div>
                <div>
                  <input type="checkbox" checked={roles?.includes("TECH_CMD")} onChange={(e) => {
                    changeRole("TECH_CMD", e.target.checked)
                  }} /> Tech commandes
                </div>
                <div>
                  <input type="checkbox" checked={roles?.includes("TECH_CMD_CM")} onChange={(e) => {
                    changeRole("TECH_CMD_CM", e.target.checked)
                  }} /> Tech commandes CM
                </div>
                <div>
                  <input type="checkbox" checked={roles?.includes("USER")} onChange={(e) => {
                    changeRole("USER", e.target.checked)
                  }} /> Client
                </div>
              </Col>


              <Col md="12">
                <hr />
                <div className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Nouveau mot de passe</span>

                    <div className="form__form-group-field">
                      <input value={admin_pass} placeholder="Nouveau mot de passe" onChange={(e) => setPass(e.target.value)} />
                    </div>
                  </div>
                </div>
              </Col>



              <Col md="12">
                <ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
                  <Button color="primary" onClick={() => editProfile({
                    first_name, last_name, email, mobile, gender, address, zipcode, admin_pass, roles
                  })}>Submit</Button>
                  <Button type="button" onClick={() => setEditing(false)}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>


              : <div className="profile__data">
                <p className="profile__name">{profile.first_name + " " + profile.last_name}</p>
                <p className="profile__work">{profile.email}</p>
                <p className="profile__contact" dir="ltr">+251-{profile.mobile}</p>
                <p className="profile__contact" >{profile.gender === "male" ? "Male" : "Female"}</p>
                <p className="profile__contact" >{profile.address + " " + profile.zipcode}</p>
              </div>}

          </div>
          <div className="profile__stats">
            <div className="profile__stat">
              <p className="profile__stat-number">{count_trips || 0}</p>
              <p className="profile__stat-title">Achats</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{count_finished || 0}</p>
              <p className="profile__stat-title">Retours</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{count_canceled || 0}</p>
              <p className="profile__stat-title">Perdus</p>
            </div>
          </div>
        </CardBody>
      </Card>}
    </Col>
  )
}

export default ProfileMain;
