export const getCountPages = (count_all, page_size = 10) => {
	if (count_all == 0) return 1;

	let pages = Math.floor(count_all / page_size);
	if (count_all % page_size > 0) pages += 1;

	return pages;
};

const ligthTheme = {
	backgroundColor: "white",
	color: "#646777",
};

const darkTheme = {
	backgroundColor: "#2e2c34",
	color: "#dddddd",
};

export const themes = {
	ligthTheme,
	darkTheme,
};
export const getTooltipStyles = (themeName, type) => {
	switch (themeName) {
		case "theme-dark": {
			const { backgroundColor, color } = darkTheme;
			return {
				contentStyle: { backgroundColor },
				itemStyle: type === "defaultItems" ? null : { color },
			};
		}
		case "theme-light": {
			return ligthTheme;
		}
		default:
			return ligthTheme;
	}
};

export const articleStockColor = (qty_stock) => {
	if (qty_stock < 1) return "#f44336db";
	else if (qty_stock < 11) return "#ff9800a6";
	return "";
};

// export const formatDate = (date = null) => {
//   var d = new Date(date),
//     month = '' + (d.getMonth() + 1),
//     day = '' + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2)
//     month = '0' + month;
//   if (day.length < 2)
//     day = '0' + day;

//   return [year, month, day].join('-');
// }

// export const leadZeros = (num, size = 5) => {
//   return num.toString().padStart(size, "0")
// }

// export const logger = (name, value, desc=null) => {
//   console.log("====================================");
//   if(desc) console.log("Description : ", desc);
//   console.log(name, value);
// }

// export const dayFromNumber = (day_number) => {
//   switch (day_number) {
//     case 0: return "sunday";
//     case 1: return "monday";
//     case 2: return "tuesday";
//     case 3: return "wednesday";
//     case 4: return "thursday";
//     case 5: return "friday";
//     case 6: return "saturday";

//     default: return "sunday";
//   }
// }

export const quillModules = {
	toolbar: [
		["bold", "italic", "underline", "strike", "blockquote"],
		[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
		["link", "image"],
		["clean"],
	],
};

export const quillFormats = [
	"header",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image",
];

export const getName = (obj, return_line = true) => {
	try {
		if (return_line) return `${obj.first_name} ${obj.last_name} \n ${obj.first_name_ar} ${obj.last_name_ar}`;
		return `${obj.first_name} ${obj.last_name} ${obj.first_name_ar} ${obj.last_name_ar}`;
	} catch (error) {
		return "";
	}
};

// export const slugify = (str)=>{
//   str = str.replace(/^\s+|\s+$/g, ""); // trim
//   str = str.toLowerCase();

//   // remove accents, swap ñ for n, etc
//   var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
//   var to = "aaaaaaeeeeiiiioooouuuunc------";

//   for (var i = 0, l = from.length; i < l; i++) {
//     str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
//   }

//   str = str
//     .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
//     .replace(/\s+/g, "-") // collapse whitespace and replace by -
//     .replace(/-+/g, "-") // collapse dashes
//     .replace(/^-+/, "") // trim - from start of text
//     .replace(/-+$/, ""); // trim - from end of text

//   return str;
// }
