import React, { useState, PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { Button, ButtonToolbar, Modal, Row, Col } from "reactstrap";
import classNames from "classnames";
import Alert from "../../helper/Alert";

import { SketchPicker } from "react-color";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import { addCtg, getCtgs } from "../../../redux/actions/categoryActions";
import CtgsTree from "./ctgs-tree";

const modalClass = classNames({
	"modal-dialog--colored": false,
	"modal-dialog--header": true,
});

class AddCtgMdl extends PureComponent {
	constructor() {
		super();
		this.state = {
			name: "",
			color: "",
			show_colors: false,
			img: null,
			img_url: "",
			parent: null,
			is_internal: false,
			is_educational_support: false,

			error_adding: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	submit = () => {
		const { name, img, img_url, parent, color, is_internal, is_educational_support } = this.state;
		if (name != "") {
			const frm = new FormData();
			if (img && img_url != "") frm.append("img", img);
			if (parent && parent != "") frm.append("parent", parent);

			frm.append("name", name);
			frm.append("color", color);
			frm.append("is_internal", is_internal);
			frm.append("is_educational_support", is_educational_support);

			this.props.addCtg(frm);
		}
	};
	checkFrm = (data) => {
		const { name } = data;
		if (name == "") return "Nom obligatoire.";
		return "";
	};

	componentWillMount() {
		this.props.getCtgs();
	}

	componentDidUpdate(prev) {
		const { loading_add_ctg, error_add_ctg, new_ctg } = this.props;

		if (!loading_add_ctg && prev.loading_add_ctg) {
			if (error_add_ctg) {
				if (error_add_ctg.message && error_add_ctg.message != "")
					this.setState({ error_frm: error_add_ctg.message });
			} else this.props.handleSuccess(new_ctg);
		}
	}

	changeImg = (e) => {
		try {
			const img = e.target.files[0];
			this.setState({ img, img_url: URL.createObjectURL(img) });
		} catch (error) {
			this.setState({ img: null, img_url: "" });
		}
	};

	render() {
		const { is_open, size, toggle, ctgs, getCtgs } = this.props;
		const { img_url, color, show_colors, is_internal, is_educational_support } = this.state;

		return (
			<Modal
				isOpen={is_open}
				size={size || "lg"}
				toggle={toggle}
				// modalClassName={`${rtl.direction}-support`}
				className={`modal-dialog--primary ${modalClass}`}
			>
				<div className="modal__header">
					<h4 className="text-modal  modal__title">Ajouter un categorie</h4>
				</div>
				<div className="modal__body" style={{ padding: "0 10px", textAlign: "left" }}>
					<Row className="form">
						<Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
							<Alert
								is_visible={this.state.error_adding != ""}
								color="danger"
								className="alert--colored"
								icon
							>
								<p>
									<span className="bold-text">Attention!</span> {this.state.error_adding}
								</p>
							</Alert>
						</Col>

						<Col md="4">
							<div className="add_img_wrap">
								<img src={img_url == "" ? ` ${process.env.PUBLIC_URL}/img/no_product.png` : img_url} />

								<div className="caption">
									<label htmlFor="add_avatar">
										<span className="lnr lnr-picture"></span>
									</label>
									<input
										onChange={(e) => this.changeImg(e)}
										id="add_avatar"
										type="file"
										accept="image/*"
										hidden
									/>
								</div>
							</div>
						</Col>

						<Col md="8">
							<div className="form__form-group">
								<span className="form__form-group-label">Nom</span>
								<div className="form__form-group-field">
									<input
										style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
										name="name"
										component="input"
										placeholder="Nom"
										onChange={(e) => this.changeVal("name", e.target.value)}
									/>
								</div>
							</div>

							<div className="mt-3 mb-3">
								<span style={{ marginRight: 8 }}>C'est un catégorie interne</span>
								<Switch
									className="custom-switch custom-switch-primary"
									checked={is_internal}
									onChange={(internal) => this.changeVal("is_internal", internal)}
								/>

								<span style={{ marginRight: 8, marginLeft: 8 }}>Support pédagogique</span>
								<Switch
									className="custom-switch custom-switch-primary"
									checked={is_educational_support}
									onChange={(educational_support) =>
										this.changeVal("is_educational_support", educational_support)
									}
								/>
							</div>

							<div style={{ position: "relative", marginBottom: 20 }}>
								<span>Couleur</span>
								<div
									onClick={() =>
										this.setState((prev) => {
											return { show_colors: !prev.show_colors };
										})
									}
									style={{
										padding: 10,
										borderRadius: 4,
										backgroundColor: color,
										cursor: "pointer",
										border: "1px solid #9E9E9E",
										width: 50,
									}}
								></div>

								{show_colors && (
									<div style={{ position: "absolute", left: 55, top: 0, zIndex: 999 }}>
										{" "}
										<SketchPicker
											color={color || "#000"}
											onChangeComplete={(color) => this.changeVal("color", color.hex)}
										/>
									</div>
								)}
							</div>

							<CtgsTree
								getCtgs={() => getCtgs()}
								select_one={true}
								data={ctgs}
								checkCtg={(ctg_id) => {
									this.setState({ parent: ctg_id });
								}}
							/>
						</Col>
					</Row>

					<div>
						<ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
							<Button color="primary" onClick={this.submit}>
								Soumettre
							</Button>
							<Button type="button" onClick={this.props.toggle}>
								Annuler
							</Button>
						</ButtonToolbar>
					</div>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = ({ category }) => {
	return {
		// ctg: category.ctg,
		ctgs: category.ctgs,
		loading_add_ctg: category.loading_add_ctg,
		error_add_ctg: category.error_add_ctg,
		new_ctg: category.new_ctg,
	};
};
export default connect(mapStateToProps, {
	addCtg,
	getCtgs,
})(AddCtgMdl);
