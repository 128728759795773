
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_TRAININGS, getTrainingsSuccess, getTrainingsError, 
  ADD_TRAINING, addTrainingError, addTrainingSuccess, 
  GET_ONE_TRAINING, getOneTrainingSuccess, getOneTrainingError, 
  EDIT_TRAINING, editTrainingError, editTrainingSuccess,

  DELETE_TRAINING, deleteTrainingError, deleteTrainingSuccess,
} from '../actions/trainingsActions';
import { api } from '../../const';



// *** GET TRAININGS
export function* watchGetTrainings() { yield takeEvery(GET_TRAININGS, getTrainings) }
function* getTrainings({ payload }) {
  try {
    const ret = yield call(getTrainingsRequest, payload);

    if (ret && ret.status == 200) yield put(getTrainingsSuccess({ trainings: ret.data?.trainings || [], count: ret.data?.count || 0}));
    else yield put(getTrainingsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getTrainingsError({ error, message: (error) ? error.message : '' }));
  }
}
const getTrainingsRequest = async (payload) => {  
  return await axios.post(api.trainings+'/filter/'+payload.offset+'/'+payload.limit, payload.filter || {})
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// // *** GET ONE TRAINING
export function* watchGetOneTraining() { yield takeEvery(GET_ONE_TRAINING, getOneTraining) }
function* getOneTraining({ payload }) {
  try {
    const ret = yield call(getOneTrainingRequest, payload);

    if (ret && ret.status == 200) yield put(getOneTrainingSuccess({ training: ret.data?.training || null }));
    else yield put(getOneTrainingError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneTrainingError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneTrainingRequest = async (payload) => {  
  return await axios.get(api.trainings+"/get-one/"+payload.training_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD TRAININGS
export function* watchAddTrainings() { yield takeEvery(ADD_TRAINING, addTraining) }
function* addTraining({ payload }) {
  try {
    const ret = yield call(addTrainingsRequest, payload);

    if (ret && ret.status == 201) yield put(addTrainingSuccess({ training: ret.data.training }));
    else yield put(addTrainingError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addTrainingError({ error, message: (error) ? error.message : '' }));
  }
}
const addTrainingsRequest = async (payload) => {  
  return await axios.post(api.trainings+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT TRAININGS
export function* watchEditTraining() { yield takeEvery(EDIT_TRAINING, editTraining) }
function* editTraining({ payload }) {
  try {
    const ret = yield call(editTrainingsRequest, payload);

    if (ret && ret.status == 200) yield put(editTrainingSuccess({ training: ret.data.training }));
    else yield put(editTrainingError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editTrainingError({ error, message: (error) ? error.message : '' }));
  }
}
const editTrainingsRequest = async (payload) => {  
  return await axios.patch(api.trainings+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE TRAININGS
export function* watchDeleteTraining() { yield takeEvery(DELETE_TRAINING, deleteTraining) }
function* deleteTraining({ payload }) {
  try {
    const ret = yield call(deleteTrainingsRequest, payload);

    if (ret && ret.status == 200) yield put(deleteTrainingSuccess({ }));
    else yield put(deleteTrainingError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteTrainingError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteTrainingsRequest = async (payload) => {  
  return await axios.delete(api.trainings+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetTrainings),
    fork(watchGetOneTraining),
    fork(watchAddTrainings),
    fork(watchEditTraining),
    fork(watchDeleteTraining),
  ]);
}