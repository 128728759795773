import React, { PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { Field, reduxForm } from 'redux-form';

import {
	Button,
	ButtonToolbar,
	Card,
	CardBody,
	Row,
	Col,
	//Nav, NavItem, TabContent, TabPane, Table, Input
} from "reactstrap";
// import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";

import { NavLink } from "react-router-dom";

import { addClient, editClient, getOneClient } from "../../../redux/actions/clientsActions";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
// import Switch from "rc-switch";
// import "rc-switch/assets/index.css";

// import classnames from 'classnames';
import {
	cities,
	client_types,
	// client_statuses, subscription_statuses
} from "../../../const";
// import SelectClient from "./SelectClient";
import SelectUsersMdl from "../users/select-users-mdl";
import AccountSearchIcon from "mdi-react/AccountSearchIcon";

class ClientCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			name: "",
			first_name: "",
			last_name: "",

			first_name_ar: "",
			last_name_ar: "",

			ref: "",
			profession: "",
			phone: "",
			phone2: "",
			type: "external",

			institution: "",

			cin: "",
			level_name: "",
			concept: "",

			address: "",
			city: "",
			region: "",

			registration_date: new Date(),
			status: "active",
			coordinator: null,
			fb: "",
			subscription_status: "no_request",

			show_select_client: false,
			client_data: {
				name: "",
				city: "",
				region: "",
				phone: "",
				phone2: "",
				address: "",
				type: "",
			},

			error_frm: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	resetClientData = () =>
		this.setState({
			client_data: {
				name: "",
				city: "",
				region: "",
				phone: "",
				phone2: "",
				address: "",
				type: "",
			},
		});

	submit = () => {
		const error_frm = this.checkFrm(this.state);
		this.setState({ error_frm });

		const { addClient, editClient, editing } = this.props;
		const {
			first_name,
			last_name,
			first_name_ar,
			last_name_ar,
			ref,
			profession,
			phone,
			phone2,
			type,
			address,
			city,
			region,
			institution,
			cin,
			level_name,
			concept,
			registration_date,
			status,
			coordinator,
			fb,
			subscription_status,
		} = this.state;

		if (error_frm == "") {
			const data = {
				first_name,
				last_name,
				first_name_ar,
				last_name_ar,
				ref,
				profession,
				phone,
				phone2,
				type: this.props.add_as_participant ? "internal" : type,
				address,
				city,
				region,
				institution,
				cin,
				level_name,
				concept,
				registration_date,
				status,
				coordinator,
				fb,
				subscription_status,

				training_ref: this.props.training_ref,
			};

			if (editing) editClient({ _id: this.props.client_id, data });
			else addClient(data);
		} else window.scrollTo(0, 0);
	};
	checkFrm = (data) => {
		const {
			first_name, // institution,
			profession,
			phone,
			address,
			city,
			fb,
		} = data;
		if (first_name === "") return "Le nom est requis.";
		if (this.props.add_as_participant) {
			// if (institution === "") return "Institution est requis.";
			if (profession === "") return "Profession est requis.";
			if (phone === "") return "Téléphone est requis.";
			if (address === "") return "Adresse est requis.";
			if (city === "") return "Gouvernorat est requis.";
			if (fb === "") return "Facebook est requis.";
		}

		return "";
	};

	componentDidUpdate(prev) {
		const {
			loading_add_client,
			error_add_client,
			new_client,
			selected_client,
			loading_one_client,
			error_one_client,
			loading_edit_client,
			error_edit_client,
			history,
			add_as_participant,
			addParticipant,
		} = this.props;

		if (!loading_add_client && prev.loading_add_client) {
			// console.log("error_add_client", error_add_client, new_client);
			if (error_add_client) {
				if (error_add_client.message && error_add_client.message != "")
					this.setState({ error_frm: error_add_client.message });
			} else {
				if (add_as_participant && addParticipant) addParticipant(new_client);
				else if (history) history.push("/app/clients/edit/" + new_client._id);
			}
		}

		// EDITING
		if (!loading_edit_client && prev.loading_edit_client) {
			// console.log("error_add_client", error_add_client, new_client);
			if (error_edit_client) {
				if (error_edit_client.message && error_edit_client.message != "")
					this.setState({ error_frm: error_edit_client.message });
			} else this.props.history.push("/app/clients/clients");
		}

		//GET ONE
		if (prev.loading_one_client && !loading_one_client && !error_one_client) {
			if (selected_client) {
				const {
					first_name,
					last_name,
					first_name_ar,
					last_name_ar,
					ref,
					profession,
					phone,
					phone2,
					type,
					address,
					city,
					region,
					institution,
					cin,
					level_name,
					concept,
					registration_date,
					status,
					coordinator,
					fb,
					subscription_status,
				} = selected_client;

				this.setState({
					first_name,
					last_name,
					first_name_ar,
					last_name_ar,
					ref,
					profession,
					phone,
					phone2,
					type,
					address,
					city,
					region,
					institution,
					cin,
					level_name,
					concept,
					registration_date,
					status,
					coordinator,
					fb,
					subscription_status,
				});
			}
		}
	}

	componentDidMount() {
		if (this.props.editing) {
			const { match, getOneClient } = this.props;
			if (match && match.params) getOneClient({ client_id: match.params.clientId });
		}
	}

	render() {
		// const { editing } = this.props
		const {
			first_name,
			last_name,
			first_name_ar,
			last_name_ar,
			ref,
			profession,
			phone,
			phone2,
			type,
			address,
			city,
			region,
			institution,
			cin,
			level_name,
			concept,
			// registration_date,
			// status,
			coordinator,
			fb,
			// subscription_status,
			show_select_client,
			client_data,
		} = this.state;

		const { add_as_participant, loading_add_client, hide_cancel_btn } = this.props;

		return (
			<div>
				<Card>
					<CardBody>
						<Row className="form">
							<Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
								<Alert
									is_visible={this.state.error_frm != ""}
									color="danger"
									className="alert--colored"
									icon
								>
									<p>
										<span className="bold-text">Attention!</span> {this.state.error_frm}
									</p>
								</Alert>
							</Col>

							{/* NAMES */}
							<Col md="6">
								<div className="form__form-group">
									<span className="form__form-group-label">الإسم</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											value={first_name_ar}
											component="input"
											onChange={(e) => this.changeVal("first_name_ar", e.target.value)}
										/>
									</div>
								</div>
							</Col>
							{/* <Col md="3">
              <div className="form__form-group">
                <span className="form__form-group-label">اللقب</span>
                <div className="form__form-group-field">
                  <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                    value={last_name_ar} component="input"
                    onChange={(e) => this.changeVal('last_name_ar', e.target.value)}
                  />
                </div>
              </div>
            </Col> */}
							<Col md="6">
								<div className="form__form-group">
									<span className="form__form-group-label">Nom</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											value={first_name}
											component="input"
											onChange={(e) => this.changeVal("first_name", e.target.value)}
										/>
									</div>
								</div>
							</Col>
							{/* <Col md="3">
              <div className="form__form-group">
                <span className="form__form-group-label">Prénom</span>
                <div className="form__form-group-field">
                  <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                    value={last_name} component="input"
                    onChange={(e) => this.changeVal('last_name', e.target.value)}
                  />
                </div>
              </div>
            </Col> */}

							{/* REFERENCE */}
							<Col md="3">
								{add_as_participant ? (
									<div className="form__form-group">
										<span className="form__form-group-label">Institution</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												name="Institution"
												defaultValue={institution}
												component="institution"
												placeholder="Institution"
												onChange={(e) => this.changeVal("institution", e.target.value)}
											/>
										</div>
									</div>
								) : (
									<div className="form__form-group">
										<span className="form__form-group-label">Référence</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												value={ref}
												component="input"
												placeholder="Référence"
												onChange={(e) => this.changeVal("ref", e.target.value)}
											/>
										</div>
									</div>
								)}
							</Col>
							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">Profession</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="name"
											defaultValue={profession}
											component="input"
											placeholder="Profession"
											onChange={(e) => this.changeVal("profession", e.target.value)}
										/>
									</div>
								</div>
							</Col>

							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">Téléphone</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="phone"
											type="number"
											defaultValue={phone}
											component="input"
											placeholder="Téléphone"
											onChange={(e) => this.changeVal("phone", e.target.value)}
										/>
									</div>
								</div>
							</Col>
							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">Téléphone 2</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="phone2"
											type="number"
											defaultValue={phone2}
											component="input"
											placeholder="Téléphone 2"
											onChange={(e) => this.changeVal("phone2", e.target.value)}
										/>
									</div>
								</div>
							</Col>

							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">Type</span>
									<div className="form__form-group-field">
										{add_as_participant ? (
											<span>Interne</span>
										) : (
											<Select
												name="type"
												defaultValue={type}
												value={client_types.filter((option) => option.value === type)}
												onChange={(e) => this.changeVal("type", e.value)}
												options={client_types}
												clearable={false}
												className="react-select"
												placeholder="Type"
												classNamePrefix="react-select"
											/>
										)}
									</div>
								</div>
							</Col>

							<Col md="9">
								<div className="form__form-group">
									<span className="form__form-group-label">Adresse</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="address"
											defaultValue={address}
											component="input"
											placeholder="Adresse"
											onChange={(e) => this.changeVal("address", e.target.value)}
										/>
									</div>
								</div>
							</Col>

							<Col md="6">
								<div className="form__form-group">
									<span className="form__form-group-label">Gouvernorat</span>
									<div className="form__form-group-field">
										<Select
											name="city"
											defaultValue={city}
											value={cities.filter((option) => option.value === city)}
											onChange={(e) => this.changeVal("city", e.value)}
											options={cities}
											clearable={false}
											className="react-select"
											placeholder="Gouvernorat"
											classNamePrefix="react-select"
										/>
									</div>
								</div>
							</Col>

							<Col md="6">
								<div className="form__form-group">
									<span className="form__form-group-label">Région</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="region"
											defaultValue={region}
											component="input"
											placeholder="Région"
											onChange={(e) => this.changeVal("region", e.target.value)}
										/>
									</div>
								</div>
							</Col>
						</Row>

						{/* INTERNAL CLIENT ATTRIBUTES */}
						{(type == "internal" || add_as_participant) && (
							<Row className="form">
								<Col md="12">
									<hr />
								</Col>

								{/* <Col md="3" className="mt-2">
              <span className="form__form-group-label">Date d'inscription</span>
              <br />
              <DatePicker selected={new Date(registration_date)}
                onChange={(e) => this.changeVal('registration_date', e)}
                placeholderText="Date d'inscription"
              />
            </Col> */}

								{/* <Col md="3">
              <span className="form__form-group-label">Status d'activité</span>{" "}
              <Select defaultValue={status}
                value={client_statuses.filter(option => option.value === status)}
                onChange={(e) => this.changeVal('status', e.value)}
                options={client_statuses} clearable={false}
                className="react-select"
                placeholder="Gouvernorat" classNamePrefix="react-select"
              />
            </Col> */}

								<Col md="3">
									<div className="form__form-group">
										<span className="form__form-group-label">CIN</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												name="cin"
												defaultValue={cin}
												component="cin"
												placeholder="cin"
												onChange={(e) => this.changeVal("cin", e.target.value)}
											/>
										</div>
									</div>
								</Col>
								<Col md="3">
									<div className="form__form-group">
										<span className="form__form-group-label">Concept</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												name="concept"
												defaultValue={concept}
												component="concept"
												placeholder="concept"
												onChange={(e) => this.changeVal("concept", e.target.value)}
											/>
										</div>
									</div>
								</Col>
								<Col md="3">
									<div className="form__form-group">
										<span className="form__form-group-label">Niveau</span>
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
												}}
												name="level_name"
												defaultValue={level_name}
												component="Niveau"
												placeholder="Niveau"
												onChange={(e) => this.changeVal("level_name", e.target.value)}
											/>
										</div>
									</div>
								</Col>

								<Col md="6">
									<div className="form__form-group">
										<span className="form__form-group-label">Coordinateur</span>
										<div className="form__form-group-field">
											<input
												readOnly
												style={{
													border: "1px solid #f2f4f7",
													color: "#646777",
												}}
												value={
													coordinator
														? coordinator?.first_name + " " + coordinator?.last_name
														: ""
												}
												component="input"
											/>
											<div
												onClick={() => {
													this.setState({
														show_select_client: true,
													});
												}}
												className="form__form-group-icon"
												style={{ cursor: "pointer" }}
											>
												<AccountSearchIcon />
											</div>
										</div>
									</div>

									{/* <div className="form__form-group">
										<span className="form__form-group-label">Cordinateur</span>
										<div
											onClick={() => this.setState({ show_select_client: true })}
											className="form__form-group-field"
										>
											<input
												readOnly
												style={{ border: "none", borderBottom: "1px solid #ddd" }}
												component="input"
												placeholder="Cordinateur"
												value={
													coordinator
														? coordinator.first_name + " " + coordinator.last_name
														: ""
												}
											/>
										</div>
									</div> */}
								</Col>
							</Row>
						)}

						<Row className="form">
							{/* {type == 'internal' && <Col md="3">
              <span className="form__form-group-label">Etat d'inscrit</span>{" "}
              <Select defaultValue={subscription_status}
                value={subscription_statuses.filter(option => option.value === subscription_status)}
                onChange={(e) => this.changeVal('subscription_status', e.value)}
                options={subscription_statuses} clearable={false}
                className="react-select"
                placeholder="Etat d'inscrit" classNamePrefix="react-select"
              />
            </Col>} */}
							<Col md={type == "internal" || add_as_participant ? 9 : 12}>
								<div className="form__form-group">
									<span className="form__form-group-label">Facebook</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="fb"
											defaultValue={fb}
											component="input"
											placeholder="fb"
											onChange={(e) => this.changeVal("fb", e.target.value)}
										/>
									</div>
								</div>
							</Col>
						</Row>

						<div className="mt-5" style={{ textAlign: "right" }}>
							<ButtonToolbar
								className="modal__footer form__button-toolbar"
								style={{ textAlign: "right" }}
							>
								{!loading_add_client ? (
									<Button color="primary" onClick={this.submit}>
										Soumettre
									</Button>
								) : null}
								{!hide_cancel_btn ? (
									<NavLink to="/app/clients">
										<Button type="button">Annuler</Button>
									</NavLink>
								) : null}
							</ButtonToolbar>
						</div>
					</CardBody>
				</Card>

				{/* MODALS */}
				{/* <SelectClient
					size="lg"
					multi={false}
					name={client_data.name}
					city={client_data.city}
					region={client_data.region}
					phone={client_data.phone}
					phone2={client_data.phone2}
					address={client_data.address}
					type={client_data.type}
					changeVal={(key, val) => {
						this.setState((prev) => {
							const client_data = prev.client_data;
							client_data[key] = val;
							return { client_data: { ...client_data } };
						});
					}}
					isOpen={show_select_client}
					toggleOpen={this.toggleSelectClientMdl}
					accept={(data) => {
						if (data && data[0])
							this.setState({
								coordinator: data[0],
								show_select_client: false,
								client_data: this.resetClientData,
							});
					}}
					selectedItems={coordinator ? [coordinator] : []}
				/> */}
				<SelectUsersMdl
					is_open={show_select_client}
					toggle={() => this.setState({ show_select_client: !show_select_client })}
					multi={false}
					size="lg"
					onSuccess={(selected_user) => {
						this.setState({
							coordinator: selected_user,
							show_select_client: false,
							client_data: this.resetClientData,
						});
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ client }) => {
	const {
		loading_add_client,
		error_add_client,
		new_client,
		selected_client,
		loading_one_client,
		error_one_client,
		loading_edit_client,
		error_edit_client,
	} = client;
	return {
		new_client,
		selected_client,
		loading_add_client,
		error_add_client,
		loading_one_client,
		error_one_client,
		loading_edit_client,
		error_edit_client,
	};
};
export default connect(mapStateToProps, {
	addClient,
	editClient,
	getOneClient,
})(ClientCmp);
