import React, { PureComponent } from 'react';
// import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import {
  Row, Col, Container,
  // Card, CardBody, Table, //ButtonGroup, ButtonToolbar, Button, Badge, CardFooter,
  // Nav, NavItem, NavLink, TabContent, TabPane,
  // CustomInput
} from 'reactstrap';

import classnames from 'classnames';

// import { Redirect } from 'react-router-dom';


// import EyeIcon from 'mdi-react/EyeIcon';
// import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
// import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import { Button } from 'reactstrap';

// import renderCheckBoxField from '../../../shared/components/form/CheckBox';

import {
  getProfile, editUser
} from "../../../redux/actions/authActions";


// import {

// } from "../../../redux/actions/articlesActions";


// import renderRadioButtonField from '../form/RadioButton';
// import { filter } from '../../../../../API/app/controllers/users-ctrl/actions/get';

// import { NavLink } from 'react-router-dom';
import Axios from 'axios';
import { api } from '../../../const';
import ProfileMain from './profile/ProfileMain';
import ProfileTabs from './profile/ProfileTabs';

class EditUsersCmp extends PureComponent {

  constructor() {
    super();
    this.state = {
      active_tab: "Vehicles",
      avatar: null,
      avatar_url: "",

      count_trips: 0, count_finished: 0, count_canceled: 0,

      fav_places: []
    };
  }

  toggle = (active_tab) => this.setState({ active_tab })


  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  // componentWillMount() {
  // 	this.props.getProfile({ _id })
  // }


  componentDidMount = () => {
    this.props.getProfile({ _id: this.props.user_id })

    // Axios.post(api.trips + "/filter/0/1", { user: this.props.user_id }).then(resp => {
    //   this.setState({ count_trips: resp.data ? resp.data.count : 0 })
    // })

    // Axios.post(api.trips + "/filter/0/1", { user: this.props.user_id, statuses: ["finished"] }).then(resp => {
    //   this.setState({ count_finished: resp.data ? resp.data.count : 0 })
    // })


    // Axios.post(api.fav_places + "/filter/0/50", { user: this.props.user_id}).then(resp => {
    //   console.log("fav_places", resp.data);
    //   this.setState({ fav_places: resp.data ? resp.data.fav_places : [] })
    // })
  }
  componentDidUpdate(prev) {
    const {
      loading_edit_user, error_edit_user
    } = this.props


    if (prev.loading_edit_user != loading_edit_user && !error_edit_user) {
      this.props.getProfile({ _id: this.props.user_id })
    }
  }

  activateVehicle = (field, vehicle_id, checked = true) => {
    const data = (field == 'active') ? { active: checked } : { available: checked }
    Axios.patch(api.vehicles + '/edit-one/' + vehicle_id, data).then(resp => {
      if (resp && resp.status == 200)
        this.props.getProfile({ _id: this.props.user_id })
    })
  }


  changeAvatar = (e) => {
    try {
      const avatar = e.target.files[0]
      this.setState({ avatar, avatar_url: URL.createObjectURL(avatar) }, () => {
        const frm = new FormData()
        frm.append('avatar', avatar)

        this.props.editUser({
          _id: this.props.user_id,
          data: frm
        })
      })
    } catch (error) { this.setState({ avatar: null, avatar_url: '' }) }
  }
  editProfile = (data) => {
    this.props.editUser({
      _id: this.props.user_id,
      data
    })
  }

  render() {
    // const { handleSubmit } = this.props;
    const { active_tab, avatar, avatar_url, count_trips, count_finished, count_canceled, fav_places } = this.state;

    const { profile } = this.props

    const vehicles = profile?.vehicles || []

    return (<Container>
      <div className="profile">
        {profile && <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain
                profile={profile} editProfile={this.editProfile} changeAvatar={this.changeAvatar}
                count_trips={count_trips} count_finished={count_finished} count_canceled={count_canceled}
                roles={profile.roles || []}
              />
              {/* <ProfileCalendar />
              <ProfileTasks /> */}
            </Row>
          </Col>
          <ProfileTabs
            vehicles={vehicles || []}
            fav_places={fav_places || []}
          />
        </Row>}
      </div>
    </Container>);
  }
}

const mapStateToProps = ({ auth }) => {
  const { loading_profile, error_profile, profile, user, loading_edit_user, error_edit_user } = auth

  return {
    profile, loading_profile, error_profile, user,
    loading_edit_user, error_edit_user,
  }
}
export default connect(mapStateToProps, {
  getProfile, editUser,
})(EditUsersCmp);