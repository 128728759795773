import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { Table, Tag, Space } from "antd";

import { EyeOutlined, RocketFilled } from "@ant-design/icons";
import Axios from "axios";
import { api, statuses } from "../../../../const";
import "./trainer.scss";
import moment from "moment";
import FollowMdl from "../../trainings/FollowMdl";

const renderStatus = (activities) => {
	const status = activities[0]?.status;
	switch (status) {
		case "not_active":
			return "Pas actif";
		case "active":
			return "Actif";
		case "in_progress":
			return "En cours";
		case "took_of":
			return "Retiré";
		case "out":
			return "Out";
		case "stopped":
			return "En Arrêt";
	}

	return "";
};

const renderFB = (activities) => {
	const fb_group = activities[0]?.fb_group;
	switch (fb_group) {
		case "subscribed":
			return "Abonné";
		case "subscription_sent":
			return "Demande envoyé";
		case "not_subscribed":
			return "Non abonné";
	}

	return "";
};

const renderJoin = (activities) => {
	const joinability = activities[0]?.joinability;
	switch (joinability) {
		case "reachable":
			return "Accessible";
		case "unavailable":
			return "Indisponible";
		case "unreachable":
			return "Inaccessible";
	}

	return "";
};

const ClientTrainings = (props) => {
	const { client, client_id, getClientTrainings = () => {} } = props;

	const columns = [
		{
			title: "Début",
			dataIndex: "start_date",
			key: "start_date",
			width: 160,

			render: (text) => {
				return <span>{moment(text).format("DD-MM-YYYY")}</span>;
			},
		},
		{
			title: "Duré",
			dataIndex: "duration",
			key: "duration",
			width: 120,

			render: (text) => {
				return <span>{text}</span>;
			},
		},
		{
			title: "Lieu",
			dataIndex: "address",
			key: "address",
			width: 120,

			render: (text, reacord) => {
				const stats = statuses.filter((option) => option.value === text);
				if (stats && stats[0]) return <span>{stats[0].label}</span>;

				return (
					<React.Fragment>
						<p style={{ margin: 0 }}>{reacord.address}</p>
						<p style={{ margin: 0 }}>{`${reacord.city} ${reacord.region}`}</p>
					</React.Fragment>
				);
			},
		},
		{
			title: "Status",
			dataIndex: "activities",
			key: "status",
			width: 110,

			render: (text, record) => {
				// console.log("----> record.activities", record.activities);
				return (
					<Fragment>
						<span>{renderStatus(record.activities)}</span>
					</Fragment>
				);
			},
		},
		{
			title: "Joignabilité",
			dataIndex: "activities",
			key: "Joignability",
			width: 110,

			render: (text, record) => {
				return (
					<Fragment>
						<span>{renderJoin(record.activities)}</span>
					</Fragment>
				);
			},
		},
		{
			title: "Facebook",
			dataIndex: "activities",
			key: "fb",
			width: 110,

			render: (text, record) => {
				return (
					<Fragment>
						<span>{renderFB(record.activities)}</span>
					</Fragment>
				);
			},
		},
		{
			title: "Formateur",
			dataIndex: "former",
			key: "former",
			render: (text, record) => {
				return (
					<span>
						{record.former?.first_name ? `${record.former.first_name} ${record.former.last_name}` : ""}
					</span>
				);
			},
		},
		{
			title: "Commercial",
			dataIndex: "commercial",
			key: "commercial",
			render: (text, record) => {
				return (
					<span>
						{record.commercial?.first_name
							? `${record.commercial.first_name} ${record.commercial.last_name}`
							: ""}
					</span>
				);
			},
		},
		{
			title: "Organisateur",
			dataIndex: "organizer",
			key: "organizer",
			render: (text, record) => {
				return (
					<span>
						{record.organizer?.first_name
							? `${record.organizer.first_name} ${record.organizer.last_name}`
							: ""}
					</span>
				);
			},
		},
		{
			title: "Référence",
			dataIndex: "ref",
			key: "ref",
		},

		{
			title: "",
			key: "action",
			align: "center",
			width: 80,

			render: (text, record) => (
				<Space size="middle">
					<NavLink to={`/app/trainings/preview/${record.key}`}>
						<EyeOutlined />
					</NavLink>
					<span
						style={{ color: "#ff4861", cursor: "pointer" }}
						onClick={() => {
							setShowActivitiesMdl(true);
							setSelectedTraining(record);
						}}
					>
						<RocketFilled />
					</span>
				</Space>
			),

			fixed: "right",
		},
	];

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);
	const [show_activities_mdl, setShowActivitiesMdl] = useState(false);
	const [selected_training, setSelectedTraining] = useState(null);

	const getPage = (page = 1, return_data = false) => {
		const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);

		const filter = { participant: client_id };

		setLoading(true);

		Axios.post(`${api.participants}/filter/${offset}/${limit}`, filter || {})
			.then((resp) => {
				setLoading(false);
				if (return_data && getClientTrainings) getClientTrainings(resp?.data);

				setCount(resp?.data?.count || 0);

				const new_data = resp?.data?.participants?.map((item) => {
					return {
						...item?.training,
						activities: item.activities || [],
						key: item?.training._id,
					};
				});

				setData(new_data || []);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (client_id) getPage(1, true);
	}, [client_id, limit]);

	// console.log("selected_training", selected_training);

	return (
		<React.Fragment>
			<Table
				columns={columns}
				dataSource={data}
				scroll={{ x: 1500 }}
				loading={loading}
				pagination={{
					total: count,
					pageSize: limit,
					pageSizeOptions: [10, 20, 30],
				}}
				onChange={(pagination, filters, sorter, extra) => {
					setLimit(pagination?.pageSize || 10);
					getPage(pagination?.current || 1);
				}}
			/>

			{show_activities_mdl && (
				<FollowMdl
					isOpen={show_activities_mdl}
					toggleOpen={() => setShowActivitiesMdl(!show_activities_mdl)}
					activity={
						selected_training?.activities?.length
							? {
									...selected_training?.activities[0],
									participant: client,
									training: selected_training,
							  }
							: {
									participant: client,
									training: selected_training,
							  }
					}
					onSuccess={() => {
						getPage(1, true);
						setShowActivitiesMdl(false);
					}}
				/>
			)}
		</React.Fragment>
	);
};

ClientTrainings.prototype = {
	getCountAll: PropTypes.func.isRequired,
	// getShipModes: PropTypes.arrayOf(PropTypes.object).isRequired,
	client_id: PropTypes.string,
	getClientTrainings: PropTypes.func,
	client: PropTypes.object,
};
export default ClientTrainings;
