// ***** ADD ARTICLE
export const ADD_ARTICLE            = 'ADD_ARTICLE';
export const ADD_ARTICLE_SUCCESS    = 'ADD_ARTICLE_SUCCESS';
export const ADD_ARTICLE_ERROR      = 'ADD_ARTICLE_ERROR';

export function addArticle(payload) {
  return {type: ADD_ARTICLE, payload};
}
export function addArticleSuccess(payload) {
  return { type: ADD_ARTICLE_SUCCESS, payload };
}
export function addArticleError(error) {
  return { type: ADD_ARTICLE_ERROR, error };
}




// ***** GET ARTICLES
export const GET_ARTICLES            = 'GET_ARTICLES';
export const GET_ARTICLES_SUCCESS    = 'GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_ERROR      = 'GET_ARTICLES_ERROR';

export function getArticles(payload) {
  return {type: GET_ARTICLES, payload};
}
export function getArticlesSuccess(payload) {
  return { type: GET_ARTICLES_SUCCESS, payload };
}
export function getArticlesError(error) {
  return { type: GET_ARTICLES_ERROR, error };
}





// ***** GET ONE ARTICLE
export const GET_ONE_ARTICLE            = 'GET_ONE_ARTICLE';
export const GET_ONE_ARTICLE_SUCCESS    = 'GET_ONE_ARTICLE_SUCCESS';
export const GET_ONE_ARTICLE_ERROR      = 'GET_ONE_ARTICLE_ERROR';

export function getOneArticle(payload) {
  return {type: GET_ONE_ARTICLE, payload};
}
export function getOneArticleSuccess(payload) {
  return { type: GET_ONE_ARTICLE_SUCCESS, payload };
}
export function getOneArticleError(error) {
  return { type: GET_ONE_ARTICLE_ERROR, error };
}



// ***** EDIT ARTICLE
export const EDIT_ARTICLE            = 'EDIT_ARTICLE';
export const EDIT_ARTICLE_SUCCESS    = 'EDIT_ARTICLE_SUCCESS';
export const EDIT_ARTICLE_ERROR      = 'EDIT_ARTICLE_ERROR';

export function editArticle(payload) {
  return {type: EDIT_ARTICLE, payload};
}
export function editArticleSuccess(payload) {
  return { type: EDIT_ARTICLE_SUCCESS, payload };
}
export function editArticleError(error) {
  return { type: EDIT_ARTICLE_ERROR, error };
}


// ***** DELETE ARTICLE
export const DELETE_ARTICLE            = 'DELETE_ARTICLE';
export const DELETE_ARTICLE_SUCCESS    = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_ERROR      = 'DELETE_ARTICLE_ERROR';

export function deleteArticle(payload) {
  return {type: DELETE_ARTICLE, payload};
}
export function deleteArticleSuccess(payload) {
  return { type: DELETE_ARTICLE_SUCCESS, payload };
}
export function deleteArticleError(error) {
  return { type: DELETE_ARTICLE_ERROR, error };
}