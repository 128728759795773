import React, { useEffect, useState } from "react";
import { Row, Table, Button, Col, Modal, ModalBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
// import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { NavLink as NavLinkNaigation, withRouter } from "react-router-dom";
import classnames from "classnames";

import Select from "react-select";

// import {
//   getClients
// } from "../../../redux/actions/clientsActions";
import { api } from "../../../const"; //no_user_img
// import { getCountPages } from "../../helper/utils";
import moment from "moment";
import Label from "reactstrap/lib/Label";
import Axios from "axios";

const FollowMdl = (props) => {
	const { isOpen, size, toggleOpen, activity, onSuccess, training, user } = props;

	const [active_tab, setActiveTab] = useState("1");

	const [status, setStatus] = useState("not_active");
	const [joinability, setJoinability] = useState("unavailable");
	const [fb_group, setFbGroup] = useState("not_subscribed");
	const [desc, setDesc] = useState("");
	const [activities, setActivities] = useState([]);

	useEffect(() => {
		// getParticipants()
		if (activity?.training?._id && activity?.training?._id !== "")
			Axios.post(`${api.participants}/filter-activities/${0}/${10}`, {
				participant: activity?.participant?._id,
				training: activity.training?._id,
			})
				.then((resp) => {
					// setLoading(false);
					const records = resp.data?.records || [];
					setActivities(records);
					// console.log("new_data", resp.data);

					if (records[0]) {
						setStatus(records[0].status);
						setFbGroup(records[0].fb_group);
						setJoinability(records[0].joinability);
					}
				})
				.catch((err) => {
					// setLoading(false);
				});
	}, []);

	if (!activity || !activity?.training?._id) return null;

	const statuses = [
		{ label: "Pas actif", name: "Pas actif", value: "not_active", key: 1 },
		{ label: "Actif", name: "Actif", value: "active", key: 2 },
		{ label: "Retiré", name: "Retiré", value: "took_of", key: 4 },
		{ label: "Out", name: "out", value: "out", key: 3, isDisabled: !user || !user?.roles.includes("ADMIN") },
		{ label: "En cours", name: "En cours", value: "in_progress", key: 4 },
		{ label: "En Arrêt", name: "En Arrêt", value: "stopped", key: 5 },
	];

	const joinabilities = [
		{ label: "Accessible", name: "Accessible", value: "reachable", key: 1 },
		{ label: "Inaccessible", name: "Inaccessible", value: "unreachable", key: 2 },
		{ label: "Indisponible", name: "Indisponible", value: "unavailable", key: 3 },
	];

	const fb_groups = [
		{ label: "Abonné", name: "Abonné", value: "subscribed", key: 1 },
		{ label: "Demande envoyé", name: "Demande envoyé", value: "subscription_sent", key: 2 },
		{ label: "Non abonné", name: "Non abonné", value: "not_subscribed", key: 3 },
	];

	// console.log("activity", activity);
	const renderStatus = (status) => {
		switch (status) {
			case "not_active":
				return "Pas actif";
			case "active":
				return "Actif";
			case "in_progress":
				return "En cours";
			case "took_of":
				return "Retiré";
			case "out":
				return "Out";
			case "stopped":
				return "En Arrêt";
		}

		return "";
	};
	const renderFB = (fb_group) => {
		switch (fb_group) {
			case "subscribed":
				return "Abonné";
			case "subscription_sent":
				return "Demande envoyé";
			case "not_subscribed":
				return "Non abonné";
		}

		return "";
	};
	const renderJoin = (joinability) => {
		switch (joinability) {
			case "reachable":
				return "Accessible";
			case "unavailable":
				return "Indisponible";
			case "unreachable":
				return "Inaccessible";
		}

		return "";
	};

	const addActivity = async () => {
		const new_activity = await Axios.post(api.participants + "/add-activity", {
			participant: activity.participant?._id,
			training: activity.training?._id,

			status,
			fb_group,
			joinability,
			desc,
		});
		// console.log("new_activity", new_activity);
		if (new_activity && new_activity.status == 201) {
			setStatus("not_active");
			setJoinability("unavailable");
			setFbGroup("not_subscribed");
			setDesc("");

			onSuccess();
		}
	};
	// console.log(activity);
	return (
		<Modal isOpen={isOpen} size={size || "lg"} toggle={toggleOpen} className="mdl_wraper">
			<ModalBody>
				<div style={{ maxHeight: 550, overflowY: "auto" }}>
					<p
						style={{
							color: "#4caf50",
							fontSize: 15,
							fontWeight: 500,
						}}
					>
						{activity.training?.name || "Suivi formation"}
						<span style={{ color: "#777", marginLeft: 10, fontSize: 14, fontWeight: 300 }}>
							({moment(activity.training?.start_date).format("ddd DD/MM/YYYY")} ,{" "}
							{activity.training?.duration || 0} Jours)
						</span>
					</p>

					<p style={{ color: training?.former ? "#28a745" : "#ff4861" }}>
						Formateur : {`${training?.former?.first_name} ${training?.former?.last_name}`}
					</p>

					<hr />

					<div className="tabs tabs--vertical tabs--vertical-colored">
						<div className="tabs__wrap">
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({ active: active_tab === "1" })}
										onClick={() => {
											setActiveTab("1");
										}}
									>
										Ajout
									</NavLink>
								</NavItem>
								{/* <NavItem>
                <NavLink
                  className={classnames({ active: active_tab === '2' })}
                  onClick={() => {
                    setActiveTab('2');
                  }}
                >
                  Historique
                </NavLink>
              </NavItem> */}
								<NavItem>
									<NavLink
										className={classnames({ active: active_tab === "3" })}
										onClick={() => {
											setActiveTab("3");
										}}
									>
										Participant
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: active_tab === "4" })}
										onClick={() => {
											setActiveTab("4");
										}}
									>
										Formation
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent
								activeTab={active_tab}
								style={{ width: "100%", padding: "0 10px", minHeight: 400 }}
							>
								<TabPane tabId="1">
									<Row>
										<Col md="12" style={{ textAlign: "left" }} className="mb-2">
											<h4>Participant :</h4>
											<NavLinkNaigation
												target="_blank"
												style={{ color: "green" }}
												to={"/app/clients/edit/" + activity.participant?._id}
											>
												<span style={{ margin: 0, color: "#50b179" }}>
													{activity.participant?.first_name +
														" " +
														activity.participant?.last_name}
												</span>
												<span style={{ margin: 0, color: "#50b179" }}>
													{activity.participant?.first_name_ar +
														" " +
														activity.participant?.last_name_ar}
												</span>
											</NavLinkNaigation>
											<p>
												<strong>Tel</strong> : {activity.participant?.phone} /{" "}
												{activity.participant?.phone2},
												<span>
													{" "}
													<strong>Adresse</strong>: {activity.participant?.city},{" "}
													{activity.participant?.region}, {activity.participant?.address}
												</span>
											</p>

											<p>
												<b>Institution</b> {activity.participant?.institution}
												<b> Profision</b> {activity.participant?.profession}
											</p>
										</Col>
										<Col md="4" style={{ textAlign: "left" }}>
											<Label>Status</Label>
											<Select
												name="Status"
												value={statuses.filter((option) => option.value === status)}
												onChange={(e) => setStatus(e.value)}
												options={statuses}
												clearable={false}
												className="react-select"
												placeholder="Status"
												classNamePrefix="react-select"
											/>
										</Col>

										<Col md="4" style={{ textAlign: "left" }}>
											<Label>Joignabilité</Label>
											<Select
												name="joinability"
												value={joinabilities.filter((option) => option.value === joinability)}
												onChange={(e) => setJoinability(e.value)}
												options={joinabilities}
												clearable={false}
												className="react-select"
												placeholder="Status"
												classNamePrefix="react-select"
											/>
										</Col>

										<Col md="4" style={{ textAlign: "left" }}>
											<Label>Facebook</Label>
											<Select
												name="fb_group"
												value={fb_groups.filter((option) => option.value === fb_group)}
												onChange={(e) => setFbGroup(e.value)}
												options={fb_groups}
												clearable={false}
												className="react-select"
												placeholder="Status"
												classNamePrefix="react-select"
											/>
										</Col>

										<Col md="12">
											<textarea
												style={{ width: "100%", marginTop: 10 }}
												value={desc}
												rows="2"
												onChange={(e) => setDesc(e.target.value)}
											/>
										</Col>
									</Row>
									<div style={{ textAlign: "right" }}>
										<Button onClick={addActivity} className="btn-success" style={{ color: "#fff" }}>
											Ajouter
										</Button>
									</div>

									<h4 style={{ textAlign: "left", margin: 0 }}>Historique</h4>
									<Table responsive hover striped>
										<thead>
											<tr>
												<th>Date</th>
												<th>Status</th>
												<th>Facebook</th>
												<th>Joignabilité</th>
												<th>Description</th>
											</tr>
										</thead>
										<tbody>
											{activities?.map((item, i) => (
												<tr key={"activity_" + i}>
													<td>{moment(item.createdAt || new Date()).format("llll")}</td>
													<td>{renderStatus(item.status)}</td>
													<td>{renderFB(item.fb_group)}</td>
													<td>{renderJoin(item.joinability)}</td>
													<td>{item.desc}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</TabPane>

								{/* PARTICIPANT INFORMATIONS */}
								<TabPane tabId="3" style={{ textAlign: "left" }}>
									{activity.participant && (
										<table>
											<tbody>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Nom</span>
													</td>
													<td>
														<NavLinkNaigation
															target="_blank"
															style={{ color: "green" }}
															to={"/app/clients/edit/" + activity.participant._id}
														>
															<p style={{ margin: 0, color: "#50b179" }}>
																{activity.participant.first_name +
																	" " +
																	activity.participant?.last_name}
															</p>
															<p style={{ margin: 0, color: "#50b179" }}>
																{activity.participant.first_name_ar +
																	" " +
																	activity.participant?.last_name_ar}
															</p>
														</NavLinkNaigation>
													</td>
												</tr>

												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Référence</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														#{activity.participant.ref}
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Téléphone</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{activity.participant.phone} / {activity.participant.phone2}
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Addresse</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{activity.participant.city}, {activity.participant.region},{" "}
														{activity.participant.address}
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Facebook</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{activity.participant.fb}
													</td>
												</tr>
											</tbody>
										</table>
									)}
								</TabPane>
								<TabPane tabId="4" style={{ textAlign: "left" }}>
									{activity.training && (
										<table>
											<tbody>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Date de début</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{moment(activity.training?.start_date).format("ddd DD/MM/YYYY")}
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Duré</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{activity.training.duration || 0} Jours
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Référence</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{activity.training.ref || ""}
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Formateur</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{`${training?.former?.first_name} ${training?.former?.last_name}` ||
															""}
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Addresse</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{activity.training.city}, {activity.training.region},{" "}
														{activity.training.address}
													</td>
												</tr>
												<tr>
													<td style={{ verticalAlign: "middle", padding: 10 }}>
														<span>Type</span>
													</td>
													<td style={{ fontWeight: "500", fontSize: 15 }}>
														{activity.training.type}
													</td>
												</tr>
											</tbody>
										</table>
									)}
								</TabPane>
							</TabContent>
						</div>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
};
const mapStatetoProps = (state) => {
	return {
		user: state?.auth?.user,
	};
};

export default withRouter(connect(mapStatetoProps, {})(FollowMdl));
