import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import PurchaseCmp from '../../shared/components/purchases/purchase-cmp';

export default class AddPurchasePage extends PureComponent {

  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return(<Container>
      <h3 className="page-title mb-3">Ajouter une achat</h3>
      <PurchaseCmp history={this.props.history} adding={true} />
    </Container>)
  }
}
