import React from "react";
import { connect } from "react-redux";
import { Button, ButtonToolbar, Modal } from "reactstrap";
import classNames from "classnames";

const ConfirmMdl = ({ is_open, toggle, accept, size, message, message2 }) => {
	const modalClass = classNames({
		"modal-dialog--colored": false,
		"modal-dialog--header": true,
	});

	return (
		<Modal isOpen={is_open} size={size || "sm"} toggle={toggle} className={`modal-dialog--danger ${modalClass}`}>
			<div className="modal__header">
				<h4 className="text-modal  modal__title" style={{ textAlign: "start" }}>
					Attention
				</h4>
			</div>

			<div className="modal__body" style={{ padding: "0 10px", textAlign: "left" }}>
				{/* <h3 className="mt-2">{message ? message : "Êtes-vous sûr de vouloir le supprimer définitivement?"} </h3> */}
				<h3 className="mt-2">{message ? message : "Êtes-vous sûr de vouloir annuler la commande?"} </h3>
				{message2 && <h4 className="mt-3">{message2}</h4>}

				<div style={{ textAlign: "right", width: "100%", height: 40, marginTop: 50 }}>
					<ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
						<Button color="danger" onClick={accept}>
							OK
						</Button>
						<Button type="button" onClick={toggle}>
							Annuler
						</Button>
					</ButtonToolbar>
				</div>
			</div>
		</Modal>
	);
};

export default connect((state) => ({
	rtl: state.rtl,
}))(ConfirmMdl);
