import React from "react";
// import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { Space, Tabs } from "antd";

// import { EyeOutlined, RocketFilled } from "@ant-design/icons";
// import Axios from "axios";
// import { api, statuses } from "../../../../const";

import LevelChilds from "./LevelChilds";
import TrainerChildsResum from "./TrainerChildsResum";

const { TabPane } = Tabs;

const ClubCm = (props) => {
	const { client_id } = props;

	// const [active_tab, setActiveTab] = useState("1");

	return (
		<React.Fragment>
      <TrainerChildsResum client_id={client_id} />
      
			<Tabs tabPosition="left" defaultActiveKey={"1"} className="mb-4 mt-2">
				{/* <TabPane style={{ padding: 0 }} tab="Minime 1" key="0">
					
				</TabPane> */}
				<TabPane style={{ padding: 0 }} tab="Minime 1" key="1">
					<LevelChilds client_id={client_id} index_level={1} index_sub_level={1} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Minime 2" key="2">
					<LevelChilds client_id={client_id} index_level={1} index_sub_level={2} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Cadet 1" key="3">
					<LevelChilds client_id={client_id} index_level={1} index_sub_level={3} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Senior 1" key="4">
					<LevelChilds client_id={client_id} index_level={1} index_sub_level={4} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Cadet 2" key="5">
					<LevelChilds client_id={client_id} index_level={2} index_sub_level={1} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Senior 2" key="6">
					<LevelChilds client_id={client_id} index_level={2} index_sub_level={2} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Senior 3" key="7">
					<LevelChilds client_id={client_id} index_level={3} index_sub_level={1} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Senior 4" key="8">
					<LevelChilds client_id={client_id} index_level={4} index_sub_level={1} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Senior 5" key="9">
					<LevelChilds client_id={client_id} index_level={5} index_sub_level={1} />
				</TabPane>
				<TabPane style={{ padding: 0 }} tab="Senior 6" key="10">
					<LevelChilds client_id={client_id} index_level={6} index_sub_level={1} />
				</TabPane>
			</Tabs>
		</React.Fragment>
	);
};

ClubCm.prototype = {
	client_id: PropTypes.func.isRequired,
	client: PropTypes.object,
};
export default ClubCm;
