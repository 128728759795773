import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardBody, Table, Row, Col, Nav, NavItem, NavLink as TabNavLink, TabContent, TabPane } from "reactstrap";

import moment from "moment";
import Select from "react-select";
import classnames from "classnames";

import AsyncSelect from "react-select/async";

// import {
//   changeSidebarVisibility
// } from "../../redux/actions/sidebarActions";

import { getShipModes } from "../../redux/actions/shipModesActions";

import { getCountPages } from "../../../src/shared/helper/utils";
import Pagination from "../../../src/shared/components/pagination/Pagination";

import { connect } from "react-redux";
import Axios from "axios";
import { api } from "../../const";

moment.locale("fr");

const current_year = new Date().getFullYear();
const years = [];
for (let i = 0; i <= current_year - 2019; i++) {
	years.push({ label: 2019 + i, value: 2019 + i, key: i });
}

const current_month = new Date().getMonth();

class OrderGains extends PureComponent {
	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			orders: [],
			totals: {},
			articles_gains: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 30,

			page_sizes: [
				// { label: '1', value: 1, key: 0 },
				{ label: "10", value: 10, key: 1 },
				{ label: "20", value: 20, key: 2 },
				{ label: "30", value: 30, key: 3 },
				{ label: "40", value: 40, key: 4 },
				{ label: "50", value: 50, key: 5 },
			],

			monthes: [
				{ label: "-", value: -1, key: 0 },
				{ label: "Janvier", value: 0, key: 1 },
				{ label: "Février", value: 1, key: 2 },
				{ label: "Mars", value: 2, key: 3 },
				{ label: "Avril", value: 3, key: 4 },
				{ label: "Mai", value: 4, key: 5 },
				{ label: "Juin", value: 5, key: 6 },
				{ label: "juillet", value: 6, key: 7 },
				{ label: "Août", value: 7, key: 8 },
				{ label: "Septembre", value: 8, key: 9 },
				{ label: "Octobre", value: 9, key: 10 },
				{ label: "Novembre", value: 10, key: 11 },
				{ label: "Décembre", value: 11, key: 12 },
			],
			selected_month: current_month,
			selected_year: current_year,

			query: "",

			show_edit_msg: false,
			order_editing: null,
			req_edit_id: null,
			status_editing: "",

			shipping_mode: "",
			ship_modes: [{ label: "Tous", value: "", key: "key" }],

			count_all_orders: 0,
			count_all_articles: 0,

			active_tab: "1",
			coordinators: [],
			coordinator_id: null,
		};
	}

	componentWillMount() {
		this.getPage();
		this.getCoordinators();
		this.props.getShipModes();
	}
	componentDidUpdate(prev) {
		const { ship_modes, loading_get_ship_modes, error_get_ship_modes } = this.props;

		// console.log("loading_get_ship_modes", loading_get_ship_modes);

		if (prev.loading_get_ship_modes && !loading_get_ship_modes && !error_get_ship_modes) {
			const new_shipmodes = [{ label: "Tous", value: "", key: "key" }];
			for (let i = 0; i < ship_modes.length; i++) {
				const ship_mode = ship_modes[i];
				new_shipmodes.push({ label: ship_mode.name, value: ship_mode.name, price: ship_mode.price, key: i });
			}

			this.setState({ ship_modes: new_shipmodes });
		}
	}

	getPage(page = 1) {
		const { selected_page_size, selected_month, selected_year, shipping_mode, query, coordinator_id } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {
			month: selected_month,
			year: selected_year,
			query,
			is_training_order: this.props.is_training_order ? true : false,
		};

		if (coordinator_id && coordinator_id !== "") filter["coordinator"] = coordinator_id;

		if (shipping_mode && shipping_mode != "") filter["shipping_mode"] = shipping_mode;

		// this.props.getOrders({ filter, offset, limit: selected_page_size })
		Axios.post(`${api.orders}/stats/gain/${offset}/${selected_page_size}`, filter)
			.then((resp) => {
				// console.log(resp);
				if (resp && resp.data) {
					this.setState({
						orders: resp.data.orders || [],
						totals: resp.data.totals ? resp.data.totals[0] || {} : {},
						count_all_orders: resp.data.count || 0,
					});
				}

				if (page === 1) this.getArticlesGains(filter);
			})
			.catch((err) => {
				if (page === 1) this.getArticlesGains(filter);
			});
	}

	getArticlesGains = (filter = {}) => {
		Axios.post(`${api.orders}/stats/gain-articles`, filter)
			.then((resp) => {
				if (resp && resp.data) {
					const new_articles = resp.data.articles || [];

					this.setState({
						articles_gains: new_articles, //.sort((a, b) => (a.qty < b.qty) ? 1 : -1) || [],
					});
				}
			})
			.catch((err) => {});
	};

	getCoordinators = (query_search = "") => {
		return new Promise((resolve) => {
			const filter = { is_coordinator: true };
			if (query_search !== "") filter["name"] = query_search;
			Axios.post(`${api.users}/filter/0/30`, filter)
				.then((resp) => {
					const data_users = resp?.data?.users || [];
					const user_options = [];

					for (let i = 0; i < data_users.length; i++) {
						user_options.push({
							// user_data: { ...data_users[i] },
							value: data_users[i]._id,
							label: `${data_users[i].first_name} ${data_users[i].last_name}`,
						});
					}

					if (query_search === "") this.setState({ coordinators: user_options });
					resolve(user_options);
				})
				.catch((err) => {
					resolve([]);
				});
		});
	};

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	render() {
		const {
			current_page,
			page_sizes,
			selected_page_size,
			count_all_orders,
			monthes,
			selected_month,
			selected_year,
			shipping_mode,
			ship_modes,
			query,
			orders,
			totals,

			articles_gains,
			active_tab,
			coordinators,
			coordinator_id,
		} = this.state;

		// console.log("orders", orders);
		return (
			<Card>
				<CardBody className="p-1">
					<div className="p-2">
						<Row>
							<Col md="2">
								<div className="form__form-group">
									<div className="form__form-group-field">
										<input
											style={{
												width: "100%",
												border: "none",
												borderBottom: "1px solid #ddd",
												padding: "12px 5px",
											}}
											defaultValue={query}
											component="input"
											placeholder="Recherche"
											onChange={(e) =>
												this.setState({ query: e.target.value }, () => this.getPage())
											}
										/>
									</div>
								</div>
							</Col>

							<Col md="2">
								<Select
									defaultValue={shipping_mode}
									value={ship_modes.filter((option) => option.value === shipping_mode)}
									onChange={(e) => this.setState({ shipping_mode: e.value }, () => this.getPage())}
									options={ship_modes}
									clearable={false}
									className="react-select"
									placeholder="Livraison"
									classNamePrefix="react-select"
								/>
							</Col>

							<Col md="3">
								<div style={{ width: 100, display: "inline-block" }}>
									<Select
										name="year"
										value={years.filter((option) => option.value === selected_year)}
										onChange={(e) =>
											this.setState({ selected_year: e.value }, () => this.getPage())
										}
										options={years}
										clearable={false}
										className="react-select"
										placeholder="Year"
										classNamePrefix="react-select"
									/>
								</div>

								<div style={{ width: 120, display: "inline-block" }}>
									<Select
										name="Page"
										// defaultValue={selected_page_size}
										value={monthes.filter((option) => option.value === selected_month)}
										onChange={(e) =>
											this.setState({ selected_month: e.value }, () => this.getPage())
										}
										options={monthes}
										clearable={false}
										className="react-select"
										placeholder="Page"
										classNamePrefix="react-select"
									/>
								</div>
							</Col>

							<Col md="4">
								{this.props.is_training_order ? (
									<AsyncSelect
										placeholder="Cordinateur"
										value={coordinators.filter((option) => option.value === coordinator_id) || null}
										defaultOptions={coordinators}
										loadOptions={(inputValue) => this.getCoordinators(inputValue)}
										onChange={(e) => {
											this.setState({ coordinator_id: e?.value }, () => this.getPage());
										}}
										isClearable
									/>
								) : null}
							</Col>

							<Col md="1">
								<div style={{ width: 60 }}>
									<Select
										name="Page"
										// defaultValue={selected_page_size}
										value={page_sizes.filter((option) => option.value === selected_page_size)}
										onChange={(e) =>
											this.setState({ selected_page_size: e.value }, () => this.getPage())
										}
										options={page_sizes}
										clearable={false}
										className="react-select"
										placeholder="Page"
										classNamePrefix="react-select"
									/>
								</div>
								{/* <strong>{count_all_orders}</strong> */}
							</Col>
						</Row>
						<hr />
					</div>

					<div className="tabs">
						<div className="tabs__wrap">
							<Nav tabs>
								<NavItem>
									<TabNavLink
										className={classnames({ active: active_tab === "1" })}
										onClick={() => {
											this.setState({ active_tab: "1" });
										}}
									>
										Commandes
									</TabNavLink>
								</NavItem>
								<NavItem>
									<TabNavLink
										className={classnames({ active: active_tab === "2" })}
										onClick={() => {
											this.setState({ active_tab: "2" });
										}}
									>
										Articles
									</TabNavLink>
								</NavItem>
							</Nav>

							<TabContent activeTab={active_tab} style={{ width: "100%", minHeight: 200 }}>
								{/* ORDERS GAINS */}
								<TabPane tabId="1">
									<Table responsive hover striped>
										<thead>
											<tr>
												<th>
													<strong style={{ color: "red", fontSize: 18 }}>
														{count_all_orders}
													</strong>
												</th>
												<th>Date</th>
												<th>Client</th>
												<th>Téléphone</th>
												{/* <th>Gouvernorat</th>
                  <th>Vile</th> */}
												<th>Adresse</th>
												<th>Livraison</th>
												<th>Coût Livraison</th>
												<th>Total CMD</th>
												<th>Total Charge</th>
												<th>Gain</th>
											</tr>
										</thead>
										<tbody className="order_rows">
											{(totals?.gain || totals?.gain === 0) && (
												<tr
													style={{
														backgroundColor: "#8bc34a",
													}}
												>
													<td colSpan="6" style={{ textAlign: "center" }}>
														<strong>Total CMD =</strong>
													</td>
													<td>{totals?.shipping_real_price}</td>
													<td>{totals?.total}</td>
													<td></td>
													<td>{(Number(totals?.gain || 0) || 0).toFixed(2)}</td>
												</tr>
											)}
											{orders &&
												orders.map((order, i) => (
													<tr style={{}} key={"order_" + i}>
														<td style={{ fontSize: 11 }}>
															<NavLink to={"/app/orders/view/" + order._id}>
																#{order.ref}
															</NavLink>
														</td>
														<td>{moment(order.date).format("ll")}</td>

														<td style={{ fontWeight: "bold" }}>
															{order.client_data &&
															order.client_data.first_name_ar &&
															order.client_data.first_name_ar != ""
																? order.client_data.first_name_ar
																: order.client_data?.first_name}
														</td>

														<td>{order.client_data ? order.client_data.phone : ""}</td>

														<td>{order.client_data ? order.client_data.address : ""}</td>

														<td>
															<strong>{order.shipping_mode}</strong>
															{/* <p style={{ color: "#777" }} >{order.shipping_price}{" dt"}</p> */}
														</td>

														<td>
															<strong>{order.shipping_real_price} TD</strong>
														</td>

														<td>{order.total}</td>

														<td>{(order.total - order.gain).toFixed(2)}</td>

														<td>
															{/* {order.gain} */}
															{(Number(order?.gain || 0) || 0).toFixed(2)}
														</td>

														{/* <td style={{ width: 60, verticalAlign: "middle" }}>
              <NavLink to={"/app/orders/view/" + order._id}>
                <span className="lnr lnr-eye mr-2" style={{ cursor: 'pointer', color: "#4CAF50", fontSize: 18 }}></span>
              </NavLink>
            </td> */}
													</tr>
												))}
										</tbody>
									</Table>

									<Pagination
										currentPage={current_page}
										totalPage={getCountPages(count_all_orders, selected_page_size)}
										onChangePage={(i) => this.onChangePage(i)}
									/>
								</TabPane>

								{/* ARTICLES TAB */}
								<TabPane tabId="2">
									<Table responsive hover striped>
										<thead>
											<tr>
												<th>
													<strong style={{ color: "red", fontSize: 18 }}>
														{articles_gains?.length}
													</strong>
												</th>
												<th>Article</th>
												<th>Nombre de vente</th>
												<th>Total Vente</th>
												<th>Total Achat</th>
												<th>Gain Total</th>
											</tr>
										</thead>
										<tbody className="order_rows">
											{articles_gains &&
												articles_gains.map((article_gain, i) => (
													<tr style={{}} key={"article_gain_" + i}>
														<td style={{ fontSize: 11 }}>
															<NavLink
																to={"/app/articles/edit/" + article_gain.article_id}
															>
																#{article_gain.ref}
															</NavLink>
														</td>

														<td style={{ fontWeight: "bold" }}>
															{article_gain.name}
															{article_gain.is_pack ? (
																<span style={{ color: "#777" }}> (composé)</span>
															) : null}
														</td>

														<td>{article_gain.qty}</td>

														<td>{(Number(article_gain.sales) || 0).toFixed(2)}</td>
														<td>{(Number(article_gain.purchases) || 0).toFixed(2)}</td>

														<td>
															{/* {(Number((article_gain.total_sale_price || 0) - Number(article_gain.total_purchase_price || 0)) || 0).toFixed(3)} */}
															{(
																Number(article_gain.sales || 0) -
																Number(article_gain.purchases || 0)
															).toFixed(2)}
														</td>
													</tr>
												))}
										</tbody>
									</Table>
								</TabPane>
							</TabContent>
						</div>
					</div>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = ({ order, auth, shipMode }) => {
	const {
		loading_get_orders,
		orders,
		count_all_orders,
		// loading_one_order, error_one_order,
		loading_edit_order,
		error_edit_order,
	} = order;

	const { ship_modes, loading_get_ship_modes, error_get_ship_modes } = shipMode;

	return {
		loading_get_orders,
		orders,
		count_all_orders,
		// loading_one_order, error_one_order,

		loading_edit_order,
		error_edit_order,

		ship_modes,
		loading_get_ship_modes,
		error_get_ship_modes,

		user: auth?.user,
	};
};
export default connect(mapStateToProps, {
	getShipModes,
})(OrderGains);
