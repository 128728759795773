import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import OrderCmp from '../../shared/components/orders/order-cmp';

import {
  changeSidebarVisibility
} from "../../redux/actions/sidebarActions";

import { connect } from 'react-redux';

class AddOrderPage extends PureComponent {

  constructor() {
    super();
    this.state = {
    };
  }

  componentDidMount() {
    try {
      if (!this.props.collapse)
        this.props.changeSidebarVisibility()
    } catch (error) { }
  }

  render() {
    return (<Container>
      {/* <h3 className="page-title mb-3">Ajouter une commande</h3> */}
      <OrderCmp history={this.props.history} adding={true} is_training_order={this.props.is_training_order} />
    </Container>)
  }
}


const mapStateToProps = ({ sidebar }) => {
  return { collapse: sidebar.collapse }
}
export default connect(mapStateToProps, {
  changeSidebarVisibility
})(AddOrderPage);
