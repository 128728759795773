import React, { Component, Fragment } from "react";
import { Row, Table, Button, Col, Modal, ModalBody, ModalFooter, CustomInput, ModalHeader } from "reactstrap";
// import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import moment from "moment";

import { getChilds } from "../../../redux/actions/childsActions";

import { getCountPages } from "../../helper/utils";
import { NavLink } from "react-router-dom";

import Pagination from "../pagination/Pagination";
import ButtonToolbar from "reactstrap/lib/ButtonToolbar";
import AddChildMdl from "./AddChildMdl";

class SelectChildMdl extends Component {
	constructor(props) {
		super(props);

		// const { messages } = this.props.intl || {};

		this.state = {
			selected_item: null,

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			show_add_child: false,
			query: "",
		};
	}

	componentDidMount() {
		this.getPage();
	}

	getPage(page = 1) {
		const { selected_page_size, filter_all, filter_status, query } = this.state;

		const { former, getChilds } = this.props;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {};
		if (!filter_all) filter["statuses"] = filter_status;

		if (query && query != "") filter["query"] = query;

		if (former) filter["former"] = former?._id;

		getChilds({ filter, offset, limit: selected_page_size });
	}

	render() {
		const { current_page, selected_page_size, query, selected_item, show_add_child } = this.state;
		const { childs, count_all_childs, isOpen, size, toggleOpen, accept, former, nb_childs } = this.props;

		return (
			<Modal isOpen={isOpen} size={size || "lg"} toggle={toggleOpen} className={"mdl_wraper p-2"}>
				<ModalHeader>
					{/* <h3 className="page-title mb-3">Ajouter un {add_as_participant ? 'participant' : 'child'} </h3> */}
					Séléctioner un Enfant (Formateur :{" "}
					{former && former.first_name_ar && former.first_name_ar != ""
						? former.first_name_ar
						: former?.first_name}
					)
				</ModalHeader>
				<ModalBody style={{ textAlign: "left" }}>
					<div className="card__title">
						{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
						<Row>
							<Col md="10" style={{ marginBottom: 10 }}>
								{/* <CheckBoxField CheckBoxField="all" value={filter_all} label="Tous" name="all" onChange={(e) => this.resetFilter("all", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}

								<div className="form__form-group" style={{ width: 200 }}>
									<span className="form__form-group-label">Recherche</span>
									<div className="form__form-group-field">
										<input
											style={{
												width: "100%",
												border: "none",
												borderBottom: "1px solid #ddd",
												padding: 5,
											}}
											defaultValue={query}
											component="input"
											placeholder="Recherche"
											onChange={(e) =>
												this.setState({ query: e.target.value }, () =>
													this.getPage(current_page)
												)
											}
										/>
									</div>
								</div>
							</Col>
							<Col md="2" style={{ marginBottom: 10 }}>
								<Button
									onClick={() => this.setState({ show_add_child: true })}
									color="success"
									className="rounded"
								>
									Ajouter
								</Button>
							</Col>
						</Row>
					</div>
					<Table responsive hover striped>
						<thead>
							<tr>
								<th></th>
								<th>Nom</th>
								<th>Niveau scolaire</th>
								<th>Age</th>
								<th>Date d'inscrit</th>
								<th>Formateur</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{childs &&
								childs.map((child, i) => (
									<tr key={"child_" + i}>
										<td style={{ verticalAlign: "middle" }}>
											<input
												onChange={() => this.setState({ selected_item: child })}
												checked={child._id === selected_item?._id}
												type="radio"
												name="select_child"
												value={child._id}
											/>
										</td>
										<td
											onClick={() => this.setState({ selected_item: child })}
											style={{ verticalAlign: "middle" }}
										>
											<p style={{ margin: 0, color: "#777" }}>
												{child.first_name + " " + child.last_name}
											</p>
											<p style={{ margin: 0, color: "#777" }}>
												{child.first_name_ar + " " + child.last_name_ar}
											</p>
										</td>

										<td
											onClick={() => this.setState({ selected_item: child })}
											style={{ verticalAlign: "middle" }}
										>
											{child.education_level}
										</td>

										<td
											onClick={() => this.setState({ selected_item: child })}
											style={{ verticalAlign: "middle" }}
										>
											{child.age}
										</td>

										<td
											onClick={() => this.setState({ selected_item: child })}
											style={{ verticalAlign: "middle" }}
										>
											{moment(child.registration_date).format("llll")}
										</td>

										<td
											onClick={() => this.setState({ selected_item: child })}
											style={{ verticalAlign: "middle" }}
										>
											{child.former &&
											child.former.first_name_ar &&
											child.former.first_name_ar != ""
												? child.former.first_name_ar
												: child.former?.first_name}
										</td>

										<td style={{ width: 60, verticalAlign: "middle" }}>
											<a target="_blank" href={"/app/childs/edit/" + child._id}>
												<span
													className="lnr lnr-eye mr-2"
													style={{ cursor: "pointer", color: "#4CAF50", fontSize: 18 }}
												></span>
											</a>
										</td>
									</tr>
								))}
						</tbody>
					</Table>

					<Pagination
						currentPage={current_page}
						totalPage={getCountPages(count_all_childs, selected_page_size)}
						onChangePage={(i) => this.onChangePage(i)}
					/>
				</ModalBody>
				<ModalFooter>
					<div className="mt-5" style={{ textAlign: "right" }}>
						<ButtonToolbar className="modal__footer form__button-toolbar" style={{ textAlign: "right" }}>
							<Button color="primary" onClick={() => accept([selected_item])}>
								Choisir
							</Button>
							<Button onClick={toggleOpen} type="button">
								Annuler
							</Button>
						</ButtonToolbar>
					</div>
				</ModalFooter>

				<AddChildMdl
					isOpen={show_add_child}
					add_to_article={true}
					toggleOpen={() => this.setState({ show_add_child: !show_add_child })}
					addToArticle={(created_childs) => {
						this.getPage();
						accept(created_childs || []);
						this.setState({ show_add_child: false });
					}}
					nb_childs={nb_childs}
					former={former}
				/>
			</Modal>
		);
	}
}

const mapStateToProps = ({ child }) => {
	const {
		loading_get_childs,
		childs,
		count_all_childs,
		loading_one_child,
		error_one_child,
		loading_delete_child,
		error_delete_child,
	} = child;
	return {
		loading_get_childs,
		childs,
		count_all_childs,
		loading_one_child,
		error_one_child,

		loading_delete_child,
		error_delete_child,
	};
};
export default connect(mapStateToProps, {
	getChilds,
})(SelectChildMdl);
