import Axios from "axios";
import React, { Component, Fragment } from "react";
import {
  Input, Button, Row, Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { api } from "../../../const";

export default class AddTrainCategoryMdl extends Component {
  constructor(props) {
    super(props);

    // const { messages } = this.props.intl || {};

    this.state = {
      name: "",
      training_names: [],
    };
  }

  submit = () => {
    const { name, training_names } = this.state
    if (name && name != "") {
      Axios.post(api.trainings + "/categories/create", { name, training_names }).then((resp) => {
        this.props.accept(resp)
      }).catch(err => {
        alert("please check your data and try again.")
      })
    }
  }

  render() {
    const { isOpen, size, toggleOpen } = this.props;
    const { name, training_names } = this.state;

    return (<Modal isOpen={isOpen} size={size || "lg"} toggle={toggleOpen} className={"mdl_wraper"} >
      <ModalHeader>
        {/* <h3 className="page-title mb-3">Ajouter un {add_as_participant ? 'participant' : 'client'} </h3> */}
        Ajouter un catégorie
      </ModalHeader>
      <ModalBody style={{ textAlign: "left" }}>
        <Row className="form">
          <Col md="12">
            <Input value={name} onChange={(e) => {
              this.setState({ name: e?.target?.value })
            }} placeholder="Nom" className="form-control" />
            <hr />
          </Col>
          <Col md="12">
            <h5>Nom des formations</h5>
          </Col>

          <Col md="12">
            <Row>
              {training_names?.length ? training_names.map((t_name, i) => <React.Fragment key={"train_name_" + i}>
                <Col md="11" style={{ marginTop: 5 }}>
                  <Input value={t_name} onChange={(e) => {
                    const new_names = [...training_names]
                    new_names[i] = e?.target?.value
                    this.setState({ training_names: new_names })
                  }} placeholder="Nom" className="form-control" />
                </Col>
                <Col md="1" style={{ textAlign: "center" }}>
                  <span onClick={() => {
                    const new_names = [...training_names]
                    new_names.splice(i, 1)
                    this.setState({ training_names: new_names })
                  }} className="lnr lnr-trash" style={{ color: "#F44336", display: 'block', marginTop: 12, cursor: "pointer" }}></span>
                </Col>
              </React.Fragment>) : null}
            </Row>

            <p onClick={() => {
              const new_names = [...training_names]
              new_names.push("")
              this.setState({ training_names: new_names })
            }} style={{ color: "green", cursor: "pointer" }}>+ Ajouter nom</p>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter style={{ paddingBottom: 0 }}>
        <Button color="primary" onClick={() => this.submit()}>Ok</Button>{" "}
        <Button color="secondary" onClick={toggleOpen}>Annuler</Button>
      </ModalFooter>
    </Modal>
    );
  }
}