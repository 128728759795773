import React from "react";
import { Card, DatePicker, Select, Space, Table, Tag, Typography } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { api } from "../../const";
import { Link } from "react-router-dom";
import moment from "moment";
import StatusBade from "../../shared/helper/StatusBadge";

const { RangePicker } = DatePicker;

const ListLogPage = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(30);
	const [count, setCount] = useState(0);

	const [start_date, setStartDate] = useState(moment());
	const [end_date, setEndDate] = useState(moment());
	const [users, setUsers] = useState([]);
	const [selected_user_id, setSelectedUserId] = useState(null);

	useEffect(() => {
		Axios.post(`${api.users}/filter/0/300`, {})
			.then((resp) => {
				setUsers(resp.data?.users || []);
			})
			.catch((err) => console.log("err get users", err));
	}, []);

	useEffect(() => {
		getPage();
	}, [start_date, end_date, selected_user_id]);

	const getPage = (page = 1) => {
		const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);

		setLoading(true);

		Axios.post(`${api.log}/filter/${offset}/${limit}`, {
			start_date: start_date?.format("YYYY-MM-DD"),
			end_date: end_date?.format("YYYY-MM-DD"),
			user: selected_user_id,
		})
			.then((resp) => {
				setLoading(false);

				setCount(resp?.data?.count || 0);

				// const new_data = resp?.data?.logs?.map((doc) => {
				// 	const item = doc?.client || {};
				// 	return {
				// 		name: `${item.first_name} ${item.last_name}`,
				// 		name_ar: `${item.first_name_ar} ${item.last_name_ar}`,
				// 		key: item._id,
				// 		phone: item.phone || "",
				// 		phone2: item.phone2 || "",
				// 		fb: item.fb || "",
				// 		city: item.city || "",
				// 		region: item.region || "",
				// 		address: item.address || "",
				// 		count_active_children: item.count_active_children || 0,

				// 		count_cmd: doc.count_cmd || 0,
				// 	};
				// });

				setData(resp?.data?.logs || []);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const renderOrder = (order) => {
		try {
			return (
				<>
					<Space size={"large"}>
						<span style={{ width: "80px", display: "block" }}>{moment(order.date).format("ll")}</span>
						<span style={{ fontWeight: "bold", width: "150px", display: "block" }}>
							{order.client_data &&
							order.client_data.first_name_ar &&
							order.client_data.first_name_ar != ""
								? order.client_data.first_name_ar
								: order.client_data?.first_name}
						</span>
						<span style={{ width: "60px", display: "block" }}>{order.total} TND</span>
						<span style={{ display: "block", width: "120px" }}>
							<StatusBade status={order.status} />
						</span>
						<a
							href={`/app${order.is_training_order ? "/orders-trains" : ""}/view/${order._id || order}`}
							target="_blank"
						>
							<span
								className="lnr lnr-eye mr-2"
								style={{
									cursor: "pointer",
									color: "#4CAF50",
									fontSize: 18,
								}}
							></span>
						</a>
					</Space>
				</>
			);
		} catch (error) {
			return null;
		}
	};

	return (
		<>
			<Typography.Title level={3}>Log</Typography.Title>
			<Card bodyStyle={{ padding: 5 }}>
				<RangePicker
					value={[start_date, end_date]}
					className="mb-3"
					onChange={(values) => {
						setStartDate(values[0]);
						setEndDate(values[1]);
					}}
					allowClear={false}
				/>

				{users.length ? (
					<Select
						value={selected_user_id}
						onChange={(value) => setSelectedUserId(value)}
						allowClear={true}
						className="ml-3"
						style={{ minWidth: 250 }}
						placeholder="Utilisateur"
					>
						{users.map((usr, indx) => (
							<Select.Option
								key={`user-${indx}`}
								value={usr?._id}
							>{`${usr?.first_name} ${usr?.last_name}`}</Select.Option>
						))}
					</Select>
				) : null}

				<Table
					loading={loading}
					columns={[
						{
							title: (
								<Tag color="orange" style={{ fontSize: 16 }}>
									{count}
								</Tag>
							),
							dataIndex: "createdAt",
							key: "createdAt",
							render: (text) => moment(text).format("llll"),
							width: 150,
						},
						{
							title: "Action",
							dataIndex: "action",
							key: "action",
							width: 150,
							render: (text) => (
								<Tag
									color={
										text === "DELETE_ORDER" ? "red" : `${text === "ADD_ORDER" ? "green" : "gold"}`
									}
								>
									{text}
								</Tag>
							),
						},

						{
							title: "Utilisateur",
							dataIndex: "user",
							key: "user",
							render: (_text, record) => {
								return (
									<Link
										to={`/app/users/edit/${record?.user?._id}`}
									>{`${record?.user?.first_name} ${record?.user?.last_name}`}</Link>
								);
							},
						},
						{
							title: "Modes",
							dataIndex: "models",
							key: "models",
							render: (_text, record) => {
								return (
									<ul style={{ listStyle: "none" }}>
										{record?.models?.map((mdl) => {
											return <li>{renderOrder(record.data_saved)}</li>;
										})}
									</ul>
								);
							},
						},
						{ title: "Données envoyées", dataIndex: "data_sent", key: "data_sent" },
					]}
					dataSource={data}
					pagination={{
						total: count,
						pageSize: limit,
						pageSizeOptions: [1, 10, 20, 30],
					}}
					onChange={(pagination, filters, sorter, extra) => {
						setLimit(pagination?.pageSize || 10);
						getPage(pagination?.current || 1);
					}}
				/>
			</Card>
		</>
	);
};

export default ListLogPage;
