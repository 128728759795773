import React, { useState, PureComponent } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, ButtonToolbar, Modal, Row, Col } from 'reactstrap';
import classNames from 'classnames';

import {
  getCtgs
} from "../../../redux/actions/categoryActions";

import CtgsTree from './ctgs-tree';

const modalClass = classNames({
  'modal-dialog--colored': false,
  'modal-dialog--header': true,
});

class SelectCtgMdl extends PureComponent {

  constructor() {
    super();
    this.state = {
      selected_ctg: null
    };
  }


  componentWillMount() {
    this.props.getCtgs()
  }

  render() {
    const { is_open, size, toggle, ctgs } = this.props
    const { selected_ctg } = this.state

    return (
      <Modal
        isOpen={is_open} size={size || "lg"}
        toggle={toggle}
        // modalClassName={`${rtl.direction}-support`}
        className={`modal-dialog--primary ${modalClass}`}
      >
        <div className="modal__header">
          <h4 className="text-modal  modal__title">Selectioner un categorie</h4>
        </div>
        <div className="modal__body" style={{ padding: '0 10px', paddingTop: 10, textAlign: 'left' }}>


          <CtgsTree select_one={true} data={ctgs} checkCtg={(ctg_id, ctg)=>this.setState({selected_ctg: ctg})} />

          <div >
            <ButtonToolbar className="modal__footer form__button-toolbar">
              <Button color="primary" onClick={()=>this.props.handleSuccess(selected_ctg)} >Selectioner</Button>
              <Button type="button"   onClick={this.props.toggle}>Annuler</Button>
            </ButtonToolbar>
          </div>
        </div>
      </Modal>
    );
  }
}


const mapStateToProps = ({ category }) => {
  return {
    ctgs: category.ctgs,
  }
}
export default connect(mapStateToProps, {
  getCtgs
})(SelectCtgMdl);