
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_ARTICLES, getArticlesSuccess, getArticlesError, 
  ADD_ARTICLE, addArticleError, addArticleSuccess, 
  GET_ONE_ARTICLE, getOneArticleSuccess, getOneArticleError, 
  EDIT_ARTICLE, editArticleError, editArticleSuccess,

  DELETE_ARTICLE, deleteArticleError, deleteArticleSuccess,
} from '../actions/articlesActions';
import { api } from '../../const';



// *** GET ARTICLES
export function* watchGetArticles() { yield takeEvery(GET_ARTICLES, getArticles) }
function* getArticles({ payload }) {
  try {
    const ret = yield call(getArticlesRequest, payload);

    if (ret && ret.status == 200) yield put(getArticlesSuccess({ articles: ret.data?.articles || [], count: ret.data?.count || 0}));
    else yield put(getArticlesError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getArticlesError({ error, message: (error) ? error.message : '' }));
  }
}
const getArticlesRequest = async (payload) => {  
  return await axios.post(api.articles+'/filter/'+payload.offset+'/'+payload.limit, payload.filter || {})
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// // *** GET ONE ARTICLE
export function* watchGetOneArticle() { yield takeEvery(GET_ONE_ARTICLE, getOneArticle) }
function* getOneArticle({ payload }) {
  try {
    const ret = yield call(getOneArticleRequest, payload);

    if (ret && ret.status == 200) yield put(getOneArticleSuccess({ article: ret.data?.article || null }));
    else yield put(getOneArticleError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneArticleError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneArticleRequest = async (payload) => {  
  return await axios.get(api.articles+"/get-one/"+payload.article_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD ARTICLES
export function* watchAddArticles() { yield takeEvery(ADD_ARTICLE, addArticle) }
function* addArticle({ payload }) {
  try {
    const ret = yield call(addArticlesRequest, payload);

    if (ret && ret.status == 201) yield put(addArticleSuccess({ article: ret.data.article }));
    else yield put(addArticleError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addArticleError({ error, message: (error) ? error.message : '' }));
  }
}
const addArticlesRequest = async (payload) => {  
  return await axios.post(api.articles+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT ARTICLES
export function* watchEditArticle() { yield takeEvery(EDIT_ARTICLE, editArticle) }
function* editArticle({ payload }) {
  try {
    const ret = yield call(editArticlesRequest, payload);

    if (ret && ret.status == 200) yield put(editArticleSuccess({ article: ret.data.article }));
    else yield put(editArticleError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editArticleError({ error, message: (error) ? error.message : '' }));
  }
}
const editArticlesRequest = async (payload) => {  
  return await axios.patch(api.articles+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE ARTICLES
export function* watchDeleteArticle() { yield takeEvery(DELETE_ARTICLE, deleteArticle) }
function* deleteArticle({ payload }) {
  try {
    const ret = yield call(deleteArticlesRequest, payload);

    if (ret && ret.status == 200) yield put(deleteArticleSuccess({ }));
    else yield put(deleteArticleError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteArticleError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteArticlesRequest = async (payload) => {  
  return await axios.delete(api.articles+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetArticles),
    fork(watchGetOneArticle),
    fork(watchAddArticles),
    fork(watchEditArticle),
    fork(watchDeleteArticle),
  ]);
}