import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, Radio } from "antd";

const all_levels = [
	{
		index_level: 1,
		index_sub_level: 1,
		name: "M1",
	},
	{
		index_level: 1,
		index_sub_level: 2,
		name: "M2",
	},
	{
		index_level: 1,
		index_sub_level: 3,
		name: "C1",
	},
	{
		index_level: 1,
		index_sub_level: 4,
		name: "S1",
	},
	{
		index_level: 2,
		index_sub_level: 1,
		name: "C2",
	},
	{
		index_level: 2,
		index_sub_level: 2,
		name: "S2",
	},
	{
		index_level: 3,
		index_sub_level: 1,
		name: "S3",
	},
	{
		index_level: 4,
		index_sub_level: 1,
		name: "S4",
	},
	{
		index_level: 5,
		index_sub_level: 1,
		name: "S5",
	},
	{
		index_level: 6,
		index_sub_level: 1,
		name: "S6",
	},
];

const ChooseNextLevelCmp = (props) => {
	const { levels = [], onOk = () => {}, onCancel = () => {}, visible } = props;

	const [value, setValue] = useState("");
	const [rest_levels, setRestLevels] = useState(all_levels);

	const onChange = (e) => {
		console.log("radio checked", e.target.value);
		setValue(e.target.value);
	};

	const onSubmit = () => {
		if (value && value?.length === 3) {
			const lv = value.split("-");
			onOk({
				index_level: Number(lv[0]),
				index_sub_level: Number(lv[1]),
			});
		}
	};

	useEffect(() => {
		const last_level = levels[levels.length - 1];

		if (last_level) {
			setRestLevels(
				all_levels.filter(
					(lv) =>
						lv.index_level > last_level.index_level ||
						(lv.index_level === last_level.index_level && lv.index_sub_level > last_level.index_sub_level)
				)
			);
		}
	}, []);

	return (
		<Modal title="Choisir Niveau" visible={visible} onOk={onSubmit} onCancel={onCancel} dis>
			<Radio.Group onChange={onChange} value={value}>
				{rest_levels.map((lv) => (
					<Radio
						value={`${lv.index_level}-${lv.index_sub_level}`}
						key={`lv-${lv.index_level}-${lv.index_sub_level}`}
					>
						{lv.name}
					</Radio>
				))}
			</Radio.Group>
		</Modal>
	);
};
ChooseNextLevelCmp.prototype = {
	levels: PropTypes.arrayOf(PropTypes.object).isRequired,
	onCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
	visible: PropTypes.bool,
};
export default ChooseNextLevelCmp;
