import {
  GET_CHILDS, GET_CHILDS_SUCCESS, GET_CHILDS_ERROR,
  ADD_CHILD, ADD_CHILD_SUCCESS, ADD_CHILD_ERROR,
  GET_ONE_CHILD, GET_ONE_CHILD_SUCCESS, GET_ONE_CHILD_ERROR,
  EDIT_CHILD, EDIT_CHILD_SUCCESS, EDIT_CHILD_ERROR,
  DELETE_CHILD, DELETE_CHILD_SUCCESS, DELETE_CHILD_ERROR
} from '../actions/childsActions';

const initialState = {
  loading_get_childs: false,
  error_get_childs: null,
  childs: [],
  count_all_childs: 0,

  new_child: null, loading_add_child: false, error_add_child: null,

  selected_child: null, 
  loading_one_child: false, error_one_child: null,
  loading_edit_child: false, error_edit_child: null,

  loading_delete_child: false, error_delete_child: null
};

const childsReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_CHILDS: return {...state, loading_get_childs: true, error_get_childs: null };
    case GET_CHILDS_SUCCESS:
      return {...state, childs: action.payload.childs, loading_get_childs: false, error_get_childs: null, count_all_childs: action.payload.count };
    case GET_CHILDS_ERROR:
      // console.log("ACTION", {error_get_childs: action.error, loading_get_childs: false, childs: []});
      return {...state, error_get_childs: action.error, loading_get_childs: false, childs: [] };



    case ADD_CHILD: return {...state, loading_add_child: true, error_add_child: null };
    case ADD_CHILD_SUCCESS:
      return {...state, new_child: action.payload.child, loading_add_child: false, error_add_child: null };
    case ADD_CHILD_ERROR:
      return {...state, error_add_child: action.error, loading_add_child: false, new_child: null };


    case GET_ONE_CHILD: return {...state, loading_one_child: true, error_one_child: null };
    case GET_ONE_CHILD_SUCCESS:
      return {...state, selected_child: action.payload.child, loading_one_child: false, error_one_child: null };
    case GET_ONE_CHILD_ERROR:
      return {...state, error_one_child: action.error, loading_one_child: false, selected_child: null };



    case EDIT_CHILD: return {...state, loading_edit_child: true, error_edit_child: null };
    case EDIT_CHILD_SUCCESS:
      return {...state, selected_child: action.payload.child, loading_edit_child: false, error_edit_child: null };
    case EDIT_CHILD_ERROR:
      return {...state, error_edit_child: action.error, loading_edit_child: false, selected_child: null };




    case DELETE_CHILD: return {...state, loading_delete_child: true, error_one_child: null };
    case DELETE_CHILD_SUCCESS:
      return {...state, loading_delete_child: false, error_delete_child: null };
    case DELETE_CHILD_ERROR:
      return {...state, error_delete_child: action.error, loading_delete_child: false };


    default: return state;
  }
};

export default childsReducer;
