import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
	Card,
	CardBody,
	Badge,
	Table,
	Button,
	Row,
	Col,
	// ButtonGroup, ButtonToolbar,
} from "reactstrap";

// import Select from 'react-select';

import { getProviders, editProvider, deleteProvider } from "../../../redux/actions/providersActions";
import { getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";

import { CheckBoxField } from "../form/CheckBox";

import { NavLink } from "react-router-dom";
import ConfirmMdl from "../confirm-mdl";
import { api } from "../../../const";

// const req_statuses = [{label: 'Request', value: 'request'}, {label: 'Accepted', value: 'accepted'}, {label: 'Refused', value: 'refused'}]

class ListProvidersCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			providers: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			show_delete_msg: false,
			req_delete_id: null,
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	componentWillMount() {
		this.getPage();
	}

	componentDidUpdate(prev) {
		const { loading_one_provider, error_one_provider, loading_delete_provider, error_delete_provider } = this.props;

		if (!loading_one_provider && prev.loading_one_provider && !error_one_provider) {
			this.getPage(this.state.current_page);
		}

		if (prev.loading_delete_provider && !loading_delete_provider && !error_delete_provider) {
			this.getPage(this.state.current_page);
		}
	}
	getPage(page = 1) {
		const { selected_page_size, filter_all, filter_status } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {};
		if (!filter_all) filter["statuses"] = filter_status;

		// if (filter_by_query != '') filter['name'] = filter_by_query

		this.props.getProviders({ filter, offset, limit: selected_page_size });
	}

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	deleteReq = () => {
		this.props.deleteProvider({ _id: this.state.req_delete_id });

		this.setState({ show_delete_msg: false });
	};

	resetFilter = (filter, checked, old_filter_status) => {
		if (checked != undefined) {
			const filter_status = JSON.parse(JSON.stringify(old_filter_status));

			if (filter == "all" && checked)
				this.setState({ filter_all: true, filter_status: [] }, () => this.getPage());
			else {
				const indx = filter_status.indexOf(filter);

				if (indx > -1) filter_status.splice(indx, 1);
				else filter_status.push(filter);

				this.setState({ filter_status, filter_all: filter_status.lengh == 0 }, () => this.getPage());
			}
		}
	};

	renderStatusBadge = (status, style = { fontSize: 13, marginRight: 5 }) => {
		switch (status) {
			case "request":
				return <Badge color="success">Request</Badge>;
			case "accepted":
				return (
					<Badge color="primary" style={style}>
						Accepted
					</Badge>
				);
			case "refused":
				return <Badge style={{ ...style, backgroundColor: "#ff4861" }}>Refused</Badge>;

			default:
				return null;
		}
	};
	render() {
		const { current_page, selected_page_size, filter_all, filter_status, show_delete_msg } = this.state;
		const { providers, count_all_providers } = this.props;

		return (
			<Card>
				<CardBody>
					<div className="card__title">
						{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
						<Row>
							<Col md="10" style={{ marginBottom: 10 }}>
								<CheckBoxField
									CheckBoxField="all"
									value={filter_all}
									label="Tous"
									name="all"
									onChange={(e) => this.resetFilter("all", e.target?.checked, filter_status)}
									color="primary"
									style={{ display: "inline-block", marginRight: 8 }}
								/>

								{/* <CheckBoxField CheckBoxField="request" value={(filter_status.includes("request"))} label="Request" name="request" onChange={(e) => this.resetFilter("request", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							</Col>
							<Col md="2" style={{ marginBottom: 10 }}>
								<NavLink to={"/app/providers/add-new"}>
									<Button color="success" className="rounded">
										Ajouter
									</Button>
								</NavLink>
							</Col>
						</Row>
					</div>
					<Table responsive hover striped>
						<thead>
							<tr>
								<th></th>
								<th></th>
								<th>Nom</th>
								<th>Téléphone</th>
								<th>Adresse</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{providers &&
								providers.map((provider, i) => (
									<tr key={"provider_" + i}>
										<td style={{ width: 50 }}>
											<img
												src={
													provider.img
														? api.root + "/" + provider.img.path
														: `${process.env.PUBLIC_URL}/img/no_product.png`
												}
												style={{ width: 40 }}
											/>
										</td>

										<th>#{provider.ref || ""}</th>

										<td style={{ verticalAlign: "middle" }}>{provider.name}</td>

										<td style={{ verticalAlign: "middle" }}>{provider.phone}</td>

										<td style={{ verticalAlign: "middle" }}>{provider.address}</td>

										<td style={{ width: 60, verticalAlign: "middle" }}>
											<a target="_blank" href={"/app/providers/edit/" + provider._id}>
												<span
													className="lnr lnr-eye mr-2"
													style={{ cursor: "pointer", color: "#4CAF50", fontSize: 18 }}
												></span>
											</a>
										</td>
										<td>
											<span
												onClick={() => {
													this.setState({
														req_delete_id: provider._id,
														show_delete_msg: true,
													});
												}}
												className="lnr lnr-trash"
												style={{ color: "#F44336", display: "block", marginTop: 12 }}
											></span>
										</td>
									</tr>
								))}
						</tbody>
					</Table>

					<Pagination
						currentPage={current_page}
						totalPage={getCountPages(count_all_providers, selected_page_size)}
						onChangePage={(i) => this.onChangePage(i)}
					/>

					<ConfirmMdl
						toggle={() =>
							this.setState((prev) => {
								return { show_delete_msg: !prev.show_delete_msg };
							})
						}
						is_open={show_delete_msg}
						accept={() => {
							this.deleteReq();
						}}
					/>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = ({ provider }) => {
	const {
		loading_get_providers,
		providers,
		count_all_providers,
		loading_one_provider,
		error_one_provider,
		loading_delete_provider,
		error_delete_provider,
	} = provider;
	return {
		loading_get_providers,
		providers,
		count_all_providers,
		loading_one_provider,
		error_one_provider,

		loading_delete_provider,
		error_delete_provider,
	};
};
export default connect(mapStateToProps, {
	getProviders,
	editProvider,
	deleteProvider,
})(ListProvidersCmp);
