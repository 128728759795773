import React, { Component } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody, //ModalFooter, CustomInput
} from "reactstrap";
// import { injectIntl } from "react-intl";
import { connect } from "react-redux";
// import Select from 'react-select';

import { getClients } from "../../../redux/actions/clientsActions";
// import { cities, client_types } from "../../../const"; //no_user_img
// import { getCountPages } from "../../helper/utils";

// import Pagination from "../pagination/Pagination";
// import ModalHeader from "reactstrap/lib/ModalHeader";
import ClientCmp from "./client-cmp";

class AddClientMdl extends Component {
	constructor(props) {
		super(props);

		// const { messages } = this.props.intl || {};

		this.state = {
			dropdownSplitOpen: false,
			modalOpen: false,
			selectedItems: [],
			lastChecked: null,

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			show_filters: true,
			// name: '',
			// phone: '',
			// address: "",
			// city: "",
			// region: "",
			// type: "",
		};
	}

	render() {
		const { isOpen, size, toggleOpen, accept, add_as_participant, addParticipant, training_ref } = this.props;

		return (
			<Modal isOpen={isOpen} size={size || "lg"} toggle={toggleOpen} className={"mdl_wraper"}>
				<ModalHeader>
					{/* <h3 className="page-title mb-3">Ajouter un {add_as_participant ? 'participant' : 'client'} </h3> */}
					Ajouter un {add_as_participant ? "participant" : "client"}
				</ModalHeader>
				<ModalBody style={{ padding: 0, textAlign: "left" }}>
					<ClientCmp
						adding={true}
						add_as_participant={true}
						addParticipant={(created_client) => addParticipant(created_client)}
						training_ref={training_ref}
						hide_cancel_btn={true}
					/>
				</ModalBody>

				{/* <ModalFooter style={{ paddingBottom: 0 }}>
        <Button color="primary" onClick={() => accept(selectedItems)}>Ok</Button>{" "}
        <Button color="secondary" onClick={toggleOpen}>Annuler</Button>
      </ModalFooter> */}
			</Modal>
		);
	}
}

const mapStateToProps = ({ client }) => {
	const { loading_get_clients, clients, count_all_clients } = client;
	return {
		loading_get_clients,
		clients,
		count_all_clients,
	};
};

export default connect(mapStateToProps, {
	getClients,
})(AddClientMdl);
