import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
	Card,
	CardBody,
	Badge,
	Table,
	Button,
	Row,
	Col,
	// ButtonGroup, ButtonToolbar,
} from "reactstrap";

// import Select from 'react-select';
import moment from "moment";

import { getTrainings, editTraining, deleteTraining } from "../../../redux/actions/trainingsActions";

import { getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";

import { CheckBoxField } from "../form/CheckBox";

import { NavLink } from "react-router-dom";
import ConfirmMdl from "../confirm-mdl";
import Axios from "axios";
import { api } from "../../../const";
// import { api } from '../../../const';
moment.locale("fr");
// const req_statuses = [{label: 'Request', value: 'request'}, {label: 'Accepted', value: 'accepted'}, {label: 'Refused', value: 'refused'}]

class ListTrainingsCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			trainings: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			show_delete_msg: false,
			req_delete_id: null,
			xl_file: null,
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	componentWillMount() {
		this.getPage();
	}

	// changeImportFile = (e) => {
	//   try {
	//     this.setState({ xl_file: e.target.files[0] })
	//   } catch (error) { }
	// }
	// importXL = (file_name = "xl") => {
	//   if (this.state.xl_file) {
	//     const frm = new FormData()
	//     frm.append(file_name, this.state.xl_file);

	//     Axios.post(`${api.trainings}/import`, frm).then(resp => {
	//       console.log(resp);
	//     }).catch(err => console.log(err))
	//   }
	// }

	componentDidUpdate(prev) {
		const { loading_one_training, error_one_training, loading_delete_training, error_delete_training } = this.props;

		if (!loading_one_training && prev.loading_one_training && !error_one_training) {
			this.getPage(this.state.current_page);
		}

		if (prev.loading_delete_training && !loading_delete_training && !error_delete_training) {
			this.getPage(this.state.current_page);
		}
	}
	getPage(page = 1) {
		const { selected_page_size, filter_all, filter_status } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {};
		if (!filter_all) filter["statuses"] = filter_status;

		// if (filter_by_query != '') filter['name'] = filter_by_query

		this.props.getTrainings({ filter, offset, limit: selected_page_size });
	}

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	deleteReq = () => {
		this.props.deleteTraining({ _id: this.state.req_delete_id });

		this.setState({ show_delete_msg: false });
	};

	resetFilter = (filter, checked, old_filter_status) => {
		if (checked != undefined) {
			const filter_status = JSON.parse(JSON.stringify(old_filter_status));

			if (filter == "all" && checked)
				this.setState({ filter_all: true, filter_status: [] }, () => this.getPage());
			else {
				const indx = filter_status.indexOf(filter);

				if (indx > -1) filter_status.splice(indx, 1);
				else filter_status.push(filter);

				this.setState({ filter_status, filter_all: filter_status.lengh == 0 }, () => this.getPage());
			}
		}
	};

	renderStatusBadge = (status, style = { fontSize: 13, marginRight: 5 }) => {
		switch (status) {
			case "request":
				return <Badge color="success">Request</Badge>;
			case "accepted":
				return (
					<Badge color="primary" style={style}>
						Accepted
					</Badge>
				);
			case "refused":
				return <Badge style={{ ...style, backgroundColor: "#ff4861" }}>Refused</Badge>;

			default:
				return null;
		}
	};
	render() {
		const { current_page, selected_page_size, filter_all, filter_status, show_delete_msg } = this.state;
		const { trainings, count_all_trainings, user } = this.props;

		return (
			<Card>
				<CardBody>
					<div className="card__title">
						{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
						<Row>
							<Col md="10" style={{ marginBottom: 10 }}>
								<CheckBoxField
									CheckBoxField="all"
									value={filter_all}
									label="Tous"
									name="all"
									onChange={(e) => this.resetFilter("all", e.target?.checked, filter_status)}
									color="primary"
									style={{ display: "inline-block", marginRight: 8 }}
								/>

								{/* <CheckBoxField CheckBoxField="request" value={(filter_status.includes("request"))} label="Request" name="request" onChange={(e) => this.resetFilter("request", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							</Col>
							<Col md="2" style={{ marginBottom: 10 }}>
								<NavLink to={"/app/trainings/add-new"}>
									<Button color="success" className="rounded">
										Ajouter
									</Button>
								</NavLink>
							</Col>
							{/* {user?.roles?.includes('ADMIN') && <Col md="12" style={{ marginBottom: 10 }}>
              <input onChange={(e) => this.changeImportFile(e)} id="add_avatar" type="file" accept=".xlsx, .xls, .csv" />
              <Button color="info" className="rounded" onClick={() => this.importXL()} >Importer</Button>
            </Col>} */}

							{/* {user?.roles?.includes('ADMIN') && <Col md="12" style={{ marginBottom: 10 }}>
              <input onChange={(e) => this.changeImportFile(e)} id="add_avatar_2" type="file" accept=".xlsx, .xls, .csv" />
              <Button color="info" className="rounded" onClick={() => this.importXL("subscribers")} >Importer SWIVI</Button>
            </Col>} */}
						</Row>
					</div>
					<Table responsive hover striped>
						<thead>
							<tr>
								<th>Début</th>
								<th>Duré</th>
								<th>Lieu</th>
								<th>Nb participants</th>
								<th>Référence</th>
								<th>Formateur</th>
								<th>Commercial</th>
								<th>Organisateur</th>
								<th>Type</th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{trainings &&
								trainings.map((training, i) => (
									<tr key={"training_" + i}>
										<td style={{ verticalAlign: "middle" }}>
											<p style={{ margin: 0, color: "#777" }}>
												{moment(training.start_date).format("ddd DD/MM/YYYY")}
											</p>
										</td>

										<td style={{ verticalAlign: "middle" }}>{training.duration}</td>

										<td style={{ verticalAlign: "middle" }}>
											<p style={{ margin: 0 }}>{training.address}</p>
											<p style={{ margin: 0 }}>{training.city + " " + training.region}</p>
										</td>

										<td style={{ textAlign: "center", verticalAlign: "middle" }}>
											{training.count_participants || 0}
										</td>

										<td style={{ verticalAlign: "middle" }}>{training.ref}</td>

										<td style={{ verticalAlign: "middle" }}>
											{training.former &&
												training.former.first_name + " " + training.former.last_name}
										</td>

										<td>
											{training.commercial &&
												training.commercial.first_name + " " + training.commercial.last_name}
										</td>

										<td>
											{training.organizer &&
												training.organizer.first_name + " " + training.organizer.last_name}
										</td>

										<td style={{ verticalAlign: "middle" }}>{training.type}</td>

										<td style={{ width: 60, verticalAlign: "middle" }}>
											<a target="_blank" href={"/app/trainings/preview/" + training._id}>
												<span
													className="lnr lnr-eye mr-2"
													style={{ cursor: "pointer", color: "#4ce1b6", fontSize: 18 }}
												></span>
											</a>
										</td>
										<td style={{ width: 60, verticalAlign: "middle" }}>
											<NavLink to={"/app/trainings/edit/" + training._id}>
												<span
													className="lnr lnr-pencil mr-2"
													style={{ cursor: "pointer", color: "#4CAF50", fontSize: 15 }}
												></span>
											</NavLink>
										</td>
										<td>
											<span
												onClick={() => {
													this.setState({
														req_delete_id: training._id,
														show_delete_msg: true,
													});
												}}
												className="lnr lnr-trash"
												style={{ color: "#F44336", display: "block", marginTop: 12 }}
											></span>
										</td>
									</tr>
								))}
						</tbody>
					</Table>

					<Pagination
						currentPage={current_page}
						totalPage={getCountPages(count_all_trainings, selected_page_size)}
						onChangePage={(i) => this.onChangePage(i)}
					/>

					<ConfirmMdl
						toggle={() =>
							this.setState((prev) => {
								return { show_delete_msg: !prev.show_delete_msg };
							})
						}
						is_open={show_delete_msg}
						accept={() => {
							this.deleteReq();
						}}
					/>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = ({ training, auth }) => {
	const {
		loading_get_trainings,
		trainings,
		count_all_trainings,
		loading_one_training,
		error_one_training,
		loading_delete_training,
		error_delete_training,
	} = training;
	return {
		loading_get_trainings,
		trainings,
		count_all_trainings,
		loading_one_training,
		error_one_training,

		loading_delete_training,
		error_delete_training,
		user: auth.user,
	};
};
export default connect(mapStateToProps, {
	getTrainings,
	editTraining,
	deleteTraining,
})(ListTrainingsCmp);
