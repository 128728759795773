export const AUTHENTICATE         = 'AUTHENTICATE';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_ERROR   = 'AUTHENTICATE_ERROR';
export const LOGOUT               = 'LOGOUT';


// ***** AUTH
export function login(payload) {
  return {type: AUTHENTICATE, payload};
}
export function loginSuccess(payload) {
  return { type: AUTHENTICATE_SUCCESS, payload };
}
export function loginError(error) {
  return { type: AUTHENTICATE_ERROR, error };
}
export function logout() {
  return { type: LOGOUT };
}




// ***** GET USERS
export const GET_USERS            = 'GET_USERS';
export const GET_USERS_SUCCESS    = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR      = 'GET_USERS_ERROR';

export function getUsers(payload) {
  return {type: GET_USERS, payload};
}
export function getUsersSuccess(payload) {
  return { type: GET_USERS_SUCCESS, payload };
}
export function getUsersError(error) {
  return { type: GET_USERS_ERROR, error };
}






// ***** ADD USER
export const ADD_USER            = 'ADD_USER';
export const ADD_USER_SUCCESS    = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR      = 'ADD_USER_ERROR';

export function addUser(payload) {
  return {type: ADD_USER, payload};
}
export function addUserSuccess(payload) {
  return { type: ADD_USER_SUCCESS, payload };
}
export function addUserError(error) {
  return { type: ADD_USER_ERROR, error };
}







// ***** EDIT USER
export const EDIT_USER            = 'EDIT_USER';
export const EDIT_USER_SUCCESS    = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR      = 'EDIT_USER_ERROR';

export function editUser(payload) {
  return {type: EDIT_USER, payload};
}
export function editUserSuccess(payload) {
  return { type: EDIT_USER_SUCCESS, payload };
}
export function editUserError(error) {
  return { type: EDIT_USER_ERROR, error };
}








// ***** GET PROFILE
export const GET_PROFILE            = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS    = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR      = 'GET_PROFILE_ERROR';

export function getProfile(payload) {
  return {type: GET_PROFILE, payload};
}
export function getProfileSuccess(payload) {
  return { type: GET_PROFILE_SUCCESS, payload };
}
export function getProfileError(error) {
  return { type: GET_PROFILE_ERROR, error };
}