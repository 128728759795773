import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListUsersCmp from '../../shared/components/users/list-users';

const ListUsersPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Utilisateurs</h3>
      </Col>

      <Col md={12}>
        <ListUsersCmp />
      </Col>
    </Row>
    <Row>
      
    </Row>
  </Container>
);

export default ListUsersPage;