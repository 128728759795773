import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ListArticlesPage from "./list-articles-page";
import ListArticlesInternalPage from "./list-articles-internal-page";
import ListArticlesSupplyPage from "./list-articles-supply-page";
import AddArticlePage from "./add-article-page";
import EditArticlePage from "./edit-article-page";

const ArticlesRoutingPages = ({ match }) => (
	<Suspense fallback={<div className="loading" />}>
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

			<Route path={`${match.url}/list`} render={(props) => <ListArticlesPage {...props} />} />

			<Route path={`${match.url}/internal`} render={(props) => <ListArticlesInternalPage {...props} />} />

			<Route path={`${match.url}/supply`} render={(props) => <ListArticlesSupplyPage {...props} />} />

			<Route path={`${match.url}/add-new`} render={(props) => <AddArticlePage {...props} />} />

			<Route path={`${match.url}/edit/:articleId`} render={(props) => <EditArticlePage {...props} />} />

			<Redirect from={`${match.url}/`} to={`${match.url}/list`} />
			<Redirect to="/error" />
		</Switch>
	</Suspense>
);
export default ArticlesRoutingPages;
