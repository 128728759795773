import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from "redux-saga";
import { reducer as reduxFormReducer } from 'redux-form';
import { 
  sidebarReducer, themeReducer, rtlReducer, 
  authReducer, categoryReducer, articlesReducer, 
  providersReducer, clientsReducer, purchasesReducer, ordersReducer, 
  shipModesReducer, trainingsReducer, childsReducer
} from './reducers/index';
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  auth: authReducer,
  article: articlesReducer,
  category: categoryReducer,
  provider: providersReducer,
  client:   clientsReducer,
  purchase: purchasesReducer,
  order: ordersReducer,

  shipMode: shipModesReducer,
  training:   trainingsReducer,
  child: childsReducer
});

const store = createStore(reducer, applyMiddleware(...middlewares));

sagaMiddleware.run(sagas);

export default store;
