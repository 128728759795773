import React, { PureComponent } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Layout from "../Layout/index";
import MainWrapper from "./MainWrapper";

import Home from "../Home/index";
import LogIn from "../LogIn/index";
import ProfilePage from "../Profile/index";

import UsersPage from "../Users/index";
import ClientsPage from "../Clients/index";
import CtgsPage from "../Categories/index";
import ArticlesPage from "../Articles/index";

import ProvidersPage from "../Providers/index";
import PurchasesPage from "../Purchases/index";
import OrdersPage from "../Orders/index";
import OrdersTrainsPage from "../OrdersTrain/index";
import OrdersSupply from "../OrdersSupply/index";
import TrainingsPage from "../Trainings/index";
import FollowsPage from "../Follows/index";
import ChildsPage from "../Childs/index";

import LogPage from "../Log/index";

import SettingsPage from "../Settings/index";
import DashRoutingPages from "../Dash/index";

// import VehiclesPage from '../Vehicles/index';

// const Router = (user) => (
//   <MainWrapper>
//     <main>
//       <Switch>
//         <Route exact path="/" component={LogIn} />
//         {user && <Route path="/" component={wrappedRoutes} />}
//       </Switch>
//     </main>dashboard
//   </MainWrapper>
// );

const admin_routes = [
	{ path: "/app/users", component: UsersPage },
	{ path: "/app/settings", component: SettingsPage },

	{ path: "/app/providers", component: ProvidersPage },
	{ path: "/app/purchases", component: PurchasesPage },
	{ path: "/app/trainings", component: TrainingsPage },
	{ path: "/app/follows", component: FollowsPage },
	{ path: "/app/childs", component: ChildsPage },

	{ path: "/app/profile", component: ProfilePage },

	{ path: "/app/clients", component: ClientsPage },
	{ path: "/app/categories", component: CtgsPage },

	{ path: "/app/articles", component: ArticlesPage },

	{ path: "/app/orders", component: OrdersPage },

	{ path: "/app/orders-trains", component: OrdersTrainsPage },
	{ path: "/app/orders-supply", component: OrdersSupply },

	{ path: "/app/log", component: LogPage },
];

const tech_routes = [
	{ path: "/app/profile", component: ProfilePage },

	{ path: "/app/clients", component: ClientsPage },
	{ path: "/app/categories", component: CtgsPage },

	{ path: "/app/articles", component: ArticlesPage },

	{ path: "/app/orders", component: OrdersPage },

	{ path: "/app/orders-trains", component: OrdersTrainsPage },

	{ path: "/app/childs", component: ChildsPage },

	{ path: "/app/log", component: LogPage },
];

const tech_trains = [
	{ path: "/app/clients", component: ClientsPage },
	{ path: "/app/follows", component: FollowsPage },
	{ path: "/app/trainings", component: TrainingsPage },
];

const Pages = (user) => {
	return (
		<Switch>
			<Route path="/app/dashboard" component={DashRoutingPages} />

			{user?.is_tech &&
				tech_routes.map((route, i) => <Route key={i} path={route.path} component={route.component} />)}

			{user?.is_tech_trains &&
				tech_trains.map((route, i) => <Route key={i} path={route.path} component={route.component} />)}

			{user?.is_admin &&
				admin_routes.map((route, i) => <Route key={i} path={route.path} component={route.component} />)}

			<Redirect to="/app/dashboard" />
		</Switch>
	);
};

const wrappedRoutes = (user) => {
	return (
		<div>
			<Layout />
			<div className="container__wrap">
				<Route path="/app" component={() => Pages(user)} />
			</div>
		</div>
	);
};

class Router extends PureComponent {
	// constructor() {
	//   super();
	//   this.state = {};
	// }

	render() {
		const user = this.props.user;
		return (
			<MainWrapper>
				<main>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/login" component={LogIn} />
						{user &&
							(user?.is_admin ||
								user?.is_tech ||
								user?.is_tech_trains ||
								user.roles.includes("TECH_CMD") ||
								user.roles.includes("TECH_CMD_CM")) && (
								<Route path="/" component={() => wrappedRoutes(user)} />
							)}

						<Redirect to="/" />
					</Switch>
				</main>
			</MainWrapper>
		);
	}
}
// export default Router;

const mapStateToProps = ({ auth }) => {
	return {
		user: auth?.user,
	};
};
export default connect(mapStateToProps, {})(Router);
