import React from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ListArticlesCmp from '../../shared/components/articles/list-articles-cmp';

const ListArticlesPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Articles</h3>
      </Col>
      <Col md={12}>
        <ListArticlesCmp only_external={true} />
      </Col>
    </Row>
    <Row>
      
    </Row>
  </Container>
);

export default ListArticlesPage;