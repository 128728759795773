import {
  GET_CLIENTS, GET_CLIENTS_SUCCESS, GET_CLIENTS_ERROR,
  ADD_CLIENT, ADD_CLIENT_SUCCESS, ADD_CLIENT_ERROR,
  GET_ONE_CLIENT, GET_ONE_CLIENT_SUCCESS, GET_ONE_CLIENT_ERROR,
  EDIT_CLIENT, EDIT_CLIENT_SUCCESS, EDIT_CLIENT_ERROR,
  DELETE_CLIENT, DELETE_CLIENT_SUCCESS, DELETE_CLIENT_ERROR
} from '../actions/clientsActions';

const initialState = {
  loading_get_clients: false,
  error_get_clients: null,
  clients: [],
  count_all_clients: 0,

  new_client: null, loading_add_client: false, error_add_client: null,

  selected_client: null, 
  loading_one_client: false, error_one_client: null,
  loading_edit_client: false, error_edit_client: null,

  loading_delete_client: false, error_delete_client: null
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_CLIENTS: return {...state, loading_get_clients: true, error_get_clients: null };
    case GET_CLIENTS_SUCCESS:
      return {...state, clients: action.payload.clients, loading_get_clients: false, error_get_clients: null, count_all_clients: action.payload.count };
    case GET_CLIENTS_ERROR:
      // console.log("ACTION", {error_get_clients: action.error, loading_get_clients: false, clients: []});
      return {...state, error_get_clients: action.error, loading_get_clients: false, clients: [] };



    case ADD_CLIENT: return {...state, loading_add_client: true, error_add_client: null };
    case ADD_CLIENT_SUCCESS:
      return {...state, new_client: action.payload.client, loading_add_client: false, error_add_client: null };
    case ADD_CLIENT_ERROR:
      return {...state, error_add_client: action.error, loading_add_client: false, new_client: null };


    case GET_ONE_CLIENT: return {...state, loading_one_client: true, error_one_client: null };
    case GET_ONE_CLIENT_SUCCESS:
      return {...state, selected_client: action.payload.client, loading_one_client: false, error_one_client: null };
    case GET_ONE_CLIENT_ERROR:
      return {...state, error_one_client: action.error, loading_one_client: false, selected_client: null };



    case EDIT_CLIENT: return {...state, loading_edit_client: true, error_edit_client: null };
    case EDIT_CLIENT_SUCCESS:
      return {...state, selected_client: action.payload.client, loading_edit_client: false, error_edit_client: null };
    case EDIT_CLIENT_ERROR:
      return {...state, error_edit_client: action.error, loading_edit_client: false, selected_client: null };




    case DELETE_CLIENT: return {...state, loading_delete_client: true, error_one_client: null };
    case DELETE_CLIENT_SUCCESS:
      return {...state, loading_delete_client: false, error_delete_client: null };
    case DELETE_CLIENT_ERROR:
      return {...state, error_delete_client: action.error, loading_delete_client: false };


    default: return state;
  }
};

export default clientsReducer;
