import React, { useState, PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { Button, ButtonToolbar, Modal, Row, Col } from "reactstrap";
import classNames from "classnames";
import renderCheckBoxField from "../form/CheckBox";
import Alert from "../../helper/Alert";

import { addUser } from "../../../redux/actions/authActions";

const modalClass = classNames({
	"modal-dialog--colored": false,
	"modal-dialog--header": true,
});

class AddUserMdl extends PureComponent {
	constructor() {
		super();
		this.state = {
			first_name: "",
			last_name: "",
			email: "",
			mobile: "",
			pass: "",
			is_tech: false,
			is_user: false,

			error_frm: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	submit = () => {
		const error_frm = this.checkFrm(this.state);
		this.setState({ error_frm });

		const { only_clients } = this.props;
		if (error_frm == "") {
			const { first_name, last_name, email, mobile, pass, is_tech, is_tech_trains, is_user } = this.state;

			const roles = [];
			if (only_clients) roles.push("USER");
			else {
				if (is_tech) roles.push("TECH");
				if (is_tech_trains) roles.push("TECH_TRAINS");
				if (is_user) roles.push("USER");
			}

			this.props.addUser({ first_name, last_name, email, mobile, pass, roles });
		}
	};
	checkFrm = (data) => {
		const { first_name, last_name, email, mobile, pass, is_tech, is_tech_trains, is_user } = data;
		if (first_name == "" || last_name == "") return "First name and last name are required.";
		if (email == "") return "Email is required.";
		if (!new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/).test(email)) return "Invalid Email.";
		if (mobile == "") return "Mobile is required.";

		const { only_clients } = this.props;
		if (!only_clients && !is_tech && !is_user && !is_tech_trains) return "Please check one of the roles.";

		if (pass == "") return "Password is required.";
		return "";
	};

	componentDidUpdate(prev) {
		const { loading_add_user, error_add_user, new_user } = this.props;

		if (!loading_add_user && prev.loading_add_user) {
			if (error_add_user) {
				if (error_add_user.message && error_add_user.message != "")
					this.setState({ error_frm: error_add_user.message });
			} else this.props.handleSuccess(new_user);
		}
	}

	render() {
		const { only_clients, is_open, size, toggle } = this.props;

		return (
			<Modal
				isOpen={is_open}
				size={size || "lg"}
				toggle={toggle}
				// modalClassName={`${rtl.direction}-support`}
				className={`modal-dialog--primary ${modalClass}`}
			>
				<div className="modal__header">
					{/* <button className="lnr lnr-cross modal__close-btn" type="button" onClick={toggle} />
            {Icon} */}
					<h4 className="text-modal  modal__title">Ajouter un membre</h4>
					{/* {(!only_clients) && <h4 className="text-modal  modal__title">Ajouter un utilisateur / Tech Support</h4>} */}
				</div>
				<div className="modal__body" style={{ padding: "0 10px", textAlign: "left" }}>
					<form
						className="form"
						onSubmit={(e) => {
							e.preventDefault();

							this.submit();
						}}
					>
						<Row>
							<Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
								<Alert
									is_visible={this.state.error_frm != ""}
									color="danger"
									className="alert--colored"
									icon
								>
									<p>
										<span className="bold-text">Attention!</span> {this.state.error_frm}
									</p>
								</Alert>
							</Col>
							<Col md="6">
								<div className="form__form-group">
									<span className="form__form-group-label">First name</span>
									<div className="form__form-group-field">
										<Field
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="first_name"
											component="input"
											placeholder="First name"
											onChange={(e) => this.changeVal("first_name", e.target.value)}
										/>
									</div>
								</div>
							</Col>
							<Col md="6">
								<div className="form__form-group">
									<span className="form__form-group-label">Last name</span>
									<div className="form__form-group-field">
										<Field
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="last_name"
											component="input"
											placeholder="Last name"
											onChange={(e) => this.changeVal("last_name", e.target.value)}
										/>
									</div>
								</div>
							</Col>
							<Col md="12">
								<div className="form__form-group">
									<span className="form__form-group-label">E-mail</span>
									<div className="form__form-group-field">
										<Field
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="email"
											component="input"
											type="email"
											placeholder="example@mail.com"
											onChange={(e) => this.changeVal("email", e.target.value)}
										/>
									</div>
								</div>
							</Col>

							<Col md="12">
								<div className="form__form-group">
									<span className="form__form-group-label">Mobile</span>
									<div className="form__form-group-field">
										<Field
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="mobile"
											component="input"
											placeholder="Mobile"
											onChange={(e) => this.changeVal("mobile", e.target.value)}
										/>
										{/* <button
                        type="button"
                        className={`form__form-group-button${true ? ' active' : ''}`}
                        // onClick={e => this.showPassword(e)}
                      ><span className="lnr lnr-lock" style={{color: "#fff"}}></span>
                      </button> */}
									</div>
								</div>
							</Col>

							<Col md="12">
								<p>Role</p>
								{only_clients && <p>Client</p>}

								{!only_clients && (
									<Row>
										<Col md="3">
											<Field
												style={{ marginRight: 5 }}
												name="is_tech"
												component={renderCheckBoxField}
												onChange={(e) =>
													this.changeVal("is_tech", e && e.target && e.target.checked)
												}
												label="Tech Support  "
												color="#4ce1b6"
												className="colored"
											/>
										</Col>
										<Col md="3">
											<Field
												name="is_user"
												component={renderCheckBoxField}
												onChange={(e) =>
													this.changeVal("is_user", e && e.target && e.target.checked)
												}
												label="Client  "
												color="#4ce1b6"
												className="colored"
											/>
										</Col>
									</Row>
								)}
							</Col>

							<Col md="12">
								<div className="form__form-group">
									<span className="form__form-group-label">Password</span>
									<div className="form__form-group-field">
										<Field
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											name="pass"
											component="input"
											placeholder="Password"
											onChange={(e) => this.changeVal("pass", e.target.value)}
										/>
										<button
											type="button"
											className={`form__form-group-button${true ? " active" : ""}`}
											// onClick={e => this.showPassword(e)}
										>
											<span className="lnr lnr-lock" style={{ color: "#fff" }}></span>
										</button>
									</div>
								</div>
							</Col>
						</Row>

						<div>
							<ButtonToolbar className="modal__footer form__button-toolbar" style={{}}>
								<Button color="primary" type="submit">
									Submit
								</Button>
								<Button type="button" onClick={this.props.toggle}>
									Cancel
								</Button>
							</ButtonToolbar>
						</div>
					</form>
				</div>
				{/* <ButtonToolbar className="modal__footer" style={{padding: '0 5px'}} >
            <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
            <Button type="submit" className="modal_ok" outline color={color} onClick={toggle}>Ajouter</Button>
          </ButtonToolbar> */}
			</Modal>
		);
	}
}

// AddUserMdl.propTypes = {
//   title: PropTypes.string,
//   message: PropTypes.string,
//   color: PropTypes.string.isRequired,
//   colored: PropTypes.bool,
//   header: PropTypes.bool,
//   // rtl: RTLProps.isRequired,
// };

// AddUserMdl.defaultProps = {
//   title: '',
//   message: '',
//   colored: false,
//   header: false,
// };

// export default connect(state => ({
//   rtl: state.rtl,
// }))(AddUserMdl);

const mapStateToProps = ({ auth }) => {
	return {
		form: "log_in_form",
		loading_login: auth.loading_login,
		user: auth.user,
		loading_add_user: auth.loading_add_user,
		error_add_user: auth.error_add_user,
		new_user: auth.new_user,
	};
};
export default connect(mapStateToProps, {
	addUser,
})(reduxForm()(AddUserMdl));
