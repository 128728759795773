import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListClientsCmp from '../../shared/components/clients/list-clients-cmp';

const ListClientsInternalPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Clients <span style={{fontSize: 12}}>( internes )</span> </h3>
      </Col>

      <Col md={12}>
        <ListClientsCmp only_internal={true} />
      </Col>
    </Row>
  </Container>
);

export default ListClientsInternalPage;