import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";

// import PropTypes from "prop-types";

import { Table, Tag, Space, Input, Row, Col, Typography, Select } from "antd";

import { EyeOutlined } from "@ant-design/icons";
import Axios from "axios";
import { api, cities } from "../../../const";

const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;

const columns = [
	{
		title: "Nom Prénom",
		dataIndex: "name",
		key: "name",
		fixed: "left",
		width: 150,

		render: (text, record) => {
			return (
				<Fragment>
					<p style={{ margin: 0 }}>{record.name || ""}</p>
					<p style={{ margin: 0 }}>{record.name_ar || ""}</p>
				</Fragment>
			);
		},
	},
	{
		title: "Tel",
		dataIndex: "phone",
		key: "phone",
		width: 95,

		render: (text, record) => {
			return (
				<Fragment>
					<p style={{ margin: 0 }}>{record.phone || ""}</p>
					<p style={{ margin: 0 }}>{record.phone2 || ""}</p>
				</Fragment>
			);
		},
	},
	{
		title: "Facebook",
		dataIndex: "fb",
		key: "fb",
		width: 150,
	},
	{
		title: "Ville",
		dataIndex: "city",
		key: "city",
		width: 100,
	},
	{
		title: "Région",
		dataIndex: "region",
		key: "region",
		width: 120,
	},
	{
		title: "address",
		key: "address",
		dataIndex: "address",
		// minWidth: 100,
	},
	{
		title: "Enfant actif",
		key: "count_active_children",
		dataIndex: "count_active_children",
		align: "center",
		fixed: "right",
		sorter: true,
		width: 60,

		render: (nb) => {
			let color = "lime";
			if (nb <= 0) color = "volcano";
			else if (nb > 10) color = "green";

			return <Tag color={color}>{nb}</Tag>;
		},
	},
	{
		title: "CMD",
		key: "count_cmd",
		dataIndex: "count_cmd",
		align: "center",
		fixed: "right",
		width: 60,

		sorter: true,

		render: (nb) => {
			let color = "lime";
			if (nb <= 0) color = "volcano";
			else if (nb > 10) color = "green";

			return <Tag color={color}>{nb}</Tag>;
		},
	},
	{
		title: "",
		key: "action",
		align: "center",
		width: 50,

		render: (text, record) => (
			<Space size="middle">
				<NavLink to={`/app/clients/trainer-preview/${record.key}`}>
					<EyeOutlined />
				</NavLink>
			</Space>
		),

		fixed: "right",
	},
];

const ListTrainersCmp = (props) => {
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);
	const [query, setQuery] = useState("");
	const [loading, setLoading] = useState(false);
	const [city, setCity] = useState("");

	const [data, setData] = useState([]);

	const getPage = (page = 1, sort_fields = []) => {
		const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);

		const filter = {
			type: "internal",
		};
		if (query && query !== "") filter["name"] = query;
		if (city && city !== "") filter["city"] = city;
		if (sort_fields?.length) filter["sort"] = sort_fields[0];

		setLoading(true);

		Axios.post(`${api.clients}/trainers/${offset}/${limit}`, filter || {})
			.then((resp) => {
				setLoading(false);

				setCount(resp?.data?.count || 0);

				const new_data = resp?.data?.trainers?.map((doc) => {
					const item = doc?.client || {};
					return {
						name: `${item.first_name} ${item.last_name}`,
						name_ar: `${item.first_name_ar} ${item.last_name_ar}`,
						key: item._id,
						phone: item.phone || "",
						phone2: item.phone2 || "",
						fb: item.fb || "",
						city: item.city || "",
						region: item.region || "",
						address: item.address || "",
						count_active_children: item.count_active_children || 0,

						count_cmd: doc.count_cmd || 0,
					};
				});

				setData(new_data || []);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getPage();
	}, [limit, query, city]);

	return (
		<React.Fragment>
			<Row>
				<Col md={8}>
					<Search
						defaultValue={query}
						placeholder="Recherche"
						// disabled
						onSearch={(text) => setQuery(text)}
						enterButton
						className="mb-2"
					/>
				</Col>
				<Col md={12} className="pl-1">
					<Text type="secondary">Gouvernorat</Text>
					<Select
						showSearch
						defaultValue=""
						style={{ width: "140px", marginLeft: "5px" }}
						value={city}
						onChange={(new_city) => setCity(new_city)}
					>
						<Option value="">Tout</Option>
						{cities.map((city, indx) => (
							<Option value={city.value} key={`city-${indx}`}>
								{city.label}
							</Option>
						))}
					</Select>
				</Col>
			</Row>

			<Table
				columns={columns}
				dataSource={data}
				scroll={{ x: 1000 }}
				loading={loading}
				pagination={{
					total: count,
					pageSize: limit,
					pageSizeOptions: [10, 20, 30],
				}}
				onChange={(pagination, filters, sorter, extra) => {
					const sort_fields = [];
					if (sorter?.column)
						sort_fields.push({
							field: sorter?.field,
							order: sorter?.order === "ascend" ? "asc" : "desc",
						});

					setLimit(pagination?.pageSize || 10);
					getPage(pagination?.current || 1, sort_fields);
				}}
			/>
		</React.Fragment>
	);
};

ListTrainersCmp.prototype = {};
export default ListTrainersCmp;
