const server = process.env.REACT_APP_API;
export const api = {
	root: server,
	users: `${server}/users`,

	ctgs: `${server}/categories`,
	articles: `${server}/articles`,
	providers: `${server}/providers`,
	clients: `${server}/clients`,
	purchases: `${server}/purchases`,
	orders: `${server}/orders`,

	ship_modes: `${server}/ship-modes`,
	trainings: `${server}/trainings`,
	participants: `${server}/participants`,
	childs: `${server}/childs`,
	log: `${server}/log`,
};

export const statuses = [
	{ label: "En attente", value: "pending", key: 0 },
	{ label: "Préparation", value: "preparing", key: 1 },
	{ label: "Préparée", value: "prepared", key: 2 },
	{ label: "Livraison", value: "shipping", key: 3 },
	{ label: "Expédiée", value: "shipped", key: 4 },
	{ label: "Payée", value: "payed", key: 5, only_admin: true },
	{ label: "Retour", value: "return", key: 6, only_admin: true },
	{ label: "Retour stock", value: "return_stock", key: 7, only_admin: true },
	{ label: "Perdue", value: "lost", key: 8, only_admin: true },
];

export const statName = (status) => {
	switch (status) {
		case "pending":
			return "En attente";
		case "preparing":
			return "Préparation";
		case "prepared":
			return "Préparée";
		case "shipping":
			return "Livraison";
		case "shipped":
			return "Expédiée";
		case "payed":
			return "Payée";
		case "return":
			return "Retour";
		case "return_stock":
			return "Retour stock";
		case "lost":
			return "Perdue";
		default:
			return "";
	}
};

export const statusColor = (status) => {
	switch (status) {
		case "pending":
			return "#ec4656";
		case "preparing":
			return "#FF9800";
		case "prepared":
			return "yellow";
		case "shipping":
			return "#007bffd6";
		case "shipped":
			return "#0052ab";
		case "payed":
			return "#28a745";
		case "return":
			return "gray";
		case "lost":
			return "#a3498b";

		default:
			return null;
	}
};

export const shipping_modes = [
	{ label: "Autre", value: "other" },
	{ label: "Poste", value: "post" },
	{ label: "Livreur", value: "delivery_man" },
];

export const pub_url = process.env.PUBLIC_URL;

export const no_product_img = `${process.env.PUBLIC_URL}/img/no_product.png`;
export const no_user_img = `${process.env.PUBLIC_URL}/img/no_user.png`;

export const client_types = [
	{ label: "Interne", value: "internal", key: 0 },
	{ label: "Externe", value: "external", key: 1 },
];
export const cities = [
	{
		label: "Tunis",
		value: "Tunis",
		key: 0,
	},
	{
		label: "Ariana",
		value: "Ariana",
		key: 1,
	},
	{
		label: "Ben Arous",
		value: "Ben Arous",
		key: 2,
	},
	{
		label: "Manouba",
		value: "Manouba",
		key: 3,
	},
	{
		label: "Nabeul",
		value: "Nabeul",
		key: 4,
	},
	{
		label: "Zaghouan",
		value: "Zaghouan",
		key: 5,
	},
	{
		label: "Bizerte",
		value: "Bizerte",
		key: 6,
	},
	{
		label: "Béja",
		value: "Béja",
		key: 7,
	},
	{
		label: "Jendouba",
		value: "Jendouba",
		key: 8,
	},
	{
		label: "Kef",
		value: "Kef",
		key: 9,
	},
	{
		label: "Siliana",
		value: "Siliana",
		key: 10,
	},
	{
		label: "Sousse",
		value: "Sousse",
		key: 11,
	},
	{
		label: "Monastir",
		value: "Monastir",
		key: 12,
	},
	{
		label: "Mahdia",
		value: "Mahdia",
		key: 13,
	},
	{
		label: "Sfax",
		value: "Sfax",
		key: 14,
	},
	{
		label: "Kairouan",
		value: "Kairouan",
		key: 15,
	},
	{
		label: "Kasserine",
		value: "Kasserine",
		key: 16,
	},
	{
		label: "Sidi Bouzid",
		value: "Sidi Bouzid",
		key: 17,
	},
	{
		label: "Gabès",
		value: "Gabès",
		key: 18,
	},
	{
		label: "Mednine",
		value: "Mednine",
		key: 19,
	},
	{
		label: "Tataouine",
		value: "Tataouine",
		key: 20,
	},
	{
		label: "Gafsa",
		value: "Gafsa",
		key: 21,
	},
	{
		label: "Tozeur",
		value: "Tozeur",
		key: 22,
	},
	{
		label: "Kebili",
		value: "Kebili",
		key: 23,
	},
];

export const client_statuses = [
	{
		label: "Active",
		value: "active",
		key: 0,
	},
	{
		label: "Non active",
		value: "not_active",
		key: 1,
	},
	{
		label: "En cours",
		value: "in_progress",
		key: 2,
	},
	{
		label: "Retiré",
		value: "removed",
		key: 3,
	},
];

export const renderActivityStatus = (activities = []) => {
	const status = activities[0]?.status;
	switch (status) {
		case "not_active":
			return "Pas actif";
		case "active":
			return "Actif";
		case "in_progress":
			return "En cours";
		case "out":
			return "Out";
	}

	return "";
};

export const activityStatus = (status = "") => {
	switch (status) {
		case "not_active":
			return {
				name: "Pas actif",
				color: "red",
			};
		case "active":
			return {
				name: "Actif",
				color: "green",
			};
		case "in_progress":
			return {
				name: "En cours",
				color: "#faad14",
			};
		case "out":
			return {
				name: "Out",
				color: "grey",
			};
	}

	return {
		name: "",
		color: "",
	};
};

export const subscription_statuses = [
	{
		label: "Non inscrit",
		value: "no_request",
		key: 0,
	},
	{
		label: "Invité",
		value: "requested",
		key: 1,
	},
	{
		label: "Inscrit",
		value: "joined",
		key: 2,
	},
];

// export const formers = [{
//   label: 'Formateur 1', value: 'Formateur 1', first_name: "Formateur 1", last_name: "Formateur 1", key: 0
// },{
//   label: 'Formateur 2', value: 'Formateur 2', first_name: "Formateur 2", last_name: "Formateur 2", key: 1
// },{
//   label: 'Autre', value: 'Autre', first_name: "Autre", last_name: "Autre", key: 2
// }]

export const article_levels = [
	{
		index: 1,
		sub_indexes: [
			{
				sub_index: 1,
				name: "M1",
				count: 0,
				color: "#ffd5d6",
			},
			{
				sub_index: 2,
				name: "M2",
				count: 0,
				color: "#ffe074",
			},
			{
				sub_index: 3,
				name: "C1",
				count: 0,
				color: "#a6ffa3",
			},
			{
				sub_index: 4,
				name: "S1",
				count: 0,
				color: "#baedff",
			},
		],
	},
	{
		index: 2,
		sub_indexes: [
			{
				sub_index: 1,
				name: "C2",
				count: 0,
				color: "#dae4c1",
			},
			{
				sub_index: 2,
				name: "S2",
				count: 0,
				color: "#e5c1ff",
			},
		],
	},
	{
		index: 3,
		sub_indexes: [
			{
				sub_index: 1,
				name: "S3",
				count: 0,
				color: "#fb8c90",
			},
		],
	},
	{
		index: 4,
		sub_indexes: [
			{
				sub_index: 1,
				name: "S4",
				count: 0,
				color: "#ffc40f",
			},
		],
	},
	{
		index: 5,
		sub_indexes: [
			{
				sub_index: 1,
				name: "S5",
				count: 0,
				color: "#0593f9",
			},
		],
	},
	{
		index: 6,
		sub_indexes: [
			{
				sub_index: 1,
				name: "S6",
				count: 0,
				color: "#ff5e59",
			},
		],
	},
];
export const levels = [
	{
		label: "Préparatoire",
		value: "0",
		key: 0,
	},
	{
		label: "1ère année",
		value: "1",
		key: 1,
	},
	{
		label: "2ième année",
		value: "2",
		key: 1,
	},
	{
		label: "3ième année",
		value: "3",
		key: 1,
	},
	{
		label: "4ième année",
		value: "4",
		key: 1,
	},
	{
		label: "5ième année",
		value: "5",
		key: 1,
	},
	{
		label: "6ième année",
		value: "6",
		key: 1,
	},
	{
		label: "7ième année",
		value: "7",
		key: 1,
	},
	{
		label: "8ième année",
		value: "8",
		key: 1,
	},
	{
		label: "9ième année",
		value: "9",
		key: 1,
	},
];

export const training_types = [
	{ label: "Interne", value: "internal", key: 0 },
	{ label: "Externe", value: "external", key: 1 },
];

// npm install -g sass
// npm install --save-dev sass
