import React, { PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { Field, reduxForm } from 'redux-form';

import {
	Button,
	ButtonToolbar,
	Card,
	CardBody,
	Row,
	Col,
	//Nav, NavItem, TabContent, TabPane, Table, Input
} from "reactstrap";
// import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";

import { NavLink } from "react-router-dom";

import { addChild, editChild, getOneChild } from "../../../redux/actions/childsActions";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
// import Switch from "rc-switch";
// import "rc-switch/assets/index.css";

// import classnames from 'classnames';
import { levels } from "../../../const";
import SelectClient from "../clients/SelectClient";

class ChildCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			first_name: "",
			last_name: "",

			first_name_ar: "",
			last_name_ar: "",

			age: 0,
			education_level: "",
			registration_date: new Date(),
			former: null,

			show_select_client: false,
			client_data: {
				name: "",
				city: "",
				region: "",
				phone: "",
				address: "",
				type: "",
			},

			error_frm: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	resetClientData = () =>
		this.setState({
			client_data: {
				name: "",
				city: "",
				region: "",
				phone: "",
				address: "",
				type: "",
			},
		});

	submit = () => {
		const error_frm = this.checkFrm(this.state);
		this.setState({ error_frm });

		const { addChild, editChild, editing } = this.props;
		const { first_name, last_name, first_name_ar, last_name_ar, age, education_level, registration_date, former } =
			this.state;

		if (error_frm === "") {
			const data = {
				first_name,
				last_name,
				first_name_ar,
				last_name_ar,
				age,
				education_level,
				registration_date,
				former: this.props.former ? this.props.former?._id : former?._id,
			};

			if (editing) editChild({ _id: this.props.child_id, data });
			else addChild(data);
		}
	};
	checkFrm = (data) => {
		const { first_name_ar, last_name_ar, age, former, education_level } = data;
		if (first_name_ar === "") return "Le nom (ar) est requis.";
		if (last_name_ar === "") return "Le prénom (ar) est requis.";
		if (!age) return "L'age est requis.";

		if (!this.props.former && (!former || former === "")) return "Le formateur est requis.";

		if (!education_level || education_level === "") return "Le niveau éducatif est requis.";
		return "";
	};

	componentDidUpdate(prev) {
		const {
			loading_add_child,
			error_add_child,
			new_child,
			selected_child,
			loading_one_child,
			error_one_child,
			loading_edit_child,
			error_edit_child,
			add_to_article,
			addToArticle,
		} = this.props;

		if (!loading_add_child && prev.loading_add_child) {
			// console.log("error_add_child", error_add_child, new_child);
			if (error_add_child) {
				if (error_add_child.message && error_add_child.message !== "")
					this.setState({ error_frm: error_add_child.message });
			} else {
				if (add_to_article && addToArticle) addToArticle(new_child);
				else this.props.history.push("/app/childs/edit/" + new_child._id);
			}
		}

		// EDITING
		if (!loading_edit_child && prev.loading_edit_child) {
			// console.log("error_add_child", error_add_child, new_child);
			if (error_edit_child) {
				if (error_edit_child.message && error_edit_child.message !== "")
					this.setState({ error_frm: error_edit_child.message });
			} else this.props.history.push("/app/childs/childs");
		}

		//GET ONE
		if (prev.loading_one_child && !loading_one_child && !error_one_child) {
			if (selected_child) {
				const {
					first_name,
					last_name,
					first_name_ar,
					last_name_ar,
					age,
					education_level,
					registration_date,
					former,
				} = selected_child;

				this.setState({
					first_name,
					last_name,
					first_name_ar,
					last_name_ar,
					age,
					education_level,
					registration_date,
					former,
				});
			}
		}
	}

	componentDidMount() {
		if (this.props.editing) {
			const { match, getOneChild } = this.props;
			if (match && match.params) getOneChild({ child_id: match.params.childId });
		}
	}

	toggleSelectClientdMdl = () =>
		this.setState((prev) => {
			return { show_select_client: !prev.show_select_client };
		});

	render() {
		const { toggleOpen, add_to_article } = this.props;
		const {
			first_name,
			last_name,
			first_name_ar,
			last_name_ar,
			age,
			education_level,
			former,
			show_select_client,
			client_data,
		} = this.state;

		return (
			<div>
				<Card>
					<CardBody>
						<Row className="form">
							<Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
								<Alert
									is_visible={this.state.error_frm !== ""}
									color="danger"
									className="alert--colored"
									icon
								>
									<p>
										<span className="bold-text">Attention!</span> {this.state.error_frm}
									</p>
								</Alert>
							</Col>

							{/* NAMES */}
							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">Nom</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											value={first_name}
											component="input"
											onChange={(e) => this.changeVal("first_name", e.target.value)}
										/>
									</div>
								</div>
							</Col>
							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">Prénom</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											value={last_name}
											component="input"
											onChange={(e) => this.changeVal("last_name", e.target.value)}
										/>
									</div>
								</div>
							</Col>
							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">الإسم</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											value={first_name_ar}
											component="input"
											onChange={(e) => this.changeVal("first_name_ar", e.target.value)}
										/>
									</div>
								</div>
							</Col>
							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">اللقب</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											value={last_name_ar}
											component="input"
											onChange={(e) => this.changeVal("last_name_ar", e.target.value)}
										/>
									</div>
								</div>
							</Col>

							{/* Age */}
							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">Age</span>
									<div className="form__form-group-field">
										<input
											style={{ width: "100%", border: "none", borderBottom: "1px solid #ddd" }}
											type="number"
											min="0"
											value={age}
											component="input"
											onChange={(e) => this.changeVal("age", e.target.value)}
										/>
									</div>
								</div>
							</Col>

							<Col md="3">
								<div className="form__form-group">
									<span className="form__form-group-label">Niveau scolaire</span>
									<div className="form__form-group-field">
										<Select
											defaultValue={education_level}
											value={levels.filter((option) => option.value === education_level)}
											onChange={(e) => this.changeVal("education_level", e.value)}
											options={levels}
											clearable={false}
											className="react-select"
											placeholder="Niveau scolaire"
											classNamePrefix="react-select"
										/>
									</div>
								</div>
							</Col>

							{/* <Col md="3" className="mt-2">
              <span className="form__form-group-label">Date d'inscription</span>
              <br />
              <DatePicker selected={new Date(registration_date)}
                onChange={(e) => this.changeVal('registration_date', e)}
                placeholderText="Date d'inscription"
              />
            </Col> */}

							{this.props.former ? (
								<Col md="6">
									{this.props.former &&
									this.props.former.first_name_ar &&
									this.props.former.first_name_ar != ""
										? this.props.former.first_name_ar
										: this.props.former?.first_name}
								</Col>
							) : (
								<Col md="6">
									<div className="form__form-group">
										<span className="form__form-group-label">Formateur</span>
										<div
											onClick={() => this.setState({ show_select_client: true })}
											className="form__form-group-field"
										>
											<input
												readOnly
												style={{ border: "none", borderBottom: "1px solid #ddd" }}
												component="input"
												placeholder="Formateur"
												value={
													former && former.first_name_ar && former.first_name_ar !== ""
														? former.first_name_ar
														: former?.first_name
												}
											/>
										</div>
									</div>
								</Col>
							)}
						</Row>

						<div className="mt-5" style={{ textAlign: "right" }}>
							<ButtonToolbar
								className="modal__footer form__button-toolbar"
								style={{ textAlign: "right" }}
							>
								<Button color="primary" onClick={this.submit}>
									Soumettre
								</Button>
								{add_to_article && toggleOpen ? (
									<Button onClick={() => toggleOpen()} type="button">
										Annuler
									</Button>
								) : (
									<NavLink to="/app/childs">
										<Button type="button">Retour</Button>
									</NavLink>
								)}
							</ButtonToolbar>
						</div>
					</CardBody>
				</Card>

				{/* MODALS */}
				<SelectClient
					size="lg"
					multi={false}
					name={client_data.name}
					city={client_data.city}
					region={client_data.region}
					phone={client_data.phone}
					address={client_data.address}
					type={client_data.type}
					first_name={client_data.first_name}
					first_name_ar={client_data.first_name_ar}
					last_name={client_data.last_name}
					last_name_ar={client_data.last_name_ar}
					changeVal={(key, val) => {
						this.setState((prev) => {
							const client_data = prev.client_data;
							client_data[key] = val;

							return { client_data: { ...client_data } };
						});
					}}
					isOpen={show_select_client}
					toggleOpen={this.toggleSelectClientdMdl}
					accept={(data) => {
						if (data && data[0])
							this.setState({
								former: data[0],
								show_select_client: false,
								client_data: this.resetClientData,
							});
					}}
					selectedItems={former ? [former] : []}
					is_training_order={true}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ child }) => {
	const {
		loading_add_child,
		error_add_child,
		new_child,
		selected_child,
		loading_one_child,
		error_one_child,
		loading_edit_child,
		error_edit_child,
	} = child;
	return {
		new_child,
		selected_child,
		loading_add_child,
		error_add_child,
		loading_one_child,
		error_one_child,
		loading_edit_child,
		error_edit_child,
	};
};
export default connect(mapStateToProps, {
	addChild,
	editChild,
	getOneChild,
})(ChildCmp);
