import React from 'react';

const bg = `${process.env.PUBLIC_URL}/img/construction.png`;
const logog = `${process.env.PUBLIC_URL}/img/logo.png`;

const Home = () => (
  <div style={{height: "100vh", background: "#1b2b39", textAlign: "center"}}>
    <img src={logog} style={{width: "230px", margin: "20px 0px" }} />
    <img src={bg} style={{width: "100%", }} />
  </div>
);

export default Home;