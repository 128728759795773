import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

// import { Table, Space, Tag, Tooltip } from "antd";

// import { EyeOutlined } from "@ant-design/icons";
import Axios from "axios";
import { api, article_levels } from "../../../../const";
import "./trainer.scss";
// import moment from "moment";

import "./trainer.scss";

// const status_style = {
// 	textAlign: "center",
// 	width: "40px",
// 	height: "40px",
// 	padding: "4px",
// 	fontSize: "22px",
// 	background: "#fafafa",
// 	border: "1px solid #d9d9d9",
// };

const TrainerChildsResum = (props) => {
	const { client_id } = props;

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [counts, setCounts] = useState({});

	const getData = () => {
		// const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);

		setLoading(true);
		Axios.post(`${api.childs}/resume`, { former: client_id })
			.then((resp) => {
				setLoading(false);
				const new_obj = {};
				// const obj = sortObj(resp?.data?.childs || {});
				const obj = resp?.data?.childs || {};
				let count_level_1 = {
					valid: 0,
					in_progress: 0,
					breakup: 0,
				};
				let count_level_2 = {
					valid: 0,
					in_progress: 0,
					breakup: 0,
				};
				let count_level_3 = {
					valid: 0,
					in_progress: 0,
					breakup: 0,
				};
				let count_level_4 = {
					valid: 0,
					in_progress: 0,
					breakup: 0,
				};
				let count_level_5 = {
					valid: 0,
					in_progress: 0,
					breakup: 0,
				};
				let count_level_6 = {
					valid: 0,
					in_progress: 0,
					breakup: 0,
				};
				for (const level_name in obj) {
					if (Object.hasOwnProperty.call(obj, level_name)) {
						const arr = obj[level_name];
						new_obj[level_name] = {};
						new_obj[level_name]["count"] = arr?.length || 0;
						new_obj[level_name]["count_in_progress"] =
							arr.filter((item) => item.status === "in_progress")?.length || 0;
						new_obj[level_name]["count_valid"] = arr.filter((item) => item.status === "valid")?.length || 0;
						new_obj[level_name]["count_breakup"] =
							arr.filter((item) => item.status === "breakup")?.length || 0;

						if (["1-1", "1-2", "1-3", "1-4"].includes(level_name)) {
							count_level_1.in_progress += new_obj[level_name].count_in_progress;
							count_level_1.valid += new_obj[level_name].count_valid;
							count_level_1.breakup += new_obj[level_name].count_breakup;
						} else if (["2-1", "2-2"].includes(level_name)) {
							count_level_2.in_progress += new_obj[level_name].count_in_progress;
							count_level_2.valid += new_obj[level_name].count_valid;
							count_level_2.breakup += new_obj[level_name].count_breakup;
						} else if (level_name === "3-1") {
							count_level_3.in_progress += new_obj[level_name].count_in_progress;
							count_level_3.valid += new_obj[level_name].count_valid;
							count_level_3.breakup += new_obj[level_name].count_breakup;
						} else if (level_name === "4-1") {
							count_level_4.in_progress += new_obj[level_name].count_in_progress;
							count_level_4.valid += new_obj[level_name].count_valid;
							count_level_4.breakup += new_obj[level_name].count_breakup;
						} else if (level_name === "5-1") {
							count_level_5.in_progress += new_obj[level_name].count_in_progress;
							count_level_5.valid += new_obj[level_name].count_valid;
							count_level_5.breakup += new_obj[level_name].count_breakup;
						} else if (level_name === "6-1") {
							count_level_6.in_progress += new_obj[level_name].count_in_progress;
							count_level_6.valid += new_obj[level_name].count_valid;
							count_level_6.breakup += new_obj[level_name].count_breakup;
						}
					}
				}
				// const new_data = resp?.data?.childs?.map((item) => {
				// 	return {
				// 		...item,
				// 		name: `${item.first_name} ${item.last_name}`,
				// 		name_ar: `${item.first_name_ar} ${item.last_name_ar}`,
				// 		key: item?._id,
				// 	};
				// });

				setData(new_obj || {});
				setCounts({
					count_level_1,
					count_level_2,
					count_level_3,
					count_level_4,
					count_level_5,
					count_level_6,
				});
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	};
	// const sortObj = (obj) => {
	//   return Object.keys(obj).sort().reduce(function (result, key) {
	//     result[key] = obj[key];
	//     return result;
	//   }, {});
	// }

	useEffect(() => {
		if (client_id) getData();
	}, [client_id]);

	if (loading) return "loading..";
	return (
		<React.Fragment>
			<table className="tbl-club-cm">
				<tbody>
					<tr className="levels-row">
						<td colSpan="12">Niveau 1</td>
						<td className="separation-cl"></td>
						<td colSpan="6">Niveau 2</td>
						<td className="separation-cl"></td>
						<td colSpan="3">Niveau 3</td>
						<td className="separation-cl"></td>
						<td colSpan="3">Niveau 4</td>
						<td className="separation-cl"></td>
						<td colSpan="3">Niveau 5</td>
						<td className="separation-cl"></td>
						<td colSpan="3">Niveau 6</td>
					</tr>

					<tr>
						<td colSpan="3">M1</td>
						<td colSpan="3">M2</td>
						<td colSpan="3">C1</td>
						<td colSpan="3">S1</td>
						<td className="separation-cl"></td>
						<td colSpan="3">C2</td>
						<td colSpan="3">S2</td>
						<td className="separation-cl"></td>
						<td colSpan="3">S3</td>
						<td className="separation-cl"></td>
						<td colSpan="3">S4</td>
						<td className="separation-cl"></td>
						<td colSpan="3">S5</td>
						<td className="separation-cl"></td>
						<td colSpan="3">S6</td>
					</tr>

					{/* DATA */}
					<tr>
						<td colSpan="3">{data["1-1"]?.count || 0}</td>
						<td colSpan="3">{data["1-2"]?.count || 0}</td>
						<td colSpan="3">{data["1-3"]?.count || 0}</td>
						<td colSpan="3">{data["1-4"]?.count || 0}</td>
						<td className="separation-cl"></td>
						<td colSpan="3">{data["2-1"]?.count || 0}</td>
						<td colSpan="3">{data["2-2"]?.count || 0}</td>
						<td className="separation-cl"></td>
						<td colSpan="3">{data["3-1"]?.count || 0}</td>
						<td className="separation-cl"></td>
						<td colSpan="3">{data["4-1"]?.count || 0}</td>
						<td className="separation-cl"></td>
						<td colSpan="3">{data["5-1"]?.count || 0}</td>
						<td className="separation-cl"></td>
						<td colSpan="3">{data["6-1"]?.count || 0}</td>
					</tr>
					<tr>
						<td className="in_progress">{data["1-1"]?.count_in_progress || 0}</td>
						<td className="valid">{data["1-1"]?.count_valid || 0}</td>
						<td className="breakup">{data["1-1"]?.count_breakup || 0}</td>

						<td className="in_progress">{data["1-2"]?.count_in_progress || 0}</td>
						<td className="valid">{data["1-2"]?.count_valid || 0}</td>
						<td className="breakup">{data["1-2"]?.count_breakup || 0}</td>

						<td className="in_progress">{data["1-3"]?.count_in_progress || 0}</td>
						<td className="valid">{data["1-3"]?.count_valid || 0}</td>
						<td className="breakup">{data["1-3"]?.count_breakup || 0}</td>

						<td className="in_progress">{data["1-4"]?.count_in_progress || 0}</td>
						<td className="valid">{data["1-4"]?.count_valid || 0}</td>
						<td className="breakup">{data["1-4"]?.count_breakup || 0}</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{data["2-1"]?.count_in_progress || 0}</td>
						<td className="valid">{data["2-1"]?.count_valid || 0}</td>
						<td className="breakup">{data["2-1"]?.count_breakup || 0}</td>

						<td className="in_progress">{data["2-2"]?.count_in_progress || 0}</td>
						<td className="valid">{data["2-2"]?.count_valid || 0}</td>
						<td className="breakup">{data["2-2"]?.count_breakup || 0}</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{data["3-1"]?.count_in_progress || 0}</td>
						<td className="valid">{data["3-1"]?.count_valid || 0}</td>
						<td className="breakup">{data["3-1"]?.count_breakup || 0}</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{data["4-1"]?.count_in_progress || 0}</td>
						<td className="valid">{data["4-1"]?.count_valid || 0}</td>
						<td className="breakup">{data["4-1"]?.count_breakup || 0}</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{data["5-1"]?.count_in_progress || 0}</td>
						<td className="valid">{data["5-1"]?.count_valid || 0}</td>
						<td className="breakup">{data["5-1"]?.count_breakup || 0}</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{data["6-1"]?.count_in_progress || 0}</td>
						<td className="valid">{data["6-1"]?.count_valid || 0}</td>
						<td className="breakup">{data["6-1"]?.count_breakup || 0}</td>
					</tr>
					<tr>
						<td className="in_progress" colSpan="4">
							{counts.count_level_1?.in_progress || 0}
						</td>
						<td className="valid" colSpan="4">
							{counts.count_level_1?.valid || 0}
						</td>
						<td className="breakup" colSpan="4">
							{counts.count_level_1?.breakup || 0}
						</td>

						<td className="separation-cl"></td>

						<td className="in_progress" colSpan="2">
							{counts.count_level_2?.in_progress || 0}
						</td>
						<td className="valid" colSpan="2">
							{counts.count_level_2?.valid || 0}
						</td>
						<td className="breakup" colSpan="2">
							{counts.count_level_2?.breakup || 0}
						</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{counts.count_level_3?.in_progress || 0}</td>
						<td className="valid">{counts.count_level_3?.valid || 0}</td>
						<td className="breakup">{counts.count_level_3?.breakup || 0}</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{counts.count_level_4?.in_progress || 0}</td>
						<td className="valid">{counts.count_level_4?.valid || 0}</td>
						<td className="breakup">{counts.count_level_4?.breakup || 0}</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{counts.count_level_5?.in_progress || 0}</td>
						<td className="valid">{counts.count_level_5?.valid || 0}</td>
						<td className="breakup">{counts.count_level_5?.breakup || 0}</td>

						<td className="separation-cl"></td>

						<td className="in_progress">{counts.count_level_6?.in_progress || 0}</td>
						<td className="valid">{counts.count_level_6?.valid || 0}</td>
						<td className="breakup">{counts.count_level_6?.breakup || 0}</td>
					</tr>
				</tbody>
			</table>
		</React.Fragment>
	);
};

TrainerChildsResum.prototype = {
	client_id: PropTypes.func.isRequired,
};
export default TrainerChildsResum;
