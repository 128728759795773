import React, { useState, useEffect, Fragment } from "react";
// import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import {
	Card,
	Skeleton,
	Row,
	Col,
	Avatar,
	Button,
	Tag,
	Space,
	Table,
	Tooltip,
	Badge,
	Switch,
	Input,
	Popconfirm,
} from "antd";

import {
	// Table as StrapTable,
	Label,
} from "reactstrap";

import {
	EditOutlined,
	CheckOutlined,
	SettingOutlined,
	UserOutlined,
	DeleteOutlined,
	ForwardOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { api, article_levels } from "../../../../const";
import "./child.scss";
import moment from "moment";
// import EditLevelTable from "../../articles/edit-level-table/EditLevelTable";
import SelectLevelMdl from "./SelectLevelMdl";
import ChooseNextLevelCmp from "./ChooseNextLevelCmp";

const { Meta } = Card;

const status_style = {
	textAlign: "center",
	width: "40px",
	height: "40px",
	padding: "4px",
	fontSize: "22px",
	background: "#fafafa",
	border: "1px solid #d9d9d9",
};

const ChildPreviewCmp = (props) => {
	const { child_id, changeName } = props;

	const [loading, setLoading] = useState(false);
	const [child, setChild] = useState(null);
	// const [active_tab, setActiveTab] = useState("cmd_cm");
	const [edit_level, setEditLevel] = useState(false);

	const [loading_save, setLoadingSave] = useState(false);

	const [levels, setLevels] = useState([]);
	const [selected_level, stSelectedLevel] = useState(null);
	const [show_select_level_mdl, setShowSelectLevelMdl] = useState(false);
	const [show_choose_next_level, setShowChooseNextLevel] = useState(false);

	const [is_edit_mode, setIsEditMode] = useState(false);
	const [first_name, setFirstName] = useState("");
	const [last_name, setLastName] = useState("");
	const [first_name_ar, setFirstNameAr] = useState("");
	const [last_name_ar, setLastNameAr] = useState("");
	const [age, setAge] = useState(0);
	const [is_draft, setIsDraft] = useState(false);

	const history_columns = [
		{
			title: "Niveau",
			dataIndex: "index",
			key: "index",
			width: 80,

			render: (text, record) => {
				try {
					return (
						<div>
							<p>Niveau {record.index_level}</p>
							<p style={{ fontWeight: "bold" }}>
								{article_levels[record.index_level - 1].sub_indexes[record.index_sub_level - 1].name}
							</p>
						</div>
					);
				} catch (error) {
					console.log(record, error);
					return null;
				}
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 80,

			render: (text, record) => {
				switch (text) {
					case "in_progress":
						return "En cours";
					case "valid":
						return (
							<Tag color="success">
								Validé{" "}
								{record?.validation_date ? moment(record.validation_date).format("DD-MM-YYYY") : ""}
							</Tag>
						);

					case "breakup":
						return (
							<Tag color="default">
								Rupture {record?.breakup_date ? moment(record.breakup_date).format("DD-MM-YYYY") : ""}
							</Tag>
						);

					default:
						return "";
				}
			},
		},
		{
			title: "Inscrit",
			dataIndex: "registration_date",
			key: "registration_date",
			width: 80,

			render: (text, record) => {
				return (
					<Fragment>
						{text ? (
							<Tag color="warning">
								{record?.registration_date ? moment(record.registration_date).format("DD-MM-YYYY") : ""}
							</Tag>
						) : null}
					</Fragment>
				);
			},
		},

		{
			title: "",
			key: "action",
			align: "center",
			width: 50,

			render: (text, record) => (
				<Space size="middle">
					<EditOutlined
						style={{ color: "#1890ff" }}
						onClick={() => {
							stSelectedLevel(record);
							setShowSelectLevelMdl(true);
						}}
					/>
					<DeleteOutlined
						style={{ color: "#ff4d4f" }}
						onClick={() => {
							try {
								const new_levels = [...levels];
								for (let indx = 0; indx < new_levels.length; indx++) {
									if (
										new_levels[indx].index_level === record.index_level &&
										new_levels[indx].index_sub_level === record.index_sub_level
									) {
										new_levels.splice(indx, 1);
										break;
									}
								}

								setLevels(sortLevels(new_levels));
							} catch (error) {}
						}}
					/>
				</Space>
			),

			fixed: "right",
		},
	];

	const getOne = () => {
		setLoading(true);

		Axios.get(`${api.childs}/get-one/${child_id}`)
			.then((resp) => {
				setLoading(false);
				const new_child = resp?.data?.child;
				setChild(new_child);

				// setNewLavel(new_child.index_level);
				// setNewSubLavel(new_child.index_sub_level);

				changeName(
					`${new_child.first_name} ${new_child.last_name} ( ${new_child.first_name_ar} ${new_child.last_name_ar} )`
				);
				
				setLevels(sortLevels(new_child.levels));
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const submitEditInfos = () => {
		setLoading(true);

		Axios.patch(`${api.childs}/edit-one/${child_id}`, {
			is_draft,
			first_name,
			last_name,
			first_name_ar,
			last_name_ar,
			age,
		})
			.then((resp) => {
				getOne();
				setIsEditMode(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getOne();
	}, [child_id]);

	const addLevel = (level) => {
		setLevels(sortLevels([...levels, level]));
	};

	const sortLevels = (levels = []) => {
		return levels.sort((a, b) =>
			a.index_level > b.index_level || (a.index_level === b.index_level && a.index_sub_level > b.index_sub_level)
				? 1
				: -1
		);
	};

	const rmDuplication = (levels = []) => {
		return levels.filter((item, indx) => {
			for (let j = indx + 1; j < levels.length; j++) {
				const e = levels[j];
				// if (e.index_level + e.index_sub_level === item.index_level + item.index_sub_level) return false;
				if (e.index_level === item.index_level && e.index_sub_level === item.index_sub_level) return false;
			}

			return true;
		});
	};
	// if (!child) return null;

	const saveNewLevels = () => {
		setLoadingSave(true);
		const default_level = {
			index_level: 1,
			index_sub_level: 1,

			status: "in_progress",
			validation_date: null,
			breakup_date: null,
			registration_date: null,
		};

		const maped_levels = levels.map((lv) => {
			if (lv._id) delete lv._id;
			return {
				...lv,
				validation_date: lv.validation_date ? moment(lv.validation_date).format("YYYY-MM-DD") : null,
				breakup_date: lv.breakup_date ? moment(lv.breakup_date).format("YYYY-MM-DD") : null,
				registration_date: lv.registration_date ? moment(lv.registration_date).format("YYYY-MM-DD") : null,
			};
		});

		Axios.patch(
			`${api.childs}/edit-one/${child_id}`,
			levels.length
				? {
						levels: maped_levels,
						...maped_levels[maped_levels?.length - 1],
				  }
				: {
						levels: [default_level],
						...default_level,
				  }
		)
			.then((resp) => {
				setLoadingSave(false);
				setEditLevel(false);
				getOne();
			})
			.catch((err) => {
				setLoadingSave(false);
				setEditLevel(false);
				getOne();
			});
	};

	const upgradeLevel = (upgrading_level) => {
		if (!child || !upgrading_level) return false;

		setLoadingSave(true);
		const sorted_levels = sortLevels(
			child?.levels?.map((item) => {
				if (item._id) delete item._id;
				return {
					...item,
				};
			}) || []
		);

		const new_level = {
			index_level: upgrading_level.index_level,
			index_sub_level: upgrading_level.index_sub_level,

			status: "in_progress",
			validation_date: null,
			breakup_date: null,
			registration_date: moment().format("YYYY-MM-DD"),
		};

		const last_level = sorted_levels[sorted_levels.length - 1];
		if (last_level && last_level.status !== "valid") {
			last_level.status = "valid";
			last_level.validation_date = moment().format("YYYY-MM-DD");
		}

		sorted_levels.push(new_level);

		Axios.patch(`${api.childs}/edit-one/${child_id}`, {
			levels: sorted_levels,
			...sorted_levels[sorted_levels?.length - 1],
		})
			.then((resp) => {
				setLoadingSave(false);
				setEditLevel(false);
				getOne();
			})
			.catch((err) => {
				setLoadingSave(false);
				setEditLevel(false);
				getOne();
			});
	};

	const validateCurrentLevel = () => {
		if (!child) return false;

		setLoadingSave(true);
		const sorted_levels = sortLevels(
			child?.levels?.map((item) => {
				if (item._id) delete item._id;
				return {
					...item,
				};
			}) || []
		);

		const last_level = sorted_levels[sorted_levels.length - 1];

		if (last_level) {
			last_level.status = "valid";
			last_level.validation_date = moment().format("YYYY-MM-DD");

			Axios.patch(`${api.childs}/edit-one/${child_id}`, {
				levels: sorted_levels,
				...last_level,
			})
				.then((resp) => {
					setLoadingSave(false);
					setEditLevel(false);
					getOne();
				})
				.catch((err) => {
					setLoadingSave(false);
					setEditLevel(false);
					getOne();
				});
		}
	};

	const renderStatus = (text, record) => {
		switch (text) {
			case "in_progress":
				return (
					<div
						style={{
							...status_style,
							color: "#faad14",
							background: "#fffbe6",
							border: "1px solid #ffe58f",
						}}
					>
						C
					</div>
				);
			case "valid":
				return (
					<div
						style={{
							...status_style,
							color: "#52c41a",
							background: "#f6ffed",
							border: "1px solid #b7eb8f",
						}}
					>
						V
					</div>

					// <Tag color="success" style={{ margin: "auto" }}>
					// 	Validé {record?.validation_date ? moment(record.validation_date).format("DD-MM-YYYY") : ""}
					// </Tag>
				);

			case "breakup":
				return (
					<div style={status_style}>R</div>
					// <Tag color="default">
					// 	Rupture {record?.breakup_date ? moment(record.breakup_date).format("DD-MM-YYYY") : ""}
					// </Tag>
				);

			default:
				return "";
		}
	};

	const renderLevels = (levels = []) => {
		return (
			<Space size={23}>
				{article_levels.map((lv, indx) => {
					return (
						<div style={{ textAlign: "center" }}>
							<div
								style={{
									color: "#646777",
									textAlign: "center",
								}}
							>
								Niveau {indx + 1}
							</div>
							<Space>
								{lv.sub_indexes.map((sub, j) => {
									return (
										<div key={`sub-lv-${indx}-${j}`}>
											<div
												style={{
													// minWidth: 80,
													// minHeight: 60,
													// backgroundColor: "#dee2e6",
													// padding: "3px",
													textAlign: "center",
												}}
											>
												<span style={{ fontWeight: "bold" }}>{sub.name}</span>
												<div>{renderSublevel(levels, indx + 1, j + 1)}</div>
											</div>
										</div>
									);
								})}
							</Space>
						</div>
					);
				})}
			</Space>
		);
	};

	const renderSublevel = (levels = [], index_level = 1, index_sub_level = 1) => {
		try {
			const lv = levels.filter(
				(item) => item.index_level === index_level && item.index_sub_level === index_sub_level
			)[0];
			if (!lv) return <div style={status_style}></div>;

			return (
				<Tooltip
					title={
						<div>
							<p>
								{lv.status === "breakup"
									? `Rupture 
						${lv?.breakup_date ? moment(lv.breakup_date).format("DD-MM-YYYY") : ""}`
									: ""}
							</p>
							<p>
								{lv.status === "valid"
									? `Validé 
						${lv?.validation_date ? moment(lv.validation_date).format("DD-MM-YYYY") : ""}`
									: ""}
							</p>
							{lv.registration_date ? (
								<p className="m-0">Inscrit {moment(lv.registration_date).format("DD-MM-YYYY")}</p>
							) : null}
						</div>
					}
					placement="bottom"
				>
					{renderStatus(lv.status, lv)}
				</Tooltip>
			);
		} catch (error) {
			return <div style={status_style}></div>;
		}
	};

	const renderContainerLaftCard = (is_draft, children) => {
		if (is_draft === true)
			return (
				<Badge.Ribbon text={"Brouillon"} style={{ fontSize: 15 }} color={"red"}>
					{children}
				</Badge.Ribbon>
			);

		return <div>{children}</div>;
	};

	const renderRegistrationPeriode = () => {
		if (!child?.registration_date) return null;
		try {
			const duration = moment.duration(moment().diff(moment(child.registration_date)));
			const days = duration.asDays();
			const monthes = Number(duration.asMonths().toFixed(0));
			const rest_days = days - monthes * 30;
			return (
				<div>
					<h4 className="mt-3 mb-0">
						<span>Durée au niveau actuel : {monthes} mois </span>
						{rest_days > 0 ? <span> {rest_days.toFixed(1)} jours </span> : null}
					</h4>
					<span style={{ color: "#777" }}>Depui {moment(child.registration_date).format("DD-MM-YYYY")}</span>
				</div>
			);
		} catch (error) {
			return null;
		}
	};

	return (
		<React.Fragment>
			<Row>
				<Col md={6} className="p-1">
					{renderContainerLaftCard(
						child?.is_draft,
						<Card
							actions={[
								<SettingOutlined key="setting" />,
								<EditOutlined
									key="edit"
									onClick={() => {
										setIsEditMode(!is_edit_mode);
										setFirstName(child?.first_name || "");
										setLastName(child?.last_name || "");
										setFirstNameAr(child?.first_name_ar || "");
										setLastNameAr(child?.last_name_ar || "");
										setAge(child?.age || 0);
										setIsDraft(child?.is_draft ? true : false);
									}}
								/>,
								// <EllipsisOutlined key="ellipsis" />,
							]}
						>
							{is_edit_mode ? (
								<div>
									<Label className="mt-3">Nom</Label>
									<Input value={first_name} onChange={(e) => setFirstName(e?.target?.value)} />
									<Label className="mt-3">Prénom</Label>
									<Input value={last_name} onChange={(e) => setLastName(e?.target?.value)} />
									<Label className="mt-3">الإسم</Label>
									<Input value={first_name_ar} onChange={(e) => setFirstNameAr(e?.target?.value)} />
									<Label className="mt-3">اللقب</Label>
									<Input value={last_name_ar} onChange={(e) => setLastNameAr(e?.target?.value)} />
									<Label className="mt-3">Age</Label>
									<Input value={age} onChange={(e) => setAge(e?.target?.value)} type="number" />
									<div className="mt-3 mb-3">
										<Switch
											checked={is_draft}
											className="mr-2"
											size="small"
											onChange={(checked) => setIsDraft(checked)}
										/>{" "}
										Brouillon
									</div>
									<div style={{ textAlign: "center" }}>
										<Button loading={loading} type="primary" onClick={() => submitEditInfos()}>
											Sauvegarder
										</Button>
									</div>
								</div>
							) : (
								<Skeleton loading={loading} avatar active>
									{child && (
										<Fragment>
											<Meta
												avatar={
													<Avatar
														shape="circle"
														size={60}
														icon={<UserOutlined />}
														src={child?.image}
													/>
												}
												title={`${child.first_name} ${child.last_name} ( ${child.first_name_ar} ${child.last_name_ar} )`}
												description={`${child.age || 0} ans`}
											/>

											<p className="mt-5">Niveau scolaire {`${child.education_level || 0}`}</p>
											<p>
												Formateur{" "}
												{`${child.former?.first_name} ${child.former?.last_name} ( ${child.former?.first_name_ar} ${child.former?.last_name_ar} )`}
											</p>
											<p>Date d'inscrit {moment(child.registration_date).format("llll")}</p>
										</Fragment>
									)}
								</Skeleton>
							)}
						</Card>
					)}
				</Col>

				{child && (
					<Col md={18} className="p-1">
						<Card bodyStyle={{ padding: 5 }} title="Status Club">
							{/* <EditLevelTable
								read_only={true}
								level={{
									index: new_level,
									sub_index: new_sub_level,
								}}
								onChange={(new_level) => {
									setNewLavel(new_level?.index || 1);
									setNewSubLavel(new_level?.sub_index || 1);
								}}
							/> */}

							{renderLevels(child?.levels)}

							{renderRegistrationPeriode()}

							{edit_level ? (
								<Fragment>
									<Table
										className="mt-3"
										columns={history_columns}
										dataSource={levels || []}
										scroll={{ x: 700 }}
										loading={loading}
										pagination={false}
										// onChange={(pagination, filters, sorter, extra) => {
										// 	// const sort_fields = [];
										// 	// if (sorter?.column)
										// 	// 	sort_fields.push({
										// 	// 		field: sorter?.field,
										// 	// 		order: sorter?.order === "ascend" ? "asc" : "desc",
										// 	// 	});
										// 	// setLimit(pagination?.pageSize || 10);
										// 	// getPage(pagination?.current || 1);
										// }}
									/>

									<div
										className="mt-3"
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
										}}
									>
										<Button
											type="default"
											loading={loading_save}
											onClick={() => {
												setEditLevel(false);
											}}
											className="mr-4"
										>
											Annuler
										</Button>

										<div style={{ textAlign: "right" }}>
											<Button
												type="primary"
												loading={loading_save}
												onClick={() => {
													saveNewLevels();
												}}
												className="mr-4"
											>
												Sauvegarder
											</Button>
											<Button
												type="secondary"
												loading={loading_save}
												onClick={() => {
													setShowSelectLevelMdl(true);
												}}
											>
												Attribuer niveau
											</Button>
										</div>
									</div>
								</Fragment>
							) : (
								<div className="mt-3" style={{ display: "flex", justifyContent: "space-between" }}>
									<Button
										type="primary"
										loading={loading_save}
										onClick={() => {
											setEditLevel(!edit_level);
										}}
									>
										<EditOutlined key="edit" /> Modifier l'historique
									</Button>

									{child?.index_level > 6 ||
									(child?.index_level === 6 && child?.status === "valid") ? null : (
										<div>
											{child?.status !== "valid" ? (
												<Popconfirm
													title="Êtes-vous sûr de passer au niveau suivant?"
													onConfirm={() => validateCurrentLevel()}
													onCancel={() => {}}
													okText="Oui"
													cancelText="Non"
												>
													<Button
														color="#4caf50"
														loading={loading_save}
														className="mr-2"
														onClick={() => {}}
													>
														<CheckOutlined key="validation" /> Valider Niveau actuel
													</Button>
												</Popconfirm>
											) : null}

											{child?.status !== "valid" && child?.index_level === 6 ? null : (
												<Button
													color="#4caf50"
													loading={loading_save}
													onClick={() => {
														setShowChooseNextLevel(true);
													}}
												>
													<ForwardOutlined key="validation" /> Passez au niveau supérieur
												</Button>
											)}
										</div>
									)}
								</div>
							)}
						</Card>
					</Col>
				)}
			</Row>

			{show_select_level_mdl && (
				<SelectLevelMdl
					action={selected_level ? "edit" : "add"}
					selected_level={selected_level}
					visible={show_select_level_mdl}
					close={() => setShowSelectLevelMdl(false)}
					checkLevelValidation={(new_level) => {
						for (let indx = 0; indx < levels.length; indx++) {
							const lv = levels[indx];
							if (
								lv.index_level === new_level?.index_level &&
								lv.index_sub_level === new_level?.index_sub_level
							)
								return false;
						}
						return true;
					}}
					submit={(level) => {
						if (!selected_level) addLevel(level);
						else {
							try {
								const new_levels = sortLevels([...levels]);

								for (let indx = 0; indx < levels.length; indx++) {
									if (
										levels[indx].index_level === selected_level.index_level &&
										levels[indx].index_sub_level === selected_level.index_sub_level
									) {
										new_levels.splice(indx, 1);
										break;
									}
								}

								new_levels.push(level);
								setLevels(rmDuplication(sortLevels(new_levels)));
							} catch (error) {}
						}
						setShowSelectLevelMdl(false);
					}}
				/>
			)}

			{show_choose_next_level && (
				<ChooseNextLevelCmp
					levels={sortLevels(child?.levels || [])}
					onOk={(new_level) => {
						upgradeLevel(new_level);
						setShowChooseNextLevel(false);
					}}
					visible={show_choose_next_level}
					onCancel={() => setShowChooseNextLevel(false)}
				/>
			)}
		</React.Fragment>
	);
};

ChildPreviewCmp.prototype = {
	changeName: PropTypes.func.isRequired,
	child_id: PropTypes.string,
};
export default ChildPreviewCmp;
