import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ListClientsPage from "./list";
import ListClientsInternalPage from "./list-internal";
import ListTrainersPage from "./list-trainers-page";
import TrainerPreviewPage from "./trainer-preview-page";
import EditClientPage from "./edit";
import AddClientPage from "./add-client-page";

const ClientsRoutingPages = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

      <Route
        path={`${match.url}/list`}
        render={(props) => <ListClientsPage {...props} />}
      />
      <Route
        path={`${match.url}/internal`}
        render={(props) => <ListClientsInternalPage {...props} />}
      />

      <Route
        path={`${match.url}/trainers`}
        render={(props) => <ListTrainersPage {...props} />}
      />
      <Route
        path={`${match.url}/trainer-preview/:trainerId`}
        render={(props) => <TrainerPreviewPage {...props} />}
      />

      <Route
        path={`${match.url}/add-new`}
        render={(props) => <AddClientPage {...props} />}
      />

      <Route
        path={`${match.url}/edit/:clientId`}
        render={(props) => <EditClientPage {...props} />}
      />

      <Redirect from={`${match.url}/`} to={`${match.url}/list`} />
      <Redirect to="/error" />
    </Switch>
  </Suspense>
);
export default ClientsRoutingPages;
