import {
  GET_TRAININGS, GET_TRAININGS_SUCCESS, GET_TRAININGS_ERROR,
  ADD_TRAINING, ADD_TRAINING_SUCCESS, ADD_TRAINING_ERROR,
  GET_ONE_TRAINING, GET_ONE_TRAINING_SUCCESS, GET_ONE_TRAINING_ERROR,
  EDIT_TRAINING, EDIT_TRAINING_SUCCESS, EDIT_TRAINING_ERROR,
  DELETE_TRAINING, DELETE_TRAINING_SUCCESS, DELETE_TRAINING_ERROR
} from '../actions/trainingsActions';

const initialState = {
  loading_get_trainings: false,
  error_get_trainings: null,
  trainings: [],
  count_all_trainings: 0,

  new_training: null, loading_add_training: false, error_add_training: null,

  selected_training: null, 
  loading_one_training: false, error_one_training: null,
  loading_edit_training: false, error_edit_training: null,

  loading_delete_training: false, error_delete_training: null
};

const trainingsReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_TRAININGS: return {...state, loading_get_trainings: true, error_get_trainings: null };
    case GET_TRAININGS_SUCCESS:
      return {...state, trainings: action.payload.trainings, loading_get_trainings: false, error_get_trainings: null, count_all_trainings: action.payload.count };
    case GET_TRAININGS_ERROR:
      // console.log("ACTION", {error_get_trainings: action.error, loading_get_trainings: false, trainings: []});
      return {...state, error_get_trainings: action.error, loading_get_trainings: false, trainings: [] };



    case ADD_TRAINING: return {...state, loading_add_training: true, error_add_training: null };
    case ADD_TRAINING_SUCCESS:
      return {...state, new_training: action.payload.training, loading_add_training: false, error_add_training: null };
    case ADD_TRAINING_ERROR:
      return {...state, error_add_training: action.error, loading_add_training: false, new_training: null };


    case GET_ONE_TRAINING: return {...state, loading_one_training: true, error_one_training: null };
    case GET_ONE_TRAINING_SUCCESS:
      return {...state, selected_training: action.payload.training, loading_one_training: false, error_one_training: null };
    case GET_ONE_TRAINING_ERROR:
      return {...state, error_one_training: action.error, loading_one_training: false, selected_training: null };



    case EDIT_TRAINING: return {...state, loading_edit_training: true, error_edit_training: null };
    case EDIT_TRAINING_SUCCESS:
      return {...state, selected_training: action.payload.training, loading_edit_training: false, error_edit_training: null };
    case EDIT_TRAINING_ERROR:
      return {...state, error_edit_training: action.error, loading_edit_training: false, selected_training: null };




    case DELETE_TRAINING: return {...state, loading_delete_training: true, error_one_training: null };
    case DELETE_TRAINING_SUCCESS:
      return {...state, loading_delete_training: false, error_delete_training: null };
    case DELETE_TRAINING_ERROR:
      return {...state, error_delete_training: action.error, loading_delete_training: false };


    default: return state;
  }
};

export default trainingsReducer;
