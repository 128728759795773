import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
	Card,
	CardBody,
	Table,
	Button,
	Row,
	Col,
	ButtonGroup,
	ButtonToolbar,
	// ButtonGroup, ButtonToolbar,
} from "reactstrap";

import moment from "moment";
import Select from "react-select";

import { getOrders, editOrder } from "../../../redux/actions/ordersActions";

import { getShipModes } from "../../../redux/actions/shipModesActions";

import { getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";

import { CheckBoxField } from "../form/CheckBox";
import axios from "axios";
import { api } from "../../../const";

import { NavLink } from "react-router-dom";
import ConfirmMdl from "../confirm-mdl";
import { statName, statusColor, statuses } from "../../../const";
import StatusBade from "../../helper/StatusBadge";
moment.locale("fr");
// const req_statuses = [{label: 'Request', value: 'request'}, {label: 'Accepted', value: 'accepted'}, {label: 'Refused', value: 'refused'}]

const current_year = new Date().getFullYear();
const years = [];
for (let i = 0; i <= current_year - 2019; i++) {
	years.push({ label: 2019 + i, value: 2019 + i, key: i });
}

const current_month = new Date().getMonth();
class ListOrdersCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			orders: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 30,
			cm_canceled: true,

			page_sizes: [
				{ label: "10", value: 10, key: 1 },
				{ label: "20", value: 20, key: 2 },
				{ label: "30", value: 30, key: 3 },
				{ label: "40", value: 40, key: 4 },
				{ label: "50", value: 50, key: 5 },
			],

			monthes: [
				{ label: "-", value: -1, key: 0 },
				{ label: "Janvier", value: 0, key: 1 },
				{ label: "Février", value: 1, key: 2 },
				{ label: "Mars", value: 2, key: 3 },
				{ label: "Avril", value: 3, key: 4 },
				{ label: "Mai", value: 4, key: 5 },
				{ label: "Juin", value: 5, key: 6 },
				{ label: "juillet", value: 6, key: 7 },
				{ label: "Août", value: 7, key: 8 },
				{ label: "Septembre", value: 8, key: 9 },
				{ label: "Octobre", value: 9, key: 10 },
				{ label: "Novembre", value: 10, key: 11 },
				{ label: "Décembre", value: 11, key: 12 },
			],
			selected_month: current_month,
			selected_year: current_year,

			query: "",

			show_edit_msg: false,
			order_editing: null,
			req_edit_id: null,
			status_editing: "",

			shipping_mode: "",
			ship_modes: [{ label: "Tous", value: "", key: "key" }],
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	editStatus = (status, order) => {
		this.setState({
			show_edit_msg: true,
			order_editing: order,
			req_edit_id: order._id,
			status_editing: status,
		});
	};
	componentWillMount() {
		this.getPage();
		this.props.getShipModes();
	}

	componentDidUpdate(prev) {
		const {
			loading_one_order,
			error_one_order,
			loading_edit_order,
			error_edit_order,

			ship_modes,
			loading_get_ship_modes,
			error_get_ship_modes,
		} = this.props;

		if (!loading_one_order && prev.loading_one_order && !error_one_order) {
			this.getPage(this.state.current_page);
		}

		if (prev.loading_edit_order && !loading_edit_order && !error_edit_order) {
			//CHANGE delivery price when CHANGE STATUS to shipped
			const order_editing = this.state.order_editing;
			if (order_editing) {
				if (this.state.status_editing == "shipped") {
					const resp = prompt(
						"Voulez vous changer la coût réel de livraison ? Commande : #" +
							order_editing?.ref +
							" / " +
							(order_editing.client_data &&
							order_editing.client_data.first_name_ar &&
							order_editing.client_data.first_name_ar != ""
								? order_editing.client_data.first_name_ar
								: order_editing.client_data?.first_name),
						order_editing?.shipping_real_price || 0
					);
					// console.log(resp);

					if (resp != null) {
						this.props.editOrder({
							_id: order_editing._id,
							data: { shipping_real_price: Number(resp) || 0 },
						});

						this.setState({ order_editing: null, status_editing: "" });
					} else this.getPage(this.state.current_page);
				} else this.getPage(this.state.current_page);
			} else this.getPage(this.state.current_page);
		}

		if (prev.loading_get_ship_modes && !loading_get_ship_modes && !error_get_ship_modes) {
			const new_shipmodes = [{ label: "Tous", value: "", key: "key" }];
			for (let i = 0; i < ship_modes.length; i++) {
				const ship_mode = ship_modes[i];
				new_shipmodes.push({ label: ship_mode.name, value: ship_mode.name, price: ship_mode.price, key: i });
			}

			this.setState({ ship_modes: new_shipmodes });
		}
	}
	getPage(page = 1) {
		const {
			selected_page_size,
			filter_all,
			filter_status,
			selected_month,
			selected_year,
			shipping_mode,
			query,
			cm_canceled,
		} = this.state;

		const { is_training_order, is_supply_order, only_canceled } = this.props;
		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {
			month: selected_month,
			year: selected_year,
			query,
			is_training_order: is_training_order || (only_canceled && cm_canceled) ? true : false,
			is_supply_order: is_supply_order ? true : false,
		};
		if (!filter_all) filter["statuses"] = filter_status;

		if (shipping_mode && shipping_mode != "") filter["shipping_mode"] = shipping_mode;

		if (only_canceled) filter["is_trash"] = true;
		// if (filter_by_query != '') filter['name'] = filter_by_query
		this.props.getOrders({ filter, offset, limit: selected_page_size });
	}

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	editReq = () => {
		this.props.editOrder({ _id: this.state.req_edit_id, data: { status: this.state.status_editing } });

		this.setState({ show_edit_msg: false });
	};

	resetFilter = (filter, checked, old_filter_status) => {
		if (checked != undefined) {
			const filter_status = JSON.parse(JSON.stringify(old_filter_status));

			if (filter == "all" && checked)
				this.setState({ filter_all: true, filter_status: [] }, () => this.getPage());
			else {
				const indx = filter_status.indexOf(filter);

				if (indx > -1) filter_status.splice(indx, 1);
				else filter_status.push(filter);

				this.setState({ filter_status, filter_all: filter_status.lengh == 0 }, () => this.getPage());
			}
		}
	};

	print = () => {
		const { is_training_order, is_supply_order } = this.props;
		axios
			.post(api.orders + "/print-parcels", { is_training_order, is_supply_order })
			.then((resp) => {
				// console.log("resp print", resp);
				if (resp.status == 200 && resp.data && !resp.data.err) {
					window.open(api.root + "/" + resp.data.pdf + "?" + new Date());
				} else alert("Veuillez vérifier vos données et réessayer");
			})
			.catch((error) => {
				alert("Veuillez vérifier vos données et réessayer");
			});
	};

	statusKey = (status) => {
		return statuses.filter((item) => item.value === status)[0]?.key || 0;
	};

	render() {
		const {
			current_page,
			page_sizes,
			selected_page_size,
			filter_all,
			filter_status,
			show_edit_msg,
			monthes,
			selected_month,
			selected_year,
			shipping_mode,
			ship_modes,
			query,
			cm_canceled,
		} = this.state;
		const { orders, count_all_orders, user, is_training_order, is_supply_order, only_canceled } = this.props;
		// console.log("order_trainings", order_trainings);
		const is_admin = user?.roles?.includes("ADMIN");

		return (
			<React.Fragment>
				{only_canceled ? null : (
					<Button onClick={() => this.print()} color="info" style={{ float: "right", marginTop: -70 }}>
						<span className="lnr lnr-printer mr-1"></span> Bordereau des colis
					</Button>
				)}

				<Card>
					<CardBody style={{ padding: 8 }}>
						<div className="card__title">
							{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
							<Row>
								<Col md="10" style={{ marginBottom: 10 }}>
									<CheckBoxField
										CheckBoxField="all"
										value={filter_all}
										label="Tous"
										name="all"
										onChange={(e) => this.resetFilter("all", e.target?.checked, filter_status)}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>

									<CheckBoxField
										CheckBoxField="pending"
										value={filter_status.includes("pending")}
										label={statName("pending")}
										name="pending"
										onChange={(e) => this.resetFilter("pending", e.target?.checked, filter_status)}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
									<CheckBoxField
										CheckBoxField="preparing"
										value={filter_status.includes("preparing")}
										label={statName("preparing")}
										name="preparing"
										onChange={(e) =>
											this.resetFilter("preparing", e.target?.checked, filter_status)
										}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
									<CheckBoxField
										CheckBoxField="prepared"
										value={filter_status.includes("prepared")}
										label={statName("prepared")}
										name="prepared"
										onChange={(e) => this.resetFilter("prepared", e.target?.checked, filter_status)}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
									<CheckBoxField
										CheckBoxField="shipping"
										value={filter_status.includes("shipping")}
										label={statName("shipping")}
										name="shipping"
										onChange={(e) => this.resetFilter("shipping", e.target?.checked, filter_status)}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
									<CheckBoxField
										CheckBoxField="shipped"
										value={filter_status.includes("shipped")}
										label={statName("shipped")}
										name="shipped"
										onChange={(e) => this.resetFilter("shipped", e.target?.checked, filter_status)}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
									<CheckBoxField
										CheckBoxField="payed"
										value={filter_status.includes("payed")}
										label={statName("payed")}
										name="payed"
										onChange={(e) => this.resetFilter("payed", e.target?.checked, filter_status)}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
									<CheckBoxField
										CheckBoxField="return"
										value={filter_status.includes("return")}
										label={statName("return")}
										name="return"
										onChange={(e) => this.resetFilter("return", e.target?.checked, filter_status)}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
									<CheckBoxField
										CheckBoxField="return_stock"
										value={filter_status.includes("return_stock")}
										label={statName("return_stock")}
										name="return_stock"
										onChange={(e) =>
											this.resetFilter("return_stock", e.target?.checked, filter_status)
										}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
									<CheckBoxField
										CheckBoxField="lost"
										value={filter_status.includes("lost")}
										label={statName("lost")}
										name="lost"
										onChange={(e) => this.resetFilter("lost", e.target?.checked, filter_status)}
										color="primary"
										style={{ display: "inline-block", marginRight: 8 }}
									/>
								</Col>
								<Col md="2" style={{ marginBottom: 10, textAlign: "right" }}>
									{!only_canceled && (
										<>
											{is_training_order && (
												<NavLink to="/app/orders-trains/add-new">
													<Button color="success" className="rounded">
														Ajouter
													</Button>
												</NavLink>
											)}

											{is_supply_order && (
												<NavLink to="/app/orders-supply/add-new">
													<Button color="success" className="rounded">
														Ajouter
													</Button>
												</NavLink>
											)}

											{!is_training_order && !is_supply_order && (
												<NavLink to="/app/orders/add-new">
													<Button color="success" className="rounded">
														Ajouter
													</Button>
												</NavLink>
											)}
										</>
									)}
								</Col>

								<Col md="2">
									<div className="form__form-group">
										{/* <span className="form__form-group-label">Recherche</span> */}
										<div className="form__form-group-field">
											<input
												style={{
													width: "100%",
													border: "none",
													borderBottom: "1px solid #ddd",
													padding: "12px 5px",
												}}
												defaultValue={query}
												component="input"
												placeholder="Recherche"
												onChange={(e) =>
													this.setState({ query: e.target.value }, () => this.getPage())
												}
											/>
										</div>
									</div>
								</Col>

								<Col md="2">
									<Select
										defaultValue={shipping_mode}
										value={ship_modes.filter((option) => option.value === shipping_mode)}
										onChange={(e) =>
											this.setState({ shipping_mode: e.value }, () => this.getPage())
										}
										options={ship_modes}
										clearable={false}
										className="react-select"
										placeholder="Livraison"
										classNamePrefix="react-select"
									/>
								</Col>

								<Col md="7">
									<div style={{ width: 100, display: "inline-block" }}>
										<Select
											name="year"
											value={years.filter((option) => option.value === selected_year)}
											onChange={(e) =>
												this.setState({ selected_year: e.value }, () => this.getPage())
											}
											options={years}
											clearable={false}
											className="react-select"
											placeholder="Year"
											classNamePrefix="react-select"
										/>
									</div>

									<div style={{ width: 120, display: "inline-block" }}>
										<Select
											name="Page"
											// defaultValue={selected_page_size}
											value={monthes.filter((option) => option.value === selected_month)}
											onChange={(e) =>
												this.setState({ selected_month: e.value }, () => this.getPage())
											}
											options={monthes}
											clearable={false}
											className="react-select"
											placeholder="Page"
											classNamePrefix="react-select"
										/>
									</div>
								</Col>

								<Col md="1">
									<div style={{ width: 60 }}>
										<Select
											name="Page"
											// defaultValue={selected_page_size}
											value={page_sizes.filter((option) => option.value === selected_page_size)}
											onChange={(e) =>
												this.setState({ selected_page_size: e.value }, () => this.getPage())
											}
											options={page_sizes}
											clearable={false}
											className="react-select"
											placeholder="Page"
											classNamePrefix="react-select"
										/>
									</div>
									{/* <strong>{count_all_orders}</strong> */}
								</Col>
							</Row>
						</div>
						{only_canceled ? (
							// <div style={{ textAlign: "right" }}>
							<ButtonToolbar dir="ltr">
								<ButtonGroup size="sm">
									<Button
										onClick={() => {
											this.setState({ cm_canceled: true }, () => this.getPage());
										}}
										style={{ backgroundColor: cm_canceled ? "#e7e2e2" : "#fff" }}
									>
										Commandes CM
									</Button>
									<Button
										onClick={() => this.setState({ cm_canceled: false }, () => this.getPage())}
										style={{ backgroundColor: !cm_canceled ? "#e7e2e2" : "#fff" }}
									>
										Commandes Management
									</Button>
								</ButtonGroup>
							</ButtonToolbar>
						) : // </div>
						null}
						<Table responsive hover striped style={{ minHeight: 400 }}>
							<thead>
								<tr>
									<th>
										<strong style={{ color: "red", fontSize: 18 }}>{count_all_orders}</strong>
									</th>
									<th>Date</th>
									<th>Client</th>
									<th>Téléphone</th>
									<th>Facebook</th>
									<th>Gouvernorat</th>
									<th>Vile</th>
									<th>Adresse</th>
									<th>Livraison</th>
									<th>Total</th>
									<th>Statut</th>
									<th></th>
								</tr>
							</thead>
							<tbody className="order_rows">
								{orders &&
									orders.map((order, i) => {
										const order_status_key = this.statusKey(order.status);

										return (
											<tr
												style={{ backgroundColor: statusColor(order.status) }}
												key={"order_" + i}
											>
												<td style={{ fontSize: 11 }}>
													{is_training_order && (
														<NavLink to={`/app/orders-trains/view/${order._id}`}>
															#{order.ref}
														</NavLink>
													)}
													{is_supply_order && (
														<NavLink to={`/app/orders-supply/view/${order._id}`}>
															#{order.ref}
														</NavLink>
													)}

													{!is_training_order && !is_supply_order && (
														<NavLink to={`/app/orders/view/${order._id}`}>
															#{order.ref}
														</NavLink>
													)}
												</td>

												<td>{moment(order.date).format("ll")}</td>

												<td style={{ fontWeight: "bold" }}>
													{order.client_data &&
													order.client_data.first_name_ar &&
													order.client_data.first_name_ar != ""
														? order.client_data.first_name_ar
														: order.client_data?.first_name}
												</td>

												<td>{order.client_data ? order.client_data.phone : ""}</td>

												<td>{order.client_data ? order.client_data.fb : ""}</td>

												<td>{order.client_data ? order.client_data.region : ""}</td>

												<td>{order.client_data ? order.client_data.city : ""}</td>

												<td>{order.client_data ? order.client_data.address : ""}</td>

												<td>
													<strong>{order.shipping_mode}</strong>
													{/* <p style={{ color: "#777" }} >{order.shipping_price}{" dt"}</p> */}
												</td>

												<td>{order.total}</td>

												<td>
													{/* {statName(order.status)} */}
													<div style={{ width: 100, display: "inline-block" }}>
														{order.is_trash ? (
															<StatusBade status={order.status} />
														) : (
															<Select
																name="status"
																defaultValue={order.status}
																value={statuses.filter(
																	(option) => option.value === order.status
																)}
																onChange={(e) => this.editStatus(e.value, order)}
																options={statuses}
																clearable={false}
																className="react-select"
																isOptionDisabled={(option) => {
																	return (
																		!is_admin &&
																		(option.only_admin ||
																			(option.key < order_status_key &&
																				!(
																					order_status_key === 2 &&
																					option.key === 1
																				)))

																		// ALLOW ONLY FROM Préparée TO Préparation for none admin users
																		// SAME FOR "orders-trains/view/.."
																	);
																}}
																placeholder="Statut"
																classNamePrefix="react-select"
															/>
														)}
													</div>
												</td>

												{/* <th>
							{order.articles && order.articles.map((sale_article, i) => (<p style={{}} key={"sale_article_" + i} >
								<span>{sale_article.qty + " x " + sale_article.name} </span>
							</p>))}
						</th> */}

												<td style={{ width: 60, verticalAlign: "middle" }}>
													{is_training_order && (
														<a
															target="_blank"
															href={`/app/orders-trains/view/${order._id}`}
														>
															<span
																className="lnr lnr-eye mr-2"
																style={{
																	cursor: "pointer",
																	color: "#4CAF50",
																	fontSize: 18,
																}}
															></span>
														</a>
													)}
													{is_supply_order && (
														<a
															target="_blank"
															href={`/app/orders-supply/view/${order._id}`}
														>
															<span
																className="lnr lnr-eye mr-2"
																style={{
																	cursor: "pointer",
																	color: "#4CAF50",
																	fontSize: 18,
																}}
															></span>
														</a>
													)}

													{!is_training_order && !is_supply_order && (
														<a target="_blank" href={`/app/orders/view/${order._id}`}>
															<span
																className="lnr lnr-eye mr-2"
																style={{
																	cursor: "pointer",
																	color: "#4CAF50",
																	fontSize: 18,
																}}
															></span>
														</a>
													)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>

						<Pagination
							currentPage={current_page}
							totalPage={getCountPages(count_all_orders, selected_page_size)}
							onChangePage={(i) => this.onChangePage(i)}
						/>

						<ConfirmMdl
							toggle={() =>
								this.setState((prev) => {
									return { show_edit_msg: !prev.show_edit_msg };
								})
							}
							message="Êtes-vous sûr de vouloir modifier le statut et la quantité en stock ?"
							is_open={show_edit_msg}
							accept={() => {
								this.editReq();
							}}
						/>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ order, auth, shipMode }) => {
	const {
		loading_get_orders,
		orders,
		count_all_orders,
		loading_one_order,
		error_one_order,
		loading_edit_order,
		error_edit_order,
	} = order;

	const { ship_modes, loading_get_ship_modes, error_get_ship_modes } = shipMode;

	return {
		loading_get_orders,
		orders,
		count_all_orders,
		loading_one_order,
		error_one_order,

		loading_edit_order,
		error_edit_order,

		ship_modes,
		loading_get_ship_modes,
		error_get_ship_modes,

		user: auth?.user,
	};
};
export default connect(mapStateToProps, {
	getOrders,
	editOrder,
	getShipModes,
})(ListOrdersCmp);
