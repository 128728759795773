import React, { PureComponent } from 'react';
import { Col, Row, Button } from 'reactstrap';

import { connect } from 'react-redux';

import {
  addShipMode
} from "../../../redux/actions/shipModesActions";

class AddShipModeCmp extends PureComponent {
  constructor() {
    super();
    this.state = {
      name: "",
      price: 0,
      extra_price: 0,
      // is_first: false
    };
  }

  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  addShipMode = () => {
    const { name, price, extra_price } = this.state

    if (name !== "" && price >= 0) this.props.addShipMode({ name, price, extra_price })
  }


  componentDidUpdate(prev) {
    if (prev.loading_add_ship_mode && !this.props.loading_add_ship_mode && !this.props.error_add_ship_mode) {
      this.props.success()
    }
  }


  render() {
    const { name, price, extra_price } = this.state

    return (<Row>
      <Col md={4}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">Mode de livraison</span>

            <div className="form__form-group-field">
              <input value={name} placeholder="Nom" onChange={(e) => this.changeVal('name', e.target.value)} />

              {/* <div className="form__form-group-icon">
                meter
              </div> */}
            </div>
          </div>
        </div>
      </Col>

      <Col md={2}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">Prix</span>

            <div className="form__form-group-field">
              <input type="number" min="0" value={price} placeholder="Prix" onChange={(e) => this.changeVal('price', e.target.value)} />

              <div className="form__form-group-icon">
                TND
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col md={2}>
        <div className="form">
          <div onClick={() => { }} className="form__form-group" style={{ cursor: "pointer" }}>
            <span className="form__form-group-label">Extra</span>

            <div className="form__form-group-field">
              <input type="number" min="0" value={extra_price} placeholder="0.00" onChange={(e) => this.changeVal('extra_price', e.target.value)} />

              <div className="form__form-group-icon">
                TND
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col md={4} style={{ textAlign: 'center', paddingTop: 16 }}>
        <Button color="success" disabled={name == "" || price < 0} onClick={this.addShipMode}>Sauvegardez</Button>
        <Button color="danger" onClick={(this.props.cancelAdding) ? this.props.cancelAdding : () => { }}>Cancel</Button>
      </Col>
    </Row>)
  }
}


const mapStateToProps = ({ shipMode }) => {
  const { loading_add_ship_mode, error_add_ship_mode } = shipMode
  return {
    loading_add_ship_mode, error_add_ship_mode
  }
}
export default connect(mapStateToProps, {
  addShipMode
})(AddShipModeCmp);