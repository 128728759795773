
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_CLIENTS, getClientsSuccess, getClientsError, 
  ADD_CLIENT, addClientError, addClientSuccess, 
  GET_ONE_CLIENT, getOneClientSuccess, getOneClientError, 
  EDIT_CLIENT, editClientError, editClientSuccess,

  DELETE_CLIENT, deleteClientError, deleteClientSuccess,
} from '../actions/clientsActions';
import { api } from '../../const';



// *** GET CLIENTS
export function* watchGetClients() { yield takeEvery(GET_CLIENTS, getClients) }
function* getClients({ payload }) {
  try {
    const ret = yield call(getClientsRequest, payload);

    if (ret && ret.status == 200) yield put(getClientsSuccess({ clients: ret.data?.clients || [], count: ret.data?.count || 0}));
    else yield put(getClientsError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getClientsError({ error, message: (error) ? error.message : '' }));
  }
}
const getClientsRequest = async (payload) => {  
  return await axios.post(api.clients+'/filter/'+payload.offset+'/'+payload.limit, payload.filter || {})
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// // *** GET ONE CLIENT
export function* watchGetOneClient() { yield takeEvery(GET_ONE_CLIENT, getOneClient) }
function* getOneClient({ payload }) {
  try {
    const ret = yield call(getOneClientRequest, payload);

    if (ret && ret.status == 200) yield put(getOneClientSuccess({ client: ret.data?.client || null }));
    else yield put(getOneClientError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOneClientError({ error, message: (error) ? error.message : '' }));
  }
}
const getOneClientRequest = async (payload) => {  
  return await axios.get(api.clients+"/get-one/"+payload.client_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD CLIENTS
export function* watchAddClients() { yield takeEvery(ADD_CLIENT, addClient) }
function* addClient({ payload }) {
  try {
    const ret = yield call(addClientsRequest, payload);

    if (ret && ret.status == 201) yield put(addClientSuccess({ client: ret.data.client }));
    else yield put(addClientError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addClientError({ error, message: (error) ? error.message : '' }));
  }
}
const addClientsRequest = async (payload) => {  
  return await axios.post(api.clients+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT CLIENTS
export function* watchEditClient() { yield takeEvery(EDIT_CLIENT, editClient) }
function* editClient({ payload }) {
  try {
    const ret = yield call(editClientsRequest, payload);

    if (ret && ret.status == 200) yield put(editClientSuccess({ client: ret.data.client }));
    else yield put(editClientError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editClientError({ error, message: (error) ? error.message : '' }));
  }
}
const editClientsRequest = async (payload) => {  
  return await axios.patch(api.clients+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE CLIENTS
export function* watchDeleteClient() { yield takeEvery(DELETE_CLIENT, deleteClient) }
function* deleteClient({ payload }) {
  try {
    const ret = yield call(deleteClientsRequest, payload);

    if (ret && ret.status == 200) yield put(deleteClientSuccess({ }));
    else yield put(deleteClientError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deleteClientError({ error, message: (error) ? error.message : '' }));
  }
}
const deleteClientsRequest = async (payload) => {  
  return await axios.delete(api.clients+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetClients),
    fork(watchGetOneClient),
    fork(watchAddClients),
    fork(watchEditClient),
    fork(watchDeleteClient),
  ]);
}