import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashStore from './DashStore';
import DashCM from './DashCM';

const DashRoutingPages = ({ match }) => (
  <Suspense fallback={<div className="loading" />}>
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/store`} />

        <Route path={`${match.url}/store`}
          render={props => <DashStore {...props} />}
        />
        <Route path={`${match.url}/cm`}
          render={props => <DashCM {...props} />}
        />

        <Redirect from={`${match.url}/`} to={`${match.url}/cm`} />
        <Redirect to="/error" />
      </Switch>
    
  </Suspense>
);
export default DashRoutingPages;