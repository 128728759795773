import React, { Fragment, PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { Field, reduxForm } from 'redux-form';
// import moment from "moment";

import {
	Button,
	ButtonToolbar,
	Card,
	CardBody,
	Row,
	Col,
	Table,
	Input,
	// Input,
	//Nav, NavItem, NavLink, TabContent, TabPane, Table, Input
} from "reactstrap";
// import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { NavLink } from "react-router-dom";

import { addOrder, editOrder, getOneOrder } from "../../../redux/actions/ordersActions";

import { getShipModes } from "../../../redux/actions/shipModesActions";

// import Switch from "rc-switch";
// import "rc-switch/assets/index.css";

// import classnames from 'classnames';
import { api, statuses, no_product_img, cities, client_types } from "../../../const";
import SelectArticle from "../articles/SelectArticle";
import SelectClient from "../clients/SelectClient";
import { articleStockColor } from "../../helper/utils";
// import AddChildMdl from '../childs/AddChildMdl';
import SelectChildMdl from "../childs/SelectChildMdl";
import Axios from "axios";
// import SelectOrder from './SelectOrder';

class OrderCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			is_new_client: true,
			client: null,

			first_name: "",
			first_name_ar: "",
			type: "external",
			fb: "",

			address: "",
			phone: "",
			phone2: "",
			city: "",
			region: "",

			shipping_address: "",
			shipping_phone: "",
			shipping_name: "",
			shipping_city: "",
			shipping_region: "",

			same_shipping_infos: true,

			show_select_client: false,

			status: "preparing",

			articles: [],
			selected_articles: [],

			date: new Date(),

			shipping_price: 0,
			shipping_mode: "",

			sub_total: 0,
			discount: 0,
			total: 0,

			show_select_prd: false,
			error_frm: "",

			ship_modes: [],

			show_add_child: false,

			note: "",

			extra_price: 0,
			use_extra_price: true,
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj, () => this.calcTotal());
	};

	toggleSelectClientMdl = () =>
		this.setState((prev) => {
			return { show_select_client: !prev.show_select_client };
		});
	addOrderClient = (data) => {
		if (data && Array.isArray(data) && data[0]) {
			this.setState({ client: data[0], show_select_client: false }, () => {
				const { first_name, first_name_ar, type, address, phone, phone2, city, region, fb } = data[0];
				this.setState({
					first_name,
					first_name_ar,
					type,
					address,
					phone,
					phone2,
					city,
					region,
					fb,
					is_new_client: false,
				});
			});
		} else this.setState({ show_select_client: false, is_new_client: true });

		this.setState({ same_shipping_infos: true });
	};

	toggleSelectArticleMdl = () =>
		this.setState((prev) => {
			return { show_select_prd: !prev.show_select_prd };
		});
	addOrderArticle = (data) => {
		if (data && Array.isArray(data)) {
			this.setState(
				(prev) => {
					const articles = prev.articles;
					const selected_articles = prev.selected_articles;

					for (let i = 0; i < data.length; i++) {
						let exist = false;
						for (let j = 0; j < articles.length; j++) {
							if (articles[j].article._id === data[i]._id) {
								exist = true;
								break;
							}
						}
						if (!exist) {
							const article_variants = [];
							try {
								const variants = data[i].variants || [];
								for (let i = 0; i < variants.length; i++) {
									const vr = variants[i];
									const vr_options = vr.options || [];
									const options = [];

									for (let j = 0; j < vr_options.length; j++) {
										options.push({
											label: vr_options[j],
											value: vr_options[j],
											variant: vr.name,
											key: j,
											selected: j === 0 ? true : false,
										});
									}
									article_variants.push({ name: vr.name, options });
								}
							} catch (error) {}

							articles.push({
								name: data[i].name,
								ref: data[i].ref,
								desc: data[i].desc,
								short_desc: data[i].short_desc,
								category_name: data[i].category ? data[i].category.name : "",

								privilege: data[i].privilege || 0,

								qty: 1,
								unit_price: data[i].price || 0,
								// total_price: data[i].price || 0,

								article: data[i],

								is_pack: data[i].is_pack,

								variants: article_variants,
								level: data[i].level,
								is_educational_support: data[i].is_educational_support,
								is_first_educ_support: data[i].is_first_educ_support,
							});

							if (this.props.is_training_order)
								this.setState((prevState) => ({
									discount: prevState.discount + (data[i].privilege || 0) * (data[i].qty || 1),
								}));

							selected_articles.push(data[i]);
						}
					}

					this.calcTotal();
					return {
						show_select_prd: false,
						articles: [...articles],
						selected_articles: [...selected_articles],
					};
				},
				() => {
					this.calcTotal();
				}
			);
		} else
			this.setState({ show_select_prd: false }, () => {
				this.calcTotal();
			});
	};
	handleRmArticle = (_id) => {
		this.setState(
			(prev) => {
				const { selected_articles, articles } = prev;
				for (let i = 0; i < selected_articles.length; i++) {
					if (selected_articles[i]._id == _id) {
						selected_articles.splice(i, 1);
						break;
					}
				}
				for (let i = 0; i < articles.length; i++) {
					if (articles[i].article._id == _id) {
						articles.splice(i, 1);
						break;
					}
				}

				return { articles: [...articles], selected_articles: [...selected_articles] };
			},
			() => {
				this.calcTotal();
			}
		);
	};

	changeArticleValue = (i, key, val) =>
		this.setState(
			(prev) => {
				const articles = prev.articles;
				articles[i][key] = val;
				return { articles: [...articles] };
			},
			() => {
				try {
					if (this.props.is_training_order) {
						if (key === "qty" || key === "unit_price") {
							let order_discount = 0;
							for (const order_article of this.state.articles) {
								order_discount += Number(order_article.qty || 1) * Number(order_article.privilege || 0);
							}

							this.setState({ discount: order_discount }, () => {
								this.calcTotal();
							});
						}
					} else this.calcTotal();
				} catch (error) {
					this.calcTotal();
				}
			}
		);

	changeArticleVariant = (indx_article, indx_vr, value_indx) => {
		this.setState((prev) => {
			try {
				const articles = prev.articles || [];
				const options = articles[indx_article].variants[indx_vr].options || [];
				for (let i = 0; i < options.length; i++) {
					options[i]["selected"] = false;
				}
				options[value_indx]["selected"] = true;

				articles[indx_article].variants[indx_vr].options = options;

				return { articles: [...JSON.parse(JSON.stringify(articles))] };
			} catch (error) {}
		});
	};

	addChildsToArticle = (childs, indx_article) => {
		try {
			this.setState((prev) => {
				const articles = prev.articles;
				// articles[indx_article]["child"] = child
				Array.isArray(articles[indx_article]["childs"])
					? articles[indx_article]["childs"].push(...childs)
					: (articles[indx_article]["childs"] = childs || []);

				return { articles: [...articles], show_add_child: false };
			});
		} catch (error) {}
	};

	rmChildToArticle = (indx_article, index_child = 0) => {
		try {
			this.setState((prev) => {
				const articles = prev.articles;
				// articles[indx_article]["child"] = null
				articles[indx_article]["childs"].splice(index_child, 1);
				return { articles: [...articles], show_add_child: false };
			});
		} catch (error) {}
	};

	checkPedagogicArticle = async (client) => {
		if (!this.props.is_training_order) return "";

		const client_orders = await Axios.post(`${api.orders}/filter/0/1`, {
			client: client?._id,
			is_training_order: true,
		});

		if (client_orders.data?.orders?.length < 1) {
			if (!this.state.articles.filter((item) => item?.article?.is_educational_support)?.length)
				return "vous devez ajouter au moins un article pédagogique pour la première commande CM";
		}

		return "";
	};

	submit = async (forced = false) => {
		let error_frm = this.checkFrm(this.state);

		// error_frm = error_frm === "" ? await this.checkPedagogicArticle(this.state.client) : error_frm;
		// this.setState({ error_frm });

		const { addOrder, editOrder, editing } = this.props;

		if (error_frm == "" || forced) {
			const {
				status,
				client,
				articles,
				date,
				shipping_price,
				shipping_mode,
				sub_total,
				discount,
				total,
				is_new_client,
				first_name,
				first_name_ar,
				type,
				fb,
				address,
				phone,
				phone2,
				city,
				region,
				note,
				extra_price,
				use_extra_price,

				same_shipping_infos,
				shipping_region,
				shipping_address,
				shipping_city,
				shipping_phone,
				shipping_name,
			} = this.state;

			const new_articles = [];
			for (let i = 0; i < articles.length; i++) {
				const item = articles[i];
				const variants = item.variants || [];
				const article_variants = [];

				for (let j = 0; j < variants.length; j++) {
					const options = variants[j].options || [];
					for (let k = 0; k < options.length; k++) {
						if (options[k].selected == true) {
							article_variants.push({ name: options[k].variant, value: options[k].value });
						}
					}
				}

				new_articles.push({
					...item,
					variants: article_variants || [],
					article: item.article._id,
					childs: item.childs || [],
					// child: item.child?._id,
					// child_data: item.child
				});
			}

			const data = {
				forced,
				status,
				date,
				shipping_price,
				shipping_mode,
				sub_total,
				discount,
				total,
				client: client ? client._id : null,
				client_data: client
					? {
							...client,
							first_name,
							first_name_ar,
							type,
							fb,
							address,
							phone,
							phone2,
							city,
							region,
					  }
					: {
							first_name,
							first_name_ar,
							type,
							fb,
							address,
							phone,
							phone2,
							city,
							region,
					  },
				is_new_client,
				articles: new_articles,

				is_training_order: this.props.is_training_order,
				is_supply_order: this.props.is_supply_order,
				note,
				extra_price,
				use_extra_price,

				same_shipping_infos,
				shipping_region,
				shipping_address,
				shipping_city,
				shipping_phone,
				shipping_name,
			};

			if (editing) editOrder({ _id: this.props.order_id, data });
			else addOrder(data);
		} else {
			this.setState({ error_frm });
			window.scrollTo(0, 0);
		}
	};

	checkFrm = (data) => {
		const { articles, first_name, first_name_ar, type, fb, phone, phone2, city, region, shipping_mode, address } =
			data;
		if (first_name == "" && first_name_ar == "") return "Le nom de client est requis.";
		if (type == "") return "Le type de client est requis.";
		if (fb == "") return "Facebook de client est requis.";
		if (phone == "" && phone2 == "") return "Téléphone de client est requis.";
		if (city == "") return "Vile de client est requis.";
		if (region == "") return "Gouvernorat de client est requis.";

		if (!articles || articles.length == 0) return "Vous devez sélectionner au moins un article.";

		// CHECK CHILDS SAME AS ARTICLES (temp removed)

		// if (this.props.is_training_order) for (const item of articles) {
		//   if (!item.childs || item.qty !== item.childs.length)
		//     return "Verifier la quantité et les enfants d'aticle " + item.name + ".";
		// }

		if (shipping_mode === "") return "Mode de livraison est requise.";

		if (shipping_mode === "Poste" || shipping_mode === "Poste CM") {
			let str = "L'adresse doit contenir le code postal.";
			try {
				const arr = address.split(" ") || [];

				for (let i = 0; i < arr.length; i++) {
					if (arr[i].length === 4 && /^\+?\d+$/.test(arr[i])) {
						// console.log(arr[i]);
						str = "";
						break;
					}
				}
			} catch (error) {
				str = "";
			}

			return str;
		}

		return "";
	};

	componentDidUpdate(prev) {
		const {
			user,
			loading_add_order,
			error_add_order,
			// new_order,
			selected_order,
			loading_one_order,
			error_one_order,
			loading_edit_order,
			error_edit_order,

			ship_modes,
			loading_get_ship_modes,
			error_get_ship_modes,

			is_training_order,
			is_supply_order,
			history,
		} = this.props;

		if (!loading_add_order && prev.loading_add_order) {
			// console.log("error_add_order", error_add_order);
			if (error_add_order) {
				if (error_add_order.message && error_add_order.message != "") {
					this.setState({ error_frm: error_add_order.message });
					window.scrollTo(0, 0);
				}
			} else {
				if (is_training_order) history.push("/app/orders-trains");
				else if (is_supply_order) history.push("/app/orders-supply");
				else history.push("/app/orders");
			}
		}

		// EDITING
		if (!loading_edit_order && prev.loading_edit_order) {
			// console.log("error_add_order", error_add_order, new_order);
			if (error_edit_order) {
				if (error_edit_order.message && error_edit_order.message != "") {
					this.setState({ error_frm: error_edit_order.message });
					window.scrollTo(0, 0);
				}
			} else {
				if (is_training_order) history.push("/app/orders-trains");
				else if (is_supply_order) history.push("/app/orders-supply");
				else history.push("/app/orders");
			}
		}

		//GET ONE
		if (prev.loading_one_order && !loading_one_order && !error_one_order) {
			if (selected_order) {
				const {
					status,
					client,
					articles,
					date,
					shipping_price,
					shipping_mode,
					sub_total,
					discount,
					total,
					note,
					extra_price,
					use_extra_price,

					same_shipping_infos,
					shipping_region,
					shipping_address,
					shipping_city,
					shipping_phone,
					shipping_name,
				} = selected_order;

				if (!user?.roles?.includes("ADMIN") && !["pending", "preparing", "prepared"].includes(status))
					history.push("/app");

				const { first_name, first_name_ar, type, address, phone, phone2, city, region, fb } =
					selected_order.client_data;

				this.setState({
					is_new_client: false,
					first_name,
					first_name_ar,
					type,
					address,
					phone,
					phone2,
					city,
					region,
					fb,
					status,
					client,
					date,
					shipping_price,
					shipping_mode,
					sub_total,
					discount,
					total,

					is_new_client: client && typeof client != "string" ? false : true,

					note,
					extra_price,
					use_extra_price,

					same_shipping_infos,
					shipping_region,
					shipping_address,
					shipping_city,
					shipping_phone,
					shipping_name,
					// articles,
				});

				const isSelectedVr = (vr_name, vr_value, article_vrs = []) => {
					for (let i = 0; i < article_vrs.length; i++) {
						if (article_vrs[i].name == vr_name && article_vrs[i].value == vr_value) return true;
					}
					return false;
				};

				const selected_articles = [];
				const order_articles = [];
				for (let x = 0; x < articles.length; x++) {
					const item = articles[x];

					const article_variants = [];
					try {
						if (!item.article || typeof item.article == "string") {
							for (let i = 0; i < item.variants.length; i++) {
								article_variants.push({
									name: item.variants[i].name,
									options: [
										{
											label: item.variants[i].name,
											variant: item.variants[i].name,
											value: item.variants[i].value,
											selected: true,
											key: 0,
										},
									],
								});
							}
						} else {
							const variants = item.article.variants || [];
							for (let i = 0; i < variants.length; i++) {
								const vr = variants[i];
								const vr_options = vr.options || [];
								const options = [];

								for (let j = 0; j < vr_options.length; j++) {
									options.push({
										label: vr_options[j],
										value: vr_options[j],
										variant: vr.name,
										key: j,
										selected: isSelectedVr(vr.name, vr_options[j], item.variants),
									});
								}
								article_variants.push({ name: vr.name, options });
							}
						}
					} catch (error) {
						console.log("error", error);
					}

					order_articles.push({
						name: item.name,
						ref: item.ref,
						desc: item.desc,
						short_desc: item.short_desc,
						category_name: item.category_name || "",

						qty: item.qty,
						privilege: item.privilege || 0,
						unit_price: item.unit_price || 0,
						article: item.article,

						is_pack: item.is_pack,

						variants: article_variants,
						level: item.level,
					});

					selected_articles.push(item.article);
				}

				this.setState({ articles: order_articles, selected_articles });
			}
		}

		if (prev.loading_get_ship_modes && !loading_get_ship_modes && !error_get_ship_modes) {
			const new_shipmodes = [];
			for (let i = 0; i < ship_modes.length; i++) {
				const ship_mode = ship_modes[i];
				new_shipmodes.push({
					label: ship_mode.name,
					value: ship_mode.name,
					price: ship_mode.price,
					extra_price: ship_mode.extra_price || 0,
					key: i,
				});
			}

			this.setState({ ship_modes: new_shipmodes });
		}
	}

	componentDidMount() {
		this.props.getShipModes();

		if (this.props.editing) {
			const { match, getOneOrder } = this.props;
			if (match && match.params) getOneOrder({ order_id: match.params.orderId });
		}
	}

	calcTotal = () => {
		const { articles, discount, shipping_price } = this.state;

		const sub_total = articles.reduce(
			(sub, order_article) => Number(order_article.unit_price) * Number(order_article.qty) + sub,
			0
		);
		this.setState({
			sub_total,
			total: Number(parseFloat(sub_total + Number(shipping_price) - Number(discount)).toFixed(2)),
		});
	};

	renderArticleChilds = (index_article, childs = []) => {
		return (
			<div>
				{childs.map((child, index_child) => (
					<div key={`child_${index_article}_${index_child}`}>
						<p>
							{child && child.first_name_ar && child.first_name_ar != ""
								? child.first_name_ar + " " + child.last_name_ar
								: child?.first_name + " " + child.last_name}
						</p>

						<span
							onClick={() => this.rmChildToArticle(index_article, index_child)}
							className="lnr lnr-trash"
							style={{ cursor: "pointer", color: "rgb(255 87 87)" }}
						></span>
					</div>
				))}

				{childs.length < this.state.articles[index_article].qty && (
					<div style={{ fontSize: 18, fontWeight: "bold" }}>
						<span
							onClick={() =>
								this.setState({ indx_article_child: index_article }, () =>
									this.setState({ show_add_child: true })
								)
							}
							className="lnr lnr-plus-circle"
							style={{ cursor: "pointer", color: "rgb(76, 175, 80)" }}
						></span>
					</div>
				)}
			</div>
		);
	};

	// searchClient = () => {
	//   this.setState({show_select_client: true})
	// }
	renderShippingInfos = () => {
		const {
			first_name_ar,
			address,
			phone,
			city,
			region,
			client,

			shipping_address,
			shipping_phone,
			shipping_name,
			shipping_city,
			shipping_region,

			same_shipping_infos,
			is_new_client,
		} = this.state;

		const { is_training_order, is_supply_order } = this.props;

		return (
			<Fragment>
				{client || (!is_training_order && is_new_client) ? (
					<Col md="12">
						<input
							type="checkbox"
							checked={same_shipping_infos}
							onChange={(e) => {
								const checked = e?.target?.checked;
								this.setState({
									same_shipping_infos: checked,
									shipping_address: address,
									shipping_city: city,
									shipping_region: region,
									shipping_phone: phone,
									shipping_name: first_name_ar,
								});
							}}
							style={{ width: "15px", float: "left" }}
						/>{" "}
						<span style={{ marginTop: "5px", display: "block", marginLeft: "20px" }}>
							Mêmes informations de livraison
						</span>
					</Col>
				) : null}
				{(client || (!is_training_order && is_new_client)) && !same_shipping_infos ? (
					<Col md="12">
						<Row>
							<Col md="6">
								<span style={{ fontSize: 12, color: "#777" }}>Nom livraison</span>
								<Input
									value={shipping_name}
									onChange={(e) => this.setState({ shipping_name: e?.target?.value })}
								/>
							</Col>
							<Col md="6">
								<span style={{ fontSize: 12, color: "#777" }}>Tel livraison</span>
								<Input
									// type="number"
									value={shipping_phone}
									onChange={(e) => this.setState({ shipping_phone: e?.target?.value })}
								/>
							</Col>
							<Col md="6">
								<span style={{ fontSize: 12, color: "#777" }}>Gouvernorat</span>
								<Input
									value={shipping_region}
									onChange={(e) => this.setState({ shipping_region: e?.target?.value })}
								/>
							</Col>
							<Col md="6">
								<span style={{ fontSize: 12, color: "#777" }}>Ville</span>
								<Input
									value={shipping_city}
									onChange={(e) => this.setState({ shipping_city: e?.target?.value })}
								/>
							</Col>
							<Col md="12">
								<span style={{ fontSize: 12, color: "#777" }}>Adresse</span>
								<Input
									value={shipping_address}
									onChange={(e) => this.setState({ shipping_address: e?.target?.value })}
								/>
							</Col>
						</Row>
					</Col>
				) : null}
			</Fragment>
		);
	};
	render() {
		const { editing, user, is_training_order, is_supply_order, loading_add_order, loading_edit_order } = this.props;

		const {
			is_new_client,
			first_name,
			first_name_ar,
			type,
			address,
			phone,
			phone2,
			city,
			region,
			fb,
			status,
			client,
			articles,
			date,
			shipping_price,
			shipping_mode,
			ship_modes,
			sub_total,
			discount,
			total,
			error_frm,
			show_select_client,
			show_select_prd,
			selected_articles,
			show_add_child,
			indx_article_child,
			note,
			extra_price,
			use_extra_price,
			same_shipping_infos,
		} = this.state;
		// const order_trainings = false
		// console.log("order_trainings", order_trainings);
		// console.log("ARTICLES", articles);

		// console.log("is_training_order", is_training_order);
		return (
			<div>
				{is_training_order && <h3 className="mb-2">Commande CM</h3>}
				{is_supply_order && <h3 className="mb-2">Commande Fourniture</h3>}

				<Card>
					<CardBody style={{ padding: "0 20px", minHeight: 500 }}>
						<Row className="form">
							<Col md="12" style={{ marginBottom: 10 }}>
								<Alert is_visible={error_frm != ""} color="danger" className="alert--colored" icon>
									<p>
										<span className="bold-text">Attention !</span> {error_frm}
									</p>
								</Alert>
							</Col>

							{/* CLIENT SECTION */}
							{!is_training_order ? (
								<Col md="7" style={{ border: "1px solid #ddd", borderRadius: 5 }}>
									<Row style={{ marginTop: 10 }}>
										<Col md="12">
											<p style={{ color: !is_new_client ? "#F44336" : "#4CAF50" }}>
												<span
													className="point"
													style={{ backgroundColor: !is_new_client ? "#F44336" : "#4CAF50" }}
												></span>
												{is_new_client ? " Nouveau client" : " Client sélectionné"}

												{!is_new_client && (
													<span
														onClick={() => {
															this.setState({ is_new_client: true });
														}}
														className="lnr lnr-trash"
														style={{ color: "#F44336", cursor: "pointer", marginLeft: 10 }}
													></span>
												)}
											</p>
										</Col>

										<Col md="4">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<input
														style={{
															width: "100%",
															border: "none",
															borderBottom: "1px solid #ddd",
														}}
														value={first_name_ar}
														component="input"
														placeholder="الإسم"
														onChange={(e) =>
															this.changeVal("first_name_ar", e.target.value)
														}
													/>
												</div>
											</div>
										</Col>
										<Col md="4">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<input
														style={{
															width: "100%",
															border: "none",
															borderBottom: "1px solid #ddd",
														}}
														value={first_name}
														component="input"
														placeholder="Nom"
														onChange={(e) => this.changeVal("first_name", e.target.value)}
													/>
												</div>
											</div>
										</Col>

										<Col md="4">
											<div
												onClick={() => this.setState({ show_select_client: true })}
												className="search_client_btn mb-3"
												style={{ width: 150, float: "right" }}
											>
												<span className="lnr lnr-magnifier ml-1 mr-1"></span> Chercher
											</div>
										</Col>

										<Col md="3">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<input
														style={{
															width: "100%",
															border: "none",
															borderBottom: "1px solid #ddd",
														}}
														type="number"
														value={phone}
														component="input"
														placeholder="Téléphone"
														onChange={(e) => this.changeVal("phone", e.target.value)}
													/>
												</div>
											</div>
										</Col>
										<Col md="3">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<input
														style={{
															width: "100%",
															border: "none",
															borderBottom: "1px solid #ddd",
														}}
														type="number"
														value={phone2}
														component="input"
														placeholder="Téléphone 2"
														onChange={(e) => this.changeVal("phone2", e.target.value)}
													/>
												</div>
											</div>
										</Col>

										<Col md="3">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<Select
														defaultValue={city}
														value={cities.filter((option) => option.value === city)}
														onChange={(e) => this.changeVal("city", e.value)}
														options={cities}
														clearable={false}
														className="react-select"
														placeholder="Gouvernorat"
														classNamePrefix="react-select"
													/>
												</div>
											</div>
										</Col>

										<Col md="3">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<input
														style={{
															width: "100%",
															border: "none",
															borderBottom: "1px solid #ddd",
														}}
														value={region}
														component="input"
														placeholder="Région"
														onChange={(e) => this.changeVal("region", e.target.value)}
													/>
												</div>
											</div>
										</Col>

										<Col md="4">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<input
														style={{
															width: "100%",
															border: "none",
															borderBottom: "1px solid #ddd",
														}}
														value={fb}
														component="input"
														placeholder="Facebook"
														onChange={(e) => this.changeVal("fb", e.target.value)}
													/>
												</div>
											</div>
										</Col>
										<Col md="8">
											<div className="form__form-group">
												<div className="form__form-group-field">
													<input
														style={{
															width: "100%",
															border: "none",
															borderBottom: "1px solid #ddd",
														}}
														value={address}
														component="input"
														placeholder="Adresse"
														onChange={(e) => this.changeVal("address", e.target.value)}
													/>
												</div>
											</div>
										</Col>

										{this.renderShippingInfos()}
									</Row>
								</Col>
							) : (
								<Col md="7" style={{ border: "1px solid #ddd", borderRadius: 5 }}>
									<Row>
										<Col md="4">
											{(first_name || first_name_ar) &&
												(first_name != "" || first_name_ar != "") && (
													<p>
														<span className="lnr lnr-user"></span> {first_name_ar} <br />{" "}
														{first_name}
													</p>
												)}
										</Col>
										<Col md="4">
											{(phone || phone2) && (phone != "" || phone2 != "") && (
												<p>
													<span className="lnr lnr-smartphone"></span> {phone} / {phone2}
												</p>
											)}
										</Col>
										<Col md="12">
											<p className="mt-2">
												{address && address != "" && (
													<span className="lnr lnr-map-marker"></span>
												)}{" "}
												{city}, {region} {address}
											</p>
										</Col>
										<Col md="8">
											<p>{fb}</p>
										</Col>
										<Col md="4">
											<div
												onClick={() => this.setState({ show_select_client: true })}
												className="search_client_btn mb-3"
												style={{ width: 150, float: "right" }}
											>
												<span className="lnr lnr-magnifier ml-1 mr-1"></span> Chercher
											</div>
										</Col>

										{this.renderShippingInfos()}
									</Row>
								</Col>
							)}

							{/* TOTAL TOP */}
							<Col md="3" style={{ border: "1px solid #ddd", borderRadius: 3 }}>
								<h2
									style={{
										textAlign: "center",
										marginTop: same_shipping_infos ? 65 : 250,
										fontSize: 50,
										fontWeight: "bolder",
										color: total > 0 ? "#4CAF50" : "#F44336",
									}}
								>
									{total.toFixed(2)}
								</h2>
							</Col>

							<Col md="2" style={{ border: "1px solid #ddd", borderRadius: 3 }}>
								<h5></h5>
							</Col>

							<Col md="2"></Col>
							<Col md="4" className="mt-2">
								<span className="form__form-group-label">Date de vente</span>{" "}
								<DatePicker
									selected={new Date(date)}
									onChange={(e) => this.changeVal("date", e)}
									placeholderText="Date"
								/>
							</Col>
							<Col md="4" className="mt-2">
								<span className="form__form-group-label">Statut</span>{" "}
								<div style={{ width: 150, display: "inline-block" }}>
									<Select
										name="status"
										defaultValue={status}
										value={statuses.filter((option) => option.value === status)}
										onChange={(e) => this.changeVal("status", e.value)}
										options={statuses}
										clearable={false}
										className="react-select"
										isOptionDisabled={(option) => {
											return option.only_admin && !user?.roles?.includes("ADMIN");
										}}
										placeholder="Statut"
										classNamePrefix="react-select"
										isDisabled={editing}
									/>
								</div>
								{/* <div className="form__form-group">
                <span className="form__form-group-label">Statut</span>
                <div className="form__form-group-field">
                  
                </div>
              </div> */}
							</Col>
							<Col md="2"></Col>

							<Col md="12" className="mt-3">
								<Table responsive hover>
									<thead>
										<tr>
											<th colSpan="2">Article</th>
											<th>Variants</th>
											{is_training_order && <th>Enfant</th>}
											{is_training_order && <th>Privilège</th>}
											<th style={{ width: 120 }}>QTE</th>
											<th style={{ width: 120 }}>Prix unitaire</th>
											<th style={{ width: 120 }}>Total</th>
											<th style={{ width: 60 }}></th>
										</tr>
									</thead>
									<tbody>
										{articles &&
											articles.map((order_article, i) => (
												<tr
													key={"article_" + i}
													style={
														!order_article.article.is_pack
															? {
																	backgroundColor: articleStockColor(
																		order_article.article.qty
																	),
															  }
															: {}
													}
												>
													<td style={{ width: 90, padding: 2 }}>
														{order_article.article && order_article.article.img ? (
															<img
																src={api.root + "/" + order_article.article.img.path}
																style={{ width: 80 }}
															/>
														) : (
															<img src={no_product_img} style={{ width: 80 }} />
														)}
													</td>

													<td style={{ verticalAlign: "middle" }}>
														{order_article.article.name}
														<p style={{ color: "#777" }}>
															{order_article.article.price} dt
														</p>
													</td>

													{/* ORDER ARTICLE VARIANTS */}
													<td style={{ verticalAlign: "middle" }}>
														{order_article.variants.map((vr, indx_vr) => {
															return (
																<div
																	className="mr-1"
																	style={{ width: 150, display: "inline-block" }}
																	key={"vr_" + indx_vr}
																>
																	<p>{vr.name}</p>
																	<Select
																		value={vr.options.filter(
																			(option) => option.selected === true
																		)}
																		onChange={(e) =>
																			this.changeArticleVariant(i, indx_vr, e.key)
																		}
																		options={vr.options}
																		clearable={false}
																		className="react-select"
																		placeholder={vr.name}
																		classNamePrefix="react-select"
																	/>
																</div>
															);
														})}
													</td>

													{is_training_order && (
														<td style={{ verticalAlign: "middle" }}>
															{this.renderArticleChilds(i, order_article.childs || [])}
														</td>
													)}

													{is_training_order && (
														<td style={{ verticalAlign: "middle" }}>
															{Number(
																(order_article.privilege || 0) *
																	(order_article.qty || 1)
															).toFixed(2)}
														</td>
													)}

													<td style={{ verticalAlign: "middle" }}>
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															type="number"
															min="1"
															defaultValue={order_article.qty}
															component="input"
															placeholder="QTE"
															onChange={(e) => {
																this.changeArticleValue(
																	i,
																	"qty",
																	Number(e.target.value)
																);

																// this.setState({discount: })
															}}
														/>
													</td>
													<td style={{ verticalAlign: "middle" }}>
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															type="number"
															min="0"
															defaultValue={order_article.unit_price}
															component="input"
															placeholder="Prix unitaire"
															onChange={(e) =>
																this.changeArticleValue(
																	i,
																	"unit_price",
																	Number(e.target.value)
																)
															}
														/>
													</td>
													<td style={{ verticalAlign: "middle" }}>
														{order_article.unit_price * order_article.qty}
													</td>
													<td style={{ verticalAlign: "middle" }}>
														<span
															onClick={() =>
																this.handleRmArticle(order_article.article._id)
															}
															style={{ color: "red", cursor: "pointer" }}
															className="lnr lnr-trash"
														></span>
													</td>
												</tr>
											))}

										{is_training_order && !client ? (
											<tr></tr>
										) : (
											<tr>
												<td
													colSpan={is_training_order ? "8" : "7"}
													style={{ fontSize: 15, fontWeight: "bold" }}
												>
													<p
														onClick={() => this.setState({ show_select_prd: true })}
														style={{
															color: "#4CAF50",
															cursor: "pointer",
															width: 100,
														}}
													>
														<span className="lnr lnr-plus-circle"></span> Ajouter
													</p>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							</Col>

							{/* <Col md="5"></Col> */}

							{/* TOTALS */}
							{/* <Col md="2"></Col> */}
							{articles.length ? (
								<Col md="5">
									<textarea
										onChange={(e) => this.setState({ note: e.target.value })}
										value={note || ""}
										rows="5"
										placeholder="Note."
										className="mt-3"
									/>
								</Col>
							) : null}
							<Col md="2" />

							{articles.length === 0 ? (
								<Col md="5"></Col>
							) : (
								<Col md="5" style={{ textAlign: "right" }}>
									<Table>
										<thead>
											<tr>
												<th></th>
												<th style={{ width: 200 }}></th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<strong>Sous-total</strong>
												</td>
												<td>{sub_total}</td>
											</tr>
											<tr>
												<td>
													<strong>Mode de livraison</strong>
												</td>
												<td>
													<Select
														defaultValue={shipping_mode}
														value={ship_modes.filter(
															(option) => option.value === shipping_mode
														)}
														onChange={(e) =>
															this.setState(
																{
																	shipping_mode: e.value,
																	shipping_price: e.price,
																	extra_price: e.extra_price || 0,
																},
																() => this.calcTotal()
															)
														}
														options={ship_modes}
														clearable={false}
														className="react-select"
														placeholder="Mode de livraison"
														classNamePrefix="react-select"
													/>
												</td>
											</tr>
											<tr>
												<td>
													<strong>Prix de livraison</strong>
												</td>
												<td>
													<div className="form__form-group-field">
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															name="shipping_price"
															type="number"
															min="0"
															value={shipping_price || 0}
															component="input"
															placeholder="Prix de livraison"
															onChange={(e) =>
																this.changeVal("shipping_price", Number(e.target.value))
															}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>{is_training_order ? "Commission" : "Remise"}</strong>
												</td>
												<td>
													<div className="form__form-group-field">
														<input
															style={{
																width: "100%",
																border: "none",
																borderBottom: "1px solid #ddd",
															}}
															name="discount"
															type="number"
															min="0"
															value={discount || 0}
															component="input"
															placeholder="Autres dépenses"
															onChange={(e) =>
																this.changeVal("discount", Number(e.target.value))
															}
														/>
													</div>
												</td>
											</tr>
											<tr>
												<td>
													<strong>Total</strong>
												</td>
												<td>
													<strong>{total}</strong>
												</td>
											</tr>
											{shipping_mode === "Poste CM" ? (
												<tr>
													<td colSpan="2">
														<input
															type="checkbox"
															checked={use_extra_price}
															onChange={(e) => {
																this.setState({ use_extra_price: e.target.checked });
															}}
															style={{
																width: 15,
																float: "left",
																marginTop: -5,
																marginRight: 5,
															}}
														/>{" "}
														Frais Poste Extra
														<strong> ({extra_price || 0} TND)</strong>
													</td>
												</tr>
											) : null}
										</tbody>
									</Table>
								</Col>
							)}
							<Col md="5"></Col>
						</Row>

						<div className="mt-5" style={{ textAlign: "right" }}>
							<ButtonToolbar
								className="modal__footer form__button-toolbar"
								style={{ textAlign: "right" }}
							>
								{loading_add_order || loading_edit_order ? (
									<Button color="primary">...</Button>
								) : (
									<>
										{error_frm && error_frm !== "" ? (
											<Button color="warning mr-4" onClick={() => this.submit(true)}>
												Soumettre quand même
											</Button>
										) : null}
										<Button color="primary" onClick={() => this.submit()}>
											Soumettre
										</Button>
									</>
								)}
								<NavLink to={is_training_order ? "/app/orders-trains/list" : "/app/orders"}>
									<Button type="button" onClick={this.props.toggle}>
										Annuler
									</Button>
								</NavLink>
							</ButtonToolbar>
						</div>
					</CardBody>
				</Card>

				{/* MODALS */}
				<SelectArticle
					size="lg"
					multi={true}
					// order_trainings={(order_trainings)}

					isOpen={show_select_prd}
					toggleOpen={this.toggleSelectArticleMdl}
					accept={this.addOrderArticle}
					selectedItems={selected_articles}
					only_internal={is_training_order}
					only_external={is_training_order ? false : true}
					only_supply={is_supply_order}
					// internal_filter={is_training_order ? "internal" : "external"}
				/>

				<SelectClient
					size="lg"
					multi={false}
					first_name={first_name}
					first_name_ar={first_name_ar}
					city={city}
					region={region}
					phone={phone}
					phone2={phone2}
					address={address}
					type={type}
					changeVal={this.changeVal}
					is_training_order={is_training_order}
					isOpen={show_select_client}
					toggleOpen={this.toggleSelectClientMdl}
					accept={this.addOrderClient}
					selectedItems={client ? [client] : []}
				/>

				{/* <AddChildMdl isOpen={show_add_child} add_to_article={true}
        toggleOpen={() => this.setState({ show_add_child: !show_add_child })}
        addToArticle={(created_child) => this.addChildToArticle(created_child, indx_article_child)}
      /> */}

				{(!is_training_order || (is_training_order && client)) && (
					<SelectChildMdl
						isOpen={show_add_child}
						add_to_article={true}
						toggleOpen={() => this.setState({ show_add_child: !show_add_child })}
						addToArticle={(created_childs) => {
							this.addChildsToArticle(created_childs, indx_article_child);
						}}
						nb_childs={articles[indx_article_child]?.qty || 1}
						former={client}
						accept={(selected_childs) => this.addChildsToArticle(selected_childs, indx_article_child)}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = ({ order, shipMode, auth }) => {
	const {
		loading_add_order,
		error_add_order,
		new_order,
		selected_order,
		loading_one_order,
		error_one_order,
		loading_edit_order,
		error_edit_order,
	} = order;

	const { ship_modes, loading_get_ship_modes, error_get_ship_modes } = shipMode;

	return {
		new_order,
		selected_order,
		loading_add_order,
		error_add_order,
		loading_one_order,
		error_one_order,
		loading_edit_order,
		error_edit_order,

		ship_modes,
		loading_get_ship_modes,
		error_get_ship_modes,

		user: auth?.user,
	};
};
export default connect(mapStateToProps, {
	addOrder,
	editOrder,
	getOneOrder,
	getShipModes,
})(OrderCmp);
