import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ArticleCmp from '../../shared/components/articles/article-cmp';

export default class EditArticlePage extends PureComponent {

  constructor() {
    super();
    this.state = {
      article_id: ''
    };
  }

  componentDidMount(){
    const { match } = this.props
    this.setState({article_id: (match && match.params) ? match.params.articleId : '' })
  }

  render(){
    return (<Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Article</h3>
        </Col>
        <Col md={12}>
          <ArticleCmp editing={true} match={this.props.match} article_id={this.state.article_id} history={this.props.history}/>
        </Col>
      </Row>
    </Container>)
  }
}