import React, { PureComponent } from 'react';
// import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ClientCmp from '../../shared/components/clients/client-cmp';

export default class AddClientPage extends PureComponent {

  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return(<Container>
      <h3 className="page-title mb-3">Ajouter un client</h3>
      <ClientCmp history={this.props.history} adding={true} />
    </Container>)
  }
}
