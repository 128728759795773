import React, { useState, PureComponent } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { Field, reduxForm } from 'redux-form';

import { 
  Button, ButtonToolbar, Card, CardBody, Row, Col, 
  //Nav, NavItem, NavLink, TabContent, TabPane, Table, Input
} from 'reactstrap';
// import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";


import {
  addProvider, editProvider, getOneProvider
} from "../../../redux/actions/providersActions";


// import Switch from "rc-switch";
// import "rc-switch/assets/index.css";

// import classnames from 'classnames';
import { api } from '../../../const';
// import SelectProvider from './SelectProvider';


const pub_url = process.env.PUBLIC_URL

class ProviderCmp extends PureComponent {

  constructor() {
    super();
    this.state = {
      name: '',
      phone: '',
      address: "",
      ref: "",
      
      img: null,
      img_url: '',

      error_frm: ""
    };
  }

  changeVal = (key, value) => {
    const obj = {}; obj[key] = value;
    this.setState(obj)
  }

  changeImg = (e, name = "img") => {
    try {
      const img = e.target.files[0]
      const obj = {}
      obj[name] = img;
      obj[name+"_url"] = URL.createObjectURL(img) 

      this.setState(obj)
    } catch (error) { 
      // this.setState({ img: null, img_url: '' }) 
    }
  }


  submit = () => {
    const error_frm = this.checkFrm(this.state)
    this.setState({ error_frm })

    const { addProvider, editProvider, editing } = this.props
    const { providers } = this.state
    
    if (error_frm == ""){
      const frm = new FormData()
      frm.append("name", this.state.name)
      frm.append("phone", this.state.phone)
      frm.append("address", this.state.address)
      
      if(this.state.ref && this.state.ref!='')
      frm.append("ref", this.state.ref)

      if(this.state.img && this.state.img_url!='')
      frm.append("img", this.state.img)
      

      if(editing) editProvider({_id: this.props.provider_id, data: frm})

      else addProvider(frm)
    } 
  }
  checkFrm = (data) => {
    const { name } = data
    if (name == "") return "Le nom est requis.";
    return ""
  }

  componentDidUpdate(prev) {
    const { 
      loading_add_provider, error_add_provider, new_provider,
      selected_provider, loading_one_provider, error_one_provider,
      loading_edit_provider,  error_edit_provider
    } = this.props

    if (!loading_add_provider && prev.loading_add_provider) {

      // console.log("error_add_provider", error_add_provider, new_provider);
      if (error_add_provider) {
        if (error_add_provider.message && error_add_provider.message != "")
        this.setState({ error_frm: error_add_provider.message })
      }

      else 
      this.props.history.push('/app/providers/edit/'+new_provider._id)
    }



    // EDITING
    if (!loading_edit_provider && prev.loading_edit_provider) {

      // console.log("error_add_provider", error_add_provider, new_provider);
      if (error_edit_provider) {
        if (error_edit_provider.message && error_edit_provider.message != "")
        this.setState({ error_frm: error_edit_provider.message })
      }

      else  this.props.history.push('/app/providers/providers')
    }



    //GET ONE
    if(prev.loading_one_provider && !loading_one_provider && !error_one_provider){
      if(selected_provider){
        const { name, phone, address, ref, img } = selected_provider

        this.setState({name, phone, address, ref, img, img_url: ''})
      }
    }
  }

  componentDidMount(){
    if(this.props.editing){
      const { match, getOneProvider } = this.props
      if(match && match.params) 
      getOneProvider({provider_id: match.params.providerId})
    }
  }





  renderProviderImage = (img, img_url, editing) => {
    if(editing){
      if(img && img_url=='') return(<img src={api.root +"/"+ img.path} />)
      else if(img && img_url!="") return(<img src={img_url} />)

      return(<img src={`${pub_url}/img/no_user.png`} />)
    }
    
    return (<img src={(!img || img_url== '') ? ` ${pub_url}/img/no_user.png` : img_url} />)
  }

  render() {
    const { editing } = this.props
    const { name, phone, address, ref, img, img_url } = this.state
    
    return (<div>
      <Card>
        <CardBody>
          {/* {adding && <div className="card__title">
            <h5 className="bold-text">Ajouter un</h5>
            <h5 className="subhead">Use default button with necessary values of properties
            <span className="red-text"> color</span>,
            <span className="red-text"> outline</span> and
            <span className="red-text"> disabled</span>
            </h5>
          </div>} */}

          <Row className="form">
            <Col md="12" style={{ marginTop: 10, marginBottom: 10 }}>
              <Alert is_visible={(this.state.error_frm != "")} color="danger" className="alert--colored" icon >
                <p><span className="bold-text">Attention!</span> {this.state.error_frm}</p>
              </Alert>
            </Col>

            <Col md="4">
              <div className="add_img_wrap">
                {this.renderProviderImage(img, img_url, editing)}
                

                <div className="caption">
                  <label htmlFor="add_avatar"><span className="lnr lnr-picture"></span></label>
                  <input onChange={(e) => this.changeImg(e)} id="add_avatar" type="file" accept="image/*" hidden />
                </div>
              </div>
            </Col>
            <Col md="8">
              <Row>
                <Col m="6">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Nom</span>
                    <div className="form__form-group-field">
                      <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                        name="name" defaultValue={name} component="input" placeholder="Nom"
                        onChange={(e) => this.changeVal('name', e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Téléphone</span>
                    <div className="form__form-group-field">
                      <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                        name="phone" type="number" defaultValue={phone} component="input" placeholder="Téléphone"
                        onChange={(e) => this.changeVal('phone', e.target.value)}
                      />
                    </div>
                  </div>
                </Col>


                
                <Col md="12">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Adresse</span>
                    <div className="form__form-group-field">
                      <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                        name="address" defaultValue={address} component="input" placeholder="Adresse"
                        onChange={(e) => this.changeVal('address', e.target.value)}
                      />
                    </div>
                  </div>
                </Col>



                {editing && <Col md="6">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Référence</span>
                    <div className="form__form-group-field">
                      <input style={{ width: '100%', border: 'none', borderBottom: '1px solid #ddd' }}
                        name="ref" defaultValue={ref} component="input" placeholder="Référence"
                        onChange={(e) => this.changeVal('ref', e.target.value)}
                      />
                    </div>
                  </div>
                </Col>}
              </Row>
            </Col>
          </Row>

          <div className="mt-5" style={{textAlign: 'right'}}>
            <ButtonToolbar className="modal__footer form__button-toolbar" style={{textAlign: 'right'}}>
              <Button color="primary" onClick={this.submit}>Soumettre</Button>
              <Button type="button" onClick={this.props.toggle}>Annuler</Button>
            </ButtonToolbar>
          </div>

        </CardBody>
      </Card>




      {/* MODALS */}
      {/* <SelectProvider size="lg" multi={true}
          isOpen={show_select_prd} toggleOpen={this.toggleAddProduct}
          accept={this.addProducts} selectedItems={products}
        /> */}
    </div>
    );
  }
}

const mapStateToProps = ({ provider }) => {
  const { 
    loading_add_provider, error_add_provider, new_provider, 
    selected_provider, loading_one_provider, error_one_provider,
    loading_edit_provider, error_edit_provider 
  } = provider
  return {
    new_provider, selected_provider, 
    loading_add_provider, error_add_provider,
    loading_one_provider, error_one_provider,
    loading_edit_provider, error_edit_provider
  }
}
export default connect(mapStateToProps, {
  addProvider, editProvider, getOneProvider
})(ProviderCmp);