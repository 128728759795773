import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";

import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
// import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import renderCheckBoxField from "../../../shared/components/form/CheckBox";

import { login } from "../../../redux/actions/authActions";

class LogInForm extends PureComponent {
	static propTypes = {
		handleSubmit: PropTypes.func.isRequired,
	};

	constructor() {
		super();
		this.state = {
			showPassword: false,
			email: "",
			pass: "",
		};
	}

	showPassword = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
	};

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	// componentDidUpdate(prev){
	//   // console.log("PREV", );
	// }

	render() {
		// const { handleSubmit } = this.props;
		const { showPassword, email, pass } = this.state;

		if (this.props.user) return <Redirect to={{ pathname: "/app", state: { from: this.props.location } }} />;
		else
			return (
				<form
					className="form"
					onSubmit={async (e) => {
						e.preventDefault();

						if (email !== "" && pass !== "") this.props.login({ email, pass });
					}}
				>
					<div className="form__form-group">
						<span className="form__form-group-label">Email</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<AccountOutlineIcon />
							</div>
							<Field
								name="email"
								value={email}
								onChange={(e) => this.changeVal("email", e.target.value)}
								component="input"
								type="text"
								placeholder="Email"
							/>
						</div>
					</div>
					<div className="form__form-group">
						<span className="form__form-group-label">Mot de passe</span>
						<div className="form__form-group-field">
							<div className="form__form-group-icon">
								<KeyVariantIcon />
							</div>
							<Field
								name="pass"
								value={pass}
								onChange={(e) => this.changeVal("pass", e.target.value)}
								component="input"
								type={showPassword ? "text" : "password"}
								placeholder="Mot de passe"
							/>
							<button
								className={`form__form-group-button${showPassword ? " active" : ""}`}
								onClick={(e) => this.showPassword(e)}
								type="button"
							>
								<EyeIcon />
							</button>
						</div>
						<div className="account__forgot-password">
							<a href="/">Mot de passe oublié?</a>
						</div>
					</div>
					<div className="form__form-group">
						<div className="form__form-group-field">
							<Field name="remember_me" component={renderCheckBoxField} label="Souviens-toi de moi" />
						</div>
					</div>
					<Button className="account__btn" type="submit" color="primary">
						Se connecter
					</Button>
					{/* <Link className="btn btn-outline-primary account__btn account__btn--small" to="/log_in">Create Account</Link> */}
				</form>
			);
	}
}

// LogInForm.propTypes = {
//   handleSubmit: PropTypes.func.isRequired
// };

// export default connect(state => ({
//   // errorMsg: state.user.error,
//   form: 'log_in_form',
// }))(reduxForm()(LogInForm));

const mapStateToProps = ({ auth }) => {
	return {
		form: "log_in_form",
		loading_login: auth.loading_login,
		user: auth.user,
	};
};
export default connect(mapStateToProps, {
	login,
})(reduxForm()(LogInForm));
