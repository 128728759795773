import React, { useState, useEffect } from "react";
import {
  Input, Table, Modal, ModalBody, ModalFooter, Button, Alert
  // CustomInput
} from "reactstrap";

import Select from 'react-select';

import ModalHeader from "reactstrap/lib/ModalHeader";
import Axios from "axios";
import { api } from "../../../const";
// import { addChild } from "../../../redux/actions/childsActions";
// import ChildCmp from "./child-cmp";

export const levels = [{
  label: 'Préparatoire', value: "0", key: 0
}, {
  label: '1ère année', value: '1', key: 1
}, {
  label: '2ième année', value: '2', key: 1
}, {
  label: '3ième année', value: '3', key: 1
}, {
  label: '4ième année', value: '4', key: 1
}, {
  label: '5ième année', value: '5', key: 1
}, {
  label: '6ième année', value: '6', key: 1
}, {
  label: '7ième année', value: '7', key: 1
}, {
  label: '8ième année', value: '8', key: 1
}, {
  label: '9ième année', value: '9', key: 1
}]

const child_data = {
  first_name: '',
  last_name: '',

  first_name_ar: '',
  last_name_ar: '',

  age: 1,
  education_level: '',
  registration_date: new Date(),
  former: null
}

// class AddChildMdl extends Component {
const AddChildMdl = props => {
  const [new_childs, setNewChilds] = useState([])
  const [loading_submit, setLoadingSubmit] = useState(false)
  const [error_msg, setErrorMsg] = useState("");

  const { isOpen, size, toggleOpen, accept, nb_childs, former, addToArticle } = props;

  useEffect(() => {
    const childs = [];

    let i = 0;
    do {
      childs.push({ ...child_data, former: former?._id })

      i += 1;
    } while (i < (nb_childs || 1));


    setNewChilds(childs)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addChildData = () => {
    try {
      const new_data = [...new_childs]
      new_data.push({ ...child_data, former: former?._id })

      setNewChilds(new_data)
    } catch (error) { }
  }

  const changeChildVal = (indx, key, value) => {
    try {
      const new_data = [...new_childs]
      new_data[indx][key] = value

      setNewChilds(new_data)
    } catch (error) { }
  }

  const checkFrm = (data = {}) => {
    const { first_name_ar, first_name, last_name_ar, last_name, age, education_level } = data
    if (first_name_ar === "" && first_name === "") return "Le nom est requis.";
    if (last_name_ar == "" && last_name === "") return "Le prénom est requis.";
    if (!age) return "L'age est requis.";

    if (!former) return "Le formateur est requis.";


    if (!education_level || education_level === "") return "Le niveau éducatif est requis.";
    return ""
  }

  const submit = () => {
    setErrorMsg("");
    const promises = [];
    let error_frm = "";

    for (const child of new_childs) {
      error_frm = checkFrm(child)

      if (error_frm === "") promises.push(new Promise((resolve, reject) => {
        Axios.post(api.childs + '/create', child)
          .then(resp => {
            resolve(resp?.data.child)
          }).catch(error => { })
      }));

      else { setErrorMsg(error_frm); break; }
    }

    if (error_frm === "") {
      setLoadingSubmit(true);

      Promise.all(promises).then(resp => {
        setLoadingSubmit(false);
        addToArticle(resp || [])
        toggleOpen()
      }).catch(e => { 
        setLoadingSubmit(false);
        setErrorMsg("Something went wrong. Please check your data and try again.");
      })
    }
  }

  return (<Modal isOpen={isOpen} size={size || "lg"} toggle={toggleOpen} className={"mdl_wraper"} style={{ maxWidth: "90%" }} >
    <ModalHeader>
      {/* <h3 className="page-title mb-3">Ajouter un {add_as_participant ? 'participant' : 'child'} </h3> */}
      Ajouter un Enfant (Formateur : {
        (former && former.first_name_ar && former.first_name_ar != "")
          ? former.first_name_ar
          : former?.first_name
      })
    </ModalHeader>
    <ModalBody style={{ padding: 0, textAlign: 'left' }}>
      {/* <ChildCmp adding={true} add_as_participant={true} addParticipant={(created_child)=>addParticipant(created_child)} /> */}

      {/* <ChildCmp adding={true} former={former} addToArticle={addToArticle} add_to_article={(add_to_article)} 
          toggleOpen={()=>toggleOpen()}
        /> */}

      {error_msg !== "" ? <Alert color="danger" className="alert--colored" icon >
        <p><span className="bold-text">Attention ! </span> {error_msg}</p>
      </Alert> : null}

      <Table striped>
        <thead>
          <th>الإسم</th>
          <th>اللقب</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Age</th>
          <th style={{ width: 150 }}>Niveau scolaire</th>
          <th></th>
        </thead>
        <tbody>
          {new_childs?.map((item, i) => {
            if (!item) return null;
            return (<tr key={"new_child_" + i}>
              <td>
                <Input className="form-control" value={item.first_name_ar}
                  onChange={(e) => changeChildVal(i, 'first_name_ar', e.target.value)}
                />
              </td>
              <td>
                <Input className="form-control" value={item.last_name_ar}
                  onChange={(e) => changeChildVal(i, 'last_name_ar', e.target.value)}
                />
              </td>
              <td>
                <Input className="form-control" value={item.first_name}
                  onChange={(e) => changeChildVal(i, 'first_name', e.target.value)}
                />
              </td>
              <td>
                <Input className="form-control" value={item.last_name}
                  onChange={(e) => changeChildVal(i, 'last_name', e.target.value)}
                />
              </td>
              <td>
                <Input type="number" className="form-control" value={item.age} min="1"
                  onChange={(e) => changeChildVal(i, 'age', e.target.value)} style={{ width: 70 }}
                />
              </td>
              <td>
                <Select defaultValue={item.education_level}
                  value={levels.filter(option => option.value === item.education_level)}
                  onChange={(e) => changeChildVal(i, 'education_level', e.value)}
                  options={levels} clearable={false}
                  className="react-select"
                  placeholder="Niveau scolaire" classNamePrefix="react-select"
                  style={{ width: 150 }}
                />
              </td>
              <td>
                <i className="lnr lnr-trash" style={{ color: "red", cursor: "pointer", verticalAlign: "middle" }} onClick={() => {
                  try {
                    const new_data = [...new_childs];
                    new_data.splice(i, 1);
                    setNewChilds(new_data)
                  } catch (error) { }
                }} />
              </td>
            </tr>)
          })}
        </tbody>
      </Table>

      <Button color="info" size="sm" style={{ marginLeft: 5 }} disabled={loading_submit}
        onClick={() => addChildData()}>Ajouter enfant
      </Button>
    </ModalBody>

    <ModalFooter style={{ paddingBottom: 0 }}>
      <Button color="primary" disabled={loading_submit} onClick={() => submit()}>Ajouter tout</Button>{" "}
      <Button color="secondary" onClick={toggleOpen}>Annuler</Button>
    </ModalFooter>
  </Modal>
  );

}

export default AddChildMdl