import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import PropTypes from "prop-types";

import { Table, Tag, Space } from "antd";

import { EyeOutlined } from "@ant-design/icons";
import Axios from "axios";
import { api, article_levels, statuses } from "../../../../const";
import "./trainer.scss";
import moment from "moment";

const columns = [
	{
		title: "Date",
		dataIndex: "date",
		key: "date",
		width: 110,

		render: (text) => {
			return <span>{moment(text).format("DD-MM-YYYY")}</span>;
		},
	},
	{
		title: "Niveaux",
		dataIndex: "levels",
		key: "levels",
		width: 360,

		render: (text, record) => {
			return renderLevels(record);
		},
	},
	{
		title: "Total (TND)",
		dataIndex: "total",
		key: "total",
		width: 120,

		render: (text, record) => {
			return <Tag color="green">{text}</Tag>;
		},
	},
	{
		title: "Statut",
		dataIndex: "status",
		key: "status",
		width: 120,

		render: (text) => {
			const stats = statuses.filter((option) => option.value === text);
			if (stats && stats[0]) return <span>{stats[0].label}</span>;

			return "";
		},
	},
	{
		title: "Livraison",
		dataIndex: "shipping_mode",
		key: "shipping_mode",
		width: 120,

		render: (text) => {
			return <span>{text}</span>;
		},
	},
	{
		title: "Référence",
		dataIndex: "ref",
		key: "ref",
	},

	{
		title: "",
		key: "action",
		align: "center",
		width: 50,

		render: (text, record) => (
			<Space size="middle">
				<NavLink to={`/app/orders-trains/view/${record.key}`}>
					<EyeOutlined />
				</NavLink>
			</Space>
		),

		fixed: "right",
	},
];

const renderLevels = (order = {}) => {
	const { articles = [] } = order;
	const levels = JSON.parse(JSON.stringify(article_levels));

	let count_all = 0;
	for (const article of articles) {
		const level = article?.level || article.article?.level;
		if (level) {
			try {
				levels[level.index - 1].sub_indexes[level.sub_index - 1].count += article.qty || 1;
				count_all += article.qty || 1;
			} catch (error) {
				console.log("ERR INCREMENT INTO LEVEL", error);
			}
		}
	}

	return (
		<Space size={3}>
			{levels.map((level, indx) => {
				if (!level) return null;

				return (
					<div key={`level-${indx}-order-${order._id}`}>
						{/* <div>Niveau {level.index}</div> */}
						<Space size={3}>
							{level.sub_indexes.map((sub_level, indx_sub) => {
								return (
									<div key={`sub-level-${indx}-${indx_sub}-order-${order._id}`}>
										<div style={{ textAlign: "center", fontWeight: "bold" }}>{sub_level.name}</div>
										<div
											style={{
												textAlign: "center",
												background: sub_level.color,
												color: "#444",
												fontWeight: "bold",
												padding: "5px 10px",
											}}
										>
											{sub_level.count}
										</div>
									</div>
								);
							})}
						</Space>
					</div>
				);
			})}

			{count_all ? (
				<div
					style={{
						width: 30,
						textAlign: "center",
						fontWeight: "bold",
						fontSize: 17,
						color: "#50b179",
						paddingTop: 20,
					}}
				>
					{count_all}
				</div>
			) : null}
		</Space>
	);
};

const TrainerCmdCM = (props) => {
	const {
		trainer_id,
		changeCountAll = (count_cmd) => {},
		// ship_modes = []
	} = props;

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [limit, setLimit] = useState(10);
	const [count, setCount] = useState(0);

	const getPage = (page = 1) => {
		const offset = page == 1 || page == 0 ? 0 : limit * (page - 1);

		const filter = {
			client: trainer_id,
			is_training_order: true,
		};
		// if (sort_fields?.length) filter["sort"] = sort_fields[0];

		setLoading(true);

		Axios.post(`${api.orders}/filter/${offset}/${limit}`, filter || {})
			.then((resp) => {
				setLoading(false);

				setCount(resp?.data?.count || 0);
				changeCountAll(resp?.data?.count || 0)

				const new_data = resp?.data?.orders?.map((item) => {
					return {
						...item,
						key: item._id,
						total: Number(item.total || 0).toFixed(2) || "",
					};
				});

				setData(new_data || []);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		if (trainer_id) getPage();
	}, [trainer_id, limit]);

	return (
		<React.Fragment>
			{/* <h4>Total : {count} </h4> */}
			<Table
				columns={columns}
				dataSource={data}
				scroll={{ x: 1100 }}
				loading={loading}
				pagination={{
					total: count,
					pageSize: limit,
					pageSizeOptions: [10, 20, 30],
				}}
				onChange={(pagination, filters, sorter, extra) => {
					// const sort_fields = [];
					// if (sorter?.column)
					// 	sort_fields.push({
					// 		field: sorter?.field,
					// 		order: sorter?.order === "ascend" ? "asc" : "desc",
					// 	});

					setLimit(pagination?.pageSize || 10);
					getPage(pagination?.current || 1);
				}}
			/>
		</React.Fragment>
	);
};

TrainerCmdCM.prototype = {
	changeCountAll: PropTypes.func.isRequired,
	// getShipModes: PropTypes.arrayOf(PropTypes.object).isRequired,
	trainer_id: PropTypes.string,
};
export default TrainerCmdCM;
