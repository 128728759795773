import {
  GET_ORDERS, GET_ORDERS_SUCCESS, GET_ORDERS_ERROR,
  ADD_ORDER, ADD_ORDER_SUCCESS, ADD_ORDER_ERROR,
  GET_ONE_ORDER, GET_ONE_ORDER_SUCCESS, GET_ONE_ORDER_ERROR,
  EDIT_ORDER, EDIT_ORDER_SUCCESS, EDIT_ORDER_ERROR,
  DELETE_ORDER, DELETE_ORDER_SUCCESS, DELETE_ORDER_ERROR
} from '../actions/ordersActions';

const initialState = {
  loading_get_orders: false,
  error_get_orders: null,
  orders: [],
  count_all_orders: 0,

  new_order: null, loading_add_order: false, error_add_order: null,

  selected_order: null, 
  loading_one_order: false, error_one_order: null,
  loading_edit_order: false, error_edit_order: null,

  loading_delete_order: false, error_delete_order: null
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_ORDERS: return {...state, loading_get_orders: true, error_get_orders: null };
    case GET_ORDERS_SUCCESS:
      return {...state, orders: action.payload.orders, loading_get_orders: false, error_get_orders: null, count_all_orders: action.payload.count };
    case GET_ORDERS_ERROR:
      // console.log("ACTION", {error_get_orders: action.error, loading_get_orders: false, orders: []});
      return {...state, error_get_orders: action.error, loading_get_orders: false, orders: [] };



    case ADD_ORDER: return {...state, loading_add_order: true, error_add_order: null };
    case ADD_ORDER_SUCCESS:
      return {...state, new_order: action.payload.order, loading_add_order: false, error_add_order: null };
    case ADD_ORDER_ERROR:
      return {...state, error_add_order: action.error, loading_add_order: false, new_order: null };


    case GET_ONE_ORDER: return {...state, loading_one_order: true, error_one_order: null };
    case GET_ONE_ORDER_SUCCESS:
      return {...state, selected_order: action.payload.order, loading_one_order: false, error_one_order: null };
    case GET_ONE_ORDER_ERROR:
      return {...state, error_one_order: action.error, loading_one_order: false, selected_order: null };



    case EDIT_ORDER: return {...state, loading_edit_order: true, error_edit_order: null };
    case EDIT_ORDER_SUCCESS:
      return {...state, selected_order: action.payload.order, loading_edit_order: false, error_edit_order: null };
    case EDIT_ORDER_ERROR:
      return {...state, error_edit_order: action.error, loading_edit_order: false, selected_order: null };




    case DELETE_ORDER: return {...state, loading_delete_order: true, error_one_order: null };
    case DELETE_ORDER_SUCCESS:
      return {...state, loading_delete_order: false, error_delete_order: null };
    case DELETE_ORDER_ERROR:
      return {...state, error_delete_order: action.error, loading_delete_order: false };


    default: return state;
  }
};

export default ordersReducer;
