import React, { Component } from "react";

import classnames from "classnames";

export default class Steps extends Component {
  constructor(props) {
    super(props);

    const { messages } = this.props.intl || {};
    
    this.state = {}
  }

  changeStep = (step, index) =>{
    this.props.changeStep({step, index})
  }

  render() {
    // const { } = this.state;
    const { steps, active_step } = this.props;

    return (<div className="menu_steps mb-3">
      <div className="bx-pager bx-default-pager">
        {Array.isArray(steps) && steps.map((step, index)=><div key={"step_"+index} 
          onClick={()=>this.changeStep(step, index)}
          className={classnames({"bx-pager-item": true, active: (active_step==step.value)})}
          style={(step.value=="main") ? {flex: "0 1 10%"} : {}}
          >
          {(step.value !='main') && <a className="bx-pager-link"> {step.label} </a>}

          {(step.value =='main') && <a className="bx-pager-link"> <span className="lnr lnr-home" style={{fontSize: 20}}></span> </a>}
          <div className="arrow"></div>
        </div>)}
      </div>

    </div>)
  }

}