/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import {
//   PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
// } from 'recharts';

// import { withTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';

// import { getTooltipStyles } from '../../shared/helper/utils';

import {
  Card, CardBody, Button, Container, //Row, Col, 
  // Table
} from 'reactstrap';

import axios from "axios";
import { api } from '../../const';
// import CardTitle from 'reactstrap/lib/CardTitle';

// import { NavLink } from 'react-router-dom';

// import { getCountPages } from '../../helper/utils';
// import Pagination from '../../shared/components/pagination/Pagination';

import moment from "moment"
import OrderGains from './OrderGains';
moment.locale('fr')

class DashStore extends Component {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    // dir: PropTypes.string.isRequired,
    // themeName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,

      wait_backup: false,

      orders: []
    };
  }

  onMouseMove = (e) => {
    const { dir } = this.props;
    if (e.tooltipPosition) {
      this.setState({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };


  // print = () => {
  //   axios.post(api.orders + '/print-parcels', {}).then(resp => {
  //     // console.log("resp print", resp);
  //     if (resp.status == 200 && resp.data && !resp.data.err) {
  //       window.open(api.root + "/" + resp.data.pdf + "?" + new Date());
  //     }
  //     else alert("Veuillez vérifier vos données et réessayer")
  //   }).catch(error => {
  //     alert("Veuillez vérifier vos données et réessayer")
  //   })
  // }


  downloadBackup = () => {
    this.setState({ wait_backup: true })

    axios.post(api.users + '/exec', {}).then(resp => {
      if (resp.status == 200 && resp.data && !resp.data.err) {
        setTimeout(() => {
          window.open(api.root + "/public/backup.zip");
          this.setState({ wait_backup: false })
        }, 5500);
      }
      else {
        alert("Veuillez vérifier vos données et réessayer")
        this.setState({ wait_backup: false })
      }
    }).catch(error => {
      alert("Veuillez vérifier vos données et réessayer")
      this.setState({ wait_backup: false })
    })
  }



  render() {
    const { wait_backup, orders, count_all_orders } = this.state;
    const { user } = this.props;

    return (<Container className="dashboard">
      <h3 className="page-title mb-2">Tableau de bord</h3>
      <Card>
        <CardBody>
          {user?.roles?.includes("ADMIN") && <Button onClick={() => { if (!wait_backup) this.downloadBackup() }} color="primary">
            {!wait_backup && <span className="lnr lnr-database mr-1"></span>}
            {!wait_backup && "Télécharger backup"}

            {wait_backup && "......"}
          </Button>}
        </CardBody>
      </Card>





      {user?.roles?.includes("ADMIN") && <OrderGains />}
    </Container >);
  }
}

const mapStateToProps = ({ auth, theme }) => {
  return {
    // theme_mode: theme.className,
    user: auth.user
  }
}
export default connect(mapStateToProps, {

})(DashStore);