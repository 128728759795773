import React, { PureComponent } from 'react';

import {
  Row, Col, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { api } from '../../../const';

import classnames from 'classnames';


export default class VariantsCmp extends PureComponent {

  constructor() {
    super();
    this.state = {
      active_tab: ''
    };
  }

  // renderCtg = (item, i) => {
  //   const { select_one, checkCtg } = this.props

  //   return (<div>
  //     <div key={"ctg_" + i + item._id} style={{
  //       backgroundColor: '#ddd',
  //       padding: 10,
  //       borderRadius: 5,
  //       marginBottom: 5
  //     }}>
  //       {select_one && <input type="radio" name="ctg" style={{marginRight: 5, maxWidth: 20, height: 15}} value={item._id} onChange={(e)=>checkCtg(e.target.value, item)} />}
  //       <img style={{ display: 'inline-block', width: 50, marginRight: 5 }} src={item.img ? `${api.root}/${item.img.path}` : ` ${process.env.PUBLIC_URL}/img/no_product.png`} />
  //       <p style={{ display: "inline-block" }} >{item.name}</p>
  //     </div>

  //     {item.children && <div style={{marginLeft: 25 }}>
  //       {item.children.map((ctg, j)=>this.renderCtg(ctg, j))}
  //     </div> }
  //   </div>)
  // }

  toggle = (active_tab) => this.setState({ active_tab })

  render() {
    const { data, 
      addVariant, deleteVariant, changeVariantName, addVariantOption, deleteVariantOption, changeVariantOptionName 
    } = this.props
    const { active_tab } = this.state
    if (!data) return null

    // console.log("DATA", data);
    return (<div>
      <div className="tabs tabs--vertical mt-4">
        <div className="tabs__wrap">
          <Nav tabs>
            {data.map((variant, i) => <NavItem key={"VARIANT_" + i}>
              {(variant && typeof variant!='string') && <NavLink
                className={classnames({ active: active_tab === variant.name })}
                onClick={() => {
                  this.toggle(variant.name);
                }}
              >{variant.name}</NavLink>}
            </NavItem>)}

            <NavItem>
              <span onClick={() => {
                addVariant()
                // if(data.length>0) this.setState({active_tab: data[data.length-1].name})
              }} className="lnr lnr-plus-circle" style={{
                color: "#50b179", fontSize: 18, cursor: "pointer"
              }}></span>
            </NavItem>
          </Nav>



          <TabContent activeTab={active_tab} style={{ width: "100%", minHeight: 200 }}>
            {data.map((variant, i) => <TabPane key={"VARIANT_CONTENT_" + i} tabId={variant.name}>
              {(variant && typeof variant!='string') && <div className="form">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input style={{ maxWidth: 150, border: 'none', borderBottom: '1px solid #ddd' }}
                      component="input" placeholder="Nom de variant" defaultValue={variant.name}
                      onChange={(e) => {
                        changeVariantName(e.target.value, i)
                        this.setState({ active_tab: e.target.value })
                      }}
                    />
                  </div>

                  {/* <Row> */}
                  {variant.options.map((option, j) => <Col md="4" key={"OPTION_" + i + j} >
                    <div className="form__form-group-field">
                      <input style={{ border: 'none', borderBottom: '1px solid #ddd' }}
                        component="input" placeholder={"option " + (j + 1)} defaultValue={option}
                      onChange={(e)=>{
                        changeVariantOptionName(e.target.value, i, j)
                      }}
                      />

                      {j == variant.options.length - 1 && <span onClick={() => {
                        addVariantOption(i)
                        // if(data.length>0) this.setState({active_tab: data[data.length-1].name})
                      }} className="lnr lnr-plus-circle" style={{
                        color: "#50b179", fontSize: 18, cursor: "pointer"
                      }}></span>}


                      {j < variant.options.length - 1 && <span onClick={() => {
                        deleteVariantOption(i, j)
                        // if(data.length>0) this.setState({active_tab: data[data.length-1].name})
                      }} className="lnr lnr-trash" style={{
                        color: "#F44336", fontSize: 18, cursor: "pointer"
                      }}></span>}
                    </div>
                  </Col>)}
                  {/* </Row> */}
                </div>



                <div onClick={() => deleteVariant(i)} className="mt-4" style={{ width: '100%' }}>
                  <p style={{ color: '#F44336', cursor: 'pointer', width: 160, float: 'right' }}> <span className="lnr lnr-trash"></span> supprimer une variante</p>
                </div>
              </div>}
            </TabPane>)}
          </TabContent>
        </div>
      </div>



    </div>)
  }
}