import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
	Card,
	CardBody,
	Badge,
	Table,
	Button,
	Row,
	Col,
	// ButtonGroup, ButtonToolbar,
} from "reactstrap";

// import Select from 'react-select';
import moment from "moment";

import { getChilds, editChild, deleteChild } from "../../../redux/actions/childsActions";
import { getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";

// import { CheckBoxField } from '../form/CheckBox';

import { NavLink } from "react-router-dom";
import ConfirmMdl from "../confirm-mdl";
// import { api } from '../../../const';
moment.locale("fr");
// const req_statuses = [{label: 'Request', value: 'request'}, {label: 'Accepted', value: 'accepted'}, {label: 'Refused', value: 'refused'}]

class ListChildsCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			childs: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			show_delete_msg: false,
			req_delete_id: null,

			query: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	componentWillMount() {
		this.getPage();
	}

	componentDidUpdate(prev) {
		const { loading_one_child, error_one_child, loading_delete_child, error_delete_child } = this.props;

		if (!loading_one_child && prev.loading_one_child && !error_one_child) {
			this.getPage(this.state.current_page);
		}

		if (prev.loading_delete_child && !loading_delete_child && !error_delete_child) {
			this.getPage(this.state.current_page);
		}
	}
	getPage(page = 1) {
		const { selected_page_size, filter_all, filter_status, query } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {};
		if (!filter_all) filter["statuses"] = filter_status;

		if (query && query != "") filter["query"] = query;

		this.props.getChilds({ filter, offset, limit: selected_page_size });
	}

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	deleteReq = () => {
		this.props.deleteChild({ _id: this.state.req_delete_id });

		this.setState({ show_delete_msg: false });
	};

	resetFilter = (filter, checked, old_filter_status) => {
		if (checked != undefined) {
			const filter_status = JSON.parse(JSON.stringify(old_filter_status));

			if (filter == "all" && checked)
				this.setState({ filter_all: true, filter_status: [] }, () => this.getPage());
			else {
				const indx = filter_status.indexOf(filter);

				if (indx > -1) filter_status.splice(indx, 1);
				else filter_status.push(filter);

				this.setState({ filter_status, filter_all: filter_status.lengh == 0 }, () => this.getPage());
			}
		}
	};

	render() {
		const { current_page, selected_page_size, filter_all, filter_status, show_delete_msg, query } = this.state;
		const { childs, count_all_childs } = this.props;

		return (
			<Card>
				<CardBody>
					<div className="card__title">
						{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
						<Row>
							<Col md="10" style={{ marginBottom: 10 }}>
								{/* <CheckBoxField CheckBoxField="all" value={filter_all} label="Tous" name="all" onChange={(e) => this.resetFilter("all", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}

								<div className="form__form-group" style={{ width: 200 }}>
									<span className="form__form-group-label">Recherche</span>
									<div className="form__form-group-field">
										<input
											style={{
												width: "100%",
												border: "none",
												borderBottom: "1px solid #ddd",
												padding: 5,
											}}
											defaultValue={query}
											component="input"
											placeholder="Recherche"
											onChange={(e) =>
												this.setState({ query: e.target.value }, () =>
													this.getPage(current_page)
												)
											}
										/>
									</div>
								</div>
							</Col>
							<Col md="2" style={{ marginBottom: 10 }}>
								<NavLink to={"/app/childs/add-new"}>
									<Button color="success" className="rounded">
										Ajouter
									</Button>
								</NavLink>
							</Col>
						</Row>
					</div>
					<Table responsive hover striped>
						<thead>
							<tr>
								<th>Nom</th>
								<th>Niveau scolaire</th>
								<th>Age</th>
								<th>Date d'inscrit</th>
								<th>Formateur</th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{childs &&
								childs.map((child, i) => (
									<tr key={"child_" + i}>
										<td style={{ verticalAlign: "middle" }}>
											<p style={{ margin: 0, color: "#777" }}>
												{child.first_name + " " + child.last_name}
											</p>
											<p style={{ margin: 0, color: "#777" }}>
												{child.first_name_ar + " " + child.last_name_ar}
											</p>
										</td>

										<td style={{ verticalAlign: "middle" }}>{child.education_level}</td>

										<td style={{ verticalAlign: "middle" }}>{child.age}</td>

										<td style={{ verticalAlign: "middle" }}>
											{moment(child.registration_date).format("llll")}
										</td>

										<td style={{ verticalAlign: "middle" }}>
											{child.former &&
											child.former.first_name_ar &&
											child.former.first_name_ar != ""
												? child.former.first_name_ar
												: child.former?.first_name}
										</td>

										<td style={{ width: 60, verticalAlign: "middle" }}>
											<a href={"/app/childs/preview/" + child._id} target="_blank">
												<span
													className="lnr lnr-eye mr-2"
													style={{ cursor: "pointer", color: "#4CAF50", fontSize: 18 }}
												></span>
											</a>
										</td>
										<td style={{ width: 60, verticalAlign: "middle" }}>
											<NavLink to={"/app/childs/edit/" + child._id}>
												<span
													className="lnr lnr-pencil mr-2"
													style={{ cursor: "pointer", color: "#4CAF50", fontSize: 18 }}
												></span>
											</NavLink>
										</td>

										<td>
											<span
												onClick={() => {
													this.setState({ req_delete_id: child._id, show_delete_msg: true });
												}}
												className="lnr lnr-trash"
												style={{ color: "#F44336", display: "block", marginTop: 12 }}
											></span>
										</td>
									</tr>
								))}
						</tbody>
					</Table>

					<Pagination
						currentPage={current_page}
						totalPage={getCountPages(count_all_childs, selected_page_size)}
						onChangePage={(i) => this.onChangePage(i)}
					/>

					<ConfirmMdl
						toggle={() =>
							this.setState((prev) => {
								return { show_delete_msg: !prev.show_delete_msg };
							})
						}
						is_open={show_delete_msg}
						accept={() => {
							this.deleteReq();
						}}
					/>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = ({ child }) => {
	const {
		loading_get_childs,
		childs,
		count_all_childs,
		loading_one_child,
		error_one_child,
		loading_delete_child,
		error_delete_child,
	} = child;
	return {
		loading_get_childs,
		childs,
		count_all_childs,
		loading_one_child,
		error_one_child,

		loading_delete_child,
		error_delete_child,
	};
};
export default connect(mapStateToProps, {
	getChilds,
	editChild,
	deleteChild,
})(ListChildsCmp);
