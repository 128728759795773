// ***** ADD CLIENT
export const ADD_CLIENT            = 'ADD_CLIENT';
export const ADD_CLIENT_SUCCESS    = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_ERROR      = 'ADD_CLIENT_ERROR';

export function addClient(payload) {
  return {type: ADD_CLIENT, payload};
}
export function addClientSuccess(payload) {
  return { type: ADD_CLIENT_SUCCESS, payload };
}
export function addClientError(error) {
  return { type: ADD_CLIENT_ERROR, error };
}




// ***** GET CLIENTS
export const GET_CLIENTS            = 'GET_CLIENTS';
export const GET_CLIENTS_SUCCESS    = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_ERROR      = 'GET_CLIENTS_ERROR';

export function getClients(payload) {
  return {type: GET_CLIENTS, payload};
}
export function getClientsSuccess(payload) {
  return { type: GET_CLIENTS_SUCCESS, payload };
}
export function getClientsError(error) {
  return { type: GET_CLIENTS_ERROR, error };
}





// ***** GET ONE CLIENT
export const GET_ONE_CLIENT            = 'GET_ONE_CLIENT';
export const GET_ONE_CLIENT_SUCCESS    = 'GET_ONE_CLIENT_SUCCESS';
export const GET_ONE_CLIENT_ERROR      = 'GET_ONE_CLIENT_ERROR';

export function getOneClient(payload) {
  return {type: GET_ONE_CLIENT, payload};
}
export function getOneClientSuccess(payload) {
  return { type: GET_ONE_CLIENT_SUCCESS, payload };
}
export function getOneClientError(error) {
  return { type: GET_ONE_CLIENT_ERROR, error };
}



// ***** EDIT CLIENT
export const EDIT_CLIENT            = 'EDIT_CLIENT';
export const EDIT_CLIENT_SUCCESS    = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_ERROR      = 'EDIT_CLIENT_ERROR';

export function editClient(payload) {
  return {type: EDIT_CLIENT, payload};
}
export function editClientSuccess(payload) {
  return { type: EDIT_CLIENT_SUCCESS, payload };
}
export function editClientError(error) {
  return { type: EDIT_CLIENT_ERROR, error };
}


// ***** DELETE CLIENT
export const DELETE_CLIENT            = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS    = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_ERROR      = 'DELETE_CLIENT_ERROR';

export function deleteClient(payload) {
  return {type: DELETE_CLIENT, payload};
}
export function deleteClientSuccess(payload) {
  return { type: DELETE_CLIENT_SUCCESS, payload };
}
export function deleteClientError(error) {
  return { type: DELETE_CLIENT_ERROR, error };
}