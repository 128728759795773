import React from "react";
import { Col, Container, Row } from "reactstrap";
import ListArticlesCmp from "../../shared/components/articles/list-articles-cmp";

const ListArticlesSupplyPage = () => (
	<Container>
		<Row>
			<Col md={12}>
				<h3 className="page-title">
					Articles <span style={{ fontSize: 12 }}>( Fournitures )</span>{" "}
				</h3>
			</Col>

			<Col md={12}>
				<ListArticlesCmp only_supply={true} />
			</Col>
		</Row>
	</Container>
);

export default ListArticlesSupplyPage;
