// ***** GET SHIPMODES
export const GET_SHIPMODES            = 'GET_SHIPMODES';
export const GET_SHIPMODES_SUCCESS    = 'GET_SHIPMODES_SUCCESS';
export const GET_SHIPMODES_ERROR      = 'GET_SHIPMODES_ERROR';

export function getShipModes(payload) {
  return {type: GET_SHIPMODES, payload};
}
export function getShipModesSuccess(payload) {
  return { type: GET_SHIPMODES_SUCCESS, payload };
}
export function getShipModesError(error) {
  return { type: GET_SHIPMODES_ERROR, error };
}






// ***** ADD SHIPMODE
export const ADD_SHIPMODE            = 'ADD_SHIPMODE';
export const ADD_SHIPMODE_SUCCESS    = 'ADD_SHIPMODE_SUCCESS';
export const ADD_SHIPMODE_ERROR      = 'ADD_SHIPMODE_ERROR';

export function addShipMode(payload) {
  return {type: ADD_SHIPMODE, payload};
}
export function addShipModeSuccess(payload) {
  return { type: ADD_SHIPMODE_SUCCESS, payload };
}
export function addShipModeError(error) {
  return { type: ADD_SHIPMODE_ERROR, error };
}






// ***** EDIT SHIPMODE
export const EDIT_SHIPMODE            = 'EDIT_SHIPMODE';
export const EDIT_SHIPMODE_SUCCESS    = 'EDIT_SHIPMODE_SUCCESS';
export const EDIT_SHIPMODE_ERROR      = 'EDIT_SHIPMODE_ERROR';

export function editShipMode(payload) {
  return {type: EDIT_SHIPMODE, payload};
}
export function editShipModeSuccess(payload) {
  return { type: EDIT_SHIPMODE_SUCCESS, payload };
}
export function editShipModeError(error) {
  return { type: EDIT_SHIPMODE_ERROR, error };
}







// ***** EDIT SHIPMODE
export const DELETE_SHIPMODE            = 'DELETE_SHIPMODE';
export const DELETE_SHIPMODE_SUCCESS    = 'DELETE_SHIPMODE_SUCCESS';
export const DELETE_SHIPMODE_ERROR      = 'DELETE_SHIPMODE_ERROR';

export function deleteShipMode(payload) {
  return {type: DELETE_SHIPMODE, payload};
}
export function deleteShipModeSuccess(payload) {
  return { type: DELETE_SHIPMODE_SUCCESS, payload };
}
export function deleteShipModeError(error) {
  return { type: DELETE_SHIPMODE_ERROR, error };
}

