import { all } from 'redux-saga/effects';
import authSaga from './sagas/authSaga';
import articleSaga from './sagas/articleSaga';
import categorySaga from './sagas/categorySaga';

import providerSaga from './sagas/providerSaga';
import clientSaga from './sagas/clientSaga';
import purchaseSaga from './sagas/purchaseSaga';
import orderSaga from './sagas/orderSaga';

import shipModeSaga from './sagas/shipModeSaga';
import trainingSaga from './sagas/trainingSaga';
import childSaga from './sagas/childSaga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    articleSaga(),
    categorySaga(),
    
    providerSaga(),
    clientSaga(),
    purchaseSaga(),
    orderSaga(),

    shipModeSaga(),
    trainingSaga(),
    childSaga()
  ]);
}
