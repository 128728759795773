import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListChildsCmp from '../../shared/components/childs/list-childs-cmp';

const ListChildsPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Enfants</h3>
      </Col>

      <Col md={12}>
        <ListChildsCmp />
      </Col>
    </Row>
  </Container>
);

export default ListChildsPage;