import {
  GET_SHIPMODES, GET_SHIPMODES_SUCCESS, GET_SHIPMODES_ERROR,
  ADD_SHIPMODE, ADD_SHIPMODE_SUCCESS, ADD_SHIPMODE_ERROR,
  EDIT_SHIPMODE, EDIT_SHIPMODE_SUCCESS, EDIT_SHIPMODE_ERROR,
  DELETE_SHIPMODE, DELETE_SHIPMODE_SUCCESS, DELETE_SHIPMODE_ERROR
} from '../actions/shipModesActions';

const initialState = {
  loading_get_ship_modes: false,
  error_get_ship_modes: null,
  ship_modes: [],
  // count_all_ship_modes: 0,

  new_ship_mode: null, loading_add_ship_mode: false, error_add_ship_mode: null,

  loading_delete_ship_mode: false, error_delete_ship_mode: null,
};

const shipModesReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_SHIPMODES: return {...state, ship_modes: [], loading_get_ship_modes: true, error_get_ship_modes: null };
    case GET_SHIPMODES_SUCCESS:
      return {...state, ship_modes: action.payload.ship_modes, loading_get_ship_modes: false, error_get_ship_modes: null };
    case GET_SHIPMODES_ERROR:
      // console.log("ACTION", {error_get_ship_modes: action.error, loading_get_ship_modes: false, ship_modes: []});
      return {...state, error_get_ship_modes: action.error, loading_get_ship_modes: false, ship_modes: [] };



    case ADD_SHIPMODE: return {...state, loading_add_ship_mode: true, error_add_ship_mode: null };
    case ADD_SHIPMODE_SUCCESS:
      return {...state, new_ship_mode: action.payload.ship_mode, loading_add_ship_mode: false, error_add_ship_mode: null };
    case ADD_SHIPMODE_ERROR:
      return {...state, error_add_ship_mode: action.error, loading_add_ship_mode: false, new_ship_mode: null };


    // case GET_ONE_SHIPMODE: return {...state, loading_one_ship_mode: true, error_one_ship_mode: null };
    // case GET_ONE_SHIPMODE_SUCCESS:
    //   return {...state, selected_ship_mode: action.payload.ship_mode, loading_add_ship_mode: false, error_one_ship_mode: null };
    // case GET_ONE_SHIPMODE_ERROR:
    //   return {...state, error_one_ship_mode: action.error, loading_one_ship_mode: false, selected_ship_mode: null };



    case EDIT_SHIPMODE: return {...state, loading_edit_ship_mode: true, error_edit_ship_mode: null };
    case EDIT_SHIPMODE_SUCCESS:
      return {...state, loading_edit_ship_mode: false, error_edit_ship_mode: null };
    case EDIT_SHIPMODE_ERROR:
      return {...state, error_edit_ship_mode: action.error, loading_edit_ship_mode: false};





    case DELETE_SHIPMODE: return {...state, loading_delete_ship_mode: true, error_delete_ship_mode: null };
    case DELETE_SHIPMODE_SUCCESS:
      return {...state, loading_delete_ship_mode: false, error_delete_ship_mode: null };
    case DELETE_SHIPMODE_ERROR:
      return {...state, error_delete_ship_mode: action.error, loading_delete_ship_mode: false};


    default: return state;
  }
};

export default shipModesReducer;
