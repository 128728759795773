
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from "axios";

import {
  GET_PURCHASES, getPurchasesSuccess, getPurchasesError, 
  ADD_PURCHASE, addPurchaseError, addPurchaseSuccess, 
  GET_ONE_PURCHASE, getOnePurchaseSuccess, getOnePurchaseError, 
  EDIT_PURCHASE, editPurchaseError, editPurchaseSuccess,

  DELETE_PURCHASE, deletePurchaseError, deletePurchaseSuccess,
} from '../actions/purchasesActions';
import { api } from '../../const';



// *** GET PURCHASES
export function* watchGetPurchases() { yield takeEvery(GET_PURCHASES, getPurchases) }
function* getPurchases({ payload }) {
  try {
    const ret = yield call(getPurchasesRequest, payload);

    if (ret && ret.status == 200) yield put(getPurchasesSuccess({ purchases: ret.data?.purchases || [], count: ret.data?.count || 0}));
    else yield put(getPurchasesError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getPurchasesError({ error, message: (error) ? error.message : '' }));
  }
}
const getPurchasesRequest = async (payload) => {  
  return await axios.post(api.purchases+'/filter/'+payload.offset+'/'+payload.limit, payload.filter || {})
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// // *** GET ONE PURCHASE
export function* watchGetOnePurchase() { yield takeEvery(GET_ONE_PURCHASE, getOnePurchase) }
function* getOnePurchase({ payload }) {
  try {
    const ret = yield call(getOnePurchaseRequest, payload);

    if (ret && ret.status == 200) yield put(getOnePurchaseSuccess({ purchase: ret.data?.purchase || null }));
    else yield put(getOnePurchaseError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(getOnePurchaseError({ error, message: (error) ? error.message : '' }));
  }
}
const getOnePurchaseRequest = async (payload) => {  
  return await axios.get(api.purchases+"/get-one/"+payload.purchase_id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};





// *** ADD PURCHASES
export function* watchAddPurchases() { yield takeEvery(ADD_PURCHASE, addPurchase) }
function* addPurchase({ payload }) {
  try {
    const ret = yield call(addPurchasesRequest, payload);

    if (ret && ret.status == 201) yield put(addPurchaseSuccess({ purchase: ret.data.purchase }));
    else yield put(addPurchaseError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(addPurchaseError({ error, message: (error) ? error.message : '' }));
  }
}
const addPurchasesRequest = async (payload) => {  
  return await axios.post(api.purchases+'/create', payload)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};









// *** EDIT PURCHASES
export function* watchEditPurchase() { yield takeEvery(EDIT_PURCHASE, editPurchase) }
function* editPurchase({ payload }) {
  try {
    const ret = yield call(editPurchasesRequest, payload);

    if (ret && ret.status == 200) yield put(editPurchaseSuccess({ purchase: ret.data.purchase }));
    else yield put(editPurchaseError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(editPurchaseError({ error, message: (error) ? error.message : '' }));
  }
}
const editPurchasesRequest = async (payload) => {  
  return await axios.patch(api.purchases+'/edit-one/'+payload._id, payload.data)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};










// *** DELETE PURCHASES
export function* watchDeletePurchase() { yield takeEvery(DELETE_PURCHASE, deletePurchase) }
function* deletePurchase({ payload }) {
  try {
    const ret = yield call(deletePurchasesRequest, payload);

    if (ret && ret.status == 200) yield put(deletePurchaseSuccess({ }));
    else yield put(deletePurchaseError({ message: ret.data ? ret.data.msg : "Error" }))

  } catch (error) {
    yield put(deletePurchaseError({ error, message: (error) ? error.message : '' }));
  }
}
const deletePurchasesRequest = async (payload) => {  
  return await axios.delete(api.purchases+'/delete-one/'+payload._id)
  .then(resp => resp)
  .catch(error => { return (error && error.response) ? error.response : error })
};




export default function* rootSaga() {
  yield all([
    fork(watchGetPurchases),
    fork(watchGetOnePurchase),
    fork(watchAddPurchases),
    fork(watchEditPurchase),
    fork(watchDeletePurchase),
  ]);
}