import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	Card, CardBody, Badge, Table, Button, Row, Col,
	// ButtonGroup, ButtonToolbar,
} from 'reactstrap';

import moment from "moment"
// import Select from 'react-select';

import {
	getPurchases, editPurchase, deletePurchase
} from "../../../redux/actions/purchasesActions";
import { getCountPages } from '../../helper/utils';
import Pagination from '../pagination/Pagination';

import { CheckBoxField } from '../form/CheckBox';

import { NavLink } from 'react-router-dom';
import ConfirmMdl from '../confirm-mdl';
// import { api } from '../../../const';
import StatusBade from '../../helper/StatusBadge';
moment.locale('fr')

// const req_statuses = [{label: 'Request', value: 'request'}, {label: 'Accepted', value: 'accepted'}, {label: 'Refused', value: 'refused'}] 

class ListPurchasesCmp extends PureComponent {

	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			purchases: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			show_delete_msg: false,
			req_delete_id: null
		};
	}


	changeVal = (key, value) => {
		const obj = {}; obj[key] = value;
		this.setState(obj)
	}

	componentWillMount() {
		this.getPage()
	}

	componentDidUpdate(prev) {
		const { 
			loading_one_purchase, error_one_purchase,
			loading_delete_purchase, error_delete_purchase
		} = this.props

		if (!loading_one_purchase && prev.loading_one_purchase && !error_one_purchase){
      
      this.getPage(this.state.current_page)
		}
		
		if (prev.loading_delete_purchase && !loading_delete_purchase && !error_delete_purchase) {
      this.getPage(this.state.current_page)
    }
	}
	getPage(page = 1) {
		const { selected_page_size, filter_all, filter_status } = this.state;

		const offset = (page == 1 || page == 0) ? 0 : selected_page_size * (page - 1);

		const filter = {}
		if (!filter_all) filter['statuses'] = filter_status

		// if (filter_by_query != '') filter['name'] = filter_by_query
		this.props.getPurchases({ filter, offset, limit: selected_page_size })
	}

	onChangePage = page => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	deleteReq = ()=>{
		this.props.deletePurchase({_id: this.state.req_delete_id})

    this.setState({ show_delete_msg: false })
	}


	resetFilter = (filter, checked, old_filter_status) => {
		if (checked != undefined) {
			const filter_status = JSON.parse(JSON.stringify(old_filter_status))

			if (filter == 'all' && checked) this.setState({ filter_all: true, filter_status: [] }, () => this.getPage())
			else {
				const indx = filter_status.indexOf(filter)

				if (indx > -1) filter_status.splice(indx, 1)
				else filter_status.push(filter)

				this.setState({ filter_status, filter_all: (filter_status.lengh == 0) }, () => this.getPage())
			}
		}
	}

	render() {
		const { 
			current_page, selected_page_size, filter_all, filter_status, show_delete_msg
		} = this.state;
		const { purchases, count_all_purchases } = this.props

		
		return (<Card>
			<CardBody>
				<div className="card__title">
					{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
					<Row>
						<Col md="10" style={{ marginBottom: 10 }}>
							<CheckBoxField CheckBoxField="all" value={filter_all} label="Tous" name="all" onChange={(e) => this.resetFilter("all", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} />

							{/* ["pending", "confirmed", "preparing", "prepared", "shipping", "shipped", "payed", "canceled", "lost"] */}
							<CheckBoxField CheckBoxField="pending" value={(filter_status.includes("pending"))} label="En attente" name="pending" onChange={(e) => this.resetFilter("pending", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} />
							{/* <CheckBoxField CheckBoxField="confirmed" value={(filter_status.includes("confirmed"))} label="Confirmé" name="confirmed" onChange={(e) => this.resetFilter("confirmed", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							{/* <CheckBoxField CheckBoxField="preparing" value={(filter_status.includes("preparing"))} label="Préparation" name="preparing" onChange={(e) => this.resetFilter("preparing", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							{/* <CheckBoxField CheckBoxField="prepared" value={(filter_status.includes("prepared"))} label="Préparé" name="prepared" onChange={(e) => this.resetFilter("prepared", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							{/* <CheckBoxField CheckBoxField="shipping" value={(filter_status.includes("shipping"))} label="Livraison" name="shipping" onChange={(e) => this.resetFilter("shipping", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							{/* <CheckBoxField CheckBoxField="shipped" value={(filter_status.includes("shipped"))} label="Expédié" name="shipped" onChange={(e) => this.resetFilter("shipped", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							<CheckBoxField CheckBoxField="payed" value={(filter_status.includes("payed"))} label="Payé" name="payed" onChange={(e) => this.resetFilter("payed", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} />
							{/* <CheckBoxField CheckBoxField="canceled" value={(filter_status.includes("canceled"))} label="Annuler" name="canceled" onChange={(e) => this.resetFilter("canceled", e.target?.checked, filter_status)} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
						</Col>
						<Col md="2" style={{ marginBottom: 10 }}>
							<NavLink to={"/app/purchases/add-new"}>
								<Button color="success" className="rounded">Ajouter</Button>
							</NavLink>
						</Col>
					</Row>
				</div>
				<Table responsive hover striped>
					<thead>
						<tr>
							{/* <th></th> */}
							<th>Ref</th>
							<th>Ref Fournisseur</th>
							<th>Date</th>
							<th>Statut</th>
							<th>Fournisseur</th>
							{/* <th>Articles</th> */}
							<th>Livraison</th>
							<th>Total</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{purchases && purchases.map((purchase, i) => (<tr key={'purchase_' + i}>
							<td style={{ verticalAlign: 'middle', width: 50 }}>
                <NavLink to={"/app/purchases/edit/"+ purchase._id}>#{purchase.ref}</NavLink>
							</td>

							<td style={{ verticalAlign: 'middle' }}>
								#{purchase.ref_provider}
							</td>

							<td style={{ verticalAlign: 'middle' }}>
								{moment(purchase.date).format("LLLL")}
							</td>

							<td style={{ verticalAlign: 'middle' }}>
								{/* {statusNameColor(purchase.status)} */}
								<StatusBade status={purchase.status} />
							</td>

							<td style={{ verticalAlign: 'middle' }}>
								{purchase.provider ? purchase.provider.name : ""}
							</td>

							{/* <th>
								{purchase.articles && purchase.articles.map((pur_article, i) => (<p style={{}} key={"pur_article_"+i} >
									<span>{pur_article.qty + " x " + pur_article.name} </span>
								</p>))}
							</th> */}

							<th>
								{purchase.shipping_mode}
								{/* <p style={{color: "#777"}} >{purchase.shipping_price}{" dt"}</p> */}
							</th>

							<th>
								{purchase.total}
							</th>

							<th>
								<NavLink to={"/app/purchases/edit/"+ purchase._id}>
									<span style={{color: "#4CAF50", fontSize: 15}} className="lnr lnr-pencil"></span>
								</NavLink>
							</th>



							{/* <td style={{ width: 60, verticalAlign: "middle" }}>
                <NavLink to={"/app/purchases/edit/"+purchase._id}>
                  <span className="lnr lnr-eye mr-2" style={{ cursor: 'pointer', color: "#4CAF50", fontSize: 18 }}></span>
                </NavLink>
              </td>
							<td>
								<span onClick={()=>{
									this.setState({req_delete_id: purchase._id, show_delete_msg: true})
								}} className="lnr lnr-trash" style={{color: "#F44336", display: 'block', marginTop: 12}}></span>
							</td> */}
						</tr>))}
					</tbody>
				</Table>

				<Pagination currentPage={current_page}
					totalPage={getCountPages(count_all_purchases, selected_page_size)}
					onChangePage={i => this.onChangePage(i)}
				/>


				<ConfirmMdl toggle={() => this.setState((prev) => { return { show_delete_msg: !prev.show_delete_msg } })}
					is_open={show_delete_msg} accept={() => {
						this.deleteReq()
					}}
				/>
			</CardBody>
		</Card>);
	}
}

const mapStateToProps = ({ purchase }) => {
	const { loading_get_purchases, purchases, count_all_purchases, 
		loading_one_purchase, error_one_purchase,
		loading_delete_purchase, error_delete_purchase 
	} = purchase
	return {
		loading_get_purchases, purchases, count_all_purchases,
		loading_one_purchase, error_one_purchase,

		loading_delete_purchase, error_delete_purchase
	}
}
export default connect(mapStateToProps, {
	getPurchases, editPurchase, deletePurchase
})(ListPurchasesCmp);