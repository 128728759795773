import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
	Card,
	CardBody,
	Badge,
	Table,
	Button,
	Row,
	Col,
	// ButtonGroup, ButtonToolbar,
} from "reactstrap";

// import Select from 'react-select';

import { getArticles, editArticle, deleteArticle } from "../../../redux/actions/articlesActions";
import { articleStockColor, getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";

import { CheckBoxField } from "../form/CheckBox";

import { NavLink } from "react-router-dom";
import ConfirmMdl from "../confirm-mdl";
import { api } from "../../../const";

// const req_statuses = [{label: 'Request', value: 'request'}, {label: 'Accepted', value: 'accepted'}, {label: 'Refused', value: 'refused'}]

class ListArticlesCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			filter_status: [],

			filter_all: true,
			is_pack: false,
			is_internal: false,
			is_prd: false,
			articles: [],

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			show_delete_msg: false,
			req_delete_id: null,

			query: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	componentWillMount() {
		this.getPage();
	}

	componentDidUpdate(prev) {
		const { loading_one_article, error_one_article, loading_delete_article, error_delete_article } = this.props;

		if (!loading_one_article && prev.loading_one_article && !error_one_article) {
			this.getPage(this.state.current_page);
		}

		if (prev.loading_delete_article && !loading_delete_article && !error_delete_article) {
			this.getPage(this.state.current_page);
		}
	}
	getPage(page = 1) {
		const { selected_page_size, filter_all, is_pack, is_internal, is_external, is_prd, query } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		const filter = {};
		if (!filter_all) {
			if (is_pack) filter["is_pack"] = true;
			if (is_prd) filter["is_prd"] = true;
			if (is_internal || is_external) {
				if (is_internal && !is_external) filter["is_internal"] = true;
				if (!is_internal && is_external) filter["is_internal"] = false;
			}

			if (query && query != "") filter["query"] = query;
		}

		const { only_external, only_internal, only_supply } = this.props;
		if (only_supply) {
			if (only_supply === true) {
				filter["is_supply"] = true;
				if (query && query != "") filter["query"] = query;
			}
		} else {
			filter["is_supply"] = false;
			if (only_external === true) filter["is_internal"] = false;
			if (only_internal === true) filter["is_internal"] = true;
		}

		console.log("only_external", only_external);
		console.log("FILTER", filter);

		// if (filter_by_query != '') filter['name'] = filter_by_query

		this.props.getArticles({ filter, offset, limit: selected_page_size });
	}

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getPage(page));
	};

	deleteReq = () => {
		this.props.deleteArticle({ _id: this.state.req_delete_id });

		this.setState({ show_delete_msg: false });
	};

	resetFilter = (filter, checked, old_filter_status) => {
		this.setState({ query: "" });

		if (checked != undefined) {
			// const filter_status = JSON.parse(JSON.stringify(old_filter_status))

			if (filter == "all") {
				if (checked)
					this.setState(
						{
							filter_all: true, //filter_status: [],
							is_pack: false,
							is_prd: false,
							is_internal: false,
							is_external: false,
						},
						() => this.getPage()
					);
				else
					this.setState(
						{
							filter_all: false, //filter_status: [],
							// is_pack: false, is_prd: false,
						},
						() => this.getPage()
					);
			} else {
				// const indx = filter_status.indexOf(filter)
				// if (indx > -1) filter_status.splice(indx, 1)
				// else filter_status.push(filter)
				// this.setState({ filter_status, filter_all: (filter_status.lengh == 0) }, () => this.getPage())
			}
		}
	};

	render() {
		const {
			current_page,
			selected_page_size,
			filter_all,
			is_pack,
			// is_internal,
			// is_external,
			is_prd,
			query,
			show_delete_msg,
		} = this.state;
		const { articles, count_all_articles, user, only_supply } = this.props;

		return (
			<Card>
				<CardBody style={{ padding: 5 }}>
					<div className="card__title">
						{/* <h5 className="bold-text">XXXX</h5>
					<h5 className="subhead">Use default table</h5> */}
						<Row>
							<Col md="10" style={{ marginBottom: 10 }}>
								{only_supply ? null : (
									<>
										<CheckBoxField
											CheckBoxField="all"
											value={filter_all}
											label="Tous"
											name="all"
											onChange={(e) => this.resetFilter("all", e.target?.checked)}
											color="primary"
											style={{ display: "inline-block", marginRight: 8 }}
										/>

										<CheckBoxField
											CheckBoxField="is_pack"
											value={is_pack}
											label="Composé"
											name="is_pack"
											onChange={(e) =>
												this.setState(
													{
														is_pack: e.target?.checked,
														is_internal: false,
														is_prd: false,
														filter_all: false,
													},
													() => this.getPage()
												)
											}
											color="primary"
											style={{ display: "inline-block", marginRight: 8 }}
										/>
										<CheckBoxField
											CheckBoxField="is_prd"
											value={is_prd}
											label="Produit"
											name="is_prd"
											onChange={(e) =>
												this.setState(
													{
														is_prd: e.target?.checked,
														is_pack: false,
														is_internal: false,
														filter_all: false,
													},
													() => this.getPage()
												)
											}
											color="primary"
											style={{ display: "inline-block", marginRight: 8 }}
										/>
									</>
								)}
								{/* <CheckBoxField CheckBoxField="is_internal" value={is_internal} label="Interne" name="is_internal" onChange={(e) => this.setState({ is_internal: e.target?.checked, is_prd: false, is_pack: false, filter_all: false }, () => this.getPage())} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
								{/* <CheckBoxField CheckBoxField="is_external" value={is_external} label="Externe" name="is_external" onChange={(e) => this.setState({ is_external: e.target?.checked, is_prd: false, is_pack: false, filter_all: false }, () => this.getPage())} color="primary" style={{ display: 'inline-block', marginRight: 8 }} /> */}
							</Col>
							<Col md="2" style={{ marginBottom: 10, textAlign: "right" }}>
								{user?.roles?.includes("ADMIN") && (
									<NavLink to={"/app/articles/add-new"}>
										<Button color="success" className="rounded">
											Ajouter
										</Button>
									</NavLink>
								)}
							</Col>
						</Row>

						<div className="form__form-group" style={{ width: 200 }}>
							<span className="form__form-group-label">Recherche</span>
							<div className="form__form-group-field">
								<input
									style={{
										width: "100%",
										border: "none",
										borderBottom: "1px solid #ddd",
										padding: 5,
									}}
									defaultValue={query}
									component="input"
									placeholder="Recherche"
									onChange={(e) =>
										this.setState({ query: e.target.value }, () => this.getPage(current_page))
									}
								/>
							</div>
						</div>
					</div>
					<Table responsive hover striped>
						<thead>
							<tr>
								<th></th>
								<th>Ref</th>
								<th>Nom</th>
								<th>Courte desc</th>
								<th>Catégorie</th>
								<th>Prix</th>
								<th>QTE</th>
								<th>Type</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody style={{ fontSize: 15, fontWeight: 500 }}>
							{articles &&
								articles.map((article, i) => (
									<tr
										style={
											!article.is_pack ? { backgroundColor: articleStockColor(article.qty) } : {}
										}
										key={"article_" + i}
									>
										<td style={{ padding: 2 }}>
											<img
												src={
													article.img
														? api.root + "/" + article.img.path
														: `${process.env.PUBLIC_URL}/img/no_product.png`
												}
												style={{ width: 100 }}
											/>
										</td>

										<td style={{ verticalAlign: "middle" }}>
											<span style={{ color: "#777" }}>#{article.ref}</span>
										</td>
										<td style={{ verticalAlign: "middle" }}>{article.name}</td>

										<td style={{ verticalAlign: "middle" }}>{article.short_desc}</td>

										<td style={{ verticalAlign: "middle" }}>{article.category?.name}</td>

										<td style={{ verticalAlign: "middle" }}>{article.price}</td>

										<td style={{ verticalAlign: "middle" }}>
											{!article.is_pack ? article.qty : ""}
										</td>

										{article.is_supply ? (
											<td style={{ verticalAlign: "middle" }}>Fourniture</td>
										) : (
											<td style={{ verticalAlign: "middle" }}>
												{article.is_internal ? "Interne" : "Externe"}
											</td>
										)}

										<td style={{ width: 60, verticalAlign: "middle" }}>
											<a href={"/app/articles/edit/" + article._id} target="_blank">
												<span
													className="lnr lnr-eye mr-2"
													style={{ cursor: "pointer", color: "#4CAF50", fontSize: 18 }}
												></span>
											</a>
										</td>
										<td style={{ verticalAlign: "middle" }}>
											{user?.roles?.includes("ADMIN") && (
												<span
													onClick={() => {
														this.setState({
															req_delete_id: article._id,
															show_delete_msg: true,
														});
													}}
													className="lnr lnr-trash"
													style={{ color: "#F44336", display: "block", marginTop: 12 }}
												></span>
											)}
										</td>
									</tr>
								))}
						</tbody>
					</Table>

					<Pagination
						currentPage={current_page}
						totalPage={getCountPages(count_all_articles, selected_page_size)}
						onChangePage={(i) => this.onChangePage(i)}
					/>

					<ConfirmMdl
						toggle={() =>
							this.setState((prev) => {
								return { show_delete_msg: !prev.show_delete_msg };
							})
						}
						is_open={show_delete_msg}
						accept={() => {
							this.deleteReq();
						}}
					/>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = ({ article, auth }) => {
	const {
		loading_get_articles,
		articles,
		count_all_articles,
		loading_one_article,
		error_one_article,
		loading_delete_article,
		error_delete_article,
	} = article;
	return {
		loading_get_articles,
		articles,
		count_all_articles,
		loading_one_article,
		error_one_article,

		loading_delete_article,
		error_delete_article,

		user: auth.user,
	};
};
export default connect(mapStateToProps, {
	getArticles,
	editArticle,
	deleteArticle,
})(ListArticlesCmp);
