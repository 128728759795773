import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ListLogPage from "./list";

const LogPage = ({ match }) => (
	<Suspense fallback={<div className="loading" />}>
		<Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />

			<Route path={`${match.url}/list`} render={(props) => <ListLogPage {...props} />} />

			<Redirect from={`${match.url}/`} to={`${match.url}/list`} />
			<Redirect to="/error" />
		</Switch>
	</Suspense>
);
export default LogPage;
