import {
  GET_ARTICLES, GET_ARTICLES_SUCCESS, GET_ARTICLES_ERROR,
  ADD_ARTICLE, ADD_ARTICLE_SUCCESS, ADD_ARTICLE_ERROR,
  GET_ONE_ARTICLE, GET_ONE_ARTICLE_SUCCESS, GET_ONE_ARTICLE_ERROR,
  EDIT_ARTICLE, EDIT_ARTICLE_SUCCESS, EDIT_ARTICLE_ERROR,
  DELETE_ARTICLE, DELETE_ARTICLE_SUCCESS, DELETE_ARTICLE_ERROR
} from '../actions/articlesActions';

const initialState = {
  loading_get_articles: false,
  error_get_articles: null,
  articles: [],
  count_all_articles: 0,

  new_article: null, loading_add_article: false, error_add_article: null,

  selected_article: null, 
  loading_one_article: false, error_one_article: null,
  loading_edit_article: false, error_edit_article: null,

  loading_delete_article: false, error_delete_article: null
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_ARTICLES: return {...state, loading_get_articles: true, error_get_articles: null };
    case GET_ARTICLES_SUCCESS:
      return {...state, articles: action.payload.articles, loading_get_articles: false, error_get_articles: null, count_all_articles: action.payload.count };
    case GET_ARTICLES_ERROR:
      // console.log("ACTION", {error_get_articles: action.error, loading_get_articles: false, articles: []});
      return {...state, error_get_articles: action.error, loading_get_articles: false, articles: [] };



    case ADD_ARTICLE: return {...state, loading_add_article: true, error_add_article: null };
    case ADD_ARTICLE_SUCCESS:
      return {...state, new_article: action.payload.article, loading_add_article: false, error_add_article: null };
    case ADD_ARTICLE_ERROR:
      return {...state, error_add_article: action.error, loading_add_article: false, new_article: null };


    case GET_ONE_ARTICLE: return {...state, loading_one_article: true, error_one_article: null };
    case GET_ONE_ARTICLE_SUCCESS:
      return {...state, selected_article: action.payload.article, loading_one_article: false, error_one_article: null };
    case GET_ONE_ARTICLE_ERROR:
      return {...state, error_one_article: action.error, loading_one_article: false, selected_article: null };



    case EDIT_ARTICLE: return {...state, loading_edit_article: true, error_edit_article: null };
    case EDIT_ARTICLE_SUCCESS:
      return {...state, selected_article: action.payload.article, loading_edit_article: false, error_edit_article: null };
    case EDIT_ARTICLE_ERROR:
      return {...state, error_edit_article: action.error, loading_edit_article: false, selected_article: null };




    case DELETE_ARTICLE: return {...state, loading_delete_article: true, error_one_article: null };
    case DELETE_ARTICLE_SUCCESS:
      return {...state, loading_delete_article: false, error_delete_article: null };
    case DELETE_ARTICLE_ERROR:
      return {...state, error_delete_article: action.error, loading_delete_article: false };


    default: return state;
  }
};

export default articlesReducer;
