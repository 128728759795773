import React, { useState, PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { Button, ButtonToolbar, Modal, Row, Col } from "reactstrap";
import classNames from "classnames";
import renderCheckBoxField from "../form/CheckBox";
import Alert from "../../helper/Alert";

import // addUser
"../../../redux/actions/authActions";
import ListUsersCmp from "./list-users";

const modalClass = classNames({
	"modal-dialog--colored": false,
	"modal-dialog--header": true,
});

class SelectUsersMdl extends PureComponent {
	constructor() {
		super();
		this.state = {
			first_name: "",
			last_name: "",
			email: "",
			mobile: "",
			pass: "",
			is_tech: false,
			is_tech_trains: false,
			is_user: false,

			error_frm: "",
		};
	}

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	submit = () => {
		const error_frm = this.checkFrm(this.state);
		this.setState({ error_frm });

		if (error_frm == "") {
			const { first_name, last_name, email, mobile, pass, is_tech, is_tech_trains, is_user } = this.state;

			const roles = [];
			if (is_tech) roles.push("TECH");
			if (is_tech_trains) roles.push("TECH_TRAINS");
			if (is_user) roles.push("USER");

			this.props.addUser({ first_name, last_name, email, mobile, pass, roles });
		}
	};
	checkFrm = (data) => {
		const { first_name, last_name, email, mobile, pass, is_tech, is_tech_trains, is_user } = data;
		if (first_name == "" || last_name == "") return "First name and last name are required.";
		if (email == "") return "Email is required.";
		if (!new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/).test(email)) return "Invalid Email.";
		if (mobile == "") return "Mobile is required.";
		if (!is_tech && !is_user && !is_tech_trains) return "Please check one of the roles.";
		if (pass == "") return "Password is required.";
		return "";
	};

	componentDidUpdate(prev) {
		const { loading_add_user, error_add_user, new_user } = this.props;

		if (!loading_add_user && prev.loading_add_user) {
			if (error_add_user) {
				if (error_add_user.message && error_add_user.message != "")
					this.setState({ error_frm: error_add_user.message });
			} else this.props.handleSuccess(new_user);
		}
	}

	render() {
		const { is_open, size, toggle, only_clients, onSuccess, multi } = this.props;
		return (
			<Modal
				isOpen={is_open}
				size={size || "sm"}
				toggle={toggle}
				className={`modal-dialog--primary ${modalClass}`}
			>
				<div className="modal__header">
					<h4 className="text-modal  modal__title" style={{ textAlign: "left" }}>
						Sélectionner
					</h4>
				</div>

				<div className="modal__body" style={{ maxHeight: 650, overflowY: "auto" }}>
					<ListUsersCmp
						only_clients={only_clients}
						show_footer={true}
						onSuccess={(data) => {
							onSuccess(data);
							toggle(() => {
								toggle && toggle();
							});
						}}
						onCancel={toggle}
						body_style={{ padding: "0 5px" }}
						card_style={{ padding: 0 }}
						multi={multi}
						show_selection={true}
					/>
				</div>

				{/* <ButtonToolbar className="modal__footer" style={{padding: '0 5px'}} >
            <Button className="modal_cancel" onClick={toggle}>Cancel</Button>{' '}
            <Button type="submit" className="modal_ok" outline color={color} onClick={toggle}>Ajouter</Button>
          </ButtonToolbar> */}
			</Modal>
		);
	}
}

const mapStateToProps = ({ auth }) => {
	return {
		form: "select_user_form",
		loading_login: auth.loading_login,
		user: auth.user,
		loading_add_user: auth.loading_add_user,
		error_add_user: auth.error_add_user,
		new_user: auth.new_user,
	};
};
export default connect(mapStateToProps, {})(reduxForm()(SelectUsersMdl));
