import React, { useState, PureComponent } from "react";
// import PropTypes from 'prop-types';
import { connect } from "react-redux";
// import { Field, reduxForm } from 'redux-form';

import Axios from "axios";

import {
	Button,
	ButtonToolbar,
	Card,
	CardBody,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Table,
} from "reactstrap";
// import renderCheckBoxField from '../form/CheckBox';
import Alert from "../../helper/Alert";

// import { NavLink } from 'react-router-dom';

// import moment from "moment"
// moment.locale('fr')
import { addTraining, editTraining, getOneTraining } from "../../../redux/actions/trainingsActions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
// import Switch from "rc-switch";
// import "rc-switch/assets/index.css";

import classnames from "classnames";
import { api, cities, formers, training_types } from "../../../const";
import SelectClientMemberMdl from "../clients/SelectClient";
import moment, { duration } from "moment";
import { getCountPages } from "../../helper/utils";
import Pagination from "../pagination/Pagination";
import ConfirmMdl from "../confirm-mdl";

import AccountSearchIcon from "mdi-react/AccountSearchIcon";
import SelectUsersMdl from "../users/select-users-mdl";
import AddClientMdl from "../clients/AddClientMdl";
import FollowMdl from "./FollowMdl";

class PreviewTrainingCmp extends PureComponent {
	constructor() {
		super();
		this.state = {
			start_date: new Date(),
			duration: 0,

			address: "",
			city: "",
			region: "",
			ref: "",

			type: "",
			error_frm: "",

			active_tab: "follow",
			name: "",
			category: "",

			// category: { type: Schema.Types.ObjectId, ref: 'TrainingCategory', default: '5fc764674875e60e6cb3675c' },
			// theme: { type: Schema.Types.ObjectId, ref: 'TrainingTheme', default: '5fc764674875e60e6cb3675c' },

			former: null,
			commercial: null,
			organizer: null,
			select_user: false,

			coordinator: null,
			select_coordinator: null,

			type: "internal",

			participants: [],
			count_all_participants: 0,
			show_delete_msg: false,
			req_delete_id: null,

			show_select_client: false,
			show_add_client: false,

			current_page: 1,
			totalPage: 1,
			selected_page_size: 10,

			training_categories: [],
			selected_ctg_names: [],

			selected_activity: null,
			open_follow_mdl: false,

			show_participants: false,
		};
	}

	getTrainCategories = () => {
		Axios.post(api.trainings + "/categories/filter/0/200").then((resp) => {
			const ctgs = resp?.data?.training_categories || [];
			let selected_ctg_names = [];
			for (const ctg of ctgs) {
				if (ctg.name === this.state.category) selected_ctg_names = ctg.training_names;
			}
			this.setState({ training_categories: ctgs, selected_ctg_names });
		});
	};

	toggle = (active_tab) => this.setState({ active_tab });
	toggleSelectClientMdl = () =>
		this.setState((prev) => {
			return { show_select_client: !prev.show_select_client };
		});

	changeVal = (key, value) => {
		const obj = {};
		obj[key] = value;
		this.setState(obj);
	};

	submit = () => {
		const error_frm = this.checkFrm(this.state);
		this.setState({ error_frm });

		const { addTraining, editTraining, editing } = this.props;
		const {
			start_date,
			duration,
			address,
			city,
			region,
			ref,
			former,
			coordinator,
			commercial,
			organizer,
			type,
			name,
			category,
		} = this.state;

		if (error_frm == "") {
			const data = {
				name,
				category,
				start_date,
				duration,
				address,
				city,
				region,
				ref,
				type,
				former: former?._id,
				commercial: commercial?._id,
				organizer: organizer?._id,
				coordinator: coordinator?._id,
			};

			if (editing) editTraining({ _id: this.props.training_id, data });
			else addTraining(data);
		}
	};
	checkFrm = (data) => {
		const { start_date } = data;
		if (!start_date) return "Date de début est requis.";
		return "";
	};

	componentDidUpdate(prev) {
		const {
			loading_add_training,
			error_add_training,
			new_training,
			selected_training,
			loading_one_training,
			error_one_training,
			loading_edit_training,
			error_edit_training,
		} = this.props;

		if (!loading_add_training && prev.loading_add_training) {
			// console.log("error_add_training", error_add_training, new_training);
			if (error_add_training) {
				if (error_add_training.message && error_add_training.message != "")
					this.setState({ error_frm: error_add_training.message });
			} else this.props.history.push("/app/trainings/edit/" + new_training._id);
		}

		// EDITING
		if (!loading_edit_training && prev.loading_edit_training) {
			// console.log("error_add_training", error_add_training, new_training);
			if (error_edit_training) {
				if (error_edit_training.message && error_edit_training.message != "")
					this.setState({ error_frm: error_edit_training.message });
			} else this.props.history.push("/app/trainings/trainings");
		}

		//GET ONE
		if (prev.loading_one_training && !loading_one_training && !error_one_training) {
			if (selected_training) {
				const {
					name,
					category,
					start_date,
					duration,
					address,
					city,
					region,
					ref,
					former,
					coordinator,
					organizer,
					commercial,
					type,
				} = selected_training;

				this.setState(
					{
						name,
						category,
						start_date,
						duration,
						address,
						city,
						region,
						ref,
						former,
						coordinator,
						organizer,
						commercial,
						type,
					},
					() => {
						//after end init
						this.getTrainCategories();
					}
				);
			}
		}
	}

	componentDidMount() {
		if (this.props.editing) {
			const { match, getOneTraining } = this.props;
			if (match && match.params) {
				getOneTraining({ training_id: match.params.trainingId });
				this.getParticipants(1);
			}
		}

		this.getTrainCategories();
	}

	addParticipant = (created_client) => {
		if (created_client)
			Axios.post(api.participants + "/create", {
				participant: created_client?._id,
				training: this.props.training_id,
				ref_participant: created_client?.ref_participant,
				adding_date: new Date(),
			})
				.then((resp) => {
					// console.log(resp);
					this.getParticipants(1);
					this.setState({ show_add_client: false });
				})
				.catch((e) => {
					console.log("error add participant", e);
					alert("Erreur, Veuillez vérifier vos données et réessayer");
				});
	};

	getParticipants = (page = 1) => {
		const { selected_page_size } = this.state;

		const offset = page == 1 || page == 0 ? 0 : selected_page_size * (page - 1);

		Axios.post(`${api.participants}/filter/${offset}/${selected_page_size}`, {
			training_id: this.props?.match?.params?.trainingId,
		}).then((resp) => {
			this.setState({
				participants: resp?.data?.participants || [],
				count_all_participants: resp?.data?.count || 0,
			});
		});
	};

	onChangePage = (page) => {
		this.setState({ current_page: page }, () => this.getParticipants(page));
	};

	deleteReq = () => {
		Axios.delete(api.participants + "/delete-one/" + this.state.req_delete_id).then((resp) => {
			this.getParticipants();
		});

		this.setState({ show_delete_msg: false });
	};

	render() {
		const {
			start_date,
			show_select_client,
			participants,
			current_page,
			count_all_participants,
			selected_page_size,
			show_delete_msg,
			select_commercial,
			select_organizer,
			select_former,
			select_user,

			show_add_client,
			open_follow_mdl,
			selected_activity,
			commercial,
			organizer,
			former,
			coordinator,
			type,
			duration,

			address,
			region,
			city,
		} = this.state;

		const { selected_training } = this.props;
		return (
			<div>
				<Card>
					<CardBody>
						<h3 style={{ color: "#28a745" }}>
							{selected_training?.category} {selected_training?.name}
							<span style={{ color: "#777", fontSize: 13 }}>
								{" "}
								( {type === "internal" ? "Interne" : "Externe"} )
							</span>
						</h3>
						<b>#{selected_training?.ref}</b>

						<p>
							Date début : <span>{moment(start_date).format("dddd DD-MM-yyyy")}</span>
						</p>
						<p>
							Duré : <span>{duration}</span> <span style={{ color: "#777" }}>(jours)</span>
						</p>

						<p>
							<span>Organisateur :</span>{" "}
							<b
								style={{ color: organizer ? "#28a745" : "#ff4861" }}
							>{`${organizer?.first_name} ${organizer?.last_name} `}</b>
							<span>Commercial :</span>{" "}
							<b
								style={{ color: commercial ? "#28a745" : "#ff4861" }}
							>{`${commercial?.first_name} ${commercial?.last_name} `}</b>
							<span>Formateur :</span>{" "}
							<b
								style={{ color: former ? "#28a745" : "#ff4861" }}
							>{`${former?.first_name} ${former?.last_name}`}</b>
						</p>

						<p>
							<span>Coordinateur :</span>{" "}
							<b
								style={{ color: coordinator ? "#28a745" : "#ff4861" }}
							>{`${coordinator?.first_name} ${coordinator?.last_name} `}</b>
						</p>

						<p>Location : {`${city}, ${region}, ${address}`}</p>

						<p>
							Participants <b style={{ color: "#28a745" }}>({count_all_participants || 0})</b>
						</p>

						<Table responsive hover striped>
							<thead>
								<tr>
									<th>Nom</th>
									<th>Date d'ajout</th>
									<th>Ref</th>
									<th>Institution</th>

									<th></th>
									<th></th>
									<th></th>
								</tr>

								{participants &&
									participants.map((item, i) => (
										<tr key={"participant_" + i}>
											{item.participant ? (
												<td>
													<span style={{ margin: 0, color: "#777" }}>
														{item.participant.first_name +
															" " +
															item.participant?.last_name}
													</span>
													<span style={{ margin: 0, color: "#777" }}>
														{item.participant.first_name_ar +
															" " +
															item.participant?.last_name_ar}
													</span>
												</td>
											) : (
												<td></td>
											)}

											<td>{moment(item.adding_date).format("ddd DD/MM/YYYY")}</td>

											<td>{item.ref_participant}</td>
											<td>{item.participant?.institution}</td>

											<td>
												<span
													onClick={() => {
														this.setState({
															selected_activity: item,
															open_follow_mdl: true,
														});
													}}
													className="lnr lnr-rocket"
													style={{
														color: "#4CAF50",
														display: "block",
														marginTop: 12,
														cursor: "pointer",
														fontSize: 18,
													}}
												></span>
											</td>

											<td style={{ width: 60, verticalAlign: "middle" }}>
												<a href={"/app/clients/edit/" + item?.participant?._id} target="blank">
													<span
														className="lnr lnr-eye mr-2"
														style={{ cursor: "pointer", color: "#4CAF50", fontSize: 18 }}
													></span>
												</a>
												{/* <NavLink target="blank" to={"/app/clients/edit/" + item?.participant?._id}>
          <span className="lnr lnr-eye mr-2" style={{ cursor: 'pointer', color: "#4CAF50", fontSize: 18 }}></span>
        </NavLink> */}
											</td>
											<td>
												<span
													onClick={() => {
														this.setState({
															req_delete_id: item._id,
															show_delete_msg: true,
														});
													}}
													className="lnr lnr-trash"
													style={{
														color: "#F44336",
														display: "block",
														marginTop: 12,
														cursor: "pointer",
													}}
												></span>
											</td>
										</tr>
									))}

								<tr>
									<td colSpan="7">
										{/* <Button color="info" onClick={() => this.setState({ show_select_client: true })}>Sélectionner</Button> */}

										<Button
											onClick={() => {
												this.setState({
													show_add_client: true,
												});
											}}
											color="success"
										>
											Ajouter
										</Button>
									</td>
								</tr>
							</thead>
							<tbody></tbody>
						</Table>

						{show_add_client && (
							<AddClientMdl
								isOpen={show_add_client}
								add_as_participant={true}
								training_ref={selected_training?.ref}
								toggleOpen={() => this.setState({ show_add_client: !show_add_client })}
								addParticipant={(created_client) => this.addParticipant(created_client)}
							/>
						)}

						<SelectClientMemberMdl
							size="lg"
							multi={false}
							// first_name={first_name} first_name_ar={first_name_ar} city={city} region={region}
							// phone={phone} phone2={phone2} address={address} type={type}

							changeVal={() => {}}
							isOpen={show_select_client}
							toggleOpen={this.toggleSelectClientMdl}
							accept={(selected_clients) => {
								if (selected_clients[0]) this.addParticipant(selected_clients[0]);

								this.setState({ show_select_client: false });
							}}
							selectedItems={[]}
						/>

						<Pagination
							currentPage={current_page}
							totalPage={getCountPages(count_all_participants, selected_page_size)}
							onChangePage={(i) => this.onChangePage(i)}
						/>

						<ConfirmMdl
							toggle={() =>
								this.setState((prev) => {
									return { show_delete_msg: !prev.show_delete_msg };
								})
							}
							is_open={show_delete_msg}
							accept={() => {
								this.deleteReq();
							}}
							message="Êtes-vous sûr de vouloir supprimer?"
						/>

						<SelectUsersMdl
							is_open={select_user}
							toggle={() => this.setState({ select_user: !select_user })}
							multi={false}
							size="lg"
							onSuccess={(selected_user) => {
								if (select_organizer) this.setState({ organizer: selected_user });
								if (select_former) this.setState({ former: selected_user });
								if (select_commercial) this.setState({ commercial: selected_user });
							}}
						/>
					</CardBody>
				</Card>

				{selected_activity && open_follow_mdl ? (
					<FollowMdl
						isOpen={open_follow_mdl}
						toggleOpen={() => this.setState({ open_follow_mdl: !open_follow_mdl })}
						training={selected_training}
						activity={selected_activity}
						onSuccess={() => {
							this.getParticipants(current_page);
							this.setState({ open_follow_mdl: false });
						}}
					/>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = ({ training }) => {
	const {
		loading_add_training,
		error_add_training,
		new_training,
		selected_training,
		loading_one_training,
		error_one_training,
		loading_edit_training,
		error_edit_training,
	} = training;
	return {
		new_training,
		selected_training,
		loading_add_training,
		error_add_training,
		loading_one_training,
		error_one_training,
		loading_edit_training,
		error_edit_training,
	};
};
export default connect(mapStateToProps, {
	addTraining,
	editTraining,
	getOneTraining,
})(PreviewTrainingCmp);
