import React, { Fragment } from "react";
// import PropTypes from "prop-types";

import { Breadcrumb, Card } from "antd";
import { HomeOutlined, UserSwitchOutlined } from "@ant-design/icons";

import { Container } from "reactstrap";
import ListTrainersCmp from "../../shared/components/clients/ListTrainersCmp";
import "antd/dist/antd.css";

const ListTrainersPage = (props) => {
	return (
		<Container>
			<Breadcrumb>
				<Breadcrumb.Item href="/">
					<HomeOutlined />
				</Breadcrumb.Item>
				<Breadcrumb.Item>
					<UserSwitchOutlined />
					<span>Formateurs</span>
				</Breadcrumb.Item>
			</Breadcrumb>

			<Card className="mt-2" bodyStyle={{ padding: 5 }}>
				<ListTrainersCmp />
			</Card>
		</Container>
	);
};
export default ListTrainersPage;
