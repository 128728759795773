import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from '../../helpers/Firebase';
// import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
	AUTHENTICATE,
	loginSuccess,
	loginError,
	GET_USERS,
	getUsersSuccess,
	getUsersError,
	ADD_USER,
	addUserError,
	addUserSuccess,
	EDIT_USER,
	editUserError,
	editUserSuccess,
	GET_PROFILE,
	getProfileSuccess,
	getProfileError,
} from "../actions/authActions";
import { api } from "../../const";

//  *** LOGIN
export function* watchAuthenticate() {
	yield takeEvery(AUTHENTICATE, authenticate);
}
function* authenticate({ payload }) {
	try {
		const ret = yield call(authRequest, {
			// by_email: (new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/).test(payload.email)),
			// email: payload.email,
			// mobile: payload.mobile || '',
			login: payload.email,
			pass: payload.pass,
		});

		if (ret && ret.status == 200) {
			try {
				if (
					ret.data.roles.includes("ADMIN") ||
					ret.data.roles.includes("TECH") ||
					ret.data.roles.includes("TECH_TRAINS") ||
					ret.data.roles.includes("TECH_CMD") ||
					ret.data.roles.includes("TECH_CMD_CM")
					// ret.data.roles.includes("USER")
				)
					yield put(loginSuccess({ user: ret.data }));
				else yield put(loginError({ message: "Unauthorized access" }));
			} catch (error) {
				yield put(loginError({ message: "Unauthorized access" }));
			}
		} else yield put(loginError({ message: "Error" }));
	} catch (error) {
		yield put(loginError({ error, message: error ? error.message : "" }));
	}
}
const authRequest = async (payload) => {
	return await axios
		.post(api.users + "/login", payload)
		.then((resp) => resp)
		.catch((error) => {
			return error && error.response ? error.response : error;
		});
};

// *** GET USERS
export function* watchGetUsers() {
	yield takeEvery(GET_USERS, getUsers);
}
function* getUsers({ payload }) {
	try {
		const ret = yield call(getUsersRequest, payload);

		if (ret && ret.status == 200)
			yield put(getUsersSuccess({ users: ret.data?.users || [], count: ret.data?.count || 0 }));
		else yield put(getUsersError({ message: ret.data ? ret.data.msg : "Error" }));
	} catch (error) {
		yield put(getUsersError({ error, message: error ? error.message : "" }));
	}
}
const getUsersRequest = async (payload) => {
	return await axios
		.post(api.users + "/filter/" + payload.offset + "/" + payload.limit, payload.filter || {})
		.then((resp) => resp)
		.catch((error) => {
			return error && error.response ? error.response : error;
		});
};

// *** ADD USERS
export function* watchAddUsers() {
	yield takeEvery(ADD_USER, addUser);
}
function* addUser({ payload }) {
	try {
		const ret = yield call(addUsersRequest, payload);

		if (ret && ret.status == 201) yield put(addUserSuccess({ user: ret.data }));
		else yield put(addUserError({ message: ret.data ? ret.data.msg : "Error" }));
	} catch (error) {
		yield put(addUserError({ error, message: error ? error.message : "" }));
	}
}
const addUsersRequest = async (payload) => {
	return await axios
		.post(api.users + "/add-new/", payload)
		.then((resp) => resp)
		.catch((error) => {
			return error && error.response ? error.response : error;
		});
};

// *** EDIT USERS
export function* watchEditUsers() {
	yield takeEvery(EDIT_USER, editUser);
}
function* editUser({ payload }) {
	try {
		const ret = yield call(editUsersRequest, payload);

		if (ret && ret.status == 200) yield put(editUserSuccess({ user: ret.data }));
		else yield put(editUserError({ message: ret.data ? ret.data.msg : "Error" }));
	} catch (error) {
		yield put(editUserError({ error, message: error ? error.message : "" }));
	}
}
const editUsersRequest = async (payload) => {
	return await axios
		.patch(api.users + "/edit-one/" + payload._id, payload.data)
		.then((resp) => resp)
		.catch((error) => {
			return error && error.response ? error.response : error;
		});
};

// *** GET USER PROFILE
export function* watchGetProfile() {
	yield takeEvery(GET_PROFILE, getProfile);
}
function* getProfile({ payload }) {
	try {
		const ret = yield call(getProfileRequest, payload);

		if (ret && ret.status == 200) yield put(getProfileSuccess({ user: ret.data.user }));
		else yield put(getProfileError({ message: ret.data ? ret.data.msg : "Error" }));
	} catch (error) {
		yield put(getProfileError({ error, message: error ? error.message : "" }));
	}
}
const getProfileRequest = async (payload) => {
	return await axios
		.get(api.users + "/get-one/" + payload._id)
		.then((resp) => resp)
		.catch((error) => {
			return error && error.response ? error.response : error;
		});
};

export default function* rootSaga() {
	yield all([
		fork(watchAuthenticate),
		fork(watchGetUsers),
		fork(watchAddUsers),
		fork(watchEditUsers),
		fork(watchGetProfile),
	]);
}
