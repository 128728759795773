import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ListCtgsCmp from '../../shared/components/ctgs/list-ctgs';

const ListCtgsPage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Catégories</h3>
      </Col>

      <Col md={12}>
        <ListCtgsCmp />
      </Col>
    </Row>
  </Container>
);

export default ListCtgsPage;