// ***** ADD PURCHASE
export const ADD_PURCHASE            = 'ADD_PURCHASE';
export const ADD_PURCHASE_SUCCESS    = 'ADD_PURCHASE_SUCCESS';
export const ADD_PURCHASE_ERROR      = 'ADD_PURCHASE_ERROR';

export function addPurchase(payload) {
  return {type: ADD_PURCHASE, payload};
}
export function addPurchaseSuccess(payload) {
  return { type: ADD_PURCHASE_SUCCESS, payload };
}
export function addPurchaseError(error) {
  return { type: ADD_PURCHASE_ERROR, error };
}




// ***** GET PURCHASES
export const GET_PURCHASES            = 'GET_PURCHASES';
export const GET_PURCHASES_SUCCESS    = 'GET_PURCHASES_SUCCESS';
export const GET_PURCHASES_ERROR      = 'GET_PURCHASES_ERROR';

export function getPurchases(payload) {
  return {type: GET_PURCHASES, payload};
}
export function getPurchasesSuccess(payload) {
  return { type: GET_PURCHASES_SUCCESS, payload };
}
export function getPurchasesError(error) {
  return { type: GET_PURCHASES_ERROR, error };
}





// ***** GET ONE PURCHASE
export const GET_ONE_PURCHASE            = 'GET_ONE_PURCHASE';
export const GET_ONE_PURCHASE_SUCCESS    = 'GET_ONE_PURCHASE_SUCCESS';
export const GET_ONE_PURCHASE_ERROR      = 'GET_ONE_PURCHASE_ERROR';

export function getOnePurchase(payload) {
  return {type: GET_ONE_PURCHASE, payload};
}
export function getOnePurchaseSuccess(payload) {
  return { type: GET_ONE_PURCHASE_SUCCESS, payload };
}
export function getOnePurchaseError(error) {
  return { type: GET_ONE_PURCHASE_ERROR, error };
}



// ***** EDIT PURCHASE
export const EDIT_PURCHASE            = 'EDIT_PURCHASE';
export const EDIT_PURCHASE_SUCCESS    = 'EDIT_PURCHASE_SUCCESS';
export const EDIT_PURCHASE_ERROR      = 'EDIT_PURCHASE_ERROR';

export function editPurchase(payload) {
  return {type: EDIT_PURCHASE, payload};
}
export function editPurchaseSuccess(payload) {
  return { type: EDIT_PURCHASE_SUCCESS, payload };
}
export function editPurchaseError(error) {
  return { type: EDIT_PURCHASE_ERROR, error };
}


// ***** DELETE PURCHASE
export const DELETE_PURCHASE            = 'DELETE_PURCHASE';
export const DELETE_PURCHASE_SUCCESS    = 'DELETE_PURCHASE_SUCCESS';
export const DELETE_PURCHASE_ERROR      = 'DELETE_PURCHASE_ERROR';

export function deletePurchase(payload) {
  return {type: DELETE_PURCHASE, payload};
}
export function deletePurchaseSuccess(payload) {
  return { type: DELETE_PURCHASE_SUCCESS, payload };
}
export function deletePurchaseError(error) {
  return { type: DELETE_PURCHASE_ERROR, error };
}