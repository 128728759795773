// ***** ADD CHILD
export const ADD_CHILD            = 'ADD_CHILD';
export const ADD_CHILD_SUCCESS    = 'ADD_CHILD_SUCCESS';
export const ADD_CHILD_ERROR      = 'ADD_CHILD_ERROR';

export function addChild(payload) {
  return {type: ADD_CHILD, payload};
}
export function addChildSuccess(payload) {
  return { type: ADD_CHILD_SUCCESS, payload };
}
export function addChildError(error) {
  return { type: ADD_CHILD_ERROR, error };
}




// ***** GET CHILDS
export const GET_CHILDS            = 'GET_CHILDS';
export const GET_CHILDS_SUCCESS    = 'GET_CHILDS_SUCCESS';
export const GET_CHILDS_ERROR      = 'GET_CHILDS_ERROR';

export function getChilds(payload) {
  return {type: GET_CHILDS, payload};
}
export function getChildsSuccess(payload) {
  return { type: GET_CHILDS_SUCCESS, payload };
}
export function getChildsError(error) {
  return { type: GET_CHILDS_ERROR, error };
}





// ***** GET ONE CHILD
export const GET_ONE_CHILD            = 'GET_ONE_CHILD';
export const GET_ONE_CHILD_SUCCESS    = 'GET_ONE_CHILD_SUCCESS';
export const GET_ONE_CHILD_ERROR      = 'GET_ONE_CHILD_ERROR';

export function getOneChild(payload) {
  return {type: GET_ONE_CHILD, payload};
}
export function getOneChildSuccess(payload) {
  return { type: GET_ONE_CHILD_SUCCESS, payload };
}
export function getOneChildError(error) {
  return { type: GET_ONE_CHILD_ERROR, error };
}



// ***** EDIT CHILD
export const EDIT_CHILD            = 'EDIT_CHILD';
export const EDIT_CHILD_SUCCESS    = 'EDIT_CHILD_SUCCESS';
export const EDIT_CHILD_ERROR      = 'EDIT_CHILD_ERROR';

export function editChild(payload) {
  return {type: EDIT_CHILD, payload};
}
export function editChildSuccess(payload) {
  return { type: EDIT_CHILD_SUCCESS, payload };
}
export function editChildError(error) {
  return { type: EDIT_CHILD_ERROR, error };
}


// ***** DELETE CHILD
export const DELETE_CHILD            = 'DELETE_CHILD';
export const DELETE_CHILD_SUCCESS    = 'DELETE_CHILD_SUCCESS';
export const DELETE_CHILD_ERROR      = 'DELETE_CHILD_ERROR';

export function deleteChild(payload) {
  return {type: DELETE_CHILD, payload};
}
export function deleteChildSuccess(payload) {
  return { type: DELETE_CHILD_SUCCESS, payload };
}
export function deleteChildError(error) {
  return { type: DELETE_CHILD_ERROR, error };
}