import React, { useState } from "react";
// import PropTypes from "prop-types";

import { Breadcrumb } from "antd";
import { HomeOutlined, UserSwitchOutlined } from "@ant-design/icons";

import { Container } from "reactstrap";
import "antd/dist/antd.css";
import ChildPreviewCmp from "../../shared/components/childs/ChildPreviewCmp/ChildPreviewCmp";

const ChildPreviewPage = (props) => {
  const [child_name, setChildName] = useState("");

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/app/clients/childs">
          <UserSwitchOutlined />
          <span>Enfants</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{child_name}</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="mt-4">
        <ChildPreviewCmp
          child_id={props?.match.params.childId}
          changeName={(new_child_name) => setChildName(new_child_name)}
        />
      </div>
    </Container>
  );
};
export default ChildPreviewPage;
