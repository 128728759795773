import React, { useState } from "react";
// import PropTypes from "prop-types";

import { Breadcrumb } from "antd";
import { HomeOutlined, UserSwitchOutlined } from "@ant-design/icons";

import { Container } from "reactstrap";
import TrainerCmp from "../../shared/components/clients/trainer/TrainerCmp";
import "antd/dist/antd.css";

const TrainerPreviewPage = (props) => {
  const [trainer_name, setTrainerName] = useState("");

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item href="/">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/app/clients/trainers">
          <UserSwitchOutlined />
          <span>Formateurs</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>{trainer_name}</span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="mt-4">
        <TrainerCmp
          trainer_id={props?.match.params.trainerId}
          changeName={(new_trainer_name) => setTrainerName(new_trainer_name)}
        />
      </div>
    </Container>
  );
};
export default TrainerPreviewPage;
